import { Grid } from '@material-ui/core';
import React, { useEffect, useMemo } from 'react';
import DropDown from '../../../Selection/DropDown';
import { useMwiMeasuresStyles } from './Styles';
import { useTranslation } from 'react-i18next';
import RadioButtons from '../../../Buttons/RadioButtons';
import DialogButton from '../../../Dialogs/DialogButton';
import MwiProfessorExchange from '../../../../sdk/com/apiomat/frontend/missio/MwiProfessorExchange';
import { countries } from '../../../../value-maps/Countries';
import { subjects } from '../../../../value-maps/Subjects';
import { alphabeticalSort } from '../../../../utils/sort.util';
import { DropDownItem } from '../../../Selection/DropDownItem';
import { useFormik } from 'formik';
import { object, date, string, number } from 'yup';
import { useOfferFormContext } from '../../context/OfferFormContext';
import FormDatePicker from '../../../DatePicker/FormDatePicker';
import FormTextField from '../../InputFields/FormTextField';

export interface FacultyExchangeProps {
  readOnlyMode: boolean;
  data: any;
  isReport?: boolean;
}

const FacultyExchange = (props: FacultyExchangeProps) => {
  const { state, dispatch } = useOfferFormContext();
  const { readOnlyMode, data, isReport } = props;
  const classes = useMwiMeasuresStyles();
  const { t } = useTranslation();

  const {
    start,
    end,
    measureDescription,
    senderUniversity,
    senderLocation,
    senderCountry,
    destinationUniversity,
    destinationLocation,
    country,
    studentsCount,
    subject,
    topic,
    topicMeaning,
    isScholarshipApplicationElsewhere,
    hasReadAdvertisingStatement,
    hasReadConditions,
  } = data.measure as MwiProfessorExchange;

  const validationSchema = useMemo(
    () =>
      object({
        start: date().required(t('mandatory_field')),
        end: date().required(t('mandatory_field')),
        measureDescription: string().required(t('mandatory_field')),

        senderUniversity: isReport ? string() : string().required(t('mandatory_field')),
        senderLocation: isReport ? string() : string().required(t('mandatory_field')),
        senderCountry: isReport ? string() : string().required(t('mandatory_field')),
        destinationUniversity: isReport ? string() : string().required(t('mandatory_field')),
        destinationLocation: isReport ? string() : string().required(t('mandatory_field')),
        country: isReport ? string() : string().required(t('mandatory_field')),
        // TODO number?
        studentsCount: string().required(t('mandatory_field')),
        subject: isReport ? string() : string().required(t('mandatory_field')),
        topic: string().required(t('mandatory_field')),
        topicMeaning: string().required(t('mandatory_field')),

        isScholarshipApplicationElsewhere: isReport ? string() : string().required(t('mandatory_field')),
        hasReadAdvertisingStatement: isReport ? number() : number().equals([1], t('mandatory_field')),
        hasReadConditions: isReport ? number() : number().equals([1], t('mandatory_field')),
      }),
    [t]
  );

  const formik = useFormik({
    initialValues: {
      start,
      end,
      measureDescription,
      senderUniversity,
      senderLocation,
      senderCountry,
      destinationUniversity,
      destinationLocation,
      country,
      studentsCount,
      subject,
      topic,
      topicMeaning,
      isScholarshipApplicationElsewhere,
      hasReadAdvertisingStatement,
      hasReadConditions,
    },
    enableReinitialize: true,
    validationSchema,
    validateOnMount: true,
    onSubmit: () => {},
  });

  useEffect(() => {
    if (!readOnlyMode) {
      if (!formik.isValidating) {
        dispatch({ subFormId: 'measure', errors: formik.errors, values: formik.values });
      }
    }
  }, [formik.errors, formik.values, dispatch, formik.isValidating, readOnlyMode, state]);

  const countryItems: DropDownItem[] = useMemo(() => countries.map(el => ({ key: el.key, value: t(el.key) })).sort(alphabeticalSort), [t]);
  const subjectItems: DropDownItem[] = useMemo(() => subjects.map(el => ({ key: el.key, value: t(el.key) })).sort(alphabeticalSort), [t]);

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <FormDatePicker
            id="start"
            name="start"
            label={t('input:label:measure:planned-project-start')}
            value={formik.values.start}
            onChange={(date: Date) => formik.setFieldValue('start', date)}
            disabled={readOnlyMode}
            errorMessage={formik.errors.start}
          />
        </Grid>
        <Grid item xs={6}>
          <FormDatePicker
            id="end"
            name="end"
            label={t('input:label:measure:planned-project-end')}
            value={formik.values.end}
            minDate={formik.values.start}
            onChange={(date: Date) => formik.setFieldValue('end', date)}
            disabled={readOnlyMode}
            errorMessage={formik.errors.end}
          />
        </Grid>
        <Grid item xs={12}>
          <FormTextField
            id="measureDescription"
            name="measureDescription"
            disabled={readOnlyMode}
            labelKey="input:label:measure:measure-description"
            value={formik.values.measureDescription}
            multiline
            rows={3}
            onChange={formik.handleChange}
            errorMessage={formik.errors.measureDescription}
          />
        </Grid>
        {!isReport && (
          <>
            <Grid item xs={12}>
              <FormTextField
                id="senderUniversity"
                name="senderUniversity"
                disabled={readOnlyMode}
                labelKey="input:label:measure:mwi:faculty-exchange:sending-university-or-institute"
                value={formik.values.senderUniversity}
                multiline
                rows={3}
                onChange={formik.handleChange}
                errorMessage={formik.errors.senderUniversity}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                id="senderLocation"
                name="senderLocation"
                disabled={readOnlyMode}
                labelKey="info:measure:mwi:faculty-exchange:address-of-sending-university-or-institute"
                descriptionKey="input:label:measure:mwi:faculty-exchange:address-of-sending-university-or-institute"
                value={formik.values.senderLocation}
                onChange={formik.handleChange}
                errorMessage={formik.errors.senderLocation}
              />
            </Grid>
            <Grid item xs={12}>
              <DropDown
                labelKey="input:label:measure:mwi:faculty-exchange:county-of-sending-university-or-institute"
                disabled={readOnlyMode}
                selectedItemKey={formik.values.senderCountry}
                items={countryItems}
                onSelectedItemChange={(key: string) => formik.setFieldValue('senderCountry', key)}
                errorMessage={formik.errors.senderCountry}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                id="destinationUniversity"
                name="destinationUniversity"
                disabled={readOnlyMode}
                labelKey="input:label:measure:mwi:faculty-exchange:target-university-or-institute"
                value={formik.values.destinationUniversity}
                onChange={formik.handleChange}
                errorMessage={formik.errors.destinationUniversity}
              />
            </Grid>
            <Grid item xs={6}>
              <FormTextField
                id="destinationLocation"
                name="destinationLocation"
                disabled={readOnlyMode}
                labelKey="input:label:measure:mwi:faculty-exchange:target-location"
                value={formik.values.destinationLocation}
                onChange={formik.handleChange}
                errorMessage={formik.errors.destinationLocation}
              />
            </Grid>
            <Grid item xs={6}>
              <FormTextField
                id="country"
                name="country"
                disabled={readOnlyMode}
                labelKey="input:label:measure:mwi:faculty-exchange:country-exchange"
                value={formik.values.country}
                onChange={formik.handleChange}
                errorMessage={formik.errors.country}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <FormTextField
            type="number"
            id="studentsCount"
            name="studentsCount"
            disabled={readOnlyMode}
            labelKey="input:label:measure:mwi:faculty-exchange:number-of-students"
            value={formik.values.studentsCount}
            onChange={formik.handleChange}
            errorMessage={formik.errors.studentsCount}
          />
        </Grid>
        {!isReport && (
          <Grid item xs={12}>
            <DropDown
              labelKey="info:measure:mwi:faculty-exchange:teaching-profession"
              helperTextKey="input:label:measure:mwi:faculty-exchange:teaching-profession"
              disabled={readOnlyMode}
              selectedItemKey={formik.values.subject}
              items={subjectItems}
              onSelectedItemChange={(key: string) => formik.setFieldValue('subject', key)}
              errorMessage={formik.errors.subject}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <FormTextField
            id="topic"
            name="topic"
            disabled={readOnlyMode}
            labelKey="info:measure:mwi:faculty-exchange:teaching-topic"
            descriptionKey="input:label:measure:mwi:faculty-exchange:teaching-topic"
            multiline
            rows={3}
            value={formik.values.topic}
            onChange={formik.handleChange}
            errorMessage={formik.errors.topic}
          />
        </Grid>
        <Grid item xs={12}>
          <FormTextField
            id="topicMeaning"
            name="topicMeaning"
            className={classes.textField}
            disabled={readOnlyMode}
            labelKey="info:measure:mwi:faculty-exchange:meaning-of-apprenticeship"
            descriptionKey="input:label:measure:mwi:faculty-exchange:meaning-of-apprenticeship"
            multiline
            rows={3}
            value={formik.values.topicMeaning}
            onChange={formik.handleChange}
            errorMessage={formik.errors.topicMeaning}
          />
        </Grid>
        {!isReport && (
          <>
            <div className={classes.questionContainer}>
              <div className={classes.questionScholarship}>
                <Grid item xs={3}>
                  <RadioButtons
                    title={t('question:label:measure:mwi:library:scholarship-application')}
                    value={formik.values.isScholarshipApplicationElsewhere}
                    disabled={readOnlyMode}
                    onSelectedValueChanged={(value: number) => formik.setFieldValue('isScholarshipApplicationElsewhere', value)}
                    errorMessage={formik.errors.isScholarshipApplicationElsewhere}
                  />
                </Grid>
              </div>
              <div className={classes.questionOne}>
                <Grid item xs={3}>
                  <RadioButtons
                    title={t('question:label:mwi:advertisement')}
                    disabled={readOnlyMode}
                    value={formik.values.hasReadAdvertisingStatement}
                    onSelectedValueChanged={(value: number) => formik.setFieldValue('hasReadAdvertisingStatement', value)}
                    errorMessage={formik.errors.hasReadAdvertisingStatement}
                  />
                </Grid>
                <DialogButton
                  className={classes.dialogButton}
                  textId={'button:label:mwi:measure:faculty-exchange:advertisement'}
                  title={'button:label:mwi:advertisement'}
                />
              </div>
              <div className={classes.questionTwo}>
                <Grid item xs={3}>
                  <RadioButtons
                    title={t('question:label:mwi:conditions')}
                    disabled={readOnlyMode}
                    value={formik.values.hasReadConditions}
                    onSelectedValueChanged={(value: number) => formik.setFieldValue('hasReadConditions', value)}
                    errorMessage={formik.errors.hasReadConditions}
                  />
                </Grid>
                <DialogButton
                  className={classes.dialogButton}
                  textId={'button:label:mwi:measure:faculty-exchange:conditions'}
                  title={'button:label:mwi:conditions'}
                />
              </div>
            </div>
          </>
        )}
      </Grid>
    </div>
  );
};

export default FacultyExchange;
