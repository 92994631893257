import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { DropDownItem } from './DropDownItem';
import SimpleDropdown from './SimpleDropDown';

export interface DropDownProps {
  labelKey?: string;
  helperTextKey?: string;
  selectedItemKey: string;
  disabled?: boolean;
  items: DropDownItem[];
  highlightedItemKeys?: string[];
  onSelectedItemChange: (key: string) => void;
  errorMessage?: string;
  outlinedTitle?: boolean;
}

const DropDown: FunctionComponent<DropDownProps> = ({
  labelKey,
  helperTextKey,
  selectedItemKey,
  disabled,
  items,
  onSelectedItemChange,
  highlightedItemKeys,
  errorMessage,
  outlinedTitle,
}) => {
  const { t } = useTranslation();

  return (
    <SimpleDropdown
      title={t(labelKey)}
      helperText={t(helperTextKey)}
      selectedItemKey={selectedItemKey}
      disabled={disabled}
      items={items}
      onSelectedItemChange={onSelectedItemChange}
      highlightedItemKeys={highlightedItemKeys}
      errorMessage={errorMessage}
      outlinedTitle={outlinedTitle}
    />
  );
};

export default DropDown;
