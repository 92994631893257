import React, { useEffect, useState } from 'react';
import { makeStyles, Theme, Typography, Tabs, Tab, Grid, Paper, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../store/index';
import LoadingIndicator from '../../components/Loading/LoadingIndicator';
import { misMeasureDropDownItems } from '../../value-maps/MisMeasureDropDownItems';
import { mwiMeasureDropDownItems } from '../../value-maps/MwiMeasureDropDownItems';
import { otherMeasureDropDownItems } from '../../value-maps/OtherMeasureDropDownItems';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import { Link } from 'react-router-dom';
import { formTemplateActions } from '../../store/formTemplate';
import { getFormTemplatesByMeasure } from '../../utils/form-templates.utils';

const useStyles = makeStyles((theme: Theme) => ({
  tabs: {
    margin: theme.spacing(2, 0),
  },
  measureItem: {
    width: 300,
    minHeight: 250,
    padding: theme.spacing(4),
  },
}));

export default () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { formTemplates, loading } = useSelector((state: AppState) => state.formTemplate);

  useEffect(() => {
    dispatch(formTemplateActions.loadFormTemplates());
  }, [dispatch]);

  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);

  const tabs = [
    { key: 'mis', label: 'form-templates:tab:mis', measures: misMeasureDropDownItems },
    { key: 'mwi', label: 'form-templates:tab:mwi', measures: mwiMeasureDropDownItems },
    { key: 'other', label: 'form-templates:tab:other', measures: otherMeasureDropDownItems },
  ];

  const filteredMeasures = tabs[activeTabIndex].measures.filter(
    measure => getFormTemplatesByMeasure(measure.key, formTemplates).length > 0
  );

  return loading === 'pending' ? (
    <LoadingIndicator />
  ) : (
    <>
      <Typography variant="h5">{t('form-templates:page-title')}</Typography>
      <Tabs value={activeTabIndex} className={classes.tabs} onChange={(_event, tabIndex) => setActiveTabIndex(tabIndex)}>
        {tabs.map(tab => (
          <Tab fullWidth key={tab.key} disableRipple label={t(tab.label)} />
        ))}
      </Tabs>
      <Grid container spacing={4}>
        {filteredMeasures.map(measure => (
          <Grid item>
            <Paper square className={classes.measureItem}>
              <Grid container direction="column" spacing={2} alignItems="center" justify="space-between">
                <Grid item>
                  <DescriptionOutlinedIcon color="primary" />
                </Grid>
                <Grid item>
                  <Typography color="primary" align="center">
                    {t(measure.value as string)}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography align="center" variant="caption">
                    {getFormTemplatesByMeasure(measure.key, formTemplates)
                      .map(el => el.name)
                      .join(', ')}
                  </Typography>
                </Grid>
                <Grid item>
                  <Button component={Link} to={`/form-templates/${measure.key}`} variant="contained" color="primary" size="medium">
                    {t('form-templates:button:use-template')}
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </>
  );
};
