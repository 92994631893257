import React from 'react';
import { Button, Theme, makeStyles, Dialog, DialogTitle, Typography, DialogContent } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import Delete from '@material-ui/icons/Delete';
import { PrimaryButton } from './Buttons';

const useStyles = makeStyles((theme: Theme) => ({
  dialogTitle: {
    textAlign: 'center',
    backgroundColor: '#f5f5f5',
    borderBottom: '1px solid #dbdbdb',
  },
  buttonsContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
  },
  cancelButtonContainer: {
    display: 'flex',
    flex: 1,
    padding: 5,
    borderTop: '1px solid #dbdbdb',
  },
  cancelButton: {
    padding: 5,
  },
  deleteButton: {
    padding: 5,
    backgroundColor: theme.palette.error.main,
  },
  deleteButtonContainer: {
    display: 'root-block',
    padding: 5,
    flex: 1,
    borderTop: '1px solid #dbdbdb',
  },
  button: {
    backgroundColor: theme.palette.error.main,
  },
  iconButton: {
    color: theme.palette.error.main,
  },
  iconButtonContained: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
}));

export interface DeleteConfirmationDialogProps {
  onDeleteConfirmed: () => void;
  onCancelClick: () => void;
  isOpen: boolean;
}

export const DeleteConfirmationDialog = (props: DeleteConfirmationDialogProps) => {
  const { isOpen, onCancelClick, onDeleteConfirmed } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div>
      <Dialog open={isOpen} fullWidth maxWidth="xs">
        <DialogTitle className={classes.dialogTitle}>
          <Typography component="span" variant="h5">
            {t('button:label:delete')}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">{t('delete:confirmation-question')}</Typography>
        </DialogContent>
        <div className={classes.buttonsContainer}>
          <div className={classes.cancelButtonContainer}>
            <PrimaryButton fullWidth className={classes.cancelButton} onClick={onCancelClick}>
              {t('button:label:cancel')}
            </PrimaryButton>
          </div>
          <div className={classes.deleteButtonContainer}>
            <PrimaryButton fullWidth className={classes.deleteButton} onClick={onDeleteConfirmed}>
              {t('button:label:delete')}
            </PrimaryButton>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export interface DeleteButtonProps {
  onDeleteConfirmed: () => void;
  type: 'icon' | 'text';
  variant?: 'text' | 'outlined' | 'contained';
}

export const DeleteButton = (props: DeleteButtonProps) => {
  const { type, variant, onDeleteConfirmed } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleOnConfirmCLicked = () => {
    onDeleteConfirmed();
    setIsOpen(false);
  };

  if (type === 'text') {
    return (
      <div>
        <PrimaryButton className={classes.button} onClick={() => setIsOpen(true)}>
          {t('button:label:delete')}
          <Delete />
        </PrimaryButton>
        <DeleteConfirmationDialog onDeleteConfirmed={handleOnConfirmCLicked} isOpen={isOpen} onCancelClick={() => setIsOpen(false)} />
      </div>
    );
  } else {
    return (
      <Button variant={variant} className={variant === 'contained' ? classes.iconButtonContained : classes.iconButton}>
        <Delete onClick={() => setIsOpen(true)} />
        <DeleteConfirmationDialog onDeleteConfirmed={handleOnConfirmCLicked} isOpen={isOpen} onCancelClick={() => setIsOpen(false)} />
      </Button>
    );
  }
};
