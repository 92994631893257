import { Module, Services, ReadCallback } from 'i18next';

interface BackendOptions {
  resources: any;
  initBackend: () => void;
  getLangFromBackend: (language: string) => Promise<any[]>;
}

class I18nBackend implements Module {
  type: 'backend' | 'logger' | 'languageDetector' | 'postProcessor' | 'i18nFormat' | '3rdParty';
  options: BackendOptions;

  constructor(_services: Services, options: BackendOptions) {
    this.type = 'backend';
    this.options = options;
  }

  init() {
    this.options.initBackend();
  }

  read(language: string, namespace: string, callback: ReadCallback) {
    this.options
      .getLangFromBackend(language)
      .then((translates: any) => {
        const result = Object.assign({}, ...translates, this.options.resources[language][namespace]);
        callback(null, result);
      })
      .catch((error: any) => {
        console.error(`Could not obtain Translation object from Backend for language "${language}":`, error);
        callback(null, this.options.resources[language][namespace]);
      });
  }
}

export default I18nBackend;
