import React from 'react';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { makeStyles, Theme } from '@material-ui/core';

const actualDeviationStatusStyles = makeStyles((theme: Theme) => ({
  errrorIcon: {
    color: theme.palette.error.main,
    '&:hover': {
      color: theme.palette.error.dark,
    },
  },
  checkIcon: {
    color: theme.palette.success.main,
    '&:hover': {
      color: theme.palette.success.dark,
    },
  },
}));

export const ActualDeviationStatus = props => {
  const { hasDeviations } = props;
  const classes = actualDeviationStatusStyles();

  return (
    <div>
      {hasDeviations ? <ErrorIcon className={classes.errrorIcon} /> : <CheckCircleIcon className={classes.checkIcon}></CheckCircleIcon>}
    </div>
  );
};
