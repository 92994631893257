import React, { useEffect, useState } from 'react';
import { ListItemIcon, makeStyles, Theme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../store/index';
import LoadingIndicator from '../../components/Loading/LoadingIndicator';
import moment from 'moment';
import GetAppIcon from '@material-ui/icons/GetApp';
import { formTemplateActions } from '../../store/formTemplate';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import DefaultDialog from '../../components/Dialogs/DefaultDialog';
import TemplateForm from '../../components/Forms/TemplateForm';
import FormTemplate from '../../sdk/com/apiomat/frontend/missio/FormTemplate';
import {
  getAttachmentTypeName,
  getMisMeasureNames,
  getMwiMeasureNames,
  getOtherMeasureNames
} from '../../utils/form-templates.utils';
import { mwiMeasureDropDownItems } from '../../value-maps/MwiMeasureDropDownItems';
import { misMeasureDropDownItems } from '../../value-maps/MisMeasureDropDownItems';
import FullScreenLoadingIndicator from '../../components/Loading/FullScreenLoadingIndicator';
import { onDownloadFile } from '../../utils/file.utils';
import { otherMeasureDropDownItems } from '../../value-maps/OtherMeasureDropDownItems';
import { OtherMeasureTypes } from '../../enums/MeasureTypes';
import MUIDataTable, { MUIDataTableColumnDef } from 'mui-datatables';
import Button from '@mui/material/Button';
import ActionMenu from '../../components/ActionMenu/ActionMenu';
import { Flaky } from '@mui/icons-material';
import { MenuItem } from '@mui/material';
import Delete from '@material-ui/icons/Delete';
import { DeleteConfirmationDialog } from '../../components/Buttons/DeleteButton';

interface TableAllTemplates {
  name: string;
  attachmentType: string;
  lastModified: Date;
  misMeasureNames: string;
  mwiMeasureNames: string;
  otherMeasureNames: string;
  actions: FormTemplate;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    '& div.MuiToolbar-root': {
      paddingRight: theme.spacing(2),
    },
    '& > div div table thead tr th.MuiTableCell-head': {
      '& > span > button': {
        padding: 0,
        textTransform: 'none',
        textAlign: 'left'
      }
    }
  },
  actionButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  }
}));

export default () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { formTemplates, loading, loadingPost } = useSelector((state: AppState) => state.formTemplate);

  useEffect(() => {
    dispatch(formTemplateActions.loadFormTemplates());
  }, [ dispatch ]);

  const [ activeTemplate, setActiveTemplate ] = useState<FormTemplate>(null);
  const [ isDeleteDialogOpen, setIsDeleteDialogOpen ] = useState<boolean>(false);
  const [ templateToDelete, setTemplateToDelete ] = useState<FormTemplate>(null);

  const onDeleteTemplate = (item: FormTemplate) => {
    setTemplateToDelete(item);
    setIsDeleteDialogOpen(true);
  };

  const handleOnDeleteConfirmed = () => {
    dispatch(formTemplateActions.deleteFormTemplate(templateToDelete));
    setTemplateToDelete(null);
    setIsDeleteDialogOpen(false);
  };

  const columns: Array<MUIDataTableColumnDef> = [
    {
      name: 'name',
      label: t('form-templates:table:name'),
      options: {
        sort: true
      }
    },
    {
      name: 'attachmentType',
      label: t('form-templates:table:attachment-type'),
      options: {
        sort: true
      }
    },
    {
      name: 'lastModified',
      label: t('form-templates:table:last-modified'),
      options: {
        customBodyRender: (value) => moment(value).format('DD.MM.YYYY'),
        sort: true,
      }
    },
    {
      name: 'misMeasureNames',
      label: t('form-templates:table:mis'),
      options: {
        sort: true
      }
    },
    {
      name: 'mwiMeasureNames',
      label: t('form-templates:table:mwi')
    },
    {
      name: 'otherMeasureNames',
      label: t('form-templates:table:other'),
      options: {
        sort: false
      }
    },
    {
      name: 'actions',
      label: ' ',
      options: {
        customBodyRender: (value, tableMeta) => {
          const id = `action-menu-${ tableMeta.rowIndex }`;

          return (
            <div className={ classes.actionButton }>
              <ActionMenu menuId={ id } icon={ <Flaky/> }>
                <MenuItem onClick={ () => onDownloadFile(value, 'file') }>
                  <ListItemIcon>
                    <GetAppIcon/>
                  </ListItemIcon>
                  { t('form-templates:button:download') }
                </MenuItem>
                <MenuItem onClick={ () => setActiveTemplate(value) }>
                  <ListItemIcon>
                    <EditIcon/>
                  </ListItemIcon>
                  { t('form-templates:button:edit') }
                </MenuItem>
                <MenuItem onClick={ () => onDeleteTemplate(value) }>
                  <ListItemIcon>
                    <Delete/>
                  </ListItemIcon>
                  { t('form-templates:button:delete') }
                </MenuItem>
              </ActionMenu>
            </div>
          );
        },
        filter: false,
        searchable: false,
        sort: false,
        viewColumns: false
      }
    }
  ];

  const data: Array<TableAllTemplates> = formTemplates.map(template => {
    return {
      name: template.name,
      attachmentType: t<string>(getAttachmentTypeName(template)),
      lastModified: template.lastModifiedAt,
      misMeasureNames: getMisMeasureNames(template)
        .map(measureName => t((misMeasureDropDownItems.find(el => el.key === measureName)?.value as string) || measureName))
        .join(', '),
      mwiMeasureNames: getMwiMeasureNames(template)
        .map(measureName => t((mwiMeasureDropDownItems.find(el => el.key === measureName)?.value as string) || ''))
        .join(', '),
      otherMeasureNames: getOtherMeasureNames(template)
        .filter(item => item !== OtherMeasureTypes.all)
        .map(measureName => t((otherMeasureDropDownItems.find(el => el.key === measureName)?.value as string) || ''))
        .join(', '),
      actions: template
    };
  });

  const AddTemplateHeaderButton = () => (
    <Button variant="outlined"
            color="primary"
            size="small"
            startIcon={<AddIcon/>}
            onClick={ () => setActiveTemplate(new FormTemplate()) }
    >
      { t('form-templates:button:add-template') }
    </Button>
  );

  return loading === 'pending' ? (
    <LoadingIndicator/>
  ) : (
    <>
      <div className={ classes.container }>
        <MUIDataTable
          title={ t('form-templates:page-title-admin') }
          data={ data }
          columns={ columns }
          options={ {
            selectableRows: 'none',
            print: false,
            download: false,
            filter: false,
            sort: true,
            sortOrder: {
              name: 'lastModified',
              direction: 'desc'
            },
            customToolbar: () => AddTemplateHeaderButton(),
            viewColumns: false,
            search: false
          } }
        />
      </div>
      <DeleteConfirmationDialog onDeleteConfirmed={ handleOnDeleteConfirmed }
                                isOpen={ isDeleteDialogOpen }
                                onCancelClick={ () => setIsDeleteDialogOpen(false) }/>
      <DefaultDialog
        title={ t(Boolean(activeTemplate?.ID) ? 'form-templates:dialog:edit-template' : 'form-templates:dialog:create-template') }
        isOpen={ Boolean(activeTemplate) }
        maxWidth="md"
        children={ <TemplateForm template={ activeTemplate } onClose={ () => setActiveTemplate(null) }/> }
        actionComponent={ null }
      />
      { loadingPost === 'pending' && <FullScreenLoadingIndicator/> }
    </>
  );
};
