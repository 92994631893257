import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { all, takeLatest, put, select } from 'redux-saga/effects';
import Offers from '../sdk/com/apiomat/frontend/missio/Offers';
import { notificationActions } from './notification';
import { AppState } from '.';
import { StateType } from '../enums/StateType';
import i18n from '../utils/i18n';
import { saveOffer } from '../utils/offer.utils';
import { createTransform } from 'redux-persist';
import {
  offerToJson,
  offerFromJson,
  reportToJson,
  reportFromJson,
  auditToJson,
  evaluationToJson,
  fromJson,
  surveyToJson,
} from '../utils/transforms';
import { push } from 'connected-react-router';
import Reports from '../sdk/com/apiomat/frontend/missio/Reports';
import { saveReport } from '../utils/report.utils';
import OfferEvaluation from '../sdk/com/apiomat/frontend/missio/OfferEvaluation';
import Audit from '../sdk/com/apiomat/frontend/missio/Audit';
import Survey from '../sdk/com/apiomat/frontend/missio/Survey';
import ContactBook from '../sdk/com/apiomat/frontend/missio/ContactBook';

/** STATE */
export interface OfflineState {
  isOnline: boolean;
  queuedItems: OfflineOffer[];
  queuedReports: OfflineReport[];
  queuedEvaluations: OfflineEvaluation[];
  queuedAudits: OfflineAudit[];
  queuedSurveys: OfflineSurvey[];
  queuedContactBooks: OfflineContactBook[];
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
}

const initialState: OfflineState = {
  isOnline: true,
  queuedItems: [],
  queuedReports: [],
  queuedEvaluations: [],
  queuedAudits: [],
  queuedSurveys: [],
  queuedContactBooks: [],
  loading: 'idle',
};

/** TYPES */
export interface OfflineOffer {
  offer: Offers;
  newStatus?: StateType;
  mutationType?: 'create' | 'save' | 'delete';
}
export interface OfflineReport {
  report: Reports;
  newStatus?: StateType;
  mutationType?: 'create' | 'save' | 'delete';
}

export interface OfflineEvaluation {
  evaluation: OfferEvaluation;
  newStatus?: StateType;
  mutationType?: 'create' | 'save' | 'delete';
}

export interface OfflineAudit {
  audit: Audit;
  newStatus?: StateType;
  mutationType?: 'create' | 'save' | 'delete';
}

export interface OfflineSurvey {
  survey: Survey;
  newStatus?: StateType;
  mutationType?: 'create' | 'save' | 'delete';
}

export interface OfflineContactBook {
  contactBook: ContactBook;
  mutationType?: 'create' | 'save' | 'delete';
}

/** SLICE */
const offlineSlice = createSlice({
  name: 'offline',
  initialState,
  reducers: {
    changeOnlineState: (state, action: PayloadAction<boolean>) => {
      state.isOnline = action.payload;
    },
    addItemIntoQueue: (state, action: PayloadAction<OfflineOffer>) => {
      const newItem = action.payload;
      const foundById = state.queuedItems.find(item => item.offer.ID === newItem.offer.ID);
      if (foundById && foundById.mutationType === 'create') {
        if (newItem.mutationType === 'save') {
          newItem.mutationType = 'create';
        } else if (newItem.mutationType === 'delete') {
          state.queuedItems = [...state.queuedItems.filter(item => item.offer.ID !== newItem.offer.ID)];
          return;
        }
      }
      state.queuedItems = [...state.queuedItems.filter(item => item.offer.ID !== newItem.offer.ID), newItem];
    },
    addReportIntoQueue: (state, action: PayloadAction<OfflineReport>) => {
      const newItem = action.payload;
      const foundById = state.queuedReports.find(item => item.report.ID === newItem.report.ID);
      if (foundById && foundById.mutationType === 'create') {
        if (newItem.mutationType === 'save') {
          newItem.mutationType = 'create';
        } else if (newItem.mutationType === 'delete') {
          state.queuedReports = [...state.queuedReports.filter(item => item.report.ID !== newItem.report.ID)];
          return;
        }
      }
      state.queuedReports = [...state.queuedReports.filter(item => item.report.ID !== newItem.report.ID), newItem];
    },
    addEvaluationIntoQueue: (state, action: PayloadAction<OfflineEvaluation>) => {
      const newItem = action.payload;
      const foundById = state.queuedEvaluations.find(item => item.evaluation.ID === newItem.evaluation.ID);
      if (foundById && foundById.mutationType === 'create') {
        if (newItem.mutationType === 'save') {
          newItem.mutationType = 'create';
        } else if (newItem.mutationType === 'delete') {
          state.queuedEvaluations = [...state.queuedEvaluations.filter(item => item.evaluation.ID !== newItem.evaluation.ID)];
          return;
        }
      }
      state.queuedEvaluations = [...state.queuedEvaluations.filter(item => item.evaluation.ID !== newItem.evaluation.ID), newItem];
    },
    addAuditIntoQueue: (state, action: PayloadAction<OfflineAudit>) => {
      const newItem = action.payload;
      const foundById = state.queuedAudits.find(item => item.audit.ID === newItem.audit.ID);
      if (foundById && foundById.mutationType === 'create') {
        if (newItem.mutationType === 'save') {
          newItem.mutationType = 'create';
        } else if (newItem.mutationType === 'delete') {
          state.queuedAudits = [...state.queuedAudits.filter(item => item.audit.ID !== newItem.audit.ID)];
          return;
        }
      }
      state.queuedAudits = [...state.queuedAudits.filter(item => item.audit.ID !== newItem.audit.ID), newItem];
    },
    addSurveyIntoQueue: (state, action: PayloadAction<OfflineSurvey>) => {
      const newItem = action.payload;
      const foundById = state.queuedSurveys.find(item => item.survey.ID === newItem.survey.ID);
      if (foundById && foundById.mutationType === 'create') {
        if (newItem.mutationType === 'save') {
          newItem.mutationType = 'create';
        } else if (newItem.mutationType === 'delete') {
          state.queuedSurveys = [...state.queuedSurveys.filter(item => item.survey.ID !== newItem.survey.ID)];
          return;
        }
      }
      state.queuedSurveys = [...state.queuedSurveys.filter(item => item.survey.ID !== newItem.survey.ID), newItem];
    },
    addContactBookIntoQueue: (state, action: PayloadAction<OfflineContactBook>) => {
      const newContactBook = action.payload;
      const foundById = state.queuedContactBooks.find(item => item.contactBook.ID === newContactBook.contactBook.ID);
      if (foundById && foundById.mutationType === 'create') {
        if (newContactBook.mutationType === 'save') {
          newContactBook.mutationType = 'create';
        } else if (newContactBook.mutationType === 'delete') {
          state.queuedContactBooks = [...state.queuedContactBooks.filter(item => item.contactBook.ID !== newContactBook.contactBook.ID)];
          return;
        }
      }
      state.queuedContactBooks = [
        ...state.queuedContactBooks.filter(item => item.contactBook.ID !== newContactBook.contactBook.ID),
        newContactBook,
      ];
    },
    uploadQueue: state => {
      state.loading = 'pending';
    },
    uploadQueueSuccess: state => {
      state.loading = 'succeeded';
      state.queuedItems = [];
    },
    uploadQueueFailure: state => {
      state.loading = 'failed';
    },
  },
});

export const offlineActions = offlineSlice.actions;
export const offlineReducer = offlineSlice.reducer;

/** SAGAS */
function* onChangeOnlineState(action: PayloadAction<boolean>) {
  const isOnline = action.payload;
  const queuedItems = yield select((state: AppState) => state.offline.queuedItems);
  const queuedReports = yield select((state: AppState) => state.offline.queuedReports);

  if (isOnline) {
    if (queuedItems.length > 0 || queuedReports.length > 0) {
      yield put(
        notificationActions.enqueueSnackbar({
          key: 'offline-handling',
          message: `You have ${queuedItems.length + queuedReports.length} unsaved items, do you want to sync now?`,
          syncActions: true,
          options: {
            variant: 'info',
            autoHideDuration: 10000000,
          },
        })
      );
    }
  } else {
    yield put(notificationActions.closeSnackbar({ key: 'offline-handling', dismissAll: true }));
  }
}

function* onUploadQueue() {
  yield put(notificationActions.closeSnackbar({ key: 'offline-handling', dismissAll: true }));
  yield put(push('/tasks/my'));
  const queuedItems: OfflineOffer[] = yield select((state: AppState) => state.offline.queuedItems);
  const queuedReports: OfflineReport[] = yield select((state: AppState) => state.offline.queuedReports);

  try {
    yield all(
      queuedItems.map(item => {
        if (item.mutationType === 'create') {
          (item.offer as any).dao.id = undefined;
        }
        return item.mutationType === 'delete' ? item.offer.delete() : saveOffer(item.offer, item.newStatus, true);
      })
    );
    yield all(
      queuedReports.map(item => {
        if (item.mutationType === 'create') {
          (item.report as any).dao.id = undefined;
        }
        return item.mutationType === 'delete' ? item.report.delete() : saveReport(item.report, item.newStatus);
      })
    );
    yield put(offlineActions.uploadQueueSuccess());
  } catch (error) {
    yield put(notificationActions.showError(i18n.t('use-cases:new-assignment:root:saving-failed-notification:body')));
    yield put(offlineActions.uploadQueueFailure());
  }
}

export function* offlineSaga() {
  yield all([takeLatest(offlineActions.changeOnlineState, onChangeOnlineState), takeLatest(offlineActions.uploadQueue, onUploadQueue)]);
}

/** TRANSFORMS */
export const offlineTransform = createTransform(
  (state: OfflineState) => {
    return {
      ...state,
      queuedItems: state.queuedItems.map(el => ({ ...el, offer: offerToJson(el.offer) })),
      queuedReports: state.queuedReports.map(el => ({ ...el, report: reportToJson(el.report) })),
      queuedEvaluations: state.queuedEvaluations.map(el => ({ ...el, evaluation: evaluationToJson(el.evaluation) })),
      queuedAudits: state.queuedAudits.map(el => ({ ...el, audit: auditToJson(el.audit) })),
      queuedSurveys: state.queuedSurveys.map(el => ({ ...el, survey: surveyToJson(el.survey) })),
      queuedContactBooks: state.queuedContactBooks?.map(el => ({ ...el, contactBook: el.contactBook.toJson() })),
    };
  },
  json => {
    return {
      ...json,
      queuedItems: json.queuedItems && json.queuedItems.map(el => ({ ...el, offer: offerFromJson(el.offer) })),
      queuedReports: json.queuedReports && json.queuedReports.map(el => ({ ...el, report: reportFromJson(el.report) })),
      queuedEvaluations:
        json.queuedEvaluations && json.queuedEvaluations.map(el => ({ ...el, evaluation: fromJson(el.evaluation, OfferEvaluation) })),
      queuedAudits: json.queuedAudits && json.queuedAudits.map(el => ({ ...el, audit: fromJson(el.audit, Audit) })),
      queuedSurveys: json.queuedSurveys && json.queuedSurveys.map(el => ({ ...el, survey: fromJson(el.survey, Survey) })),
      queuedContactBooks:
        json.queuedContactBooks && json.queuedContactBooks.map(el => ({ ...el, contactBook: fromJson(el.contactBook, ContactBook) })),
      loading: 'idle',
    };
  },
  { whitelist: ['offline'] }
);
