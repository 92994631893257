import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SnackbarKey, OptionsObject } from 'notistack';
import i18n from '../utils/i18n';
import { createExtractErrorMessageFn } from "../utils/error.utils";

/** TYPES */
export interface NotificationObject {
  key: SnackbarKey;
  message: string;
  syncActions?: boolean;
  options?: OptionsObject;
  dismissed?: boolean;
}

/** STATE */
export interface NotificationState {
  notifications: NotificationObject[];
}

const initialState: NotificationState = {
  notifications: [],
};

const t = i18n.t

/** SLICE */
const enqueueSnackbar = (state: NotificationState, action: PayloadAction<NotificationObject>) => {
  state.notifications = [
    ...state.notifications,
    {
      key: action.payload.key,
      ...action.payload,
    },
  ];
};

const getMessageFromError = createExtractErrorMessageFn(t);

const notificationSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    showSuccessMessage: (state, action: PayloadAction<string>) => {
      const notification: NotificationObject = {
        key: new Date().getTime() + Math.random(),
        message: action.payload,
        options: { variant: 'success' },
      };

      enqueueSnackbar(state, { payload: notification, type: action.type });
    },
    showError: (state, action: PayloadAction<Error | string>) => {
      const notification: NotificationObject = {
        key: new Date().getTime() + Math.random(),
        message: getMessageFromError(action.payload),
        options: { variant: 'error' },
      };

      console.error('[Notification] Present error:', action.payload);

      enqueueSnackbar(state, { payload: notification, type: action.type });
    },
    enqueueSnackbar,
    closeSnackbar: (state, action: PayloadAction<{ dismissAll: boolean; key: SnackbarKey }>) => {
      state.notifications = state.notifications.map(notification =>
        action.payload.dismissAll || notification.key === action.payload.key ? { ...notification, dismissed: true } : { ...notification }
      );
    },
    removeSnackbar: (state, action: PayloadAction<SnackbarKey>) => {
      state.notifications = state.notifications.filter(notification => notification.key !== action.payload);
    },
  },
});

export const notificationActions = notificationSlice.actions;
export const notificationReducer = notificationSlice.reducer;
