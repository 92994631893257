import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StateType } from '../enums/StateType';
import OfferEvaluation from '../sdk/com/apiomat/frontend/missio/OfferEvaluation';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { AppState } from '.';
import Datastore from '../sdk/com/apiomat/frontend/Datastore';
import { notificationActions } from './notification';
import { offlineActions } from './offline';
import { push } from 'connected-react-router';
import i18n from '../utils/i18n';
import { createTransform } from 'redux-persist';
import { evaluationFromJson, evaluationToJson } from '../utils/transforms';
import { saveEvaluation } from '../utils/evaluation.utils';

export interface EvaluationState {
  evaluations: OfferEvaluation[];
  currentEvaluation: { evaluation: OfferEvaluation } | null;
  loadingEvaluations: 'idle' | 'pending' | 'succeeded' | 'failed' | 'loading-more';
  loadingCurrentEvaluation: 'idle' | 'pending' | 'succeeded' | 'failed';
}

const initialState: EvaluationState = {
  evaluations: [],
  currentEvaluation: null,
  loadingEvaluations: 'idle',
  loadingCurrentEvaluation: 'idle',
};

/** SLICE */
const evaluationSlice = createSlice({
  name: 'evaluation',
  initialState,
  reducers: {
    loadEvaluations: (state, _action: PayloadAction<string>) => {
      state.loadingEvaluations = 'pending';
    },
    loadEvaluationsSuccess: (state, action: PayloadAction<OfferEvaluation[]>) => {
      state.loadingEvaluations = 'succeeded';
      state.evaluations = action.payload;
    },
    loadEvaluationsFailure: state => {
      state.loadingEvaluations = 'failed';
    },
    loadCurrentEvaluation: (state, _action: PayloadAction<string>) => {
      state.loadingEvaluations = 'pending';
    },
    loadCurrentEvaluationSuccess: (state, action: PayloadAction<OfferEvaluation>) => {
      state.loadingEvaluations = 'succeeded';

      const evaluation = action.payload;
      state.currentEvaluation = { evaluation: evaluation };
    },
    loadCurrentEvaluationFailure: state => {
      state.loadingEvaluations = 'failed';
    },
    updateCurrentEvaluation: (state, _action: PayloadAction<OfferEvaluation>) => {
      state.currentEvaluation = { evaluation: _action.payload };
    },
    saveCurrentEvaluation: (state, _action: PayloadAction<StateType>) => {
      state.loadingCurrentEvaluation = 'pending';
    },
    saveCurrentEvaluationSuccess: (state, action: PayloadAction<OfferEvaluation>) => {
      const evaluation = action.payload;
      state.loadingCurrentEvaluation = 'succeeded';
      state.evaluations = [...state.evaluations.filter(el => el.ID !== evaluation.ID), evaluation];
    },
    saveCurrentEvaluationFailure: state => {
      state.loadingCurrentEvaluation = 'failed';
    },
  },
});

export const evaluationActions = evaluationSlice.actions;
export const evaluationReducer = evaluationSlice.reducer;

/** SAGAS */
function* onLoadEvaluations(action: PayloadAction<string>) {
  const isOnline: boolean = yield select((state: AppState) => state.offline.isOnline);
  const query = action.payload || '';

  if (isOnline === false) {
    const localEvaluations: OfferEvaluation[] = yield select((state: AppState) => state.evaluation.evaluations);
    yield put(evaluationActions.loadEvaluationsSuccess(localEvaluations || []));
    return;
  }
  try {
    const evaluations: OfferEvaluation[] = yield call(() => OfferEvaluation.getOfferEvaluations(`${query} order by lastModifiedAt DESC`));
    yield all([...evaluations.map(evaluation => call(() => evaluation.loadAttachments()))]);
    yield all([...evaluations.map(evaluation => call(() => evaluation.loadOffer()))]);

    yield put(evaluationActions.loadEvaluationsSuccess(evaluations));
  } catch (e) {
    yield put(evaluationActions.loadEvaluationsFailure());
  }
}

function* onLoadCurrentEvaluation(action: PayloadAction<any>) {
  const isOnline: boolean = yield select((state: AppState) => state.offline.isOnline);
  const id = action.payload;

  if (isOnline === false) {
    const evaluations: OfferEvaluation[] = yield select((state: AppState) => state.evaluation.evaluations);
    yield put(evaluationActions.loadCurrentEvaluationSuccess(evaluations.find(el => el.ID === id)));
    return;
  }

  try {
    const evaluations: OfferEvaluation = new OfferEvaluation();
    const href = `${Datastore.Instance.createModelHref(evaluations)}/${id}`;

    yield call(() => evaluations.load(href));
    yield call(() => evaluations.loadAttachments());
    yield call(() => evaluations.loadOffer());

    yield put(evaluationActions.loadCurrentEvaluationSuccess(evaluations));
  } catch (e) {
    yield put(notificationActions.showError('No entry found'));
    yield put(evaluationActions.loadCurrentEvaluationFailure());
  }
}

function* onSaveCurrentEvaluation(action: PayloadAction<StateType>) {
  const evaluation: OfferEvaluation = yield select((state: AppState) => state.evaluation.currentEvaluation.evaluation);
  const isOnline: boolean = yield select((state: AppState) => state.offline.isOnline);

  const isCreation = Boolean(evaluation.ID) === false;
  const newStatus = action.payload;

  if (isOnline === false) {
    yield put(offlineActions.addEvaluationIntoQueue({ evaluation, newStatus, mutationType: isCreation ? 'create' : 'save' }));
    evaluation.state.name = StateType.unsavedChanges;

    if (isCreation) {
      yield put(push(`/tasks/evaluation/new/${evaluation.ID}`));
    }

    yield put(notificationActions.showSuccessMessage(i18n.t('assignment:status:offline')));
    yield put(evaluationActions.saveCurrentEvaluationSuccess(evaluation));

    return;
  }

  try {
    const updatedEvaluation = yield call(() => saveEvaluation(evaluation, newStatus));
    yield put(evaluationActions.updateCurrentEvaluation(updatedEvaluation));

    yield put(notificationActions.showSuccessMessage(i18n.t('use-cases:new-assignment:root:saved-notification:title')));
    yield put(evaluationActions.saveCurrentEvaluationSuccess(updatedEvaluation));
    yield put(push(`/tasks/my`));
  } catch (e) {
    evaluation.state.name = StateType.unsavedChanges;
    yield put(evaluationActions.updateCurrentEvaluation(evaluation));
    yield put(notificationActions.showError(i18n.t('use-cases:new-assignment:root:saving-failed-notification:body')));

    yield put(evaluationActions.saveCurrentEvaluationFailure());
  }
}

export function* evaluationSaga() {
  yield all([takeLatest(evaluationActions.loadEvaluations, onLoadEvaluations)]);
  yield all([takeLatest(evaluationActions.loadCurrentEvaluation, onLoadCurrentEvaluation)]);
  yield all([takeLatest(evaluationActions.saveCurrentEvaluation, onSaveCurrentEvaluation)]);
}

/** TRANSFORMS */
export const evaluationTransform = createTransform(
  (state: EvaluationState) => {
    return {
      ...state,
      evaluations: state.evaluations.map(evaluationToJson),
      currentEvaluation: state.currentEvaluation?.evaluation ? evaluationToJson(state.currentEvaluation?.evaluation) : null,
    };
  },
  json => {
    return {
      ...json,
      evaluations: json.evaluations && json.evaluations.map(evaluationFromJson),
      currentEvaluation: null,
      loadingEvaluations: 'idle',
      loadingCurrentEvaluation: 'idle',
    };
  },
  { whitelist: ['evaluation'] }
);
