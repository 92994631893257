import React from 'react';
import { MwiMeasureTypes } from '../../../enums/MeasureTypes';
import Conference from './mwi-measures/Conference';
import ConferenceParticipation from './mwi-measures/ConferenceParticipation';
import Scholarship from './mwi-measures/Scholarship';
import FacultyExchange from './mwi-measures/FacultyExchange';
import Library from './mwi-measures/Library';
import PublicationElectronic from './mwi-measures/PublicationElectronic';
import PublicationPrint from './mwi-measures/PublicationPrint';
import ResearchPerson from './mwi-measures/ResearchPerson';
import Translation from './mwi-measures/Translation';
import ResearchStudy from './mwi-measures/ResearchStudy';
import ResearchInstitution from './mwi-measures/ResearchInstitution';
import SpecialProject from './mwi-measures/SpecialProject';

export interface MwiMeasureFieldsProps {
  measureName: string;
  data: any;
  readOnlyMode: boolean;
  isReport?: boolean;
}

const MwiMeasureFields = (props: MwiMeasureFieldsProps) => {
  const { readOnlyMode, measureName, data, isReport } = props;
  switch (measureName) {
    case MwiMeasureTypes.conference:
      return <Conference isReport={isReport} data={data} readOnlyMode={readOnlyMode} />;
    case MwiMeasureTypes.conferenceParticipation:
      return <ConferenceParticipation isReport={isReport} data={data} readOnlyMode={readOnlyMode} />;
    case MwiMeasureTypes.scholarship:
      return <Scholarship isReport={isReport} data={data} readOnlyMode={readOnlyMode} />;
    case MwiMeasureTypes.facultyExchange:
      return <FacultyExchange isReport={isReport} data={data} readOnlyMode={readOnlyMode} />;
    case MwiMeasureTypes.library:
      return <Library isReport={isReport} data={data} readOnlyMode={readOnlyMode} />;
    case MwiMeasureTypes.publicationElectronic:
      return <PublicationElectronic isReport={isReport} data={data} readOnlyMode={readOnlyMode} />;
    case MwiMeasureTypes.publicationPrint:
      return <PublicationPrint isReport={isReport} data={data} readOnlyMode={readOnlyMode} />;
    case MwiMeasureTypes.researchPerson:
      return <ResearchPerson isReport={isReport} data={data} readOnlyMode={readOnlyMode} />;
    case MwiMeasureTypes.researchStudy:
      return <ResearchStudy isReport={isReport} data={data} readOnlyMode={readOnlyMode} />;
    case MwiMeasureTypes.researchInstitution:
      return <ResearchInstitution isReport={isReport} data={data} readOnlyMode={readOnlyMode} />;
    case MwiMeasureTypes.translation:
      return <Translation isReport={isReport} data={data} readOnlyMode={readOnlyMode} />;
    case MwiMeasureTypes.specialProject:
      return <SpecialProject isReport={isReport} data={data} readOnlyMode={readOnlyMode} />;
    default:
      return <div />;
  }
};

export default MwiMeasureFields;
