import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AppState } from '../../store';
import LoadingIndicator from '../../components/Loading/LoadingIndicator';
import FullScreenLoadingIndicator from '../../components/Loading/FullScreenLoadingIndicator';
import FormHead from '../../components/Forms/FormHead';
import { InvalidResult } from '../../validators/OfferValidation';
import { makeStyles, Tab, Tabs } from '@material-ui/core';
import MissingFieldsDialog from '../../components/Dialogs/MissingFieldsDialog';
import { useTranslation } from 'react-i18next';
import { StateType } from '../../enums/StateType';
import Survey from '../../sdk/com/apiomat/frontend/missio/Survey';
import { surveyActions } from '../../store/survey';
import SurveyForm from '../../components/Forms/Survey/SurveyForm';
import { getTranslatedSortedAttachmentTypes } from '../../utils/attachment.util';
import OldAttachmentForm from '../../components/Forms/OldAttachmentForm';

const useStyles = makeStyles(() => ({
  titleContainer: {
    flex: 1,
    display: 'flex',
    paddingTop: 15,
    paddingBottom: 15,
  },
  buttonsContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    justifyItems: 'space-between',
  },
  saveButton: {
    width: window.innerWidth / 12,
    display: 'flex',
    borderRadius: 0,
  },
  separator: {
    paddingLeft: 5,
    paddingRight: 5,
  },
  uploadButton: {
    width: window.innerWidth / 12,
    display: 'flex',
    borderRadius: 0,
  },
  titleTextFieldContainer: {
    flex: 1,
    display: 'flex',
    paddingTop: 10,
    paddingBottom: 10,
  },
  tabContainer: {
    paddingTop: 20,
    paddingBottom: 20,
  },
}));

interface TabItem {
  key: string;
  label: string;
  content?: React.ReactNode;
}

export default () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();

  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const [isMissingFieldsDialogActive, setMissingFieldsDialogActive] = useState<boolean>(false);
  const [missingFields] = useState<InvalidResult[]>([]);

  useEffect(() => {
    if (id) {
      dispatch(surveyActions.loadCurrentSurvey(id));
    }
  }, [dispatch, id]);

  const { currentSurvey, loadingSurveys, loadingCurrentSurvey } = useSelector((state: AppState) => state.survey);

  const survey = currentSurvey?.survey;

  const readOnly = survey?.state?.name === StateType.easydor || survey?.state?.name === StateType.easydorError;

  if (loadingCurrentSurvey === 'pending') {
    return <FullScreenLoadingIndicator />;
  } else if (loadingSurveys === 'pending') {
    return <LoadingIndicator />;
  }
  if (!survey) {
    return null;
  }

  const updateSurvey = (survey: Survey) => dispatch(surveyActions.updateCurrentSurvey(survey));

  const tabs: TabItem[] = [
    {
      key: 'survey',
      label: 'tab:title:survey',
      content: <SurveyForm updateSurvey={updateSurvey} survey={survey} readOnlyMode={readOnly} />,
    },
    {
      key: 'attachments',
      label: 'tab:title:attachments',
      content: (
        <OldAttachmentForm
          data={survey}
          readOnlyMode={readOnly}
          showDropDown={!readOnly}
          items={getTranslatedSortedAttachmentTypes(t)}
          update={updateSurvey}
        />
      ),
    },
  ];

  const onSave = () => {
    dispatch(surveyActions.saveCurrentSurvey(StateType.cloud));
  };

  const onUpload = () => {
    dispatch(surveyActions.saveCurrentSurvey(StateType.completed));
  };

  return (
    <>
      <FormHead
        mode={readOnly ? 'review-admin' : 'edit'}
        title={survey.offerName}
        isReadonly={true}
        showAdminButtons={false}
        showUserButtons={false}
        isEasydorError={survey?.state?.name === StateType.easydorError}
        onTitleChange={() => {}}
        titleDescription={t('create-survey')}
        onSave={onSave}
        onUpload={onUpload}
        isAssignment={false}
      />
      <div className={classes.tabContainer}>
        <Tabs variant="scrollable" scrollButtons="on" value={activeTabIndex} onChange={(_event, tabIndex) => setActiveTabIndex(tabIndex)}>
          {tabs.map(tab => (
            <Tab fullWidth key={tab.key} disableRipple label={t(tab.label)} />
          ))}
        </Tabs>
        {tabs[activeTabIndex].content}
        {isMissingFieldsDialogActive && (
          <MissingFieldsDialog invalidResults={missingFields} onCancelButtonClick={() => setMissingFieldsDialogActive(false)} />
        )}
      </div>
    </>
  );
};
