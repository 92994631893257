import React from 'react';
import AllMyTasks from './AllMyTasks';
import SharedTasks from './SharedReports';
import TabView, { TabItem } from '../../components/Tabs/TabView';

export default () => {
  const tabs: TabItem[] = [
    {
      key: 'all-my-tasks',
      label: 'tab:title:my-tasks',
      value: <AllMyTasks />,
    },
    {
      key: 'shared-tasks',
      label: 'tab:title:shared-tasks',
      value: <SharedTasks />,
    },
  ];

  return <TabView tabs={tabs} />;
};
