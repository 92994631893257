import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { put, call, all, takeLatest, select, takeLeading } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import AOMSessionToken from '../sdk/com/apiomat/frontend/AOMSessionToken';
import { notificationActions } from './notification';
import { AppState } from './index';
import Datastore from '../sdk/com/apiomat/frontend/Datastore';
import MUser from '../sdk/com/apiomat/frontend/missio/MUser';
import i18n from '../utils/i18n';
import PasswordResetRequest from '../sdk/com/apiomat/frontend/missio/PasswordResetRequest';
import { createTransform } from 'redux-persist';
import { toJson, fromJson } from '../utils/transforms';
import { Roles } from '../enums/Roles';
import { ErrorMessage } from '../enums/ErrorMessage';
import TokenVerification from '../sdk/com/apiomat/frontend/missio/TokenVerification';
import { changeLanguage } from '../utils/language.utils';
import { ErrorCode } from '../enums/ErrorCode';
import Contact from '../sdk/com/apiomat/frontend/missio/Contact';
import { refreshToken } from '../utils/auth.utils';

/** STATE */
export interface AuthState {
  user: MUser | null;
  userContacts: Contact[] | null;
  token: AOMSessionToken | null;
  isVerified: boolean;
  isAuthenticated: boolean;
  isAdmin: boolean;
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
  loadingByToken: 'idle' | 'pending' | 'succeeded' | 'failed';
}

const initialState: AuthState = {
  user: null,
  userContacts: null,
  token: null,
  isVerified: false,
  isAuthenticated: false,
  isAdmin: false,
  loading: 'idle',
  loadingByToken: 'idle',
};

/** TYPES */
export interface UserCredentials {
  userName: string;
  password: string;
}

export interface LoginObject {
  user: MUser;
  token: AOMSessionToken;
}

export interface UserUpdate {
  email: string;
  firstName: string;
  lastName: string;
  churchType: string;
  churchCountry: string;
  churchRole: string;
  phoneNumber: string;
  language: string;
}

export interface TokenVerificationObject {
  email: string;
  token: string;
}

export interface PasswordUpdate {
  password: string;
  oldPassword: string;
}

/** SLICE */
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginWithCredentials: (state, _action: PayloadAction<UserCredentials>) => {
      state.loading = 'pending';
    },
    loginWithCredentialsSuccess: (state, action: PayloadAction<LoginObject>) => {
      state.loading = 'succeeded';
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.isAuthenticated = true;
      state.isAdmin = Boolean((action.payload.user.roles || []).includes(Roles.ADMIN));
    },
    loginWithCredentialsFailure: state => {
      state.loading = 'failed';
      state.isAuthenticated = false;
      state.isAdmin = false;
    },
    loginWithToken: state => {
      state.loadingByToken = 'pending';
    },
    loginWithTokenSuccess: (state, action: PayloadAction<LoginObject>) => {
      state.loadingByToken = 'succeeded';
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.isAuthenticated = true;
      state.isAdmin = Boolean((action.payload.user.roles || []).includes(Roles.ADMIN));
    },
    loginWithTokenFailure: state => {
      state.loadingByToken = 'failed';
      state.isAuthenticated = false;
      state.isAdmin = false;
    },
    updateUser: (state, _action: PayloadAction<UserUpdate>) => {
      state.loading = 'pending';
    },
    updateUserSuccess: (state, action: PayloadAction<MUser>) => {
      state.loading = 'succeeded';
      state.user = action.payload;
    },
    updateUserFailure: state => {
      state.loading = 'failed';
    },
    resetPassword: (state, _action: PayloadAction<string>) => {
      state.loading = 'pending';
    },
    resetPasswordSuccess: state => {
      state.loading = 'succeeded';
    },
    resetPasswordFailure: state => {
      state.loading = 'failed';
    },
    changePassword: (state, _action: PayloadAction<PasswordUpdate>) => {
      state.loading = 'pending';
    },
    changePasswordSuccess: state => {
      state.loading = 'succeeded';
    },
    changePasswordFailure: state => {
      state.loading = 'failed';
    },
    verifyToken: (state, _action: PayloadAction<TokenVerificationObject>) => {
      state.loading = 'pending';
    },
    verifyTokenSuccess: state => {
      state.loading = 'succeeded';
      state.isVerified = true;
    },
    verifyTokenFailure: state => {
      state.loading = 'failed';
      state.isVerified = false;
    },
    logout: state => {
      state.loading = 'idle';
      state.user = null;
      state.token = null;
      state.isAuthenticated = false;
      state.isAdmin = false;
    },
  },
});

export const authActions = authSlice.actions;
export const authReducer = authSlice.reducer;

/** SAGAS */
function* onLoginWithCredentials(action: PayloadAction<UserCredentials>) {
  const { userName, password } = action.payload;
  const ACCESS_EXPIRATION = 30 * 24 * 60 * 60;
  const REFRESH_EXPIRATION = 100 * 24 * 60 * 60;

  try {
    let user = new MUser();
    user.userName = userName;
    user.password = password;

    const token = yield call(async () => {
      Datastore.configureAsUser(user);
      await user.loadMe();

      return user.requestSessionToken(true, undefined, ACCESS_EXPIRATION, REFRESH_EXPIRATION);
    });

    yield put(authActions.loginWithCredentialsSuccess({ user, token }));
    yield call(() => changeLanguage(user.language));
    yield put(push('/'));
  } catch (error) {
    if (error?.message?.includes(ErrorMessage.USER_IS_INACTIVE)) {
      yield put(notificationActions.showError(i18n.t('login-failed-inactive')));
    } else if (error?.message?.includes(ErrorMessage.ACCOUNT_UNVERIFIED)) {
      yield put(notificationActions.showError(i18n.t('account-unverified')));
    } else {
      yield put(notificationActions.showError(i18n.t('login-failed')));
    }

    yield put(authActions.loginWithCredentialsFailure());
  }
}

function* onLoginWithToken() {
  const { token, user } = yield select((state: AppState) => state.auth);

  const isOnline: boolean = yield select((state: AppState) => state.offline.isOnline);
  let loginObject: LoginObject;

  if (isOnline === false) {
    Datastore.configureWithSessionToken(token.sessionToken);
    yield put(authActions.loginWithTokenSuccess({ token, user }));
    yield put(push('/'));
    return;
  }

  try {
    let user = new MUser();

    if (token.expirationDate > Date.now()) {
      user.sessionToken = token.sessionToken;

      Datastore.configureWithSessionToken(token.sessionToken);
      try {
        yield call(() => user.loadMe());
        loginObject = { user, token };
      } catch {
        /** failed to log in with existing session token, trying refresh token */
        loginObject = yield call(() => refreshToken(token));
      }
    } else {
      /** existing session token expired, trying refresh token */
      loginObject = yield call(() => refreshToken(token));
    }

    yield put(authActions.loginWithTokenSuccess(loginObject));
    yield call(() => changeLanguage(user.language));
  } catch (error) {
    yield put(push('/'));
    yield put(authActions.loginWithTokenFailure());
  }
}

function* onVerifyToken(action: PayloadAction<TokenVerificationObject>) {
  const payload: TokenVerificationObject = action.payload;
  try {
    Datastore.configureAsGuest();
    const tokenVerification = new TokenVerification();
    const decodedEmail = decodeURI(payload.email);
    tokenVerification.email = decodedEmail;
    tokenVerification.verificationToken = payload.token;

    yield call(() => tokenVerification.save(false));
    yield put(notificationActions.showSuccessMessage(i18n.t('email-verify:success')));
    yield put(authActions.verifyTokenSuccess());
  } catch (error) {
    const errorMessage = error?.message;
    if (errorMessage?.includes(ErrorCode.MAIL_ALREADY_VERIFIED)) {
      yield put(authActions.verifyTokenSuccess());
      yield put(notificationActions.showSuccessMessage(i18n.t('email-verify:already-verified')));
    } else if (errorMessage?.includes(ErrorCode.MAIL_VALIDATION_ERROR)) {
      yield put(notificationActions.showError(i18n.t('email-verify:invalid-token')));
    } else {
      yield put(notificationActions.showError(error));
    }
    yield put(authActions.verifyTokenFailure());
  }
}

function* onUpdateUser(action: PayloadAction<UserUpdate>) {
  const { email, firstName, lastName, churchType, churchCountry, churchRole, phoneNumber, language } = action.payload;
  const user: MUser = yield select((state: AppState) => state.auth.user);
  const isOnline: boolean = yield select((state: AppState) => state.offline.isOnline);

  if (isOnline === false) {
    yield put(notificationActions.showError(i18n.t('you-are-offline')));
    yield put(authActions.updateUserFailure());
    return;
  }

  try {
    user.email = email;
    user.firstName = firstName;
    user.lastName = lastName;
    user.church = churchType;
    user.churchCountry = churchCountry;
    user.churchRole = churchRole;
    user.phone = phoneNumber;
    user.language = language;

    yield call(() => user.save());
    yield put(authActions.updateUserSuccess(user));
    yield call(() => changeLanguage(user.language));
  } catch (error) {
    yield put(notificationActions.showError(error));
    yield put(authActions.updateUserFailure());
  }
}

function* onResetPassword(action: PayloadAction<string>) {
  const isOnline: boolean = yield select((state: AppState) => state.offline.isOnline);

  if (isOnline === false) {
    yield put(notificationActions.showError(i18n.t('you-are-offline')));
    yield put(authActions.resetPasswordFailure());
    return;
  }

  const email = action.payload;
  try {
    const resetRequest = new PasswordResetRequest();
    resetRequest.userName = email;

    Datastore.configureAsGuest();
    yield call(() => resetRequest.save());
    yield put(notificationActions.showSuccessMessage(i18n.t('forgot-password-success')));
    yield put(authActions.resetPasswordSuccess());

    yield put(push('/'));
  } catch (error) {
    yield put(notificationActions.showError(error));
    yield put(authActions.resetPasswordFailure());
  }
}

function* onChangePassword(action: PayloadAction<PasswordUpdate>) {
  const isOnline: boolean = yield select((state: AppState) => state.offline.isOnline);

  if (isOnline === false) {
    yield put(notificationActions.showError(i18n.t('you-are-offline')));
    yield put(authActions.changePasswordFailure());
    return;
  }

  const password = action.payload.password;
  const oldPassword = action.payload.oldPassword;
  const user = yield select((state: AppState) => state.auth.user);
  try {
    user.password = password;

    Datastore.configureWithCredentials(user.userName, oldPassword);
    yield call(() => user.save());
    Datastore.configureWithCredentials(user.userName, password);
    yield put(notificationActions.showSuccessMessage(i18n.t('use-cases:new-assignment:root:saved-notification:body')));
    yield put(authActions.changePasswordSuccess());

    yield put(push('/'));
  } catch (error) {
    yield put(notificationActions.showError(error));
    yield put(authActions.changePasswordFailure());
  }
}

function* onLogout() {
  Datastore.configureAsGuest();
  yield put(push('/login'));
}

export function* authSaga() {
  yield all([
    takeLeading(authActions.loginWithCredentials, onLoginWithCredentials),
    takeLeading(authActions.loginWithToken, onLoginWithToken),
    takeLatest(authActions.updateUser, onUpdateUser),
    takeLatest(authActions.resetPassword, onResetPassword),
    takeLatest(authActions.changePassword, onChangePassword),
    takeLatest(authActions.logout, onLogout),
    takeLatest(authActions.verifyToken, onVerifyToken),
  ]);
}

/** TRANSFORMS */
export const authTransform = createTransform(
  (state: AuthState) => {
    return {
      ...state,
      user: state.user && toJson(state.user),
    };
  },
  json => {
    return {
      ...json,
      user: json.user && fromJson(json.user, MUser),
      loading: 'idle',
      isAuthenticated: Boolean(json.user),
      loadingByToken: json.user ? 'succeeded' : 'idle',
    };
  },
  { whitelist: ['auth'] }
);
