import { DropDownItem } from '../components/Selection/DropDownItem';
import { OtherMeasureTypes } from '../enums/MeasureTypes';

export const otherMeasureDropDownItems: DropDownItem[] = [
  {
    key: OtherMeasureTypes.other,
    value: 'label:measure:dropdown:other',
  },
  {
    key: OtherMeasureTypes.all,
    value: 'label:measure:dropdown:all',
  },
];
