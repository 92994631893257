import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, makeStyles, Theme } from '@material-ui/core';
import { offerActions } from '../../store/offers';
import { useDispatch, useSelector } from 'react-redux';
import Offers from '../../sdk/com/apiomat/frontend/missio/Offers';
import LoadingIndicator from '../../components/Loading/LoadingIndicator';
import { AppState } from '../../store/index';
import { ApplicationStatus } from '../../components/ApplicationStatus/ApplicationStatus';
import { onDownloadFile } from '../../utils/file.utils';
import { StateType } from '../../enums/StateType';
import { State } from '../../sdk/com/apiomat/frontend/missio';
import MUIDataTable, { MUIDataTableColumnDef } from 'mui-datatables';
import { PictureAsPdf } from '@material-ui/icons';
import ChangeAssignmentDialog from '../../components/Dialogs/ChangeAssignmentDialog';
import { pdfExportActions } from '../../store/pdfExport';
import ExportButton from '../../components/Buttons/ExportButton';
import { orderBy } from 'natural-orderby';
import CopyAssignmentDialog from '../../components/Dialogs/CopyAssignmentDialog';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ActionMenuMyAssignment from '../../components/ActionMenu/ActionMenuMyAssignment';
import { Tooltip } from '@mui/material';
import { Warning } from '@mui/icons-material';

interface TableMyAssignment {
  name: string;
  projectNumber: string;
  status: Offers;
  approved: State;
  actions: Offers;
}

const useStyles = makeStyles((theme: Theme) => ({
  rejectionView: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  separator: {
    padding: 5,
  },
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  actionButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  deleteButton: {
    color: theme.palette.error.main,
    '& svg': {
      color: theme.palette.error.main,
    },
  },
}));

const getMuiDatatableTheme = () =>
  createTheme({
    components: {
      // @ts-ignore
      MUIDataTableToolbar: {
        styleOverrides: {
          actions: {
            display: 'flex',
            flex: 0,
            justifyItems: 'flex-end',
          },
        },
      },
    },
  });

export default () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const user = useSelector((state: AppState) => state.auth.user);
  const { offers, loading, loadingOffer, incompleteOffers } = useSelector((state: AppState) => state.offer);
  const { exporting, offersToExport } = useSelector((state: AppState) => state.pdfExport);
  const { isOnline } = useSelector((state: AppState) => state.offline);

  const [activeOffer, setActiveOffer] = useState(null);
  const [isChangeAssignmentDialogOpen, setIsChangeAssignmentDialogOpen] = useState<boolean>(false);
  const [isCopyAssignmentDialogOpen, setIsCopyAssignmentDialogOpen] = useState<boolean>(false);
  const isIncomplete = (offer: Offers) =>
    offer.state?.name !== StateType.unsavedChanges && offer.state?.name !== StateType.cloud && !!incompleteOffers[offer.id];

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);

  const [rowsSelected, setRowsSelected] = useState<Array<number>>([]);
  const columns: Array<MUIDataTableColumnDef> = [
    {
      name: 'indicatorIcon',
      label: ' ',
      options: {
        display: true,
        searchable: false,
      },
    },
    {
      name: 'name',
      label: t('my-assignment:header:name'),
    },
    {
      name: 'projectNumber',
      label: t('my-assignment:header:project-number'),
    },
    {
      name: 'status',
      label: t('my-assignment:header:status'),
      options: {
        customBodyRender: value => {
          return (
            <div className={classes.rejectionView}>
              {t(`assignment:status:${value.state?.name}`)}
              {value?.state?.name === StateType.easydorRejected && value?.rejectionFile && (
                <>
                  <div className={classes.separator} />
                  <Button onClick={() => onDownloadFile(value.rejectionFile, 'file')}>
                    <PictureAsPdf color="primary" />
                  </Button>
                </>
              )}
            </div>
          );
        },
      },
    },
    {
      name: 'approved',
      label: t('my-assignment:header:approved'),
      options: {
        customBodyRender: value => {
          return <ApplicationStatus state={value} />;
        },
      },
    },
    {
      name: 'actions',
      label: t('my-assignment:header:actions'),
      options: {
        customBodyRender: (offer, tableMeta) => {
          const id = `action-menu-${tableMeta.rowIndex}`;

          const isReadOnly =
            offer?.state?.name !== StateType.unsavedChanges &&
            offer?.state?.name !== StateType.cloud &&
            offer?.state?.name !== StateType.rejected &&
            offer?.state?.name !== StateType.proposalUserCloud;

          const isProposalAllowed =
            offer?.state?.name === StateType.easydorInProgress ||
            offer?.state?.name === StateType.proposalInProgressUser ||
            offer?.state?.name === StateType.proposalInProgressMissio ||
            offer?.state?.name === StateType.proposalRejectedUser ||
            offer?.state?.name === StateType.proposalRejectedMissio;

          const isProposalMissio = offer?.state?.name === StateType.proposalMissio;

          const isDeleteAllowed =
            offer?.state?.name === StateType.easydorClosed ||
            offer?.state?.name === StateType.cloud ||
            offer?.state?.name === StateType.unsavedChanges ||
            offer?.state?.name === StateType.proposalUserCloud ||
            offer?.state?.name === StateType.easydorRejected;

          return (
            <div className={classes.actionButton}>
              <ActionMenuMyAssignment
                menuId={id}
                offer={offer}
                isVisible={isProposalMissio}
                isReadOnly={isReadOnly}
                isProposalAllowed={isProposalAllowed}
                openChangeAssignmentDialog={openChangeAssignmentDialog}
                openCopyAssignmentDialog={openCopyAssignmentDialog}
                isDeleteAllowed={isDeleteAllowed}
                openDeleteDialog={openDeleteDialog}
                deleteOffer={deleteOffer}
                isDeleteDialogOpen={isDeleteDialogOpen}
                cancelDeleteDialog={cancelDeleteDialog}
                disabled={isIncomplete(offer)}
              />
            </div>
          );
        },
        filter: false,
        searchable: false,
        sort: false,
        viewColumns: false,
      },
    },
  ];

  const openChangeAssignmentDialog = (offer: Offers) => {
    setActiveOffer(offer);
    setIsChangeAssignmentDialogOpen(true);
  };

  const cancelChangeAssignmentDialog = () => {
    setIsChangeAssignmentDialogOpen(false);
    setActiveOffer(null);
  };

  const openCopyAssignmentDialog = (offer: Offers) => {
    setActiveOffer(offer);
    setIsCopyAssignmentDialogOpen(true);
  };

  const cancelCopyAssignmentDialog = () => {
    setIsCopyAssignmentDialogOpen(false);
    setActiveOffer(null);
  };

  const openDeleteDialog = () => {
    setIsDeleteDialogOpen(true);
  };

  const cancelDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
  };

  const deleteOffer = (offer: Offers) => {
    dispatch(offerActions.deleteOffer(offer));
    setIsDeleteDialogOpen(false);
  };

  useEffect(() => {
    dispatch(offerActions.loadOffers(`ownerUserName == "${user.userName}"`));
  }, [dispatch, user.userName]);

  // delete selected rows when download was finished
  useEffect(() => {
    if (exporting !== 'pending') {
      setRowsSelected([]);
      dispatch(pdfExportActions.setOrDeleteAllOffers([]));
    }
  }, [exporting, dispatch]);

  const muiDatatableToggleAllItemsSelection = (allRowsSelected: any[]) => {
    const items = [];
    if (allRowsSelected.length === 0) {
      setRowsSelected([]);
    } else {
      dataSource.forEach(item => items.push(item.actions));
      setRowsSelected(allRowsSelected.map(row => row.dataIndex));
    }
    dispatch(pdfExportActions.setOrDeleteAllOffers(items));
  };

  const muiDatatableToggleItemSelection = (currentRowsSelected: any[], allRowsSelected: any[]) => {
    const item = dataSource[currentRowsSelected[0].dataIndex].actions;
    setRowsSelected(allRowsSelected.map(row => row.dataIndex));
    dispatch(pdfExportActions.addOrDeleteOfferToExport(item));
  };

  const dataSource: Array<TableMyAssignment> = orderBy(
    offers,
    [v => v.projectId, v => v.versionNr, v => v.lastModifiedAt],
    ['desc', 'desc', 'desc']
  ).map(offer => {
    return {
      indicatorIcon: isIncomplete(offer) ? (
        <Tooltip title={'Failed to load all required dependencies'}>
          <Warning color={'warning'} />
        </Tooltip>
      ) : (
        <></>
      ),
      name: offer.name,
      projectNumber: offer.projectNumber,
      status: offer,
      approved: offer.state,
      actions: offer,
    };
  });

  const HeaderCustomToolbar = () => (
    <ExportButton
      isDisabled={offersToExport?.length !== 0 && exporting !== 'pending' && isOnline}
      onExportClick={() => dispatch(pdfExportActions.exportOffers())}
      loading={exporting === 'pending'}
    />
  );

  return loading === 'pending' || loadingOffer === 'pending' ? (
    <LoadingIndicator />
  ) : (
    <div className={classes.container}>
      <ThemeProvider theme={getMuiDatatableTheme()}>
        <MUIDataTable
          title={t('my-assignments')}
          data={dataSource}
          columns={columns}
          options={{
            selectableRows: 'multiple',
            selectableRowsHeader: true,
            selectToolbarPlacement: 'none',
            rowsSelected: rowsSelected,
            onRowSelectionChange: (currentRowsSelected, allRowsSelected) => {
              if (exporting !== 'pending') {
                if (currentRowsSelected.length === 1) {
                  muiDatatableToggleItemSelection(currentRowsSelected, allRowsSelected);
                } else {
                  muiDatatableToggleAllItemsSelection(allRowsSelected);
                }
              }
            },
            isRowSelectable: () => exporting !== 'pending',
            print: false,
            download: false,
            filter: false,
            sort: false,
            customToolbar: () => HeaderCustomToolbar(),
            viewColumns: false,
            search: false,
          }}
        />
      </ThemeProvider>
      {activeOffer && (
        <>
          <ChangeAssignmentDialog
            offer={activeOffer}
            isOpen={isChangeAssignmentDialogOpen}
            onCancelButtonClick={cancelChangeAssignmentDialog}
          />
          <CopyAssignmentDialog offer={activeOffer} isOpen={isCopyAssignmentDialogOpen} onCancelButtonClick={cancelCopyAssignmentDialog} />
        </>
      )}
    </div>
  );
};
