import axios from 'axios';
import Datastore from '../sdk/com/apiomat/frontend/Datastore';
import AbstractClientDataModel from '../sdk/com/apiomat/frontend/AbstractClientDataModel';
import { notificationActions } from '../store/notification';
import SdkInfo from '../sdk/com/apiomat/frontend/SdkInfo';
import User from '../sdk/com/apiomat/frontend/basics/User';
import MAttachment from '../sdk/com/apiomat/frontend/missio/MAttachment';

export const getFileTypeName = (name: string): { fileType: string; fileName: string } => {
  const nameArr = name.split('.');
  const fileType = nameArr.pop();
  const fileName = nameArr.join('');
  return { fileType, fileName };
};

export const getFileLink = async (
  aomObj: AbstractClientDataModel,
  fileAttribute: string,
  validity: number = 360,
  oneTime: boolean = true
): Promise<string> => {
  const { AOMBASEURL, AOMAPIKEY, AOMSYS } = User;
  const moduleName = aomObj['moduleName'];
  const modelName = aomObj['modelName'];
  const moduleVersion = SdkInfo.getVersion(moduleName);

  const resourceUrl = aomObj['dao'][`${fileAttribute}URL`];
  const fileId = resourceUrl.substring(resourceUrl.lastIndexOf('/') + 1);

  const url = `${AOMBASEURL}/data/files/${moduleName}/v/${moduleVersion}/${modelName}/${aomObj.ID}/${fileAttribute}/${fileId}.key?apiKey=${AOMAPIKEY}&system=${AOMSYS}`;

  const res = await axios.post(url, `validity=${validity}&oneTime=${oneTime}`, {
    headers: Datastore.Instance.getHeader(true, { contentType: 'application/x-www-form-urlencoded' }),
  });
  const tokenUrl = res?.headers['location'];
  if (Boolean(tokenUrl) === false) {
    throw new Error('No location header returned');
  }
  return tokenUrl;
};

export const onDownloadFile = async (
  item: AbstractClientDataModel,
  fileAttribute: string,
  validity: number = 360,
  oneTime: boolean = true
) => {
  try {
    const link = await getFileLink(item, fileAttribute, validity, oneTime);
    if (Boolean(link)) {
      window.open(link, '_blank');
    }
  } catch (error) {
    notificationActions.showError(error);
  }
};

export const postFile = async (attachment: MAttachment, data: AbstractClientDataModel) => {
  const { fileType, fileName } = getFileTypeName(attachment.name);
  const file = (attachment as any).dao.file;
  await attachment.save();
  await attachment.postFile(file, fileName, fileType);
  return data.postAttachments(attachment);
};

export const onDownloadZip = async (fileURL: string) => {
  if (Boolean(fileURL)) {
    window.open(fileURL, '_blank');
  }
};
