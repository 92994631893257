import { GeneralValueMap } from './GeneralValueMap';

export const subjects: GeneralValueMap[] = [
  {
    locale: 'lbl.subject.trl',
    value: 'Theologie der Religionen',
    key: 'TRL',
  },
  {
    locale: 'lbl.subject.pp',
    value: 'Philosophie',
    key: 'PP',
  },
  {
    locale: 'lbl.subject.tvr',
    value: 'Vergleichende Religionswissenschaft',
    key: 'TVR',
  },
  {
    locale: 'lbl.subject.sga',
    value: 'Afrikanische Geschichte',
    key: 'SGA',
  },
  {
    locale: 'lbl.subject.tt',
    value: 'Theologie',
    key: 'TT',
  },
  {
    locale: 'lbl.subject.sre',
    value: 'Allgemeine Religionswissenschaft',
    key: 'SRE',
  },
  {
    locale: 'lbl.subject.san',
    value: 'Anthropologie',
    key: 'SAN',
  },
  {
    locale: 'lbl.subject.set',
    value: 'Ethnologie',
    key: 'SET',
  },
  {
    locale: 'lbl.subject.sin',
    value: 'Indologie',
    key: 'SIN',
  },
  {
    locale: 'lbl.subject.pan',
    value: 'Philosophische Anthropologie',
    key: 'PAN',
  },
  {
    locale: 'lbl.subject.pre',
    value: 'Religionsphilosophie',
    key: 'PRE',
  },
  {
    locale: 'lbl.subject.ppr',
    value: 'Praktische Philosophie',
    key: 'PPR',
  },
  {
    locale: 'lbl.subject.tpx',
    value: 'Pastoralsoziologie',
    key: 'TPX',
  },
  {
    locale: 'lbl.subject.uuu',
    value: 'unbekannt',
    key: 'UUU',
  },
  {
    locale: 'lbl.subject.tdc',
    value: 'Christologie',
    key: 'TDC',
  },
  {
    locale: 'lbl.subject.pha',
    value: 'Handlungstheorie',
    key: 'PHA',
  },
  {
    locale: 'lbl.subject.plo',
    value: 'Logik',
    key: 'PLO',
  },
  {
    locale: 'lbl.subject.sko',
    value: 'Kommunikationswissenschaft',
    key: 'SKO',
  },
  {
    locale: 'lbl.subject.tat',
    value: 'Exegese des Alten Testaments',
    key: 'TAT',
  },
  {
    locale: 'lbl.subject.sgc',
    value: 'Guidance and Counselling',
    key: 'SGC',
  },
  {
    locale: 'lbl.subject.psp',
    value: 'Sprachphilosophie',
    key: 'PSP',
  },
  {
    locale: 'lbl.subject.tth',
    value: 'Theologie',
    key: 'TTH',
  },
  {
    locale: 'lbl.subject.phe',
    value: 'Hermeneutische Philosophie',
    key: 'PHE',
  },
  {
    locale: 'lbl.subject.pte',
    value: 'Technikphilosophie',
    key: 'PTE',
  },
  {
    locale: 'lbl.subject.tmo',
    value: 'Moraltheologie',
    key: 'TMO',
  },
  {
    locale: 'lbl.subject.pme',
    value: 'Metaphysik',
    key: 'PME',
  },
  {
    locale: 'lbl.subject.tpr',
    value: 'Propädeutik',
    key: 'TPR',
  },
  {
    locale: 'lbl.subject.trs',
    value: 'Religionssoziologie',
    key: 'TRS',
  },
  {
    locale: 'lbl.subject.tka',
    value: 'Katechetik',
    key: 'TKA',
  },
  {
    locale: 'lbl.subject.ssa',
    value: 'Sozialarbeit',
    key: 'SSA',
  },
  {
    locale: 'lbl.subject.tbp',
    value: 'Biblische Philologie',
    key: 'TBP',
  },
  {
    locale: 'lbl.subject.srp',
    value: 'Religionspädagogik',
    key: 'SRP',
  },
  {
    locale: 'lbl.subject.ts',
    value: 'Systematische Theologie',
    key: 'TS',
  },
  {
    locale: 'lbl.subject.tks',
    value: 'Krankenpastoral',
    key: 'TKS',
  },
  {
    locale: 'lbl.subject.sfk',
    value: 'Friedens- und Konfliktforschung',
    key: 'SFK',
  },
  {
    locale: 'lbl.subject.te',
    value: 'Exegese',
    key: 'TE',
  },
  {
    locale: 'lbl.subject.sjo',
    value: 'Journalistik',
    key: 'SJO',
  },
  {
    locale: 'lbl.subject.pna',
    value: 'Naturphilosophie',
    key: 'PNA',
  },
  {
    locale: 'lbl.subject.tps',
    value: 'Psychologie/Psychotherapie',
    key: 'TPS',
  },
  {
    locale: 'lbl.subject.trp',
    value: 'Religionspädagogik',
    key: 'TRP',
  },
  {
    locale: 'lbl.subject.pg',
    value: 'Griechische Philosophie',
    key: 'PG',
  },
  {
    locale: 'lbl.subject.tkm',
    value: 'Kirchenmusik',
    key: 'TKM',
  },
  {
    locale: 'lbl.subject.sgi',
    value: 'Indische Geschichte',
    key: 'SGI',
  },
  {
    locale: 'lbl.subject.tnt',
    value: 'Exegese des Neuen Testaments',
    key: 'TNT',
  },
  {
    locale: 'lbl.subject.sve',
    value: 'Verwaltungswissenschaften',
    key: 'SVE',
  },
  {
    locale: 'lbl.subject.tui',
    value: 'Utriusque iuris',
    key: 'TUI',
  },
  {
    locale: 'lbl.subject.tsp',
    value: 'Spiritualität',
    key: 'TSP',
  },
  {
    locale: 'lbl.subject.tgb',
    value: 'Gemeindeberatung',
    key: 'TGB',
  },
  {
    locale: 'lbl.subject.tph',
    value: 'Philosophie (Grundstudium)',
    key: 'TPH',
  },
  {
    locale: 'lbl.subject.spa',
    value: 'Pädagogik',
    key: 'SPA',
  },
  {
    locale: 'lbl.subject.tet',
    value: 'theologische Ethik',
    key: 'TET',
  },
  {
    locale: 'lbl.subject.tde',
    value: 'Ekklesiologie',
    key: 'TDE',
  },
  {
    locale: 'lbl.subject.tpz',
    value: 'Soziale Pastoral',
    key: 'TPZ',
  },
  {
    locale: 'lbl.subject.tki',
    value: 'Kirchengeschichte',
    key: 'TKI',
  },
  {
    locale: 'lbl.subject.spe',
    value: 'Erziehungspsychologie',
    key: 'SPE',
  },
  {
    locale: 'lbl.subject.spo',
    value: 'Politologie',
    key: 'SPO',
  },
  {
    locale: 'lbl.subject.tra',
    value: 'Theologie und Religiöse Anthropologie',
    key: 'TRA',
  },
  {
    locale: 'lbl.subject.tpt',
    value: 'Pastoraltheologie',
    key: 'TPT',
  },
  {
    locale: 'lbl.subject.tb',
    value: 'Biblische Theologie',
    key: 'TB',
  },
  {
    locale: 'lbl.subject.tok',
    value: 'Ökumenische Theologie',
    key: 'TOK',
  },
  {
    locale: 'lbl.subject.sia',
    value: 'Islamwissenschaft und Arabistik',
    key: 'SIA',
  },
  {
    locale: 'lbl.subject.ttf',
    value: 'feministische Theologie',
    key: 'TTF',
  },
  {
    locale: 'lbl.subject.ppo',
    value: 'Politische Philosophie',
    key: 'PPO',
  },
  {
    locale: 'lbl.subject.ssy',
    value: 'Syrologie',
    key: 'SSY',
  },
  {
    locale: 'lbl.subject.tmi',
    value: 'Missionswissenschaft',
    key: 'TMI',
  },
  {
    locale: 'lbl.subject.sfw',
    value: 'Familienwissenschaften',
    key: 'SFW',
  },
  {
    locale: 'lbl.subject.tca',
    value: 'Christliche Archäologie',
    key: 'TCA',
  },
  {
    locale: 'lbl.subject.sku',
    value: 'Kulturwissenschaften',
    key: 'SKU',
  },
  {
    locale: 'lbl.subject.smk',
    value: 'Kirchenmusik',
    key: 'SMK',
  },
  {
    locale: 'lbl.subject.sli',
    value: 'Literaturwissenschaft',
    key: 'SLI',
  },
  {
    locale: 'lbl.subject.tre',
    value: 'Religionswissenschaft',
    key: 'TRE',
  },
  {
    locale: 'lbl.subject.tkl',
    value: 'Ikonographie',
    key: 'TKL',
  },
  {
    locale: 'lbl.subject.tjp',
    value: 'Justitia et Pax',
    key: 'TJP',
  },
  {
    locale: 'lbl.subject.skg',
    value: 'Kunstgeschichte',
    key: 'SKG',
  },
  {
    locale: 'lbl.subject.tpc',
    value: 'Caritaswissenschaft',
    key: 'TPC',
  },
  {
    locale: 'lbl.subject.sso',
    value: 'Soziologie',
    key: 'SSO',
  },
  {
    locale: 'lbl.subject.sme',
    value: 'Medizin',
    key: 'SME',
  },
  {
    locale: 'lbl.subject.sen',
    value: 'Entwicklungswissenschaften',
    key: 'SEN',
  },
  {
    locale: 'lbl.subject.sbi',
    value: 'Bibliothekswissenschaft',
    key: 'SBI',
  },
  {
    locale: 'lbl.subject.sju',
    value: 'Jurisprudenz',
    key: 'SJU',
  },
  {
    locale: 'lbl.subject.tkr',
    value: 'Kirchenrecht',
    key: 'TKR',
  },
  {
    locale: 'lbl.subject.sak',
    value: 'Kulturanthropologie',
    key: 'SAK',
  },
  {
    locale: 'lbl.subject.ser',
    value: 'Erziehungswissenschaft',
    key: 'SER',
  },
  {
    locale: 'lbl.subject.tpk',
    value: 'Katechetische Pastoral',
    key: 'TPK',
  },
  {
    locale: 'lbl.subject.sge',
    value: 'Geschichtswissenschaft',
    key: 'SGE',
  },
  {
    locale: 'lbl.subject.tdd',
    value: 'Dogmatik',
    key: 'TDD',
  },
  {
    locale: 'lbl.subject.tso',
    value: 'Soziallehre',
    key: 'TSO',
  },
  {
    locale: 'lbl.subject.sar',
    value: 'Archivwissenschaft',
    key: 'SAR',
  },
  {
    locale: 'lbl.subject.pge',
    value: 'Geschichtsphilosophie',
    key: 'PGE',
  },
  {
    locale: 'lbl.subject.sat',
    value: 'Religionsanthropologie',
    key: 'SAT',
  },
  {
    locale: 'lbl.subject.tpf',
    value: 'Familienpastoral',
    key: 'TPF',
  },
  {
    locale: 'lbl.subject.tpp',
    value: 'Pastoralpsychologie',
    key: 'TPP',
  },
  {
    locale: 'lbl.subject.tpj',
    value: 'Jugendpastoral und -katechese',
    key: 'TPJ',
  },
  {
    locale: 'lbl.subject.ssw',
    value: 'Sozialwissenschaft',
    key: 'SSW',
  },
  {
    locale: 'lbl.subject.tfu',
    value: 'Fundamentaltheologie',
    key: 'TFU',
  },
  {
    locale: 'lbl.subject.pku',
    value: 'Kulturphilosophie',
    key: 'PKU',
  },
  {
    locale: 'lbl.subject.tds',
    value: 'Sakramententheologie',
    key: 'TDS',
  },
  {
    locale: 'lbl.subject.spk',
    value: 'Klinische Psychologie',
    key: 'SPK',
  },
  {
    locale: 'lbl.subject.pso',
    value: 'Sozialphilosophie',
    key: 'PSO',
  },
  {
    locale: 'lbl.subject.tc',
    value: 'Christianity',
    key: 'TC',
  },
  {
    locale: 'lbl.subject.pph',
    value: 'Philosophiegeschichte',
    key: 'PPH',
  },
  {
    locale: 'lbl.subject.pwt',
    value: 'Wissenschaftstheorie',
    key: 'PWT',
  },
  {
    locale: 'lbl.subject.prj',
    value: 'Rechtsphilosophie',
    key: 'PRJ',
  },
  {
    locale: 'lbl.subject.tge',
    value: 'Christliche Gesellschaftslehre',
    key: 'TGE',
  },
  {
    locale: 'lbl.subject.tpg',
    value: 'Philosophische Grundfragen der Theologie',
    key: 'TPG',
  },
  {
    locale: 'lbl.subject.spt',
    value: 'Psychotherapie',
    key: 'SPT',
  },
  {
    locale: 'lbl.subject.tpa',
    value: 'Patristik',
    key: 'TPA',
  },
  {
    locale: 'lbl.subject.tkk',
    value: 'Kirchenkunst',
    key: 'TKK',
  },
  {
    locale: 'lbl.subject.ttg',
    value: 'Theologie und Geisteswissenschaften',
    key: 'TTG',
  },
  {
    locale: 'lbl.subject.sps',
    value: 'Psychologie',
    key: 'SPS',
  },
  {
    locale: 'lbl.subject.sdi',
    value: 'Einführung in den christlich-islamischen Dialog',
    key: 'SDI',
  },
  {
    locale: 'lbl.subject.srg',
    value: 'Religionsgeschichte',
    key: 'SRG',
  },
  {
    locale: 'lbl.subject.ssp',
    value: 'Sprachwissenschaft (Linguistik)',
    key: 'SSP',
  },
  {
    locale: 'lbl.subject.tci',
    value: 'Christlich-islamischer Dialog',
    key: 'TCI',
  },
  {
    locale: 'lbl.subject.tli',
    value: 'Liturgiewissenschaft',
    key: 'TLI',
  },
  {
    locale: 'lbl.subject.ska',
    value: 'Archäologie und Kunstgeschichte',
    key: 'SKA',
  },
  {
    locale: 'lbl.subject.pet',
    value: 'philosophische Ethik',
    key: 'PET',
  },
  {
    locale: 'lbl.subject.ppi',
    value: 'Indische Philosophie',
    key: 'PPI',
  },
];
