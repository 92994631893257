import State from '../sdk/com/apiomat/frontend/missio/State';
import Measure from '../sdk/com/apiomat/frontend/missio/Measure';
import Environment from '../sdk/com/apiomat/frontend/missio/Environment';
import TargetGroup from '../sdk/com/apiomat/frontend/missio/TargetGroup';
import Formation from '../sdk/com/apiomat/frontend/missio/Formation';
import ChildProjection from '../sdk/com/apiomat/frontend/missio/ChildProjection';
import Monitoring from '../sdk/com/apiomat/frontend/missio/Monitoring';
import Offers from '../sdk/com/apiomat/frontend/missio/Offers';
import { ContactType } from '../enums/ContactType';
import { OfferType } from '../enums/OfferType';
import { StateType } from '../enums/StateType';
import Impact from '../sdk/com/apiomat/frontend/missio/Impact';
import Contact from '../sdk/com/apiomat/frontend/missio/Contact';
import { postFile } from './file.utils';
import { ApplicationNewState } from './application-state';
import { ContactObj } from '../store/offers';
import { updateMeasureClass } from './measure.utils';
import { CostPlan, Reports } from '../sdk/com/apiomat/frontend/missio';
import MAttachment from '../sdk/com/apiomat/frontend/missio/MAttachment';
import { LoadReferencedDataResult, tryLoad } from './load-references.utils';

const impactToJson = (impact: Impact): any => {
  const newItem = impact.toJson();
  (newItem as any).impactIndicators = impact.impactIndicators.map(item => item.toJson());
  (newItem as any).children = impact.children.map(impactToJson);
  return newItem;
};

export const prepareOfferForSaving = (offer: Offers, contacts: Contact[]) => {
  (offer as any).dao.environment = offer.environment.toJson();
  (offer as any).dao.targetGroup = offer.targetGroup.toJson();
  (offer as any).dao.formation = offer.formation.toJson();
  (offer as any).dao.childProtection = offer.childProtection.toJson();
  (offer as any).dao.monitoring = offer.monitoring.toJson();
  (offer as any).dao.state = offer.state.toJson();
  (offer as any).dao.attachments = offer.attachments.map(attachment => attachment.toJson());
  (offer as any).dao.impacts = offer.impacts.map(impactToJson);

  offer.costPlans.forEach(costPlan => {
    (costPlan as any).dao.costPlanItems = costPlan.costPlanItems.map(item => item.toJson());
    (costPlan as any).dao.currencyRates = costPlan.currencyRates.map(item => item.toJson());
    (costPlan as any).dao.financialPlan = costPlan.financialPlan.toJson();
    (costPlan as any).dao.financialPlan.financialPlanItems = costPlan.financialPlan.financialPlanItems.map(item => item.toJson());
  });

  setContactsOnOffer(offer, contacts);
};

export const setContactsOnOffer = (offer: Offers, contacts: Contact[]) => {
  const contactRecipient = contacts
    .find(contact => contact.contactType === ContactType.beneficiaryPrivate || contact.contactType === ContactType.beneficiaryInstitue)
    ?.toJson();
  if (contactRecipient !== undefined) {
    (contactRecipient as any).id = null;
  }
  (offer as any).dao.contactRecipient = contactRecipient;
  (offer as any).dao.contactCreator = getContactWithContactTypeAndClearedID(contacts, ContactType.applicant);
  (offer as any).dao.contactOwner = getContactWithContactTypeAndClearedID(contacts, ContactType.owner);
  (offer as any).dao.contactProjectOwner = getContactWithContactTypeAndClearedID(contacts, ContactType.projectManager);
  (offer as any).dao.contactRecommendedBy = getContactWithContactTypeAndClearedID(contacts, ContactType.recommendedBy);
};

const getContactWithContactTypeAndClearedID = (contacts: Contact[], contactType: ContactType) => {
  const contactWithType = contacts.find(contact => contact.contactType === contactType)?.toJson();
  if (contactWithType !== undefined) {
    (contactWithType as any).id = null;
  }
  return contactWithType;
};

export const initOffer = (measureType: OfferType): Offers => {
  const offer = new Offers();
  offer.measureType = measureType;

  const offerState = new State();
  offerState.name = StateType.unsavedChanges;

  (offer as any).hashmap.state = offerState;
  (offer as any).hashmap.measure = new Measure();
  (offer as any).hashmap.environment = new Environment();
  (offer as any).hashmap.targetGroup = new TargetGroup();
  (offer as any).hashmap.formation = new Formation();
  (offer as any).hashmap.childProtection = new ChildProjection();
  (offer as any).hashmap.monitoring = new Monitoring();
  (offer as any).hashmap.attachments = [];
  (offer as any).hashmap.costPlans = [];
  (offer as any).hashmap.impacts = [];

  (offer as any).dao.state = offer.state;
  (offer as any).dao.measure = offer.measure;
  (offer as any).dao.environment = offer.environment;
  (offer as any).dao.targetGroup = offer.targetGroup;
  (offer as any).dao.formation = offer.formation;
  (offer as any).dao.childProtection = offer.childProtection;
  (offer as any).dao.monitoring = offer.monitoring;
  (offer as any).dao.attachments = offer.attachments;
  (offer as any).dao.costPlans = offer.costPlans;
  (offer as any).dao.impacts = offer.impacts;

  return offer;
};

export const createOfferCopy = async (offer: Offers, contactObjs: ContactObj[], stateName: StateType, createProposal: boolean) => {
  const contacts: Contact[] = contactObjs.map(el => el.contactObject);
  const copiedOffer = new Offers();
  copiedOffer.measureType = offer.measureType as OfferType;

  /* new state */
  const offerState = new State();
  offerState.name = stateName;

  (copiedOffer as any).hashmap.state = offerState;
  (copiedOffer as any).hashmap.environment = new Environment();
  (copiedOffer as any).hashmap.targetGroup = new TargetGroup();
  (copiedOffer as any).hashmap.formation = new Formation();
  (copiedOffer as any).hashmap.childProtection = new ChildProjection();
  (copiedOffer as any).hashmap.monitoring = new Monitoring();
  (copiedOffer as any).hashmap.attachments = [];
  (copiedOffer as any).hashmap.costPlans = [];
  (copiedOffer as any).hashmap.impacts = [];

  (copiedOffer as any).dao.state = copiedOffer.state;
  (copiedOffer as any).dao.environment = copiedOffer.environment;
  (copiedOffer as any).dao.targetGroup = copiedOffer.targetGroup;
  (copiedOffer as any).dao.formation = copiedOffer.formation;
  (copiedOffer as any).dao.childProtection = copiedOffer.childProtection;
  (copiedOffer as any).dao.monitoring = copiedOffer.monitoring;
  (copiedOffer as any).dao.attachments = copiedOffer.attachments;
  (copiedOffer as any).dao.costPlans = copiedOffer.costPlans;
  (copiedOffer as any).dao.impacts = copiedOffer.impacts;

  if (createProposal) {
    copiedOffer.projectId = offer.projectId;
    copiedOffer.projectNumber = offer.projectNumber;
    copiedOffer.versionNr = offer.versionNr;
    copiedOffer.parentDocumentsFolderId = offer.parentDocumentsFolderId;
  }
  copiedOffer.referent = offer.referent;
  copiedOffer.overallProjectEnd = offer.overallProjectEnd;
  copiedOffer.overallProjectStart = offer.overallProjectStart;
  copiedOffer.timetable = offer.timetable;
  copiedOffer.currentPositionOfTheApplicant = offer.currentPositionOfTheApplicant;
  copiedOffer.name = offer.name;
  copiedOffer.hasReports = offer.hasReports;
  copiedOffer.recommendation = offer.recommendation;
  copiedOffer.creator = offer.creator;

  if (offer.contactOwner) {
    const contactOwner = getContactWithContactTypeAndClearedID(contacts, ContactType.owner);
    if (contactOwner) {
      (copiedOffer as any).dao.contactOwner = contactOwner;
    } else {
      (copiedOffer as any).hashmap.contactOwner = new Contact();
      (copiedOffer as any).dao.contactOwner = copiedOffer.contactOwner;
      (offer as any).contactOwner.dao.id = undefined;
      (offer as any).contactOwner.dao.createdAt = undefined;
      (offer as any).contactOwner.dao.lastModifiedAt = undefined;
      copiedOffer.contactOwner.fromJson(offer.contactOwner.toJson());
      (copiedOffer as any).dao.contactOwner = copiedOffer.contactOwner.toJson();
    }
  }

  if (offer.environment) {
    (offer as any).environment.dao.id = undefined;
    (offer as any).environment.dao.createdAt = undefined;
    (offer as any).environment.dao.lastModifiedAt = undefined;
    copiedOffer.environment.fromJson(offer.environment.toJson());
  }

  if (offer.contactRecipient) {
    const contactRecipient = contacts
      .find(contact => contact.contactType === ContactType.beneficiaryPrivate || contact.contactType === ContactType.beneficiaryInstitue)
      ?.toJson();
    if (contactRecipient !== undefined) {
      (contactRecipient as any).id = null;
    }

    if (contactRecipient) {
      (copiedOffer as any).dao.contactRecipient = contactRecipient;
    } else {
      (copiedOffer as any).hashmap.contactRecipient = new Contact();
      (copiedOffer as any).dao.contactRecipient = copiedOffer.contactRecipient;
      (offer as any).contactRecipient.dao.id = undefined;
      (offer as any).contactRecipient.dao.createdAt = undefined;
      (offer as any).contactRecipient.dao.lastModifiedAt = undefined;
      copiedOffer.contactRecipient.fromJson(offer.contactRecipient.toJson());
      (copiedOffer as any).dao.contactRecipient = copiedOffer.contactRecipient.toJson();
    }
  }

  if (offer.targetGroup) {
    (offer as any).targetGroup.dao.id = undefined;
    (offer as any).targetGroup.dao.createdAt = undefined;
    (offer as any).targetGroup.dao.lastModifiedAt = undefined;
    copiedOffer.targetGroup.fromJson(offer.targetGroup.toJson());
  }

  if (offer.formation) {
    (offer as any).formation.dao.id = undefined;
    (offer as any).formation.dao.createdAt = undefined;
    (offer as any).formation.dao.lastModifiedAt = undefined;
    copiedOffer.formation.fromJson(offer.formation.toJson());
  }

  offer.impacts.forEach(impact => {
    const newImpact = new Impact();
    (impact as any).dao.id = undefined;
    (impact as any).dao.createdAt = undefined;
    (impact as any).dao.lastModifiedAt = undefined;
    newImpact.fromJson(impact.toJson());
    copiedOffer.impacts.push(newImpact);
  });

  if (offer.contactRecommendedBy) {
    const contactRecommendedBy = getContactWithContactTypeAndClearedID(contacts, ContactType.recommendedBy);
    if (contactRecommendedBy) {
      (copiedOffer as any).dao.contactRecommendedBy = contactRecommendedBy;
    } else {
      (copiedOffer as any).hashmap.contactRecommendedBy = new Contact();
      (copiedOffer as any).dao.contactRecommendedBy = copiedOffer.contactRecommendedBy;
      (offer as any).contactRecommendedBy.dao.id = undefined;
      (offer as any).contactRecommendedBy.dao.createdAt = undefined;
      (offer as any).contactRecommendedBy.dao.lastModifiedAt = undefined;
      copiedOffer.contactRecommendedBy.fromJson(offer.contactRecommendedBy.toJson());
      (copiedOffer as any).dao.contactRecommendedBy = copiedOffer.contactRecommendedBy.toJson();
    }
  }

  if (offer.childProtection) {
    (offer as any).childProtection.dao.id = undefined;
    (offer as any).childProtection.dao.createdAt = undefined;
    (offer as any).childProtection.dao.lastModifiedAt = undefined;
    copiedOffer.childProtection.fromJson(offer.childProtection.toJson());
  }

  if (offer.monitoring) {
    (offer as any).monitoring.dao.id = undefined;
    (offer as any).monitoring.dao.createdAt = undefined;
    (offer as any).monitoring.dao.lastModifiedAt = undefined;
    copiedOffer.monitoring.fromJson(offer.monitoring.toJson());
  }

  if (offer.contactProjectOwner) {
    const contactProjectOwner = getContactWithContactTypeAndClearedID(contacts, ContactType.projectManager);
    if (contactProjectOwner) {
      (copiedOffer as any).dao.contactProjectOwner = contactProjectOwner;
    } else {
      (copiedOffer as any).hashmap.contactProjectOwner = new Contact();
      (copiedOffer as any).dao.contactProjectOwner = copiedOffer.contactProjectOwner;
      (offer as any).contactProjectOwner.dao.id = undefined;
      (offer as any).contactProjectOwner.dao.createdAt = undefined;
      (offer as any).contactProjectOwner.dao.lastModifiedAt = undefined;
      copiedOffer.contactProjectOwner.fromJson(offer.contactProjectOwner.toJson());
      (copiedOffer as any).dao.contactProjectOwner = copiedOffer.contactProjectOwner.toJson();
    }
  }

  if (offer.contactCreator) {
    const contactCreator = getContactWithContactTypeAndClearedID(contacts, ContactType.applicant);
    if (contactCreator) {
      (copiedOffer as any).dao.contactCreator = contactCreator;
    } else {
      (copiedOffer as any).hashmap.contactCreator = new Contact();
      (copiedOffer as any).dao.contactCreator = copiedOffer.contactCreator;
      (offer as any).contactCreator.dao.id = undefined;
      (offer as any).contactCreator.dao.createdAt = undefined;
      (offer as any).contactCreator.dao.lastModifiedAt = undefined;
      copiedOffer.contactCreator.fromJson(offer.contactCreator.toJson());
      (copiedOffer as any).dao.contactCreator = copiedOffer.contactCreator.toJson();
    }
  }

  // prepare copied offer for saving
  (copiedOffer as any).dao.environment = copiedOffer.environment.toJson();
  (copiedOffer as any).dao.targetGroup = copiedOffer.targetGroup.toJson();
  (copiedOffer as any).dao.formation = copiedOffer.formation.toJson();
  (copiedOffer as any).dao.childProtection = copiedOffer.childProtection.toJson();
  (copiedOffer as any).dao.monitoring = copiedOffer.monitoring.toJson();
  (copiedOffer as any).dao.state = copiedOffer.state.toJson();
  (copiedOffer as any).dao.impacts = copiedOffer.impacts.map(impactToJson);

  await copiedOffer.save();

  await offer.loadMeasure();

  if (offer.measure) {
    (copiedOffer as any).hashmap.measure = new Measure();
    (copiedOffer as any).dao.measure = copiedOffer.measure;
    copiedOffer.measure.name = offer.measure.name;

    const originalMeasureJson = offer.measure.toJson();
    delete (originalMeasureJson as any).id;
    delete (originalMeasureJson as any).foreignId;
    delete (originalMeasureJson as any).createdAt;
    delete (originalMeasureJson as any).lastModifiedAt;
    delete (originalMeasureJson as any).href;
    delete (originalMeasureJson as any).applicationName;
    delete (originalMeasureJson as any).moduleName;
    delete (originalMeasureJson as any).moduleVersion;
    delete (originalMeasureJson as any).parentEntity;
    delete (originalMeasureJson as any).documentsFolderId;

    updateMeasureClass(copiedOffer, offer.measure.name);

    copiedOffer.measure.fromJson(originalMeasureJson);

    await copiedOffer.measure.save();
    await copiedOffer.postMeasure(copiedOffer.measure);
  }

  await offer.loadCostPlans();

  if (offer.costPlans) {
    const copiedCostPlans = [];
    offer.costPlans.forEach(item => {
      const newCostPlan = new CostPlan();
      const costPlan = item.toJson();
      delete (costPlan as any).id;
      delete (costPlan as any).createdAt;
      delete (costPlan as any).lastModifiedAt;
      delete (costPlan as any).href;
      delete (costPlan as any).applicationName;
      delete (costPlan as any).moduleName;
      delete (costPlan as any).moduleVersion;
      delete (costPlan as any).parentEntity;
      delete (costPlan as any).documentsFolderId;

      newCostPlan.fromJson(costPlan);

      copiedCostPlans.push(newCostPlan);
    });

    copiedCostPlans.forEach(copiedCostPlan => {
      (copiedCostPlan as any).dao.costPlanItems = copiedCostPlan.costPlanItems.map(item => item.toJson());
      (copiedCostPlan as any).dao.currencyRates = copiedCostPlan.currencyRates.map(item => item.toJson());
      (copiedCostPlan as any).dao.financialPlan = copiedCostPlan.financialPlan.toJson();
      (copiedCostPlan as any).dao.financialPlan.financialPlanItems = copiedCostPlan.financialPlan.financialPlanItems.map(item =>
        item.toJson()
      );
    });

    for (const costPlan of copiedCostPlans) {
      await costPlan.save();
      await copiedOffer.postCostPlans(costPlan);
    }
  }

  await offer.loadAttachments();

  if (offer.attachments) {
    for (const attachment of offer.attachments) {
      const file = await attachment.loadFile();
      const copiedFields = attachment.toJson();
      delete (copiedFields as any).id;
      delete (copiedFields as any).createdAt;
      delete (copiedFields as any).lastModifiedAt;
      delete (copiedFields as any).href;
      delete (copiedFields as any).fileURL;
      delete (copiedFields as any).applicationName;
      delete (copiedFields as any).moduleName;
      delete (copiedFields as any).moduleVersion;
      delete (copiedFields as any).parentEntity;

      const newAttachment = new MAttachment();
      newAttachment.fromJson(copiedFields);
      (newAttachment as any).dao.file = file;
      (newAttachment as any).dao.isDeleted = false;

      await postFile(newAttachment, copiedOffer);
    }
  }

  return copiedOffer.ID;
};

export const saveOffer = async (offer: Offers, newStatus: StateType, isCreation: boolean = false): Promise<Offers> => {
  const attachmentsToUpload = offer.attachments.filter(el => Boolean((el as any).dao.file) && Boolean((el as any).dao.isDeleted) === false);
  const attachmentsToDelete = offer.attachments.filter(el => Boolean((el as any).dao.isDeleted) && Boolean(el.ID));

  const costPlansToDelete = offer.costPlansToDelete || [];

  const measure = offer.measure;
  const costPlans = offer.costPlans;

  if (isCreation) {
    await offer.save();
  }

  /** Fix for changing measure */
  if (measure.ID) {
    await measure.save();
  } else {
    await measure.save();
    await offer.postMeasure(measure);
  }

  /** no maps because of concurrency error */
  /** Upload new attachments */
  for (const attachment of attachmentsToUpload) {
    await postFile(attachment, offer);
  }
  /** Delete attachments that are marked as deleted **/
  for (const attachmentToDelete of attachmentsToDelete) {
    await attachmentToDelete.delete();
  }

  /* Upload new costPlans */
  for (const costPlan of costPlans) {
    if (costPlan.ID) {
      await costPlan.save();
    } else {
      await costPlan.save();
      await offer.postCostPlans(costPlan);
    }
  }

  /* delete old costPlans */
  for (const costPlanToDelete of costPlansToDelete) {
    await costPlanToDelete.delete();
  }

  /** Update state */
  offer.state.name = newStatus;
  await offer.save();

  return offer;
};

export const loadReferencedData = async (offer: Offers): Promise<LoadReferencedDataResult> => {
  const result = await Promise.all([
    tryLoad(offer.loadMeasure(), `failed to load measure for offer id: ${offer.ID}`),
    tryLoad(offer.loadAttachments(), `failed to load attachments for offer id: ${offer.ID}`),
    tryLoad(offer.loadCostPlans(), `failed to load cost plans for offer id: ${offer.ID}`),
  ]);

  const failures = result.filter(it => !!it);

  return {
    id: offer.ID,
    failures,
  };
};

export const updateOfferAttachments = async (offer: Offers | Reports): Promise<Offers | Reports> => {
  const attachmentsToUpload = offer.attachments.filter(el => Boolean((el as any).dao.file) && Boolean((el as any).dao.isDeleted) === false);
  const attachmentsToDelete = offer.attachments.filter(el => Boolean((el as any).dao.isDeleted) && Boolean(el.ID));

  for (const attachment of attachmentsToUpload) {
    await postFile(attachment, offer);
  }

  for (const attachmentToDelete of attachmentsToDelete) {
    await attachmentToDelete.delete();
  }

  await offer.save();

  return offer;
};

export const updateOfferStatus = async (offer: Offers, newStatus: ApplicationNewState): Promise<Offers> => {
  offer.state.name = newStatus.status;
  offer.state.rejectionReason = newStatus.message;
  await offer.save();

  return offer;
};

export const getFilledContactsFromOffer = (offer: Offers): Contact[] => {
  const { contactOwner, contactCreator, contactRecipient, contactProjectOwner, contactRecommendedBy } = offer;

  return [contactOwner, contactCreator, contactRecipient, contactProjectOwner, contactRecommendedBy].filter(contact => Boolean(contact));
};
