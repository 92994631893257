import React, { useEffect, useState } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { DefaultTextField } from '../../TextInputs/TextInputs';
import { useDispatch } from 'react-redux';
import { offerActions } from '../../../store/offers';
import { reportActions } from '../../../store/report';
import { isEmpty } from 'lodash';
import { Action, useOfferFormContext } from '../context/OfferFormContext';
import FormTextField from '../InputFields/FormTextField';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  contentContainer: {
    paddingBottom: 30,
    paddingTop: 30,
  },
}));

export interface FormationFormProps {
  readOnlyMode: boolean;
  isReport?: boolean;
  formation?: any;
  data?: any;
}

const FormationForm = (props: FormationFormProps) => {
  const { readOnlyMode, isReport, data } = props;
  let { formation } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [ pastoralPlan, setPastoralPlan ] = useState<string>(formation.pastoralPlan);
  const { state, dispatch: dispatchOfferContext } = useOfferFormContext();

  const formErrorCount = isEmpty(pastoralPlan) ? 1 : 0;
  useEffect(() => {
    if (!readOnlyMode) {
      const actionObject: Action =
        formErrorCount === 1
          ? {
            subFormId: 'formation',
            errors: { pastoralPlan: 'missing mandatory field' },
            values: {},
          }
          : {
            subFormId: 'formation',
            errors: {},
            values: {},
          };

      if (!('formation' in state)) {
        dispatchOfferContext(actionObject);
      } else if (state['formation'].errorCount !== formErrorCount) {
        dispatchOfferContext(actionObject);
      }
    }
  }, [ dispatchOfferContext, formErrorCount, readOnlyMode, state ]);

  return (
    <Grid container spacing={ 3 } className={ classes.contentContainer }>
      { isReport && (
        <Grid item xs={ 12 }>
          <DefaultTextField
            disabled={ readOnlyMode }
            labelKey="input:label:relation-pastoralplan"
            helperTextKey="info:formation-relation-pastoralplan"
            multiline
            rows={ 3 }
            value={ pastoralPlan }
            onChange={ event => {
              formation.pastoralPlan = event.target.value;
              setPastoralPlan(event.target.value);
              dispatch(reportActions.updateCurrentReport(data));
            } }
            isError={ formErrorCount > 0 }
          />
        </Grid>
      ) }
      { !isReport && (
        <>
          <Grid item xs={ 12 }>
            <FormTextField
              id="pastoralPlan"
              name="pastoralPlan"
              disabled={ readOnlyMode }
              labelKey="input:label:relation-pastoralplan"
              descriptionKey="info:formation-relation-pastoralplan"
              multiline
              rows={ 3 }
              value={ pastoralPlan }
              onChange={ event => {
                formation.pastoralPlan = event.target.value;
                setPastoralPlan(event.target.value);
                dispatch(offerActions.updateCurrentOffer(data));
              } }
              errorMessage={ formErrorCount === 1 ? t('mandatory_field') : undefined }
            />
          </Grid>
          <Grid item xs={ 12 }>
            <DefaultTextField
              disabled={ readOnlyMode }
              labelKey="input:label:planning-and-history"
              helperTextKey="info:formation-planning-and-history"
              multiline
              rows={ 3 }
              value={ formation?.history }
              onChange={ event => {
                formation.history = event.target.value;
                dispatch(offerActions.updateCurrentOffer(data));
              } }
            />
          </Grid>
          <Grid item xs={ 12 }>
            <DefaultTextField
              disabled={ readOnlyMode }
              multiline
              rows={ 3 }
              labelKey="input:label:preproject-planning"
              helperTextKey="info:formation-preproject-planning"
              value={ formation?.preProject }
              onChange={ event => {
                formation.preProject = event.target.value;
                dispatch(offerActions.updateCurrentOffer(data));
              } }
            />
          </Grid>
        </>
      ) }
    </Grid>
  );
};

export default FormationForm;
