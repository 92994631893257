import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { DefaultTextField } from '../../TextInputs/TextInputs';
import OfferEvaluation from '../../../sdk/com/apiomat/frontend/missio/OfferEvaluation';

export const useStyles = makeStyles(() => ({
  contentContainer: {
    paddingBottom: 30,
    paddingTop: 30,
  },
  textField: {
    paddingBottom: 25,
    paddingTop: 5,
    marginTop: 30,
  },
}));

export interface OfferEvaluationFormProps {
  readOnlyMode: boolean;
  evaluation: OfferEvaluation;
  updateEvaluation: (evaluation: OfferEvaluation) => void;
}

const OfferEvaluationForm = (props: OfferEvaluationFormProps) => {
  const { readOnlyMode, evaluation, updateEvaluation } = props;
  const classes = useStyles();

  return (
    <Grid container spacing={ 3 } className={ classes.contentContainer }>
      <Grid item xs={ 12 }>
        <DefaultTextField
          className={ classes.textField }
          disabled={ true }
          labelKey="input:label:evaluation-description"
          value={ evaluation.evaluationDescription }
          multiline
          rows={ 3 }
          maxRows={ 10 }
        />
      </Grid>
      <Grid item xs={ 12 }>
        <DefaultTextField
          className={ classes.textField }
          disabled={ readOnlyMode }
          labelKey="input:label:evaluation-feedback"
          value={ evaluation.evaluationFeedback }
          multiline
          rows={ 3 }
          maxRows={ 10 }
          onChange={ event => {
            evaluation.evaluationFeedback = event.target.value;
            updateEvaluation(evaluation);
          } }
        />
      </Grid>
    </Grid>
  );
};

export default OfferEvaluationForm;
