import React, { useEffect, useState } from 'react';
import { IconButton, makeStyles, Menu, MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { authActions } from '../../store/auth';
import { Person, PowerSettingsNew, Sync, SystemUpdateAlt } from '@material-ui/icons';
import isElectron from '../../utils/electron';
import { styled, Tooltip } from '@mui/material';
import User from '../../sdk/com/apiomat/frontend/basics/User';

const UpdateChecker = styled('div')({
  '@keyframes rotate': {
    from: {
      transform: 'rotate(0deg)'
    },
    to: {
      transform: 'rotate(-360deg)'
    }
  },
  animation: 'rotate 2s infinite ease',
  width: 24,
  height: 24,
  cursor: 'pointer'
});

const UpdateCheckerNewVersion = styled('div')({
  '@keyframes pulsate': {
    from: {
      opacity: 1,
    },
    to: {
      opacity: 0,
    }
  },
  animation: 'pulsate 1.5s infinite ease-in',
  width: 24,
  height: 24,
  cursor: 'pointer'
});

const useStyles = makeStyles(() => ({
  container: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    paddingLeft: 17,
  },
  menuList: {
    '& > div > ul': {
      paddingBottom: 0
    }
  },
  menuListItem: {
    paddingLeft: 15
  },
  itemIcon: {
    marginRight: 15
  },
  autoUpdateContainer: {
    marginRight: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }
}));

export default () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [ open, setOpen ] = useState<boolean>(false);
  const [ anchorElement, setAnchorElement ] = useState<HTMLElement>();
  const [ isCheckingUpdates, setIsCheckingUpdates ] = useState<boolean>(false);
  const [ isNewVersion, setIsNewVersion ] = useState<boolean>(false);

  useEffect(() => {
    if (isElectron()) {
      setIsCheckingUpdates(true);
      window.electronAPI.send('electronAPI-check-for-updates', {
        'update:download': t('update:download'),
        'update:later': t('update:later'),
        'update:application-update': t('update:application-update'),
        'update:a-new-version-is-available': t('update:a-new-version-is-available'),
        'aomSystem': User.AOMSYS.toLowerCase()
      });

      window.electronAPI.receive('electronAPI-update-available', () => {
        setIsCheckingUpdates(false);
        setIsNewVersion(true);
      });

      window.electronAPI.receive('electronAPI-update-not-available', () => {
        setIsCheckingUpdates(false);
      });

      window.electronAPI.receive('electronAPI-update-error', () => {
        setIsCheckingUpdates(false);
        setIsNewVersion(false);
      });
    }
  }, []);

  const displayElectronUpdateDialog = () => {
    window.electronAPI.send('electronAPI-display-update-dialog');
  }

  const openMenu = (anchorElement: HTMLElement) => {
    setOpen(true);
    setAnchorElement(anchorElement);
  };

  const logout = () => {
    setOpen(false);
    dispatch(authActions.logout());
  };

  return (
    <div className={ classes.container }>
      <div className={ classes.autoUpdateContainer }>
        { isNewVersion &&
          <Tooltip title={ t('update:application-update') }>
            <UpdateCheckerNewVersion onClick={ displayElectronUpdateDialog }><SystemUpdateAlt/></UpdateCheckerNewVersion>
          </Tooltip> }
        { isCheckingUpdates &&
          <Tooltip title={ t('update:check-for-updates') }>
            <UpdateChecker><Sync/></UpdateChecker>
          </Tooltip> }
      </div>
      <IconButton
        onClick={ e => openMenu(e.currentTarget) }
        aria-owns={ open ? 'menu-appbar' : undefined }
        aria-haspopup="true"
        color="inherit"
        aria-label="Account"
      >
        <AccountCircle/>
      </IconButton>
      <Menu anchorEl={ anchorElement } id="menu-appbar" open={ open } onClose={ () => setOpen(false) }
            className={ classes.menuList }>
        <MenuItem onClick={ () => setOpen(false) } button component={ Link } to="/my-account"
                  className={ classes.menuListItem }>
          <Person className={ classes.itemIcon }/>{ t('my-account') }
        </MenuItem>
        <MenuItem onClick={ logout } button className={ classes.menuListItem }>
          <PowerSettingsNew className={ classes.itemIcon }/>{ t('logout') }
        </MenuItem>
      </Menu>
    </div>
  );
};
