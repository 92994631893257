import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { offerActions } from '../../store/offers';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AppState } from '../../store';
import FullScreenLoadingIndicator from '../../components/Loading/FullScreenLoadingIndicator';
import LoadingIndicator from '../../components/Loading/LoadingIndicator';
import { Button, Divider, Grid, Icon, makeStyles, Typography } from '@material-ui/core';
import classNames from 'classnames';
import { createPlanOverviewObject } from '../../utils/cost-plans.utils';
import CostPlanFormDialog from '../../components/Forms/CostPlans/CostPlanFormDialog';
import CostPlan from '../../sdk/com/apiomat/frontend/missio/CostPlan';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { OfferType } from '../../enums/OfferType';

const useStyles = makeStyles(() => ({
  plansContainer: {
    marginTop: 30,
  },
}));

interface CostPlansOverviewDialogObject {
  isVisible: boolean;
  costPlan: CostPlan;
  tabIndex?: number;
}

export default () => {
  const classes = useStyles();
  const { id } = useParams<{ id: string | undefined }>();
  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      dispatch(offerActions.loadCurrentOffer(id));
    }
  }, [dispatch, id]);

  const { currentOffer, loading, loadingOffer } = useSelector((state: AppState) => state.offer);
  const offer = currentOffer && currentOffer.offer;

  if (loadingOffer === 'pending') {
    return <FullScreenLoadingIndicator />;
  }

  if (loading === 'pending') {
    return <LoadingIndicator />;
  }

  if (!offer) {
    return null;
  }

  const costPlans = offer?.costPlans;
  const planOverviewObject = createPlanOverviewObject(costPlans);

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <CostPlansOverviewHeader offer={offer} />
      </Grid>
      <Grid item>
        <Grid container item justify="space-between" className={classes.plansContainer}>
          <Grid item xs={12} md={6}>
            <Plans
              plans={planOverviewObject.costPlans}
              total={planOverviewObject.costPlanTotal}
              isFinancialPlan={false}
              isTheMoneyAchieved={planOverviewObject.costPlanTotal <= planOverviewObject.financialPlanTotal}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Plans plans={planOverviewObject.financialPlans} total={planOverviewObject.financialPlanTotal} isFinancialPlan={true}></Plans>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const Plans = props => {
  const classes = plansStyle();
  const { plans, total, isFinancialPlan, isTheMoneyAchieved } = props;
  const styleOfTotalColorLabel = isFinancialPlan ? '' : isTheMoneyAchieved ? classes.labelAchieved : classes.labelNotAchieved;
  const { t } = useTranslation();
  const [activeDialog, setActiveDialog] = useState<CostPlansOverviewDialogObject>({
    isVisible: false,
    costPlan: null,
    tabIndex: 0,
  });

  const onEditButtonClick = (costPlan: CostPlan) => {
    setActiveDialog({
      isVisible: true,
      costPlan: costPlan,
      tabIndex: isFinancialPlan ? 1 : 0,
    });
  };

  const onCancelButtonClick = () => {
    setActiveDialog({
      isVisible: false,
      costPlan: null,
      tabIndex: 0,
    });
  };

  return (
    <Grid container item direction="column">
      <Grid item>
        <Typography variant="subtitle1">
          {isFinancialPlan ? t('label:cost-plan-overview:financial-plan-title') : t('label:cost-plan-overview:cost-plan-title')}
        </Typography>
      </Grid>
      <Grid item className={classNames(classes.totalMoney, styleOfTotalColorLabel)}>
        <Typography variant="subtitle2">
          {isFinancialPlan ? t('label:cost-plan-overview:planned-total') : t('label:cost-plan-overview:current-total')}
        </Typography>
        <Typography variant="subtitle2">{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(total)}</Typography>
      </Grid>
      <Grid item>
        {plans.map(plan => (
          <Grid key={plan.ID} container item xs={6} justify="space-between" className={classes.phase}>
            <Grid item xs={9}>
              <Grid container item xs={12} direction="column" spacing={1}>
                <Grid item>
                  <Typography variant="body1">{plan.phase}</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1">
                    {t('label:cost-plan-overview:total')} :{' '}
                    {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(plan.sum)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={() => onEditButtonClick(isFinancialPlan ? plan.costPlan : plan)}
              >
                <Icon>visibility</Icon>
              </Button>
              <CostPlanFormDialog
                isOpen={activeDialog.isVisible}
                isReadonly={true}
                costPlan={activeDialog.costPlan}
                costPlanPhases={plans.map(plan => plan.phase)}
                onCancelButtonClick={onCancelButtonClick}
                tabIndex={isFinancialPlan ? 1 : 0}
              />
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

const plansStyle = makeStyles(() => ({
  labelAchieved: {
    color: '#63A373 !important',
  },
  labelNotAchieved: {
    color: '#F07D00 !important',
  },
  totalMoney: {
    marginTop: '30px',
    marginBottom: '30px',
    color: '#6f6f6f',
  },
  phase: {
    marginTop: 10,
    marginBottom: 50,
  },
}));

const CostPlansOverviewHeader = (props: any) => {
  const classes = headerStyle();
  const { offer } = props;
  const { t } = useTranslation();

  return (
    <Grid container item direction="column" spacing={2}>
      <Grid item>
        <Grid container item spacing={2}>
          <Grid item>
            <Typography
              variant="subtitle1"
              className={classes.assignmentName}
              color="primary"
              component={Link}
              to={`/assignment/${offer.measureType === OfferType.mis ? 'mis' : 'mwi'}/${offer.ID}`}
            >
              {offer.name}
            </Typography>
          </Grid>
          <Grid item className={classes.greaterThanSign}>
            <Typography component="span">
              <ChevronRightIcon />
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1">{t('label:cost-plan-overview:title')}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Button className={classes.backButton} size="medium" variant="outlined" color="primary" component={Link} to={'/assignment/my'}>
          {t('label:cost-plan-overview:back-button')}
        </Button>
      </Grid>
      <Divider classes={{ root: classes.divider }} />
    </Grid>
  );
};

const headerStyle = makeStyles(() => ({
  assignmentName: {
    textTransform: 'capitalize',
    textDecoration: 'none',
  },
  backButton: {
    maxWidth: 150,
  },
  divider: {
    marginTop: 20,
    backgroundColor: '#1AA6E3',
  },
  greaterThanSign: {
    display: 'flex',
    alignItems: 'center',
  },
}));
