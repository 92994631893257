import React from 'react';
import { Button, makeStyles, Theme } from '@material-ui/core';
import { Contacts } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) => ({
  importButton: {
    backgroundColor: theme.palette.success.main,
    marginRight: 8,
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
    },
  },
  importButtonDisabled: {
    marginRight: 8,
    backgroundColor: theme.palette.grey.A100,
  },
  importButtonIcon: {
    marginRight: 8,
  },
}));

interface ContactButtonProps {
  label: String;
  onClick?: () => void;
  isDisabled?: boolean;
}

export const ContactButton = (props: ContactButtonProps) => {
  const { label, onClick, isDisabled } = props;
  const classes = useStyles();

  return (
    <Button className={isDisabled ? classes.importButtonDisabled : classes.importButton} onClick={onClick} disabled={isDisabled}>
      <Contacts className={classes.importButtonIcon} />
      {label}
    </Button>
  );
};
