import { GeneralValueMap } from './GeneralValueMap';

export const financialPlanTypes: GeneralValueMap[] = [
  {
    locale: 'lbl.financialPlanTypeOwn',
    value: 'finance-plan:finance-plan-type:personal-contribution',
    key: '3',
  },
  {
    locale: 'lbl.financialPlanTypeMissio',
    value: 'finance-plan:finance-plan-type:missio',
    key: '1',
  },
  {
    locale: 'lbl.financialPlanTypeOther',
    value: 'finance-plan:finance-plan-type:other-income',
    key: '4',
  },
  {
    locale: 'lbl.financialPlanTypeThird',
    value: 'finance-plan:finance-plan-type:third-party-funds',
    key: '2',
  },
];
