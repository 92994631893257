import { PlanOverview } from './plan-overview';
import CostPlan from '../sdk/com/apiomat/frontend/missio/CostPlan';

export const createPlanOverviewObject = (costPlans: CostPlan[]): PlanOverview => {
  const planOverviewObject = costPlans.reduce(
    (planOverviewObject: PlanOverview, costPlan) => {
      const financialPlan = costPlan.financialPlan;
      planOverviewObject.financialPlans.push({
        sum: financialPlan.sumEuro,
        phase: String(costPlan.phase),
        ID: financialPlan.ID,
        costPlan: costPlan,
      });
      planOverviewObject.costPlans.push(costPlan);
      planOverviewObject.financialPlanTotal += financialPlan.sumEuro;
      planOverviewObject.costPlanTotal += costPlan.sum;
      return planOverviewObject;
    },
    {
      costPlanTotal: 0,
      financialPlanTotal: 0,
      financialPlans: [],
      costPlans: [],
    }
  );

  return planOverviewObject;
};
