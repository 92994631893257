import React, { useState, useEffect } from 'react';
import { Grid, Checkbox, FormControlLabel, Typography, Button, DialogActions } from '@material-ui/core';
import { DefaultTextField } from '../TextInputs/TextInputs';
import { useTranslation } from 'react-i18next';
import DropDown from '../Selection/DropDown';
import { misMeasureDropDownItems } from '../../value-maps/MisMeasureDropDownItems';
import { mwiMeasureDropDownItems } from '../../value-maps/MwiMeasureDropDownItems';
import FormTemplate from '../../sdk/com/apiomat/frontend/missio/FormTemplate';
import { useDispatch } from 'react-redux';
import { getMisMeasureNames, getMwiMeasureNames, getOtherMeasureNames } from '../../utils/form-templates.utils';
import { DocumentPickerButton } from '../Buttons/DocumentPickerButton';
import { FileData, formTemplateActions } from '../../store/formTemplate';
import { onDownloadFile } from '../../utils/file.utils';
import { otherMeasureDropDownItems } from '../../value-maps/OtherMeasureDropDownItems';
import { OtherMeasureTypes } from '../../enums/MeasureTypes';
import { getTranslatedSortedAttachmentTypes } from '../../utils/attachment.util';

export interface TemplateFormProps {
  template: FormTemplate;
  onClose: () => void;
}

const TemplateForm = (props: TemplateFormProps) => {
  const { template, onClose } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [name, setName] = useState<string>('');
  const [attachmentType, setAttachmentType] = useState<string>('');
  const [misMeasures, setMisMeasures] = useState<string[]>([]);
  const [mwiMeasures, setMwiMeasures] = useState<string[]>([]);
  const [otherMeasures, setOtherMeasures] = useState<string[]>([]);
  const [file, setFile] = useState<FileData>(null);

  useEffect(() => {
    setName(template?.name);
    setAttachmentType(template?.attachmentType);
    setMisMeasures(getMisMeasureNames(template));
    setMwiMeasures(getMwiMeasureNames(template));
    setOtherMeasures(getOtherMeasureNames(template));
    setFile({ fileURL: template?.fileURL, name: '' });
  }, [template]);

  const toggleMisMeasure = (key: string) => {
    if (misMeasures.includes(key)) {
      setMisMeasures(misMeasures.filter(el => el !== key));
    } else {
      setMisMeasures([...misMeasures, key]);
    }
  };

  const toggleMwiMeasure = (key: string) => {
    if (mwiMeasures.includes(key)) {
      setMwiMeasures(mwiMeasures.filter(el => el !== key));
    } else {
      setMwiMeasures([...mwiMeasures, key]);
    }
  };

  const toggleOtherMeasure = (key: string) => {
    if (otherMeasures.includes(key)) {
      setOtherMeasures(otherMeasures.filter(el => el !== key));
    } else {
      setOtherMeasures([...otherMeasures, key]);
    }
  };

  const onDocumentSelected = (fileBuffer: string | ArrayBuffer | null, name: string) => {
    if (!fileBuffer) {
      throw new Error('Error while selecting file');
    }

    setFile({ fileBuffer, name });
  };

  const onSave = async () => {
    template.name = name;
    template.attachmentType = attachmentType;
    template.measureTypes = [...misMeasures, ...mwiMeasures, ...otherMeasures];
    if (!template.measureTypes.includes(OtherMeasureTypes.all)) template.measureTypes.push(OtherMeasureTypes.all);
    dispatch(formTemplateActions.saveFormTemplate({ formTemplate: template, file }));
    onClose();
  };

  const isInvalid =
    Boolean(name) === false ||
    Boolean(attachmentType) === false ||
    (Boolean(file.fileURL) === false && Boolean(file.fileBuffer) === false) ||
    [...misMeasures, ...mwiMeasures, ...otherMeasures].length === 0;

  return (
    <div>
      <Grid container direction="column" spacing={3}>
        <Grid item xs={12}>
          <DefaultTextField labelKey="form-templates:dialog:name" value={name} onChange={event => setName(event.target.value)} />
        </Grid>
        <Grid item xs={12}>
          <DropDown
            labelKey={'form-templates:dialog:attachment-type'}
            items={getTranslatedSortedAttachmentTypes(t)}
            selectedItemKey={attachmentType}
            onSelectedItemChange={(value: string) => setAttachmentType(value)}
          />
        </Grid>
        <Grid container item xs={12} spacing={2} alignItems="center">
          {Boolean(file?.fileURL) && (
            <Grid item xs={2}>
              <Button
                variant="contained"
                fullWidth
                color="primary"
                onClick={() => onDownloadFile(template, 'file')}
                style={{ minHeight: 52 }}
              >
                {t('view')}
              </Button>
            </Grid>
          )}
          <Grid item xs={Boolean(file?.fileURL) ? 10 : 12}>
            <DocumentPickerButton onDocumentSelected={(file, name) => onDocumentSelected(file, name)} fileName={file?.name} />
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Typography variant="subtitle2">{t('form-templates:dialog:mis')}</Typography>
          <Grid item xs={12} container spacing={1}>
            {misMeasureDropDownItems.map(item => (
              <Grid item>
                <FormControlLabel
                  control={<Checkbox checked={misMeasures.includes(item.key)} onChange={() => toggleMisMeasure(item.key)} />}
                  label={t(item.value as string)}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Typography variant="subtitle2">{t('form-templates:dialog:mwi')}</Typography>
          <Grid item xs={12} container spacing={1}>
            {mwiMeasureDropDownItems.map(item => (
              <Grid item>
                <FormControlLabel
                  control={<Checkbox checked={mwiMeasures.includes(item.key)} onChange={() => toggleMwiMeasure(item.key)} />}
                  label={t(item.value as string)}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Typography variant="subtitle2">{t('form-templates:dialog:other')}</Typography>
          <Grid item xs={12} container spacing={1}>
            {otherMeasureDropDownItems
              .filter(item => item.key !== OtherMeasureTypes.all)
              .map(item => (
                <Grid item>
                  <FormControlLabel
                    control={<Checkbox checked={otherMeasures.includes(item.key)} onChange={() => toggleOtherMeasure(item.key)} />}
                    label={t(item.value as string)}
                  />
                </Grid>
              ))}
          </Grid>
        </Grid>
      </Grid>
      <DialogActions>
        <Button variant="contained" onClick={onClose}>
          {t('button:label:cancel')}
        </Button>
        <Button variant="contained" color="primary" onClick={onSave} disabled={isInvalid}>
          {t('button:label:save')}
        </Button>
      </DialogActions>
    </div>
  );
};

export default TemplateForm;
