import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { Theme, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import { AppBarLogo } from '../Logo/Logo';
import UserMenu from './UserMenu';
import { useTranslation } from 'react-i18next';
import SearchBar from '../Search/SearchBar';
import { useDispatch } from 'react-redux';
import { userActions } from '../../store/users';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.primary.light,
  },
}));

export default () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();

  const searchUsers = (searchTerm: string) => {
    const query = `lastName like "${searchTerm}" or email like "${searchTerm}" or navContactId like "${searchTerm}"`;
    dispatch(userActions.searchUsers(query));
  };

  return (
    <AppBar position="fixed" className={classNames(classes.appBar)}>
      <Toolbar disableGutters>
        <AppBarLogo />
        {location.pathname === '/user-management' && (
          <SearchBar placeholder={`${t('user-last-name')}, ${t('user-contact-id')}, ${t('user-email')}`} onChange={searchUsers} />
        )}
        <UserMenu />
      </Toolbar>
    </AppBar>
  );
};
