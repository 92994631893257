import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Translation from '../sdk/com/apiomat/frontend/localisation/Translation';

import { Language } from '../enums/Language';
import Datastore from '../sdk/com/apiomat/frontend/Datastore';
import I18nBackend from './i18n-backend';
import moment from 'moment';

export const languages = [Language.EN, Language.DE, Language.FR, Language.PT];

const getLangFromAom = async (language: string): Promise<any[]> => {
  const translates = await Translation.getTranslations(`language=="${language}"`);
  return (translates || []).map(item => item.wording);
};

const initBackend = () => {
  Datastore.configureAsGuest();
};

const backend = new I18nBackend(null, {
  resources: {
    [Language.DE]: { translation: {} },
    [Language.EN]: { translation: {} },
    [Language.FR]: { translation: {} },
    [Language.PT]: { translation: {} },
  },
  getLangFromBackend: getLangFromAom,
  initBackend,
});

/** add local translations */
i18n
  .use(backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: Language.EN,
    debug: false,
    whitelist: languages,
    nsSeparator: false,
    keySeparator: false,
    saveMissing: true,
    react: {
      wait: true,
      bindI18nStore: 'added',
    } as any,
  });

i18n.on('languageChanged', lang => moment.locale(lang));
i18n.on('missingKey', (lngs, namespace, key, res) => {
  if (key) {
    i18n.reloadResources();
  }
});

export default i18n;
