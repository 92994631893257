export enum ValidationType {
  fieldNotEmpty = 'fieldNotEmpty',
  biggerThanZero = 'biggerThanZero',
  greaterThanOrEqualToZero = 'greaterThanOrEqualToZero',
  equalToOne = 'equalToOne',
  equalToZeroOrOne = 'equalToZeroOrOne',
}

export interface ValidationCondition {
  readonly discriminator: ValidationType;
  readonly key: string;
  readonly value: string | number | undefined;
}

export interface InvalidResult {
  readonly key: string;
  readonly discriminator: ValidationType;
}

/*export const checkIfReportBeforeCurrentReportHasBeenSent = (report: Report, reports: Report[]): string => {
  const costPlanPhaseBefore = Number(report.costPlan.phase) - 1;
  if (costPlanPhaseBefore !== 0) {
    const reportBefore = reports.find(report => report.costPlan.phase === costPlanPhaseBefore);
    if (reportBefore?.state?.name === StateType.completed || reportBefore?.state?.name === StateType.easydor) {
      return null;
    } else {
      return 'use-cases:new-report:root:report-before-not-uploaded';
    }
  }
  return null;
};*/
