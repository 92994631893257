import React from 'react';
import TabView, { TabItem } from '../../components/Tabs/TabView';
import { StateType } from '../../enums/StateType';
import MyTasks from './MyTasks';

const AllMyTasks = () => {
  const tabs: TabItem[] = [
    {
      key: 'open-tasks',
      label: 'tab:title:open-tasks',
      value: (
        <MyTasks
          querySuffix={`and state.name!="${StateType.completed}" and state.name!="${StateType.approved}" and state.name!="${StateType.easydor}"`}
        />
      ),
    },
    {
      key: 'completed-tasks',
      label: 'tab:title:completed-tasks',
      value: (
        <MyTasks
          querySuffix={`and state.name!="${StateType.cloud}" and state.name!="${StateType.enquiry}" and state.name!="${StateType.easydorError}"`}
        />
      ),
    },
    {
      key: 'all-tasks',
      label: 'tab:title:all-tasks',
      value: <MyTasks querySuffix={``} />,
    },
  ];

  return <TabView tabs={tabs} />;
};

export default AllMyTasks;
