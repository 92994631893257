import * as React from 'react';
import { CircularProgress, makeStyles, Theme } from '@material-ui/core';

export interface LoadingIndicatorProps {
  size?: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  progressWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  progress: {
    margin: theme.spacing(2),
  },
}));

const LoadingIndicator = (props: LoadingIndicatorProps) => {
  const classes = useStyles();
  return (
    <div className={ classes.progressWrapper }>
      <CircularProgress color="primary" className={ classes.progress } size={ props.size ? props.size : 40 }/>
    </div>
  );
};

export default LoadingIndicator;
