import Offers from '../sdk/com/apiomat/frontend/missio/Offers';
import { OfferType } from '../enums/OfferType';
import Reports from '../sdk/com/apiomat/frontend/missio/Reports';

export const isOfferTypeMis = (offer: Offers | Reports): boolean => {
  return offer.measureType === OfferType.mis;
};

export const isOfferTypeMwi = (offer: Offers | Reports): boolean => {
  return offer.measureType === OfferType.mwi;
};
