import { makeStyles } from '@material-ui/core';
import { TextField, TextFieldProps, Typography } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiFormLabel-root.Mui-error': {
      color: 'orange',
    },
    '& .MuiInput-underline.Mui-error:after': {
      borderColor: 'orange',
    },
    '& .MuiFormHelperText-root.Mui-error': {
      color: 'orange',
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: 'white',
    },
  },
  descriptionText: {
    '&.MuiTypography-root': {
      display: 'inline-flex',
      flexDirection: 'column',
      position: 'relative',
      color: 'rgba(0, 0, 0, 0.6)',
      marginLeft: 14,
      marginRight: 14
    }
  }
}));

export type FormTextFieldProps = {
  id: string;
  name: string;
  labelKey: string;
  descriptionKey?: string;
  errorMessage?: string;
} & Omit<TextFieldProps, 'id' | 'name' | 'label' | 'helperText' | 'error' | 'variant'>;

const FormTextField: FunctionComponent<FormTextFieldProps> = props => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { id, name, labelKey, descriptionKey, errorMessage, ...textFieldProps } = props;

  return (
    // ignore ts bug https://github.com/mui/material-ui/issues/15697
    //@ts-ignore
    <>
      <TextField
        { ...textFieldProps }
        id={ id }
        name={ name }
        classes={ classes }
        fullWidth
        label={ t(labelKey) }
        error={ Boolean(errorMessage) }
        helperText={ errorMessage }
      />
      { descriptionKey &&
        <Typography variant={ 'caption' } className={ classes.descriptionText }>
          { t(descriptionKey) }
        </Typography> }
    </>
  );
};

export default FormTextField;
