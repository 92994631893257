import React, { useState } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { DefaultTextField } from '../../components/TextInputs/TextInputs';
import SaveIcon from '@material-ui/icons/Save';
import MUser from '../../sdk/com/apiomat/frontend/missio/MUser';
import DefaultDialog from '../../components/Dialogs/DefaultDialog';
import { useTranslation } from 'react-i18next';
import EditIcon from '@material-ui/icons/Edit';
import { UserContactIDChange } from '../../store/users';

const useStyles = makeStyles(() => ({
  contactIdContent: {
    display: 'flex',
  },
  contactInfoContainer: {
    minWidth: '140px',
    maxWidth: '200px',
  },
}));

export interface ContactInfoProps {
  user?: MUser;
  saveNavContactId: (idChange: UserContactIDChange) => void;
}

export default (props: ContactInfoProps) => {
  const { user, saveNavContactId } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [navContactId, setNavContactId] = useState(user.navContactId || '');
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div className={classes.contactInfoContainer}>
      <div className={classes.contactIdContent}>
        <DefaultTextField value={user.navContactId} disabled={true} />
        <Button onClick={() => setIsOpen(true)}>
          <EditIcon color="primary" />
        </Button>
      </div>
      {isOpen && (
        <DefaultDialog
          maxWidth="xs"
          isOpen={isOpen}
          children={
            <div className={classes.contactIdContent}>
              <DefaultTextField value={navContactId} onChange={event => setNavContactId(event.target.value)} />
              <Button
                onClick={() => {
                  saveNavContactId({ user, navContactId });
                  setIsOpen(false);
                }}
              >
                <SaveIcon color="primary" />
              </Button>
            </div>
          }
          title={t('contact:contact-id:dialog:column:navigation-id')}
          actionComponent={
            <>
              <Button
                onClick={() => {
                  setNavContactId(user.navContactId);
                  setIsOpen(false);
                }}
                color="primary"
              >
                {t('button:label:cancel')}
              </Button>
            </>
          }
        />
      )}
    </div>
  );
};
