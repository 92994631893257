import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import DropDown from '../../../Selection/DropDown';
import { useTranslation } from 'react-i18next';
import Scholarship from '../../../../sdk/com/apiomat/frontend/missio/Scholarship';
import { graduations } from '../../../../value-maps/Graduations';
import { alphabeticalSort } from '../../../../utils/sort.util';
import { DropDownItem } from '../../../Selection/DropDownItem';
import { useOfferFormContext } from '../../context/OfferFormContext';
import { object, date, string } from 'yup';
import FormDatePicker from '../../../DatePicker/FormDatePicker';
import FormTextField from '../../InputFields/FormTextField';
import { useFormik } from 'formik';

export interface ScholarshipProps {
  readOnlyMode: boolean;
  data: any;
  isReport?: boolean;
}

const ScholarshipForm = (props: ScholarshipProps) => {
  const { readOnlyMode, data, isReport } = props;
  const { t } = useTranslation();
  const { state, dispatch } = useOfferFormContext();

  const {
    start,
    end,
    measureDescription,
    priorScholars,
    description,
    studyReason,
    university,
    location,
    reasonLocation,
    locationDescription,
    graduation,
  } = data.measure as Scholarship;

  const validationSchema = object({
    start: date().required(t('mandatory_field')),
    end: date().required(t('mandatory_field')),
    measureDescription: string().required(t('mandatory_field')),
    priorScholars: string(),
    description: string(),
    studyReason: string(),
    university: string(),
    location: string(),
    reasonLocation: string(),
    locationDescription: string(),
    graduation: string(),
  });

  const formik = useFormik({
    initialValues: {
      start,
      end,
      measureDescription,
      priorScholars,
      description,
      studyReason,
      university,
      location,
      reasonLocation,
      locationDescription,
      graduation,
    },
    enableReinitialize: true,
    validationSchema,
    validateOnMount: true,
    onSubmit: () => {},
  });

  useEffect(() => {
    if (!readOnlyMode) {
      if (!formik.isValidating) {
        dispatch({ subFormId: 'measure', errors: formik.errors, values: formik.values });
      }
    }
  }, [formik.errors, formik.values, dispatch, formik.isValidating, readOnlyMode, state]);

  const graduationItems: DropDownItem[] = graduations.map(el => ({ key: el.key, value: t(el.key) })).sort(alphabeticalSort);

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <FormDatePicker
            id="start"
            name="start"
            label={t('input:label:measure:planned-project-start')}
            value={formik.values.start}
            onChange={value => formik.setFieldValue('start', value)}
            disabled={readOnlyMode}
            errorMessage={formik.errors.start}
          />
        </Grid>
        <Grid item xs={6}>
          <FormDatePicker
            id="end"
            name="end"
            label={t('input:label:measure:planned-project-end')}
            value={formik.values.end}
            minDate={formik.values.start}
            onChange={value => formik.setFieldValue('end', value)}
            disabled={readOnlyMode}
            errorMessage={formik.errors.end}
          />
        </Grid>
        <Grid item xs={12}>
          <FormTextField
            id="measureDescription"
            name="measureDescription"
            disabled={readOnlyMode}
            labelKey="input:label:measure:measure-description"
            value={formik.values.measureDescription}
            multiline
            rows={3}
            onChange={formik.handleChange}
            errorMessage={formik.errors.measureDescription}
          />
        </Grid>
        {!isReport && (
          <>
            <Grid item xs={12}>
              <FormTextField
                id="priorScholars"
                name="priorScholars"
                disabled={readOnlyMode}
                labelKey="input:label:measure:scholarship-previous-scholarship"
                descriptionKey="info:measure:scholarship:previous-scholarship"
                value={formik.values.priorScholars}
                multiline
                rows={3}
                maxRows={10}
                onChange={formik.handleChange}
                errorMessage={formik.errors.priorScholars}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                id="description"
                name="description"
                disabled={readOnlyMode}
                labelKey="input:label:measure:scholarship-studies-description"
                descriptionKey="info:measure:scholarship:studies-description"
                value={formik.values.description}
                multiline
                rows={3}
                maxRows={10}
                onChange={formik.handleChange}
                errorMessage={formik.errors.description}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                id="studyReason"
                name="studyReason"
                disabled={readOnlyMode}
                labelKey="input:label:measure:scholarship-studying-reasons"
                descriptionKey="info:measure:scholarship:studying-reasons"
                value={formik.values.studyReason}
                multiline
                rows={3}
                maxRows={10}
                onChange={formik.handleChange}
                errorMessage={formik.errors.studyReason}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                id="university"
                name="university"
                disabled={readOnlyMode}
                labelKey="input:label:measure:scholarship-university"
                descriptionKey="info:measure:scholarship:university"
                value={formik.values.university}
                multiline
                rows={3}
                maxRows={10}
                onChange={formik.handleChange}
                errorMessage={formik.errors.university}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                id="location"
                name="location"
                disabled={readOnlyMode}
                labelKey="input:label:measure:scholarship-study-location"
                descriptionKey="info:measure:scholarship:study-location"
                value={formik.values.location}
                multiline
                rows={3}
                maxRows={10}
                onChange={formik.handleChange}
                errorMessage={formik.errors.location}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                id="reasonLocation"
                name="reasonLocation"
                disabled={readOnlyMode}
                labelKey="input:label:measure:scholarship-study-place-reason"
                descriptionKey="info:measure:scholarship:study-place-reason"
                value={formik.values.reasonLocation}
                multiline
                rows={3}
                maxRows={10}
                onChange={formik.handleChange}
                errorMessage={formik.errors.reasonLocation}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                id="locationDescription"
                name="locationDescription"
                disabled={readOnlyMode}
                labelKey="input:label:measure:scholarship-previous-location-description"
                value={formik.values.locationDescription}
                multiline
                rows={3}
                maxRows={10}
                onChange={formik.handleChange}
                errorMessage={formik.errors.locationDescription}
              />
            </Grid>
            <Grid item xs={12}>
              <DropDown
                labelKey="info:measure:scholarship:desired-degree"
                helperTextKey="input:label:measure:scholarship-desired-degree"
                items={graduationItems}
                disabled={readOnlyMode}
                selectedItemKey={formik.values.graduation}
                onSelectedItemChange={(value: string) => formik.setFieldValue('graduation', value)}
                errorMessage={formik.errors.graduation}
              />
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
};

export default ScholarshipForm;
