import React from 'react';
import { useTranslation } from 'react-i18next';
import LockIcon from '@material-ui/icons/Lock';
import EmailIcon from '@material-ui/icons/EmailOutlined';
import { InputAdornment, TextField, TextFieldProps } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiFormLabel-root.Mui-error': {
      color: 'orange',
    },
    '& .MuiInput-underline.Mui-error:after': {
      borderColor: 'orange',
    },
    '& .MuiFormHelperText-root.Mui-error': {
      color: 'orange',
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: 'white',
    },
  },
}));

export const EmailTextField = (props: TextFieldProps) => {
  const { t } = useTranslation();

  return (
    <TextField
      { ...props }
      type="email"
      placeholder={ t('input:placeholder:email') }
      InputProps={ {
        startAdornment: (
          <InputAdornment position="start">
            <EmailIcon color="disabled"/>
          </InputAdornment>
        ),
      } }
    />
  );
};

export const PasswordTextField = (props: TextFieldProps) => {
  const { t } = useTranslation();

  return (
    <TextField
      { ...props }
      type="password"
      placeholder={ t('input:placeholder:password') }
      InputProps={ {
        startAdornment: (
          <InputAdornment position="start">
            <LockIcon color="disabled"/>
          </InputAdornment>
        ),
      } }
    />
  );
};

type DefaultTextFieldProps = {
  labelKey?: string;
  helperTextKey?: string;
  isError?: boolean;
};

export const DefaultTextField = (props: DefaultTextFieldProps & Omit<TextFieldProps, 'label' | 'helperText'>) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { labelKey, helperTextKey, isError, ...textFieldProps } = props;
  return (
    // ignore ts bug https://github.com/mui/material-ui/issues/15697
    //@ts-ignore
    <TextField
      classes={ classes }
      { ...textFieldProps }
      fullWidth
      label={ props.labelKey ? t(props.labelKey) : undefined }
      helperText={ props.helperTextKey ? t(props.helperTextKey) : undefined }
      value={ props.value === undefined ? '' : props.value }
      error={ isError }
    />
  );
};

export const DeprecatedTextField = (props: TextFieldProps) => <TextField { ...props } fullWidth/>;

export interface DefaultNumberFieldProps {
  step?: number;
  onValueChanged?: (value: number) => void;
  _error?: any;
  errorMessage?: string;
}

export const DefaultNumberField = (
  props: DefaultNumberFieldProps & DefaultTextFieldProps & Omit<TextFieldProps, 'label' | 'helperText'>
) => {
  const { value, step, onValueChanged, _error, errorMessage } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const parseInput = (value: string): number | false => {
    const parsedValue = Number.parseFloat(value).toFixed(4);
    if (isNaN(Number(parsedValue))) {
      return false;
    } else {
      return Number(parsedValue);
    }
  };

  const handleValueChanged = (value: string): void => {
    if (!onValueChanged) {
      return;
    }
    if (value.split('.').length > 1 && value.split('.')[1].length > 4) {
      return;
    }
    onValueChanged(parseInput(value) as number);
  };

  if (value === undefined) {
    handleValueChanged('0');
  }

  return (
    // ignore ts bug https://github.com/mui/material-ui/issues/15697
    //@ts-ignore
    <TextField
      classes={ classes }
      { ...props }
      fullWidth
      InputLabelProps={ { shrink: true } }
      type="number"
      inputProps={{
        step: step ? step : 1
      }}
      label={ props.labelKey ? t(props.labelKey) : undefined }
      helperText={ props.helperTextKey ? t(props.helperTextKey) : Boolean(errorMessage) ? errorMessage : undefined }
      { ...(_error && { error: true, helperText: _error }) }
      value={ value === undefined ? 0 : value }
      error={ Boolean(errorMessage) }
      onChange={ event => handleValueChanged(event.target.value) }
    />
  );
};
