import React, { useEffect, useMemo } from 'react';
import { Grid } from '@material-ui/core';
import DropDown from '../../../Selection/DropDown';
import { useMwiMeasuresStyles } from './Styles';
import { useTranslation } from 'react-i18next';
import RadioButtons from '../../../Buttons/RadioButtons';
import DialogButton from '../../../Dialogs/DialogButton';
import { countries } from '../../../../value-maps/Countries';
import MwiConferenceAttendance from '../../../../sdk/com/apiomat/frontend/missio/MwiConferenceAttendance';
import { subjects } from '../../../../value-maps/Subjects';
import { alphabeticalSort } from '../../../../utils/sort.util';
import { DropDownItem } from '../../../Selection/DropDownItem';
import { useFormik } from 'formik';
import { object, date, string, number } from 'yup';
import FormDatePicker from '../../../DatePicker/FormDatePicker';
import FormTextField from '../../InputFields/FormTextField';

import { useOfferFormContext } from '../../context/OfferFormContext';

export interface ConferenceParticipationProps {
  readOnlyMode: boolean;
  data: any;

  isReport?: boolean;
}

const ConferenceParticipation = (props: ConferenceParticipationProps) => {
  const { state, dispatch } = useOfferFormContext();
  const { readOnlyMode, data, isReport } = props;
  const classes = useMwiMeasuresStyles();
  const { t } = useTranslation();

  const {
    start,
    end,
    measureDescription,
    organiserName,
    organiserLocation,
    organiserCountry,
    university,
    location,
    country,
    subject,
    topic,
    topicMeaning,
    isSpeaker,
    lectureSubject,
    relevanceAttendance,
    isScholarshipApplicationElsewhere,
    hasReadAdvertisingStatement,
    hasReadConditions,
  } = data.measure as MwiConferenceAttendance;

  const validationSchema = useMemo(
    () =>
      object({
        start: date().required(t('mandatory_field')),
        end: date().required(t('mandatory_field')),
        measureDescription: string().required(t('mandatory_field')),
        organiserName: isReport ? string() : string().required(t('mandatory_field')),
        organiserLocation: isReport ? string() : string().required(t('mandatory_field')),
        organiserCountry: isReport ? string() : string().required(t('mandatory_field')),
        university: string(),
        location: isReport ? string() : string().required(t('mandatory_field')),
        country: isReport ? string() : string().required(t('mandatory_field')),
        subject: isReport ? string() : string().required(t('mandatory_field')),
        topic: isReport ? string() : string().required(t('mandatory_field')),
        topicMeaning: string().required(t('mandatory_field')),
        isSpeaker: isReport ? number() : number().equals([0, 1], t('mandatory_field')),
        lectureSubject: isReport ? string() : string().required(t('mandatory_field')),
        relevanceAttendance: string().required(t('mandatory_field')),
        isScholarshipApplicationElsewhere: isReport ? string() : string().required(t('mandatory_field')),
        hasReadAdvertisingStatement: isReport ? number() : number().equals([1], t('mandatory_field')),
        hasReadConditions: isReport ? number() : number().equals([1], t('mandatory_field')),
      }),
    [t]
  );

  const formik = useFormik({
    initialValues: {
      start,
      end,
      measureDescription,
      organiserName,
      organiserLocation,
      organiserCountry,
      university,
      location,
      country,
      subject,
      topic,
      topicMeaning,
      isSpeaker,
      lectureSubject,
      relevanceAttendance,
      isScholarshipApplicationElsewhere,
      hasReadAdvertisingStatement,
      hasReadConditions,
    },
    enableReinitialize: true,
    validationSchema,
    validateOnMount: true,
    onSubmit: () => {},
  });

  const countryItems: DropDownItem[] = useMemo(() => countries.map(el => ({ key: el.key, value: t(el.key) })).sort(alphabeticalSort), [t]);
  const subjectItems: DropDownItem[] = useMemo(() => subjects.map(el => ({ key: el.key, value: t(el.key) })).sort(alphabeticalSort), [t]);

  useEffect(() => {
    if (!readOnlyMode) {
      if (!formik.isValidating) {
        dispatch({ subFormId: 'measure', errors: formik.errors, values: formik.values });
      }
    }
  }, [formik.errors, formik.values, dispatch, formik.isValidating, readOnlyMode, state]);

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <FormDatePicker
            id="start"
            name="start"
            label={t('input:label:measure:planned-project-start')}
            value={formik.values.start}
            disabled={readOnlyMode}
            onChange={value => formik.setFieldValue('start', value)}
            errorMessage={formik.errors.start}
          />
        </Grid>
        <Grid item xs={6}>
          <FormDatePicker
            id="end"
            name="end"
            label={t('input:label:measure:planned-project-end')}
            value={formik.values.end}
            minDate={formik.values.start}
            disabled={readOnlyMode}
            onChange={value => formik.setFieldValue('end', value)}
            errorMessage={formik.errors.end}
          />
        </Grid>
        <Grid item xs={12}>
          <FormTextField
            id="measureDescription"
            name="measureDescription"
            disabled={readOnlyMode}
            labelKey="input:label:measure:measure-description"
            value={formik.values.measureDescription}
            multiline
            rows={3}
            onChange={formik.handleChange}
            errorMessage={formik.errors.measureDescription}
          />
        </Grid>
        {!isReport && (
          <>
            <Grid item xs={12}>
              <FormTextField
                id="organiserName"
                name="organiserName"
                disabled={readOnlyMode}
                labelKey="input:label:measure:mwi:conference-participation:organizers-name"
                value={formik.values.organiserName}
                onChange={formik.handleChange}
                errorMessage={formik.errors.organiserName}
              />
            </Grid>
            <Grid item xs={6}>
              <FormTextField
                id="organiserLocation"
                name="organiserLocation"
                disabled={readOnlyMode}
                labelKey="input:label:measure:mwi:conference-participation:organizers-address"
                value={formik.values.organiserLocation}
                onChange={formik.handleChange}
                errorMessage={formik.errors.organiserLocation}
              />
            </Grid>
            <Grid item xs={6}>
              <FormTextField
                id="organiserCountry"
                name="organiserCountry"
                disabled={readOnlyMode}
                labelKey="input:label:measure:mwi:conference-participation:organizers-country"
                value={formik.values.organiserCountry}
                onChange={formik.handleChange}
                errorMessage={formik.errors.organiserCountry}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                id="university"
                name="university"
                disabled={readOnlyMode}
                labelKey="input:label:measure:mwi:conference-participation:university"
                value={formik.values.university}
                onChange={formik.handleChange}
                errorMessage={formik.errors.university}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                id="location"
                name="location"
                disabled={readOnlyMode}
                labelKey="input:label:measure:mwi:conference-participation:location"
                value={formik.values.location}
                onChange={formik.handleChange}
                errorMessage={formik.errors.location}
              />
            </Grid>
            <Grid item xs={12}>
              <DropDown
                labelKey="input:label:measure:mwi:conference-participation:country"
                items={countryItems}
                disabled={readOnlyMode}
                onSelectedItemChange={(key: string) => formik.setFieldValue('country', key)}
                selectedItemKey={formik.values.country}
                errorMessage={formik.errors.country}
              />
            </Grid>
            <Grid item xs={12}>
              <DropDown
                labelKey="input:label:measure:mwi:conference-participation:subject"
                helperTextKey="info:measure:mwi:conference-participation:subject"
                disabled={readOnlyMode}
                selectedItemKey={formik.values.subject}
                items={subjectItems}
                onSelectedItemChange={(key: string) => formik.setFieldValue('subject', key)}
                errorMessage={formik.errors.subject}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                id="topic"
                name="topic"
                disabled={readOnlyMode}
                labelKey="input:label:measure:mwi:conference-participation:topic-of-the-conference"
                descriptionKey="info:measure:mwi:conference-participation:topic-of-the-conference"
                value={formik.values.topic}
                multiline
                rows={3}
                onChange={formik.handleChange}
                errorMessage={formik.errors.topic}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <FormTextField
            id="topicMeaning"
            name="topicMeaning"
            disabled={readOnlyMode}
            labelKey="input:label:measure:mwi:conference-participation:meaning-of-the-conference"
            descriptionKey="info:measure:mwi:conference-participation:meaning-of-the-conference"
            value={formik.values.topicMeaning}
            multiline
            rows={3}
            onChange={formik.handleChange}
            errorMessage={formik.errors.topicMeaning}
          />
        </Grid>
        {!isReport && (
          <>
            <Grid item xs={3}>
              <RadioButtons
                title={t('question:label:measure:mwi:conference-participation:own-lecture')}
                value={formik.values.isSpeaker}
                disabled={readOnlyMode}
                onSelectedValueChanged={(value: number) => formik.setFieldValue('isSpeaker', value)}
              />
            </Grid>
            <Grid item xs={9}>
              <FormTextField
                id="lectureSubject"
                name="lectureSubject"
                disabled={readOnlyMode}
                labelKey="info:measure:mwi:conference-participation:topic-of-the-own-lecture"
                value={formik.values.lectureSubject}
                multiline
                rows={3}
                onChange={formik.handleChange}
                errorMessage={formik.errors.lectureSubject}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <FormTextField
            id="relevanceAttendance"
            name="relevanceAttendance"
            disabled={readOnlyMode}
            labelKey="input:label:measure:mwi:conference-participation:relevance-of-participation"
            descriptionKey="info:measure:mwi:conference-participation:relevance-of-participation"
            value={formik.values.relevanceAttendance}
            multiline
            rows={3}
            onChange={formik.handleChange}
            errorMessage={formik.errors.relevanceAttendance}
          />
        </Grid>
        {!isReport && (
          <>
            <div className={classes.questionContainer}>
              <div className={classes.questionScholarship}>
                <Grid item xs={3}>
                  <RadioButtons
                    title={t('question:label:measure:mwi:library:scholarship-application')}
                    value={formik.values.isScholarshipApplicationElsewhere}
                    disabled={readOnlyMode}
                    onSelectedValueChanged={(value: number) => formik.setFieldValue('isScholarshipApplicationElsewhere', value)}
                    errorMessage={formik.errors.isScholarshipApplicationElsewhere}
                  />
                </Grid>
              </div>
              <div className={classes.questionOne}>
                <Grid item xs={3}>
                  <RadioButtons
                    title={t('question:label:mwi:advertisement')}
                    disabled={readOnlyMode}
                    value={formik.values.hasReadAdvertisingStatement}
                    onSelectedValueChanged={(value: number) => formik.setFieldValue('hasReadAdvertisingStatement', value)}
                    errorMessage={formik.errors.hasReadAdvertisingStatement}
                  />
                </Grid>
                <DialogButton
                  className={classes.dialogButton}
                  textId={'button:label:mwi:measure:conference-participation:advertisement'}
                  title={'button:label:mwi:advertisement'}
                />
              </div>
              <div className={classes.questionTwo}>
                <Grid item xs={3}>
                  <RadioButtons
                    title={t('question:label:mwi:conditions')}
                    disabled={readOnlyMode}
                    value={formik.values.hasReadConditions}
                    onSelectedValueChanged={(value: number) => formik.setFieldValue('hasReadConditions', value)}
                    errorMessage={formik.errors.hasReadConditions}
                  />
                </Grid>
                <DialogButton
                  className={classes.dialogButton}
                  textId={'button:label:mwi:measure:conference-participation:conditions'}
                  title={'button:label:mwi:conditions'}
                />
              </div>
            </div>
          </>
        )}
      </Grid>
    </div>
  );
};

export default ConferenceParticipation;
