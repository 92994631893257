import { DropDownItem } from '../components/Selection/DropDownItem';
import { SurveyTypes } from '../enums/SurveyTypes';

export const surveyDropDownItems: DropDownItem[] = [
  {
    key: SurveyTypes.positive,
    value: 'label:survey:dropdown:positive',
  },
  {
    key: SurveyTypes.negative,
    value: 'label:survey:dropdown:negative',
  },
  {
    key: SurveyTypes.nagativeWithObligations,
    value: 'label:survey:dropdown:negative-with-obligations',
  },
];
