export enum MisMeasureTypes {
  evaluation = 'Evaluation',
  pastoralProgram = 'PastoralPlan',
  equipmentPurchase = 'AcquisitionOfEquipment',
  realEstate = 'Property',
  vehicle = 'Vehicle',
  conference = 'Conference',
  media = 'Media',
  trainingPrograms = 'Apprenticeship',
  publication = 'Publication',
  scholarshipCongregation = 'CollectiveScholarshipCongregation',
  scholarshipInstitute = 'CollectiveScholarshipInstitution',
  technologyPurchase = 'AcquisitionOfTechnicalDevice',
  scholarship = 'Scholarship',
  maintenance = 'Upkeep',
}

export enum MwiMeasureTypes {
  conferenceParticipation = 'MwiConferenceAttendance',
  conference = 'MwiConference',
  facultyExchange = 'MwiProfessorExchange',
  publicationElectronic = 'MwiPublicationElectronic',
  publicationPrint = 'MwiPublicationPrint',
  library = 'MwiLibrary',
  researchInstitution = 'MwiResearchInstitution',
  researchPerson = 'MwiResearchPerson',
  researchStudy = 'MwiResearchStudy',
  scholarship = 'MwiScholarship',
  translation = 'MwiTranslation',
  specialProject = 'MwiSpecialProject',
}

export enum OtherMeasureTypes {
  other = 'OtherMeasure',
  all = 'AllMeasures',
}
