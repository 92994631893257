import i18n from '../utils/i18n';
export const changeLanguage = (language: string) => {
  let i18nLanguage = '';
  switch (language) {
    case 'EN':
      i18nLanguage = 'en-US';
      break;
    case 'FR':
      i18nLanguage = 'fr-FR';
      break;
    case 'DE':
      i18nLanguage = 'de-DE';
      break;
    case 'PT':
      i18nLanguage = 'pt-PT';
      break;
    default:
      i18nLanguage = 'en-US';
  }

  i18n.changeLanguage(i18nLanguage);
};
