import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography, makeStyles, Theme } from '@material-ui/core';
import Contact from '../../sdk/com/apiomat/frontend/missio/Contact';
import DataGrid, { DataGridColumn } from '../DataGrid/DataGrid';
import { contactTypeDropdown, getContactName } from '../../utils/contact.utils';
import DefaultDialog from './DefaultDialog';
import { DeleteButton } from '../Buttons/DeleteButton';
import CheckIcon from '@material-ui/icons/Check';
import DropDown from '../Selection/DropDown';
import { ContactType } from '../../enums/ContactType';
import { GeneralContactType } from '../../enums/GeneralContactType';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: '12px 12px 0 12px',
  },
  iconContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  checkIcon: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  dropDown: {
    marginTop: 36,
    maxWidth: '300px',
  },
  table: {
    paddingTop: 0,
  },
}));

export interface ContactImportDialogProps {
  onImportButtonClick: (contact: Contact, contactType: string) => void;
  onCancelButtonClick: () => void;
  onEditButtonClick: (contact: Contact) => void;
  deleteContact: (contact: Contact) => void;
  isOpen: boolean;
  contacts: Contact[];
}

const ContactImportDialog = (props: ContactImportDialogProps) => {
  const { isOpen, onImportButtonClick, onCancelButtonClick, deleteContact, onEditButtonClick, contacts } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const [contactType, setContactType] = useState<string>();

  const filterContacts = (contactType: string): Contact[] => {
    switch (contactType) {
      case ContactType.owner:
      case ContactType.beneficiaryInstitue:
        return contacts?.filter(contact => contact.generalContactType === GeneralContactType.INSTITUTE);
      case ContactType.applicant:
      case ContactType.beneficiaryPrivate:
      case ContactType.projectManager:
      case ContactType.recommendedBy:
        return contacts?.filter(contact => contact.generalContactType === GeneralContactType.PERSON);
      default:
        return [];
    }
  };

  const columns: DataGridColumn<Contact>[] = [
    { header: t('label:contact-table-name'), valueExtractor: getContactName },
    {
      header: t('contact:contact-id:dialog:column:address'),
      valueExtractor: contact => `${contact.place || ''} ${contact.place && contact.country ? ',' : ''} ${t(contact.country) || ''}`,
    },
    {
      header: '',
      valueExtractor: contact => contact,
      component: contact => (
        <div className={classes.iconContainer}>
          <Button variant="contained" className={classes.checkIcon} onClick={() => {}}>
            <CheckIcon onClick={() => onImportButtonClick(contact, contactType)} />
          </Button>
          <div style={{ padding: 5 }} />
          <Button onClick={() => onEditButtonClick(contact)}>
            <EditIcon color="primary" />
          </Button>
          <DeleteButton type="icon" onDeleteConfirmed={() => deleteContact(contact)} />
        </div>
      ),
    },
  ];

  return (
    <DefaultDialog
      isOpen={isOpen}
      maxWidth={'md'}
      title={
        <div className={classes.container}>
          <Typography component="span" variant="h5">
            {t('contact:form:import:dialog-title')}
          </Typography>
          <Typography variant="body1">{t('contact:form:import:dialog-subtitle')}</Typography>
          <div className={classes.dropDown}>
            <DropDown
              labelKey="dropdown/contact-type"
              items={contactTypeDropdown(t)}
              selectedItemKey={contactType}
              onSelectedItemChange={(value: string) => setContactType(value)}
            />
          </div>
        </div>
      }
      children={
        <div className={classes.table}>
          <DataGrid
            datasource={filterContacts(contactType)}
            columns={columns}
            emptyMessage={{ title: t('data-grid:empty-message-title'), component: '' }}
          />
        </div>
      }
      actionComponent={
        <>
          <Button onClick={onCancelButtonClick} color="primary">
            {t('button:label:cancel')}
          </Button>
        </>
      }
    />
  );
};

export default ContactImportDialog;
