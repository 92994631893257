import { Button, DialogContentText } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { OfferType } from '../../enums/OfferType';
import Offers from '../../sdk/com/apiomat/frontend/missio/Offers';
import { Link } from 'react-router-dom';
import DefaultDialog from './DefaultDialog';
import { OfferModes } from '../../enums/OfferModes';

export interface ChangeAssignmentDialogProps {
  isOpen: boolean;
  offer: Offers;
  onCancelButtonClick: () => void;
}

const ChangeAssignmentDialog = (props: ChangeAssignmentDialogProps) => {
  const { isOpen, offer, onCancelButtonClick } = props;
  const { t } = useTranslation();

  return (
    <DefaultDialog
      maxWidth="sm"
      isOpen={isOpen}
      actionComponent={
        <>
          <Button onClick={onCancelButtonClick} color="primary">
            {t('button:label:cancel')}
          </Button>
          <Button
            color="primary"
            component={Link}
            to={`/assignment/${offer.measureType === OfferType.mis ? 'mis' : 'mwi'}/${OfferModes.proposal}/${offer.ID}`}
          >
            {t('button:label:new-change-assignment')}
          </Button>
        </>
      }
      title={t('dialog:change-assignment-title')}
      children={<DialogContentText>{t('dialog:change-assignment-text')}</DialogContentText>}
    />
  );
};

export default ChangeAssignmentDialog;
