import Contact from '../sdk/com/apiomat/frontend/missio/Contact';
import { ContactType } from '../enums/ContactType';
import { TFunction } from 'i18next';
import { GeneralContactType } from '../enums/GeneralContactType';

export const getContactName = (contact: Contact): string => {
  let contactName: string;
  switch (contact.contactType) {
    case ContactType.owner:
    case ContactType.beneficiaryInstitue:
      contactName = contact.instituteName || '';
      break;
    default:
      contactName = `${contact.firstName || ''} ${contact.lastName || ''}`;
  }
  return contactName;
};

export const copyContact = (contact: Contact): Contact => {
  const copyContact = new Contact();
  copyContact?.fromJson(contact?.toJson());
  return copyContact;
};

export const contactTypeDropdown = (t: TFunction) => {
  return Object.values(ContactType).map(key => ({
    key,
    value: t(`edit-contact-type:${key}`),
  }));
};

export const getGeneralContactType = (contactType: string): string => {
  switch (contactType) {
    case ContactType.owner:
    case ContactType.beneficiaryInstitue:
      return GeneralContactType.INSTITUTE;
    case ContactType.applicant:
    case ContactType.beneficiaryPrivate:
    case ContactType.projectManager:
    case ContactType.recommendedBy:
      return GeneralContactType.PERSON;
    default:
      return '';
  }
};
