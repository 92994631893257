import { GeneralValueMap } from './GeneralValueMap';

export const costPlanCategories: GeneralValueMap[] = [
  {
    locale: 'lbl.costPlanCategoryProjectActivity',
    value: 'cost-plan:cost-plan-category:project-activities',
    key: '1',
  },
  {
    locale: 'lbl.costPlanCategoryProjectStaff',
    value: 'cost-plan:cost-plan-category:personnel',
    key: '3',
  },
  {
    locale: 'lbl.costPlanCategoryProjectInvestments',
    value: 'cost-plan:cost-plan-category:investments',
    key: '2',
  },
  {
    locale: 'lbl.costPlanCategoryProjectProjectManagement',
    value: 'cost-plan:cost-plan-category:project-management',
    key: '4',
  },
];
