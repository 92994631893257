import { DropDownItem } from '../components/Selection/DropDownItem';
import { MisMeasureTypes } from '../enums/MeasureTypes';

export const misMeasureDropDownItems: DropDownItem[] = [
  {
    key: MisMeasureTypes.pastoralProgram,
    value: 'label:measure:dropdown:pastoral-program',
  },
  {
    key: MisMeasureTypes.equipmentPurchase,
    value: 'label:measure:dropdown:equipment-purchase',
  },
  {
    key: MisMeasureTypes.realEstate,
    value: 'label:measure:dropdown:real-estate',
  },
  {
    key: MisMeasureTypes.evaluation,
    value: 'label:measure:dropdown:evaluation',
  },
  {
    key: MisMeasureTypes.vehicle,
    value: 'label:measure:dropdown:vehicle',
  },
  {
    key: MisMeasureTypes.conference,
    value: 'label:measure:dropdown:conference',
  },
  {
    key: MisMeasureTypes.media,
    value: 'label:measure:dropdown:media',
  },
  {
    key: MisMeasureTypes.trainingPrograms,
    value: 'label:measure:dropdown:training-programs',
  },
  {
    key: MisMeasureTypes.publication,
    value: 'label:measure:dropdown:publication',
  },
  {
    key: MisMeasureTypes.scholarshipCongregation,
    value: 'label:measure:dropdown:scholarship-congregation',
  },
  {
    key: MisMeasureTypes.scholarshipInstitute,
    value: 'label:measure:dropdown:scholarship-institute',
  },
  {
    key: MisMeasureTypes.technologyPurchase,
    value: 'label:measure:dropdown:technology-purchase',
  },
  {
    key: MisMeasureTypes.scholarship,
    value: 'label:measure:dropdown:scholarship',
  },
  {
    key: MisMeasureTypes.maintenance,
    value: 'label:measure:dropdown:maintenance',
  },
];
