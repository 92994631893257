import { GeneralValueMap } from './GeneralValueMap';

export const graduations: GeneralValueMap[] = [
  {
    locale: 'lbl.graduation.mp',
    value: 'M.Ph.',
    key: 'MP',
  },
  {
    locale: 'lbl.graduation.pt',
    value: 'Th.D.',
    key: 'PT',
  },
  {
    locale: 'lbl.graduation.ll',
    value: 'Licentiate',
    key: 'LL',
  },
  {
    locale: 'lbl.graduation.zp',
    value: 'Postgraduiertendiplom',
    key: 'ZP',
  },
  {
    locale: 'lbl.graduation.dg',
    value: 'DEUG',
    key: 'DG',
  },
  {
    locale: 'lbl.graduation.mg',
    value: 'Magister',
    key: 'MG',
  },
  {
    locale: 'lbl.graduation.mn',
    value: 'Master II',
    key: 'MN',
  },
  {
    locale: 'lbl.graduation.bp',
    value: 'B.Ph.',
    key: 'BP',
  },
  {
    locale: 'lbl.graduation.oa',
    value: 'ohne Abschluss',
    key: 'OA',
  },
  {
    locale: 'lbl.graduation.li',
    value: 'Licence (2. cycle)',
    key: 'LI',
  },
  {
    locale: 'lbl.graduation.ds',
    value: 'D.E.S.',
    key: 'DS',
  },
  {
    locale: 'lbl.graduation.di',
    value: 'Diplom',
    key: 'DI',
  },
  {
    locale: 'lbl.graduation.bf',
    value: 'Baccalaureat',
    key: 'BF',
  },
  {
    locale: 'lbl.graduation.bs',
    value: 'B.Sc.',
    key: 'BS',
  },
  {
    locale: 'lbl.graduation.be',
    value: 'B.A.',
    key: 'BE',
  },
  {
    locale: 'lbl.graduation.pu',
    value: 'Doctorat unifié',
    key: 'PU',
  },
  {
    locale: 'lbl.graduation.ma',
    value: 'M.A.',
    key: 'MA',
  },
  {
    locale: 'lbl.graduation.mm',
    value: 'Master I',
    key: 'MM',
  },
  {
    locale: 'lbl.graduation.pd',
    value: 'Ph.D.',
    key: 'PD',
  },
  {
    locale: 'lbl.graduation.kp',
    value: 'Postgraduierten-Kurs',
    key: 'KP',
  },
  {
    locale: 'lbl.graduation.ph',
    value: 'Habilitation au doctorat',
    key: 'PH',
  },
  {
    locale: 'lbl.graduation.bt',
    value: 'B.Th.',
    key: 'BT',
  },
  {
    locale: 'lbl.graduation.po',
    value: 'Doctorat',
    key: 'PO',
  },
  {
    locale: 'lbl.graduation.na',
    value: 'nicht-wissenschaftlicher Abschluss',
    key: 'NA',
  },
  {
    locale: 'lbl.graduation.lz',
    value: 'Lizentiat',
    key: 'LZ',
  },
  {
    locale: 'lbl.graduation.ls',
    value: 'Licence spéciale',
    key: 'LS',
  },
  {
    locale: 'lbl.graduation.bg',
    value: 'Graduat',
    key: 'BG',
  },
  {
    locale: 'lbl.graduation.de',
    value: 'DEA',
    key: 'DE',
  },
  {
    locale: 'lbl.graduation.mt',
    value: 'M.Th.',
    key: 'MT',
  },
  {
    locale: 'lbl.graduation.pc',
    value: 'Doctorat conjoint',
    key: 'PC',
  },
  {
    locale: 'lbl.graduation.lu',
    value: 'Licence unique',
    key: 'LU',
  },
  {
    locale: 'lbl.graduation.ko',
    value: 'Konzertexamen',
    key: 'KO',
  },
  {
    locale: 'lbl.graduation.ms',
    value: 'M.Sc.',
    key: 'MS',
  },
  {
    locale: 'lbl.graduation.bl',
    value: 'Licence (1. cycle)',
    key: 'BL',
  },
  {
    locale: 'lbl.graduation.mi',
    value: 'Maîtrise',
    key: 'MI',
  },
];
