import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, TextField, TextFieldProps } from '@material-ui/core';

export interface PasswordChangeProps {
  onCancelButtonClick?: () => void;
  onSaveButtonClick?: () => void;
  isOpen: boolean;
  content?: string;
}

const PasswordChange = (props: PasswordChangeProps & TextFieldProps) => {
  const { t } = useTranslation();
  const { isOpen, onCancelButtonClick, onSaveButtonClick, content } = props;
  return (
    <Dialog open={isOpen} maxWidth={'xl'} onClose={onCancelButtonClick} aria-labelledby="form-dialog-title">
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
        <TextField autoFocus fullWidth type={'password'} {...props} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancelButtonClick} color="primary">
          {t('button:label:cancel')}
        </Button>
        <Button onClick={onSaveButtonClick} color="primary">
          {t('button:label:change-password')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PasswordChange;
