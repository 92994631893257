import axios, { AxiosInterceptorManager, AxiosResponse } from 'axios';
import { Store } from 'redux';
import { authActions, LoginObject } from './auth';
import { ConcurrentRefreshHandler, RefreshHandlerConfig, refreshToken, verifyCredentials } from '../utils/auth.utils';
import { Datastore } from '../sdk/com/apiomat/frontend';
import {
  applyRetryFlag,
  isAuthenticationRequest,
  isBearerRequest,
  isRetryRequest,
  RetryRequestConfig,
  updateBearerToken,
} from '../utils/axios.utils';

type RequestInterceptor = Parameters<AxiosInterceptorManager<RetryRequestConfig>['use']>[0];
type ResponseInterceptor = Parameters<AxiosInterceptorManager<AxiosResponse<RetryRequestConfig>>['use']>[0];

const createRefreshInterceptors = (store: Store) => {
  const refreshHandlerConf: RefreshHandlerConfig<LoginObject> = {
    currentCredentials() {
      const { user, token } = store.getState().auth;

      return { user, token };
    },
    refresh(creds) {
      return refreshToken(creds.token);
    },
    validateCredentials(creds) {
      return verifyCredentials(creds.user);
    },
    onSuccessfulRefresh(creds) {
      Datastore.configureWithSessionToken(creds.token.sessionToken);
      store.dispatch(authActions.loginWithTokenSuccess(creds));
    },
    onFailedRefresh() {
      store.dispatch(authActions.logout());
    },
  };

  /*
   * Needs to be a singleton, so that request + response interceptors can share state
   */
  const refreshHandler = new ConcurrentRefreshHandler(refreshHandlerConf);

  /**
   * Halts all outgoing requests if a token refresh is currently in progress.
   * Applies the updated token before releasing the halted requests.
   */
  const requestInterceptor: RequestInterceptor = async req => {
    /* ignore request made with different or no auth method */
    if (!isBearerRequest(req)) {
      return req;
    }

    /* do not modify authN requests */
    if (isAuthenticationRequest(req)) {
      return req;
    }

    /* no active refresh, no need to do any work */
    if (!refreshHandler.isBusy) {
      return req;
    }

    let updatedReq = req;
    try {
      const credentials = await refreshHandler.read();

      updatedReq = updateBearerToken(req, credentials.token.sessionToken);
    } catch (err) {
      // ignore and use original request config
    }

    return updatedReq;
  };

  /**
   * Retries requests that failed due to a 401 response with a refreshed token.
   * Only the first failed request triggers the request. All other subsequent responses
   * are placed in a queue to be retried once the refreshed token is available.
   *
   * Also:
   *  - updates credentials stored in redux
   *  - triggers logout if fetch of refresh token fails
   */
  const responseInterceptor: ResponseInterceptor = async response => {
    const config = response.config;

    /* ignore everything that is not an authN failure when requesting a normal resource */
    const authenticationFailed = response.status === 401;
    const isBearerAuth = isBearerRequest(config);
    if (!authenticationFailed || !isBearerAuth) {
      return response;
    }

    /* authN requests should not trigger a refresh, because these are used to perform the refresh */
    if (isAuthenticationRequest(config)) {
      return response;
    }

    /* skip requests that already have been retried */
    if (isRetryRequest(config)) {
      return response;
    }

    /* do not attempt to refresh if no token is currently present */
    const currentToken = store.getState().auth.token;
    const user = store.getState().auth.user;

    if (!currentToken || !user) {
      return response;
    }

    try {
      const { token } = await refreshHandler.request();
      const updatedConfig = applyRetryFlag(updateBearerToken(config, token.sessionToken));

      return axios.request(updatedConfig);
    } catch (err) {
      return response;
    }
  };

  return {
    request: requestInterceptor,
    response: responseInterceptor,
  };
};

export const interceptor = (store: Store) => {
  const interceptors = createRefreshInterceptors(store);

  axios.interceptors.request.use(interceptors.request);
  axios.interceptors.response.use(interceptors.response);
};
