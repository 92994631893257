import { FormikErrors, FormikState } from 'formik';
import { isEqual } from 'lodash';
import React, { createContext, FunctionComponent, ReactNode, useContext, useEffect, useReducer } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import usePrevious from '../../../hooks/usePrevious';
import { Offers, Reports } from '../../../sdk/com/apiomat/frontend/missio';
import { FormTabKeys } from '../FormTab';

type State = {
  // @ts-ignore
  [key: FormTabKeys]: {
    errorCount?: number;
    values: { [key: string]: unknown };
  };
};

export type Action = {
  subFormId: FormTabKeys | 'title';
  errors: FormikErrors<{ [key: string]: string | Date | number }>;
  values: {
    [key: string]: unknown;
  };
};

type Dispatch = (action: Action, formik?: FormikState<unknown>) => void;

const offerFormReducer = (state: State, action: Action): State => {
  const { subFormId, errors, values } = action;
  if (subFormId in state) {
    const errorCount = Object.keys(errors).length;
    if (state[subFormId].errorCount === errorCount && isEqual(state[subFormId].values, values)) {
      return state;
    }
  }

  const newState = {
    ...state,
    [subFormId]: {
      errorCount: Object.keys(errors).length,
      values: values,
    },
  };
  return newState;
};

// rewrite dates to unix timestamp
const rewriteDate = (state: State): State =>
  'measure' in state
    ? {
        ...state,
        measure: {
          ...state['measure'],
          start: state['measure'].start?.getTime() ?? undefined,
          end: state['measure'].end?.getTime() ?? undefined,
        },
      }
    : state;

const OfferFormContext = createContext<{ state: State; dispatch: Dispatch } | undefined>(undefined);

const OfferFormProvider: FunctionComponent<{
  children: ReactNode;
  data: Offers | Reports;
  isReport?: boolean;
  onChange: (data: Offers | Reports) => void;
}> = ({ children, data, onChange }) => {
  const [state, dispatch] = useReducer(offerFormReducer, {});
  const prevState = usePrevious(state);

  useEffect(() => {
    if (Object.keys(state).length > 0 && !isEqual(prevState, state)) {
      const rewrittenState = rewriteDate(state);
      const tabKeys = Object.keys(rewrittenState);
      tabKeys.forEach(tabKey => {
        // rewrite 'target' to 'targetGroup'
        const rewrittenTabKey = tabKey === 'target' ? 'targetGroup' : tabKey;
        const keys = Object.keys(rewrittenState[tabKey]['values']);
        keys.forEach(key => (data[rewrittenTabKey][key] = rewrittenState[tabKey]['values'][key]));
      });
      onChange(data);
    }
  }, [state, onChange, data, prevState]);

  const debouncedDispatch = useDebouncedCallback((action: Action) => dispatch(action), 300);

  return <OfferFormContext.Provider value={{ state, dispatch: debouncedDispatch }}>{children}</OfferFormContext.Provider>;
};

const useOfferFormContext = () => {
  const context = useContext(OfferFormContext);
  if (context === undefined) {
    throw new Error(`useFormErrorContext must be used within a FormErrorContextProvider`);
  }
  return context;
};

const useFormError = (): boolean => {
  const { state } = useContext(OfferFormContext);
  const keys = Object.keys(state);
  return keys.some(key => state[key].errorCount !== 0);
};

export const checkForFormErrorChange = (state: State, action: Action): boolean => {
  const errorCount = action.subFormId in action ? state[action.subFormId].errorCount : 0;
  return errorCount !== Object.keys(action.errors).length;
};

export { OfferFormProvider, useOfferFormContext, useFormError };
