import React from 'react';
import OpenReports from './OpenReports';
import ArchivedReports from './ArchivedReports';
import TabView, { TabItem } from '../../components/Tabs/TabView';

export default () => {
  const tabs: TabItem[] = [
    {
      key: 'open-reports',
      label: 'tab:title:open-reports',
      value: <OpenReports />,
    },
    {
      key: 'archived-reports',
      label: 'tab:title:archived-reports',
      value: <ArchivedReports />,
    },
  ];

  return <TabView tabs={tabs} />;
};
