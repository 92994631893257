import React, { useEffect, useState } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { DefaultTextField } from '../../TextInputs/TextInputs';
import { useDispatch } from 'react-redux';
import { offerActions } from '../../../store/offers';
import { reportActions } from '../../../store/report';
import FormTextField from '../InputFields/FormTextField';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Action, useOfferFormContext } from '../context/OfferFormContext';

const useStyles = makeStyles(() => ({
  contentContainer: {
    paddingBottom: 30,
    paddingTop: 30,
  },
}));

export interface EnvironmentFormProps {
  readOnlyMode: boolean;
  isReport?: boolean;
  data?: any;
}

const EnvironmentForm = (props: EnvironmentFormProps) => {
  const { readOnlyMode, isReport, data } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { state, dispatch: dispatchOfferContext } = useOfferFormContext();
  let environment = data.environment;
  const [ description, setDescription ] = useState<string>(isReport ? environment : environment?.description);
  const [ reason, setReason ] = useState<string>(environment?.reason);

  const formError = isReport ? false : isEmpty(description);
  useEffect(() => {
    if (!readOnlyMode) {
      const actionObject: Action = formError
        ? {
          subFormId: 'environment',
          errors: { description: 'missing mandatory field' },
          values: {},
        }
        : {
          subFormId: 'environment',
          errors: {},
          values: {},
        };

      if (!('environment' in state)) {
        dispatchOfferContext(actionObject);
      } else if ((state['environment'].errorCount === 1 && !formError) || (state['environment'].errorCount === 0 && formError)) {
        dispatchOfferContext(actionObject);
      }
    }
  }, [ dispatchOfferContext, formError, readOnlyMode, state ]);

  return (
    <Grid container spacing={ 3 } className={ classes.contentContainer }>
      { isReport && (
        <Grid item xs={ 12 }>
          <DefaultTextField
            disabled={ readOnlyMode }
            value={ description }
            multiline
            rows={ 3 }
            labelKey="input:label:environment-description"
            helperTextKey="info:environment-description"
            onChange={ event => {
              data.environment = event.target.value;
              setDescription(event.target.value);
              dispatch(reportActions.updateCurrentReport(data));
            } }
          />
        </Grid>
      ) }
      { !isReport && (
        <>
          <Grid item xs={ 12 }>
            <FormTextField
              id="description"
              name="description"
              disabled={ readOnlyMode }
              value={ description }
              multiline
              rows={ 3 }
              labelKey="input:label:environment-description"
              descriptionKey="info:environment-description"
              onChange={ event => {
                environment.description = event.target.value;
                setDescription(event.target.value);
                dispatch(offerActions.updateCurrentOffer(data));
              } }
              errorMessage={ formError ? t('mandatory_field') : undefined }
            />
          </Grid>
          <Grid item xs={ 12 }>
            <DefaultTextField
              disabled={ readOnlyMode }
              value={ reason }
              multiline
              rows={ 3 }
              labelKey="input:label:environment-reason"
              helperTextKey="info:environment-reason"
              onChange={ event => {
                environment.reason = event.target.value;
                setReason(event.target.value);
                dispatch(offerActions.updateCurrentOffer(data));
              } }
            />
          </Grid>
        </>
      ) }
    </Grid>
  );
};

export default EnvironmentForm;
