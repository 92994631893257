import { createMuiTheme } from '@material-ui/core/styles';

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: 'rgb(11, 161, 226)',
      light: '#ffffff',
    },
    secondary: {
      main: '#060606',
    },
  },

  typography: {
    fontFamily: 'Helvetica',
    subtitle1: {
      fontSize: '1.375rem',
      color: '#6f6f6f',
    },
    subtitle2: {
      fontSize: '1.125rem',
    },
  },

  overrides: {
    MuiTab: {
      root: {
        textTransform: 'none',
      },
    },

    MuiTabs: {
      indicator: {
        backgroundColor: 'rgb(79, 195, 247)',
      },
    },

    MuiButton: {
      containedPrimary: {
        color: 'rgb(255, 255, 255, 0.87)',
      },
      contained: {
        color: 'rgb(79, 195, 247)',
        backgroundColor: '#ffffff',
        boxShadow: 'none',
      },
      root: {
        fontSize: '0.975rem',
        minWidth: 50,
        minHeight: 40,
        color: 'rgb(255, 255, 255, 0.87)',
        textTransform: 'none',
        borderRadius: 0,
      },
    },

    MuiToolbar: {
      root: {
        paddingLeft: 6,
        paddingRight: 24,
      },
    },

    MuiListSubheader: {
      root: {
        height: 40,
      },
    },

    MuiList: {
      padding: {
        paddingTop: 0,
      },
    },

    MuiListItemIcon: {
      root: {
        minWidth: 40,
      },
    },

    MuiListItem: {
      gutters: {
        paddingLeft: 24,
        paddingRight: 24,
      },
      root: {
        '&$selected:active': {
          backgroundColor: 'rgb(79, 195, 247)',
        },
        '&$selected': {
          backgroundColor: 'rgb(79, 195, 247)',
        },
        '&$selected:hover': {
          backgroundColor: 'rgb(79, 195, 247)',
        },
        '&$selected:focus': {
          backgroundColor: 'rgb(79, 195, 247)',
        },
      },
    },

    MuiListItemText: {
      root: {
        paddingLeft: 8,
        paddingRight: 8,
      },
      primary: {
        color: '#fbf4ff',
      },
      secondary: {
        color: '#060606',
      },
    },

    MuiDivider: {
      root: {
        backgroundColor: '#5b5b5c',
      },
      light: {
        backgroundColor: '#fff',
      },
    },

    MuiFormControl: {
      marginNormal: {
        marginTop: 0,
      },
    },

    MuiTableCell: {
      head: {
        color: 'rgb(79, 195, 247)',
      },
    },

    MuiInputLabel: {
      formControl: {
        transform: 'translate(0, 1.5px) scale(0.75)',
      },
    },

    MuiInput: {
      formControl: {
        backgroundColor: 'white',
      },
      underline: {
        '&:hover:not($disabled):not($error):not($focused):before': {
          borderBottomWidth: '1px',
          borderBottomStyle: 'solid',
          borderBottomColor: 'rgb(232,218,231)',
        },
        '&:before': {
          borderBottomWidth: '1px',
          borderBottomColor: 'rgb(232,218,231)',
        },
        '&:after': {
          borderBottomWidth: '2px',
          borderBottomStyle: 'solid',
          borderBottomColor: 'rgb(79, 195, 247)',
        },
      },
    },

    MuiInputAdornment: {
      positionStart: {
        marginRight: 0,
      },
    },

    MuiInputBase: {
      multiline: {
        padding: 0,
      },
      inputMultiline: {
        padding: 10,
      },
      input: {
        padding: 10,
        backgroundColor: '#fff',
        boxShadow: 'none',
        borderRadius: 0,
        outline: 'none',
        borderTop: 'none',
        borderLeft: 'none',
        borderRight: 'none',
        borderBottom: `1px solid #dddddd`,
      },
    },

    MuiDialogTitle: {
      root: {
        padding: 12,
      },
    },

    MuiDialogContent: {
      root: {
        padding: 24,
      },
    },

    MuiFormLabel: {
      root: {
        '&$root': {
          color: 'rgb(79, 195, 247)',
        },
        '&$focused': {
          color: 'rgb(79, 195, 247)',
        },
      },
    },
  },
});
