import React from 'react';
import { Grid, makeStyles, Theme, IconButton } from '@material-ui/core';
import SingleLineDataGrid from '../../DataGrid/SingleLineDataGrid';
import { DataGridColumn } from '../../DataGrid/DataGrid';
import DropDown from '../../Selection/DropDown';
import { useTranslation } from 'react-i18next';
import { DefaultNumberField } from '../../TextInputs/TextInputs';
import DeleteIcon from '@material-ui/icons/Delete';
import { currencies } from '../../../value-maps/Currencies';
import CostPlan from '../../../sdk/com/apiomat/frontend/missio/CostPlan';
import DatePicker from '../../DatePicker/DatePicker';
import CurrencyConversion from '../../../sdk/com/apiomat/frontend/missio/CurrencyConversion';

const useStyles = makeStyles((theme: Theme) => ({
  dataGridContainer: {
    flex: 1,
    display: 'flex',
    overflowX: 'auto',
    overflowY: 'hidden',
    marginTop: theme.spacing(1),
    padding: theme.spacing(0, 2),
  },
  numberFields: {
    flex: 1,
    minWidth: 100,
  },
  dropDowns: {
    flex: 1,
    minWidth: 70,
  },
  dataGridDeleteButton: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    color: theme.palette.primary.main,
  },
  amount: {
    maxWidth: 100,
    minWidth: 100,
  },
  classic: {
    maxWidth: 120,
    minWidth: 100,
  },
  date: {
    minWidth: 155,
  },
}));

export interface CurrencyDataGridProps {
  costPlan: CostPlan;
  isReadonly: boolean;
  updateCostPlan: (costPlan: CostPlan) => void;
}

const CurrencyDataGrid = (props: CurrencyDataGridProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isReadonly, costPlan, updateCostPlan } = props;

  const currencyConversionItems = costPlan.currencyConversionTable.currencyConversions;

  const selectedSourceCurrencies = costPlan.currencyConversionTable.sourceCurrencyRates
    .filter(currencyRate => Boolean(currencyRate.currency))
    .map(currencyRate => currencies.find(currency => currency.key === currencyRate.currency));

  const selectedTargetCurrencies = costPlan.currencyConversionTable.targetCurrencyRates
    .filter(currencyRate => Boolean(currencyRate.currency))
    .map(currencyRate => currencies.find(currency => currency.key === currencyRate.currency));

  const onDeleteButtonClicked = (currencyConversionItem: CurrencyConversion) => {
    const index = costPlan.currencyConversionTable.currencyConversions.indexOf(currencyConversionItem);
    costPlan.currencyConversionTable.currencyConversions.splice(index, 1);
    updateCostPlan(costPlan);
  };

  const calcTargetCurrencySum = (item: CurrencyConversion) => {
    return item.sourceAmount * (item.exchangeRate || 0) - (item.exchangeFee || 0) || 0;
  };

  const columns: DataGridColumn<CurrencyConversion>[] = [
    {
      header: t('input:label:date'),
      valueExtractor: dataRecord => dataRecord.conversionDate,
      component: dataRecord => (
        <div className={classes.date}>
          <DatePicker
            label=""
            value={dataRecord.conversionDate}
            isReadonly={isReadonly}
            onChange={(date: Date) => {
              dataRecord.conversionDate = date;
              updateCostPlan(costPlan);
            }}
          />
        </div>
      ),
    },
    {
      header: t('label:currency-table:exchange-sum'),
      valueExtractor: dataRecord => dataRecord.sourceAmount,
      component: dataRecord => (
        <div className={classes.amount}>
          <DefaultNumberField
            fullWidth
            value={dataRecord.sourceAmount}
            className={classes.numberFields}
            onValueChanged={(value: number) => {
              dataRecord.sourceAmount = value;
              updateCostPlan(costPlan);
            }}
            disabled={isReadonly}
          />
        </div>
      ),
    },
    {
      header: t('label:currency-table:dialog:source-currency'),
      valueExtractor: dataRecord => dataRecord.sourceCurrency,
      component: dataRecord => (
        <div className={classes.dropDowns}>
          <DropDown
            selectedItemKey={dataRecord.sourceCurrency}
            items={selectedSourceCurrencies
              .map(x => ({ key: x.key, value: t(x.value) }))
              .sort((a, b) => a.value.localeCompare(b.value, 'en-US', { ignorePunctuation: true }))}
            onSelectedItemChange={(value: string) => {
              dataRecord.sourceCurrency = value;
              updateCostPlan(costPlan);
            }}
            disabled={isReadonly}
          />
        </div>
      ),
    },
    {
      header: t('label:currency-table:dialog:target-currency'),
      valueExtractor: dataRecord => dataRecord.targetCurrency,
      component: dataRecord => (
        <div className={classes.dropDowns}>
          <DropDown
            selectedItemKey={dataRecord.targetCurrency}
            items={selectedTargetCurrencies
              .map(x => ({ key: x.key, value: t(x.value) }))
              .sort((a, b) => a.value.localeCompare(b.value, 'en-US', { ignorePunctuation: true }))}
            onSelectedItemChange={(value: string) => {
              dataRecord.targetCurrency = value;
              updateCostPlan(costPlan);
            }}
            disabled={isReadonly}
          />
        </div>
      ),
    },
    {
      header: t('label:currency-table:current-exchange'),
      valueExtractor: dataRecord => dataRecord.exchangeRate,
      component: dataRecord => (
        <div className={classes.amount}>
          <DefaultNumberField
            fullWidth
            value={dataRecord.exchangeRate}
            className={classes.numberFields}
            onValueChanged={(value: number) => {
              dataRecord.exchangeRate = value;
              updateCostPlan(costPlan);
            }}
            disabled={isReadonly}
          />
        </div>
      ),
    },
    {
      header: t('label:currency-table:exchange-fee'),
      valueExtractor: dataRecord => dataRecord.exchangeFee,
      component: dataRecord => (
        <div className={classes.amount}>
          <DefaultNumberField
            fullWidth
            value={dataRecord.exchangeFee}
            className={classes.numberFields}
            onValueChanged={(value: number) => {
              dataRecord.exchangeFee = value;
              updateCostPlan(costPlan);
            }}
            disabled={isReadonly}
          />
        </div>
      ),
    },
    {
      header: t('label:currency-table:sum-target-currency'),
      valueExtractor: dataRecord => dataRecord.key,
      component: dataRecord => (
        <div className={classes.classic}>
          <DefaultNumberField fullWidth className={classes.numberFields} value={calcTargetCurrencySum(dataRecord)} disabled={true} />
        </div>
      ),
    },
    {
      header: t('label:currency-table:receipt-number'),
      valueExtractor: dataRecord => dataRecord.receiptNumber,
      component: dataRecord => (
        <div className={classes.classic}>
          <DefaultNumberField
            fullWidth
            className={classes.numberFields}
            value={dataRecord.receiptNumber}
            onValueChanged={(value: number) => {
              dataRecord.receiptNumber = value;
              updateCostPlan(costPlan);
            }}
            disabled={isReadonly}
          />
        </div>
      ),
    },
    {
      header: '',
      valueExtractor: dataRecord => dataRecord.ID,
      component: dataRecord =>
        isReadonly ? (
          false
        ) : (
          <IconButton onClick={() => onDeleteButtonClicked(dataRecord)}>
            <DeleteIcon color="primary" />
          </IconButton>
        ),
    },
  ];

  return (
    <div className={classes.dataGridContainer}>
      <Grid container spacing={3}>
        <SingleLineDataGrid
          columns={columns}
          datasource={currencyConversionItems}
          emptyMessage={{
            title: '',
            component: columns.map(x => x.component),
          }}
        />
      </Grid>
    </div>
  );
};

export default CurrencyDataGrid;
