import React, { useEffect, useState } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { DefaultTextField } from '../../TextInputs/TextInputs';
import { useDispatch } from 'react-redux';
import { offerActions } from '../../../store/offers';
import { reportActions } from '../../../store/report';
import { Action, useOfferFormContext } from '../context/OfferFormContext';
import { isOfferTypeMis } from '../../../guards/OfferTypeGuard';
import { isEmpty } from 'lodash';

const useStyles = makeStyles(() => ({
  contentContainer: {
    paddingBottom: 30,
    paddingTop: 30,
  },
}));

export interface ChildProtectionFormProps {
  readOnlyMode: boolean;
  isReport?: boolean;
  data?: any;
}

const ChildProtectionForm = (props: ChildProtectionFormProps) => {
  const { readOnlyMode, isReport, data } = props;

  const classes = useStyles();
  const dispatch = useDispatch();
  const { state, dispatch: dispatchOfferFormContext } = useOfferFormContext();

  let childProtection = data.childProtection;

  const [ childProtectionValue, setChildProtectionValue ] =
    useState<string | undefined>(isReport ? childProtection : childProtection?.description);

  const isMandatoryField = data.targetGroup.totalNumberOfMenAndWomenUnderEighteen > 0 && isOfferTypeMis(data);

  useEffect(() => {
    if (!readOnlyMode) {
      const actionObject: Action = {
        subFormId: 'childProtection',
        errors: isMandatoryField
          ? isEmpty(childProtectionValue)
            ? {
              description: 'is mandatory field',
            }
            : {}
          : {},
        values: {},
      };
      if (
        !('childProtection' in state) ||
        ('childProtection' in state && state['childProtection']['errorCount'] !== Object.keys(actionObject.errors).length)
      ) {
        dispatchOfferFormContext(actionObject);
      }
    }
  }, [ childProtectionValue, dispatch, dispatchOfferFormContext, isMandatoryField, readOnlyMode, state ]);

  return (
    <Grid container spacing={ 3 } className={ classes.contentContainer }>
      { !isReport && (
        <Grid item xs={ 12 }>
          <DefaultTextField
            disabled={ readOnlyMode }
            labelKey="input:label:child-protection-description"
            helperTextKey="info:child-protection-description"
            value={ childProtectionValue }
            multiline
            rows={ 3 }
            onChange={ event => {
              childProtection.description = event.target.value;
              setChildProtectionValue(event.target.value);
              dispatch(offerActions.updateCurrentOffer(data));
            } }
            isError={ isMandatoryField && isEmpty(childProtectionValue) }
          />
        </Grid>
      ) }
      { isReport && (
        <Grid item xs={ 12 }>
          <DefaultTextField
            disabled={ readOnlyMode }
            labelKey="input:label:child-protection-description"
            helperTextKey="info:child-protection-description"
            value={ childProtectionValue }
            multiline
            rows={ 3 }
            onChange={ event => {
              data.childProtection = event.target.value;
              setChildProtectionValue(event.target.value);
              dispatch(reportActions.updateCurrentReport(data));
            } }
            isError={ isMandatoryField && isEmpty(childProtectionValue) }
          />
        </Grid>
      ) }
    </Grid>
  );
};

export default ChildProtectionForm;
