import React from 'react';
import { FormControl, InputLabel, makeStyles, MenuItem, Select } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { FlagDE, FlagFR, FlagPT, FlagUS } from '../Logo/Logo';

export interface LanguageProps {
  language: string;
  inputLabel: string;
  isReadOnly?: boolean;
  onChange: (language: string) => void;
}

const useStyles = makeStyles({
  languageSelectorMenuItem: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
});

const LanguageSelection = (props: LanguageProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { language, inputLabel, onChange, isReadOnly } = props;
  return (
    <FormControl fullWidth disabled={isReadOnly}>
      <InputLabel htmlFor="culture-select">{t(inputLabel)}</InputLabel>
      <Select
        value={language}
        onChange={e => onChange(e.target.value as string)}
        inputProps={{
          name: 'culture-select',
          id: 'culture-select',
        }}
      >
        <MenuItem value="DE">
          <div className={classes.languageSelectorMenuItem}>
            <FlagDE />{t('text:culture-de_de')}
          </div>
        </MenuItem>
        <MenuItem value="EN">
          <div className={classes.languageSelectorMenuItem}>
            <FlagUS />{t('text:culture-en_us')}
          </div>
        </MenuItem>
        <MenuItem value="FR">
          <div className={classes.languageSelectorMenuItem}>
            <FlagFR />{t('text:culture-fr_fr')}
          </div>
        </MenuItem>
        <MenuItem value="PT">
          <div className={classes.languageSelectorMenuItem}>
            <FlagPT />{t('text:culture-pt_pt')}
          </div>
        </MenuItem>
      </Select>
    </FormControl>
  );
};
export default LanguageSelection;
