import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

type PrivateRouteProps = RouteProps & {
  isAuthenticated: boolean;
};

const PrivateRoute = ({ isAuthenticated, ...rest }: PrivateRouteProps) => {
  return !isAuthenticated ? <Redirect to="/login" /> : <Route {...rest} />;
};

export default PrivateRoute;
