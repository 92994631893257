import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';

const UpdateInfoBox = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        padding: '5px',
        border: '1px solid orange',
        marginBottom: 2,
        borderRadius: '4px',
      }}
    >
      <Typography variant="body2" align="center" color="orange">
        <WarningRoundedIcon sx={{ color: 'orange' }} />
        <br />
        {t('update-info')}
      </Typography>
    </Box>
  );
};

export default UpdateInfoBox;
