import React from 'react';
import { Grid, IconButton, makeStyles, Theme } from '@material-ui/core';
import SingleLineDataGrid from '../../DataGrid/SingleLineDataGrid';
import { DataGridColumn } from '../../DataGrid/DataGrid';
import DropDown from '../../Selection/DropDown';
import { useTranslation } from 'react-i18next';
import { DefaultNumberField, DefaultTextField } from '../../TextInputs/TextInputs';
import DeleteIcon from '@material-ui/icons/Delete';
import CostPlanItem from '../../../sdk/com/apiomat/frontend/missio/CostPlanItem';
import { costPlanCategories } from '../../../value-maps/CostPlanCategories';
import { costPlanTypes } from '../../../value-maps/CostPlanTypes';
import { currencies } from '../../../value-maps/Currencies';
import CostPlan from '../../../sdk/com/apiomat/frontend/missio/CostPlan';
import { alphabeticalSort } from '../../../utils/sort.util';

const useStyles = makeStyles((theme: Theme) => ({
  dataGridContainer: {
    flex: 1,
    display: 'flex',
    overflowX: 'auto',
    overflowY: 'hidden',
    marginTop: theme.spacing(1)
  },
  textFields: {
    flex: 1,
    minWidth: 100,
  },
  numberFields: {
    flex: 1,
    minWidth: 100,
  },
  dropDowns: {
    flex: 1,
    minWidth: 70,
  },
  dataGridDeleteButton: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    color: theme.palette.primary.main,
  },
  pos: {
    maxWidth: 70,
    minWidth: 70,
  },
  term: {
    minWidth: 180,
  },
  amount: {
    maxWidth: 100,
    minWidth: 100,
  },
  classic: {
    maxWidth: 120,
    minWidth: 100,
  },
  multiply: {
    maxWidth: 100,
    minWidth: 70,
  },
  comment: {
    minWidth: 150,
  },
}));

export const POSITION_ARRAY_ELEMENTS = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
];

export interface CostPlanDataGridProps {
  costPlan: CostPlan;
  isReadonly: boolean;
  updateCostPlan: (costPlan: CostPlan) => void;
}

const CostPlanDataGrid = (props: CostPlanDataGridProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isReadonly, costPlan, updateCostPlan } = props;

  const sortedCostPlanItems = costPlan.costPlanItems.sort((a, b) => {
    let valueA: string | number = a.createdAt.getTime();
    let valueB: string | number = b.createdAt.getTime();

    if (a.ID && b.ID) {
      valueA = a.ID;
      valueB = b.ID;
    }

    return (valueA > valueB ? 1 : -1);
  });

  const selectedCurrencies = costPlan.currencyRates
    .filter(currencyRate => Boolean(currencyRate.currency))
    .map(currencyRate => currencies.find(currency => currency.key === currencyRate.currency));

  const onDeleteButtonClicked = (costPlanItem: CostPlanItem) => {
    const index = costPlan.costPlanItems.indexOf(costPlanItem);
    costPlan.costPlanItems.splice(index, 1);
    updateCostPlan(costPlan);
  };

  const columns: DataGridColumn<CostPlanItem>[] = [
    {
      header: t('label:cost-plan:category'),
      valueExtractor: dataRecord => dataRecord.key,
      component: dataRecord => (
        <div className={ classes.dropDowns }>
          <DropDown
            selectedItemKey={ dataRecord.costPlanCategory }
            items={ costPlanCategories.map(x => ({
              key: x.key,
              locale: x.locale,
              value: t(x.value)
            })).sort(alphabeticalSort) }
            onSelectedItemChange={ (value: string) => {
              dataRecord.costPlanCategory = value;
              updateCostPlan(costPlan);
            } }
            disabled={ isReadonly }
            errorMessage={ !dataRecord.costPlanCategory ? t('mandatory_field') : null }
          />
        </div>
      ),
    },
    {
      header: t('label:cost-plan:cost-type'),
      valueExtractor: dataRecord => dataRecord.costType,
      component: dataRecord => (
        <div className={ classes.dropDowns }>
          <DropDown
            selectedItemKey={ dataRecord.costPlanType }
            items={ costPlanTypes.map(x => ({
              key: x.key,
              locale: x.locale,
              value: t(x.value)
            })).sort(alphabeticalSort) }
            onSelectedItemChange={ (value: string) => {
              dataRecord.costPlanType = value;
              updateCostPlan(costPlan);
            } }
            disabled={ isReadonly }
            errorMessage={ !dataRecord.costPlanType ? t('mandatory_field') : null }
          />
        </div>
      ),
    },
    {
      header: t('label:cost-plan:position-one'),
      valueExtractor: dataRecord => dataRecord.positionOne,
      component: dataRecord => (
        <div className={ classes.dropDowns }>
          <DropDown
            selectedItemKey={ dataRecord.positionOne }
            items={ POSITION_ARRAY_ELEMENTS.map(value => {
              return {
                key: value,
                value: value,
              };
            }).concat({ key: dataRecord.positionOne, value: dataRecord.positionOne }) }
            onSelectedItemChange={ value => {
              dataRecord.positionOne = value;
              updateCostPlan(costPlan);
            } }
            disabled={ isReadonly }
          />
        </div>
      ),
    },
    {
      header: t('label:cost-plan:position-two'),
      valueExtractor: dataRecord => dataRecord.positionTwo,
      component: dataRecord => (
        <div className={ classes.pos }>
          <DefaultTextField
            fullWidth
            value={ dataRecord.positionTwo }
            onChange={ event => {
              dataRecord.positionTwo = event.target.value;
              updateCostPlan(costPlan);
            } }
            disabled={ isReadonly }
          />
        </div>
      ),
    },
    {
      header: t('label:cost-plan:term'),
      valueExtractor: dataRecord => dataRecord.term,
      component: dataRecord => (
        <div className={ classes.term }>
          <DefaultTextField
            fullWidth
            value={ dataRecord.description }
            onChange={ event => {
              dataRecord.description = event.target.value;
              updateCostPlan(costPlan);
            } }
            disabled={ isReadonly }
          />
        </div>
      ),
    },
    {
      header: t('label:cost-plan:unit'),
      valueExtractor: dataRecord => dataRecord.unit,
      component: dataRecord => (
        <div className={ classes.classic }>
          <DefaultTextField
            fullWidth
            className={ classes.textFields }
            value={ dataRecord.unit }
            onChange={ event => {
              dataRecord.unit = event.target.value;
              updateCostPlan(costPlan);
            } }
            disabled={ isReadonly }
          />
        </div>
      ),
    },
    {
      header: t('label:cost-plan:amount'),
      valueExtractor: dataRecord => dataRecord.amount,
      component: dataRecord => (
        <div className={ classes.amount }>
          <DefaultNumberField
            fullWidth
            value={ dataRecord.amount }
            className={ classes.numberFields }
            onValueChanged={ (value: number) => {
              dataRecord.amount = value;
              updateCostPlan(costPlan);
            } }
            disabled={ isReadonly }
          />
        </div>
      ),
    },
    {
      header: <div className={ classes.multiply }>{ t('label:cost-plan:unit-of-multiply') }</div>,
      valueExtractor: dataRecord => dataRecord.unitOfFactor,
      component: dataRecord => (
        <div className={ classes.multiply }>
          <DefaultTextField
            className={ classes.textFields }
            fullWidth
            value={ dataRecord.unitOfFactor }
            onChange={ event => {
              dataRecord.unitOfFactor = event.target.value;
              updateCostPlan(costPlan);
            } }
            disabled={ isReadonly }
          />
        </div>
      ),
    },
    {
      header: t('label:cost-plan:optional-multiply'),
      valueExtractor: dataRecord => dataRecord.optionalFactor,
      component: dataRecord => (
        <div className={ classes.classic }>
          <DefaultNumberField
            fullWidth
            className={ classes.numberFields }
            value={ dataRecord.optionalFactor }
            onValueChanged={ (value: number) => {
              dataRecord.optionalFactor = value;
              updateCostPlan(costPlan);
            } }
            disabled={ isReadonly }
          />
        </div>
      ),
    },
    {
      header: t('label:cost-plan:cost-per-unit'),
      valueExtractor: dataRecord => dataRecord.costPerUnit,
      component: dataRecord => (
        <div className={ classes.classic }>
          <DefaultNumberField
            fullWidth
            className={ classes.numberFields }
            value={ dataRecord.costPerUnit }
            step={0.01}
            onValueChanged={ (value: number) => {
              dataRecord.costPerUnit = value;
              updateCostPlan(costPlan);
            } }
            disabled={ isReadonly }
            errorMessage={ !(dataRecord.costPerUnit > 0) ? t('mandatory_field') : null }
          />
        </div>
      ),
    },
    {
      header: t('label:cost-plan:national-currency-grid'),
      valueExtractor: dataRecord => dataRecord.nationalCurrencyGrid.name,
      component: dataRecord => (
        <div className={ classes.dropDowns }>
          <DropDown
            selectedItemKey={ dataRecord.currency }
            items={ selectedCurrencies
              .map(x => ({ key: x.key, value: t(x.value) }))
              .sort((a, b) => a.value.localeCompare(b.value, 'en-US', { ignorePunctuation: true })) }
            onSelectedItemChange={ (value: string) => {
              dataRecord.currency = value;
              updateCostPlan(costPlan);
            } }
            disabled={ isReadonly }
            errorMessage={ !dataRecord.currency ? t('mandatory_field') : null }
          />
        </div>
      ),
    },
    {
      header: t('label:cost-plan:total-sum'),
      valueExtractor: dataRecord => dataRecord.totalSum,
      component: dataRecord => (
        <div className={ classes.classic }>
          <DefaultTextField
            fullWidth
            disabled={ true }
            value={ new Intl.NumberFormat().format(dataRecord.totalSum) }
            className={ classes.numberFields }
          />
        </div>
      ),
    },
    {
      header: t('label:cost-plan:total-sum-eur'),
      valueExtractor: dataRecord => dataRecord.totalSumEUR,
      component: dataRecord => (
        <div className={ classes.classic }>
          <DefaultTextField
            fullWidth
            value={ new Intl.NumberFormat('de-DE', {
              style: 'currency',
              currency: 'EUR'
            }).format(dataRecord.totalSumEUR) }
            disabled={ true }
            className={ classes.numberFields }
          />
        </div>
      ),
    },
    {
      header: t('label:cost-plan:comment'),
      valueExtractor: dataRecord => dataRecord.comment,
      component: dataRecord => (
        <DefaultTextField
          fullWidth
          className={ classes.comment }
          value={ dataRecord.comment }
          onChange={ event => {
            dataRecord.comment = event.target.value;
            updateCostPlan(costPlan);
          } }
          disabled={ isReadonly }
        />
      ),
    },
    {
      header: '',
      valueExtractor: dataRecord => dataRecord.ID,
      component: dataRecord =>
        isReadonly ? false : (
          <IconButton onClick={ () => onDeleteButtonClicked(dataRecord) }>
            <DeleteIcon color="primary"/>
          </IconButton>
        ),
    },
  ];

  return (
    <div className={ classes.dataGridContainer }>
      <Grid container>
        <SingleLineDataGrid
          columns={ columns }
          datasource={ sortedCostPlanItems }
          emptyMessage={ {
            title: '',
            component: columns.map(x => x.component),
          } }
        />
      </Grid>
    </div>
  );
};

export default CostPlanDataGrid;
