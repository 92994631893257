import { combineReducers, configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { all, fork } from 'redux-saga/effects';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createHashHistory as createHistory } from 'history';
import { authReducer, authSaga, authTransform } from './auth';
import { notificationReducer } from './notification';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { userReducer, userSaga } from './users';
import { offerReducer, offerSaga, offerTransform } from './offers';
import { offlineReducer, offlineSaga, offlineTransform } from './offline';
import { formTemplateReducer, formTemplateSaga } from './formTemplate';
import { reportReducer, reportSaga, reportTransform } from './report';
import { evaluationReducer, evaluationSaga, evaluationTransform } from './evaluation';
import { auditReducer, auditSaga, auditTransform } from './audit';
import { surveyReducer, surveySaga, surveyTransform } from './survey';
import { shareReportReducer, shareReportSaga } from './shareReport';
import { pdfExportReducer, pdfExportSaga } from './pdfExport';
import { contactBookTransform, contactBookReducer, contactSaga } from './contactBook';
import { interceptor } from './interceptor';
import { referentSaga, referentsReducer } from './referent';

export const history = createHistory({ basename: '' });

const sagaMiddleware = createSagaMiddleware();

const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['auth', 'offer', 'offline', 'report', 'evaluation', 'audit', 'survey', 'contact', 'contactBook'],
  blacklist: [],
  transforms: [
    authTransform,
    offerTransform,
    offlineTransform,
    reportTransform,
    contactBookTransform,
    surveyTransform,
    evaluationTransform,
    auditTransform,
  ],
};

const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  offer: offerReducer,
  report: reportReducer,
  formTemplate: formTemplateReducer,
  notifiaction: notificationReducer,
  router: connectRouter(history),
  offline: offlineReducer,
  evaluation: evaluationReducer,
  audit: auditReducer,
  survey: surveyReducer,
  shareReport: shareReportReducer,
  pdfExport: pdfExportReducer,
  contactBook: contactBookReducer,
  referent: referentsReducer,
});

const rootSaga = function* root() {
  yield all([
    fork(userSaga),
    fork(authSaga),
    fork(offerSaga),
    fork(reportSaga),
    fork(auditSaga),
    fork(evaluationSaga),
    fork(offlineSaga),
    fork(formTemplateSaga),
    fork(surveySaga),
    fork(shareReportSaga),
    fork(pdfExportSaga),
    fork(contactSaga),
    fork(referentSaga),
  ]);
};

export type AppState = ReturnType<typeof rootReducer>;

export default () => {
  const persistedReducer = persistReducer(persistConfig, rootReducer);
  const store = configureStore({
    reducer: persistedReducer,
    middleware: [sagaMiddleware, routerMiddleware(history)],
  });
  interceptor(store);
  sagaMiddleware.run(rootSaga);
  const persistor = persistStore(store);
  return { store, persistor };
};
