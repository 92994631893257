import { GeneralValueMap } from './GeneralValueMap';
import { MisMeasureTypes, MwiMeasureTypes } from '../enums/MeasureTypes';

export const commonAttachmentTypes: GeneralValueMap[] = [
  {
    locale: 'de',
    value: 'attachments:attachment-type:recommendation',
    key: 'Empfehlung',
    mandatoryForOfferMeasures: [
      MisMeasureTypes.equipmentPurchase,
      MisMeasureTypes.trainingPrograms,
      MisMeasureTypes.technologyPurchase,
      MisMeasureTypes.realEstate,
      MisMeasureTypes.evaluation,
      MisMeasureTypes.vehicle,
      MisMeasureTypes.conference,
      MisMeasureTypes.pastoralProgram,
      MisMeasureTypes.media,
      MisMeasureTypes.publication,
      MisMeasureTypes.scholarshipInstitute,
      MisMeasureTypes.scholarshipCongregation,
      MisMeasureTypes.maintenance,
      MisMeasureTypes.scholarship,
      MwiMeasureTypes.library,
      MwiMeasureTypes.researchInstitution,
      MwiMeasureTypes.researchStudy,
      MwiMeasureTypes.researchPerson,
      MwiMeasureTypes.conference,
      MwiMeasureTypes.conferenceParticipation,
      MwiMeasureTypes.facultyExchange,
      MwiMeasureTypes.publicationElectronic,
      MwiMeasureTypes.publicationPrint,
      MwiMeasureTypes.scholarship,
      MwiMeasureTypes.translation,
      MwiMeasureTypes.specialProject,
    ],
  },
  {
    locale: 'de',
    value: 'attachments:attachment-type:schedule',
    key: 'Zeitplan',
    mandatoryForOfferMeasures: [
      MisMeasureTypes.equipmentPurchase,
      MisMeasureTypes.trainingPrograms,
      MisMeasureTypes.technologyPurchase,
      MisMeasureTypes.realEstate,
      MisMeasureTypes.evaluation,
      MisMeasureTypes.vehicle,
      MisMeasureTypes.conference,
      MisMeasureTypes.pastoralProgram,
      MisMeasureTypes.media,
      MisMeasureTypes.publication,
      MisMeasureTypes.scholarshipInstitute,
      MisMeasureTypes.scholarshipCongregation,
      MisMeasureTypes.maintenance,
      MisMeasureTypes.scholarship,
      MwiMeasureTypes.researchInstitution,
      MwiMeasureTypes.researchStudy,
      MwiMeasureTypes.researchPerson,
      MwiMeasureTypes.conference,
      MwiMeasureTypes.facultyExchange,
      MwiMeasureTypes.specialProject,
    ],
    mandatoryForReportMeasures: [MisMeasureTypes.realEstate],
  },
  {
    locale: 'de',
    value: 'attachments:attachment-type:detail-description',
    key: 'Detail Beschreibung',
    mandatoryForOfferMeasures: [
      MisMeasureTypes.trainingPrograms,
      MisMeasureTypes.technologyPurchase,
      MisMeasureTypes.pastoralProgram,
      MisMeasureTypes.media,
      MwiMeasureTypes.researchPerson,
      MwiMeasureTypes.facultyExchange,
      MwiMeasureTypes.specialProject,
    ],
    mandatoryForReportMeasures: [
      MisMeasureTypes.pastoralProgram,
      MisMeasureTypes.media,
      MwiMeasureTypes.library,
      MwiMeasureTypes.researchPerson,
      MwiMeasureTypes.facultyExchange,
      MwiMeasureTypes.specialProject,
    ],
  },
  {
    locale: 'de',
    value: 'attachments:attachment-type:child-protection-policy',
    key: 'Safeguarding',
    mandatoryForOfferMeasures: [
      MisMeasureTypes.evaluation,
      MisMeasureTypes.pastoralProgram,
      MisMeasureTypes.equipmentPurchase,
      MisMeasureTypes.realEstate,
      MisMeasureTypes.vehicle,
      MisMeasureTypes.conference,
      MisMeasureTypes.media,
      MisMeasureTypes.trainingPrograms,
      MisMeasureTypes.technologyPurchase,
      MisMeasureTypes.publication,
      MisMeasureTypes.scholarshipCongregation,
      MisMeasureTypes.scholarshipInstitute,
      MisMeasureTypes.scholarship,
      MisMeasureTypes.maintenance,
      MwiMeasureTypes.conferenceParticipation,
      MwiMeasureTypes.conference,
      MwiMeasureTypes.facultyExchange,
      MwiMeasureTypes.publicationElectronic,
      MwiMeasureTypes.publicationPrint,
      MwiMeasureTypes.library,
      MwiMeasureTypes.researchInstitution,
      MwiMeasureTypes.researchPerson,
      MwiMeasureTypes.researchStudy,
      MwiMeasureTypes.scholarship,
      MwiMeasureTypes.translation,
      MwiMeasureTypes.specialProject,
    ],
  },
  {
    locale: 'de',
    value: 'attachments:attachment-type:pastoral-plan',
    key: 'Pastoralplan',
    mandatoryForOfferMeasures: [
      MisMeasureTypes.equipmentPurchase,
      MisMeasureTypes.trainingPrograms,
      MisMeasureTypes.technologyPurchase,
      MisMeasureTypes.realEstate,
      MisMeasureTypes.evaluation,
      MisMeasureTypes.vehicle,
      MisMeasureTypes.conference,
      MisMeasureTypes.pastoralProgram,
      MisMeasureTypes.media,
      MisMeasureTypes.publication,
      MisMeasureTypes.scholarshipInstitute,
      MisMeasureTypes.scholarshipCongregation,
      MisMeasureTypes.maintenance,
      MisMeasureTypes.scholarship,
    ],
  },
  {
    locale: 'de',
    value: 'attachments:attachment-type:cost-estimate',
    key: 'Kostenvoranschlag',
    mandatoryForOfferMeasures: [
      MisMeasureTypes.equipmentPurchase,
      MisMeasureTypes.technologyPurchase,
      MisMeasureTypes.vehicle,
      MisMeasureTypes.publication,
      MwiMeasureTypes.library,
      MwiMeasureTypes.translation,
      MwiMeasureTypes.specialProject,
    ],
  },
  {
    locale: 'de',
    value: 'attachments:attachment-type:receipt-list',
    key: 'Belegliste',
    mandatoryForReportMeasures: [
      MisMeasureTypes.equipmentPurchase,
      MisMeasureTypes.trainingPrograms,
      MisMeasureTypes.technologyPurchase,
      MisMeasureTypes.realEstate,
      MisMeasureTypes.evaluation,
      MisMeasureTypes.vehicle,
      MisMeasureTypes.conference,
      MisMeasureTypes.pastoralProgram,
      MisMeasureTypes.media,
      MisMeasureTypes.publication,
      MisMeasureTypes.scholarshipInstitute,
      MisMeasureTypes.scholarshipCongregation,
      MwiMeasureTypes.library,
      MwiMeasureTypes.researchInstitution,
      MwiMeasureTypes.researchPerson,
      MwiMeasureTypes.researchStudy,
      MwiMeasureTypes.conference,
      MwiMeasureTypes.conferenceParticipation,
      MwiMeasureTypes.facultyExchange,
      MwiMeasureTypes.publicationElectronic,
      MwiMeasureTypes.publicationPrint,
    ],
  },
  {
    locale: 'de',
    value: 'attachments:attachment-type:copy-receipt',
    key: 'Kopie Beleg ',
    mandatoryForReportMeasures: [
      MisMeasureTypes.equipmentPurchase,
      MisMeasureTypes.technologyPurchase,
      MisMeasureTypes.vehicle,
      MisMeasureTypes.publication,
      MwiMeasureTypes.library,
      MwiMeasureTypes.researchInstitution,
      MwiMeasureTypes.researchPerson,
      MwiMeasureTypes.researchStudy,
      MwiMeasureTypes.conference,
      MwiMeasureTypes.conferenceParticipation,
      MwiMeasureTypes.facultyExchange,
      MwiMeasureTypes.publicationElectronic,
      MwiMeasureTypes.publicationPrint,
    ],
  },
  {
    locale: 'de',
    value: 'attachments:attachment-type:employees',
    key: 'Liste von Mitarbeitern',
    mandatoryForOfferMeasures: [
      MisMeasureTypes.trainingPrograms,
      MisMeasureTypes.realEstate,
      MisMeasureTypes.evaluation,
      MisMeasureTypes.conference,
      MisMeasureTypes.pastoralProgram,
      MisMeasureTypes.publication,
      MwiMeasureTypes.researchInstitution,
      MwiMeasureTypes.conference,
    ],
    mandatoryForReportMeasures: [
      MisMeasureTypes.trainingPrograms,
      MisMeasureTypes.realEstate,
      MisMeasureTypes.evaluation,
      MisMeasureTypes.conference,
      MisMeasureTypes.pastoralProgram,
      MisMeasureTypes.publication,
      MisMeasureTypes.scholarshipInstitute,
    ],
  },
  {
    locale: 'de',
    value: 'attachments:attachment-type:participant-list',
    key: 'Teilnehmerliste',
    mandatoryForOfferMeasures: [MisMeasureTypes.conference, MwiMeasureTypes.conference, MwiMeasureTypes.conferenceParticipation],
    mandatoryForReportMeasures: [
      MisMeasureTypes.conference,
      MwiMeasureTypes.conference,
      MwiMeasureTypes.conferenceParticipation,
      MwiMeasureTypes.facultyExchange,
    ],
  },
  {
    locale: 'de',
    value: 'attachments:attachment-type:construction-photographs',
    key: 'Gebäudefotos',
    mandatoryForReportMeasures: [MisMeasureTypes.realEstate],
  },
  {
    locale: 'de',
    value: 'input:label:measure:mis:real-estate:square-metre-of-building',
    key: 'Wohn-/Nutzfläche Räume',
    mandatoryForOfferMeasures: [MisMeasureTypes.realEstate],
    mandatoryForReportMeasures: [MisMeasureTypes.realEstate],
  },
  {
    locale: 'de',
    value: 'attachments:attachment-type:construction-plan',
    key: 'Bauplan',
    mandatoryForOfferMeasures: [MisMeasureTypes.realEstate],
  },
  { locale: 'de', value: 'attachments:attachment-type:building-permit', key: 'Baugenehmigung' },
  { locale: 'de', value: 'attachments:attachment-type:structural-analysis', key: 'Baustatik' },
  {
    locale: 'de',
    value: 'attachments:attachment-type:property-owner',
    key: 'Eigentümer Grundstück',
    mandatoryForOfferMeasures: [MisMeasureTypes.realEstate],
  },
  {
    locale: 'de',
    value: 'attachments:attachment-type:building-owner',
    key: 'Eigentümer Gebäude',
    mandatoryForOfferMeasures: [MisMeasureTypes.realEstate],
  },
  { locale: 'de', value: 'attachments:attachment-type:income-calculation', key: 'Einkommensberechnung' },
  { locale: 'de', value: 'attachments:attachment-type:businessplan', key: 'Businessplan' },
  {
    locale: 'de',
    value: 'attachments:attachment-type:terms-of-reference',
    key: 'Terms of Reference',
    mandatoryForOfferMeasures: [MisMeasureTypes.evaluation],
  },
  {
    locale: 'de',
    value: 'attachments:attachment-type:evaluation',
    key: 'Evaluationsbericht',
    mandatoryForReportMeasures: [MisMeasureTypes.evaluation],
  },
  {
    locale: 'de',
    value: 'attachments:attachment-type:detailed-program',
    key: 'Detailliertes Programm',
    mandatoryForOfferMeasures: [MisMeasureTypes.conference],
    mandatoryForReportMeasures: [MisMeasureTypes.conference],
  },
  {
    locale: 'de',
    value: 'attachments:attachment-type:publication-description',
    key: 'Beschreibung Publikation',
    mandatoryForOfferMeasures: [
      MisMeasureTypes.publication,
      MwiMeasureTypes.publicationElectronic,
      MwiMeasureTypes.publicationPrint,
      MwiMeasureTypes.translation,
    ],
  },
  {
    locale: 'de',
    value: 'attachments:attachment-type:profit-loss-statement',
    key: 'Gewinn- und Verlustrechnung',
    mandatoryForOfferMeasures: [MisMeasureTypes.publication, MwiMeasureTypes.publicationElectronic, MwiMeasureTypes.publicationPrint],
    mandatoryForReportMeasures: [MisMeasureTypes.publication, MwiMeasureTypes.specialProject],
  },
  {
    locale: 'de',
    value: 'attachments:attachment-type:index',
    key: 'Inhaltsverzeichnis',
    mandatoryForOfferMeasures: [
      MisMeasureTypes.publication,
      MwiMeasureTypes.publicationPrint,
      MwiMeasureTypes.publicationElectronic,
      MwiMeasureTypes.translation,
    ],
    mandatoryForReportMeasures: [MisMeasureTypes.publication],
  },
  {
    locale: 'de',
    value: 'attachments:attachment-type:candidates',
    key: 'Liste der Kandidaten/Begünstigte',
    mandatoryForOfferMeasures: [MisMeasureTypes.trainingPrograms, MisMeasureTypes.scholarshipCongregation, MisMeasureTypes.maintenance],
    mandatoryForReportMeasures: [
      MisMeasureTypes.trainingPrograms,
      MisMeasureTypes.scholarshipInstitute,
      MisMeasureTypes.scholarshipCongregation,
      MisMeasureTypes.maintenance,
    ],
  },
  {
    locale: 'de',
    value: 'attachments:attachment-type:curriculum-vitae',
    key: 'Lebenslauf des Begünstigten',
    mandatoryForOfferMeasures: [
      MisMeasureTypes.scholarshipInstitute,
      MisMeasureTypes.scholarshipCongregation,
      MisMeasureTypes.maintenance,
      MisMeasureTypes.scholarship,
      MwiMeasureTypes.scholarship,
    ],
    mandatoryForReportMeasures: [MisMeasureTypes.scholarshipInstitute],
  },
  {
    locale: 'de',
    value: 'attachments:attachment-type:receipt-copy',
    key: 'Belegexemplar',
    mandatoryForReportMeasures: [
      MisMeasureTypes.publication,
      MwiMeasureTypes.researchInstitution,
      MwiMeasureTypes.researchPerson,
      MwiMeasureTypes.researchStudy,
      MwiMeasureTypes.conference,
      MwiMeasureTypes.publicationPrint,
      MwiMeasureTypes.scholarship,
      MwiMeasureTypes.translation,
    ],
  },
  {
    locale: 'de',
    value: 'attachments:attachment-type:statement-from-institution',
    key: 'Stellungnahme Institution',
    mandatoryForOfferMeasures: [
      MisMeasureTypes.scholarship,
      MwiMeasureTypes.researchInstitution,
      MwiMeasureTypes.researchPerson,
      MwiMeasureTypes.scholarship,
    ],
  },
  {
    locale: 'de',
    value: 'attachments:attachment-type:certificates',
    key: 'Zeugnisse',
    mandatoryForOfferMeasures: [
      MisMeasureTypes.scholarship,
      MwiMeasureTypes.researchStudy,
      MwiMeasureTypes.publicationElectronic,
      MwiMeasureTypes.scholarship,
    ],
    mandatoryForReportMeasures: [MisMeasureTypes.scholarship],
  },
  {
    locale: 'de',
    value: 'attachments:attachment-type:other',
    key: 'Sonstiges',
  },
  {
    locale: 'de',
    value: 'attachments:attachment-type:institution-profile',
    key: 'Profil der Institution',
  },
  {
    locale: 'de',
    value: 'attachments:attachment-type:self-commitment',
    key: 'Selbstverpflichtungserklärung',
    mandatoryForOfferMeasures: [
      MwiMeasureTypes.scholarship,
    ],
  },
  {
    locale: 'de',
    value: 'attachments:attachment-type:certificate-of-clearance',
    key: 'Unbedenklichkeitserklärung',
  },
  {
    locale: 'de',
    value: 'attachments:attachment-type:certificate-of-clearance-for-lay-people',
    key: 'Unbedenklichkeitserklärung für Laien',
  },
];

export const mwiAttachmentTypes: GeneralValueMap[] = [
  {
    locale: 'de',
    value: 'attachments:attachment-type:number-of-books',
    key: 'Anzahl der Bücher in Bibliothek nach Kategorien',
    mandatoryForOfferMeasures: [MwiMeasureTypes.library],
  },
  {
    locale: 'de',
    value: 'attachments:attachment-type:list-of-licences',
    key: 'Liste der zu kaufenden Lizenzen',
    mandatoryForOfferMeasures: [MwiMeasureTypes.library],
  },
  {
    locale: 'de',
    value: 'attachments:attachment-type:library-visitor',
    key: 'Besucher der Bibliothek',
    mandatoryForOfferMeasures: [MwiMeasureTypes.library],
  },
  {
    locale: 'de',
    value: 'attachments:attachment-type:budget',
    key: 'Jahresbudget',
    mandatoryForOfferMeasures: [MwiMeasureTypes.library, MwiMeasureTypes.researchInstitution],
  },
  {
    locale: 'de',
    value: 'attachments:attachment-type:maintenance-costs',
    key: 'Wartungskosten',
  },
  {
    locale: 'de',
    value: 'attachments:attachment-type:pro-forma-receipt',
    key: 'Pro-Forma-Rechnung',
  },
  {
    locale: 'de',
    value: 'attachments:attachment-type:list-of-books',
    key: 'Bücherliste',
    mandatoryForOfferMeasures: [MwiMeasureTypes.library],
  },
  {
    locale: 'de',
    value: 'attachments:attachment-type:development-plan',
    key: 'Forschungsagenda',
    mandatoryForOfferMeasures: [MwiMeasureTypes.library, MwiMeasureTypes.researchInstitution, MwiMeasureTypes.publicationElectronic],
  },
  {
    locale: 'de',
    value: 'attachments:attachment-type:assessment-supervisor',
    key: 'Gutachten Doktorvater / Betreuer',
    mandatoryForOfferMeasures: [MwiMeasureTypes.researchStudy, MwiMeasureTypes.publicationPrint],
  },
  {
    locale: 'de',
    value: 'attachments:attachment-type:passport-photo',
    key: 'Passbild',
    mandatoryForOfferMeasures: [MwiMeasureTypes.researchStudy, MwiMeasureTypes.researchPerson, MwiMeasureTypes.scholarship],
  },
  {
    locale: 'de',
    value: 'attachments:attachment-type:research-report',
    key: 'Forschungsbericht',
    mandatoryForReportMeasures: [MwiMeasureTypes.researchStudy, MwiMeasureTypes.researchPerson, MwiMeasureTypes.researchInstitution],
  },
  {
    locale: 'de',
    value: 'attachments:attachment-type:program',
    key: 'Programm',
    mandatoryForOfferMeasures: [MwiMeasureTypes.conference, MwiMeasureTypes.conferenceParticipation],
    mandatoryForReportMeasures: [MwiMeasureTypes.conference, MwiMeasureTypes.conferenceParticipation],
  },
  {
    locale: 'de',
    value: 'attachments:attachment-type:research-plan',
    key: 'Forschungsplan',
    mandatoryForOfferMeasures: [MwiMeasureTypes.researchStudy, MwiMeasureTypes.researchInstitution, MwiMeasureTypes.researchPerson],
  },
  {
    locale: 'de',
    value: 'attachments:attachment-type:conference-report',
    key: 'Beitrag',
    mandatoryForOfferMeasures: [MwiMeasureTypes.conferenceParticipation],
    mandatoryForReportMeasures: [MwiMeasureTypes.conference, MwiMeasureTypes.conferenceParticipation],
  },
  {
    locale: 'de',
    value: 'attachments:attachment-type:invitation-letter',
    key: 'Einladungsschreiben',
    mandatoryForOfferMeasures: [MwiMeasureTypes.conferenceParticipation, MwiMeasureTypes.facultyExchange],
  },
  {
    locale: 'de',
    value: 'attachments:attachment-type:sell-after-two-years',
    key: 'Verkauf nach 2 Jahren',
    mandatoryForReportMeasures: [MwiMeasureTypes.publicationPrint],
  },
  {
    locale: 'de',
    value: 'attachments:attachment-type:curriculum-vitae-mwi',
    key: 'Lebenslauf',
    mandatoryForOfferMeasures: [
      MwiMeasureTypes.researchPerson,
      MwiMeasureTypes.researchStudy,
      MwiMeasureTypes.conferenceParticipation,
      MwiMeasureTypes.facultyExchange,
      MwiMeasureTypes.publicationPrint,
      MwiMeasureTypes.publicationElectronic,
    ],
  },
  {
    locale: 'de',
    value: 'attachments:attachment-type:additional-languages',
    key: 'zusätzliche Sprachkenntnisse',
    mandatoryForOfferMeasures: [MwiMeasureTypes.scholarship],
  },
  {
    locale: 'de',
    value: 'attachments:attachment-type:knowdledge-university-language',
    key: 'Kenntnisse der Sprache des Landes der Universität',
    mandatoryForOfferMeasures: [MwiMeasureTypes.scholarship],
  },
  {
    locale: 'de',
    value: 'attachments:attachment-type:german-certificates',
    key: 'Zertifikate Deutsch',
  },
  {
    locale: 'de',
    value: 'attachments:attachment-type:future-activity-field',
    key: 'künftiges Tätigkeitsgebiet',
    mandatoryForOfferMeasures: [MwiMeasureTypes.scholarship],
  },
  {
    locale: 'de',
    value: 'attachments:attachment-type:travel-expenses',
    key: 'Fahrtkostenübernahme',
    mandatoryForOfferMeasures: [MwiMeasureTypes.scholarship],
  },
  {
    locale: 'de',
    value: 'attachments:attachment-type:academic-cv',
    key: 'akademischer Lebenslauf',
    mandatoryForOfferMeasures: [MwiMeasureTypes.scholarship],
  },
  {
    locale: 'de',
    value: 'attachments:attachment-type:justification-study-place',
    key: 'Begründung des Studienortes',
  },
  {
    locale: 'de',
    value: 'attachments:attachment-type:completion-certificate',
    key: 'Abschlusszeugnis',
    mandatoryForReportMeasures: [MwiMeasureTypes.scholarship],
  },
  {
    locale: 'de',
    value: 'attachments:attachment-type:new-address',
    key: 'neue Adresse',
    mandatoryForReportMeasures: [MwiMeasureTypes.scholarship],
  },
  {
    locale: 'de',
    value: 'attachments:attachment-type:planned-pastoral-action',
    key: '(geplante) pastorale Tätigkeit',
  },
  {
    locale: 'de',
    value: 'attachments:attachment-type:estimate-of-cost-books',
    key: 'Kostenvoranschlag Buchdruck',
    mandatoryForOfferMeasures: [MwiMeasureTypes.publicationPrint, MwiMeasureTypes.publicationElectronic],
  },
  {
    locale: 'de',
    value: 'attachments:attachment-type:scholarship-earlier',
    key: 'Stipendium ehemalig',
  },
  {
    locale: 'de',
    value: 'attachments:attachment-type:project-scheme',
    key: 'Projektskizze',
  },
  {
    locale: 'de',
    value: 'attachments:attachment-type:letter-of-motivation',
    key: 'Motivationsschreiben',
    mandatoryForOfferMeasures: [MwiMeasureTypes.scholarship],
  },
];

export const allAttachmentTypes: GeneralValueMap[] = [...mwiAttachmentTypes, ...commonAttachmentTypes];
