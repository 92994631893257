import { StateType } from '../../enums/StateType';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import React, { useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import StatusMessageDialog from '../Dialogs/StatusMessageDialog';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';
import { getSortedCommentHistoryKeys } from '../../utils/comment-history.utils';

const assignmentStatusStyles = makeStyles((theme: Theme) => ({
  statusContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer',
  },
  rejectionMessage: {
    marginLeft: 8,
    textAlign: 'left',
  },
  statusText: {
    fontWeight: 400,
    fontSize: '0.75rem',
    lineHeight: 1.66,
    fontStyle: 'italic',
    letterSpacing: '0.03333em',
    display: 'block',
  },
  dots: {
    color: theme.palette.primary.main,
  },
  cancelIcon: {
    color: theme.palette.error.main,
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.error.dark,
    },
  },
  checkIcon: {
    color: theme.palette.success.main,
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.success.dark,
    },
  },
  questionMarkIcon: {
    color: theme.palette.warning.main,
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.warning.dark,
    },
  },
}));

export const ApplicationStatus = props => {
  const { state } = props;
  const { t } = useTranslation();
  const [ isStatusMessageBoxOpen, setIsStatusMessageBoxOpen ] = useState(false);
  const classes = assignmentStatusStyles();

  const commentHistoryKeys = !state || !state?.rejectionReasonHistory ? [] : getSortedCommentHistoryKeys(Object.keys(state?.rejectionReasonHistory));
  const statusMessageText = state?.rejectionReason ? state?.rejectionReason :
    (commentHistoryKeys.length > 0 ? state?.rejectionReasonHistory[commentHistoryKeys[0]] : '');

  /**
   * Return tooltip element, which includes truncated string with '...' concatenated to its end.
   */
  const getLastCommentElement = () => {
    if (statusMessageText && statusMessageText.length <= 100) {
      return <span className={ classes.statusText }>{ statusMessageText }</span>;
    } else {
      return (
        <Tooltip title={ t('status-message-dialog:button:show-more') }>
          <span className={ classes.statusText }>
            { statusMessageText?.slice(0, 100) }
            <span className={ classes.dots }>
              ...
            </span>
          </span>
        </Tooltip>
      );
    }
  };

  const openHistoryCommentPopup = () => {
    setIsStatusMessageBoxOpen(true);
  };

  const closeHistoryCommentPopup = () => {
    setIsStatusMessageBoxOpen(false);
  };

  const renderedContent = () => {
    switch (state?.name) {
      case StateType.rejected :
        return (
          <>
            <div className={classes.statusContainer} onClick={openHistoryCommentPopup}>
              <CancelIcon className={classes.cancelIcon} />
              <span className={classes.rejectionMessage}>{getLastCommentElement()}</span>
            </div>
            <StatusMessageDialog
              isOpen={isStatusMessageBoxOpen}
              comment={state?.rejectionReason}
              commentHistoryMap={state?.rejectionReasonHistory}
              commentHistoryKeys={commentHistoryKeys}
              isRejectionMessage={true}
              onCancelClick={closeHistoryCommentPopup}
            />
          </>
        );
      case state?.name === StateType.enquiry :
        return (
          <>
            <div className={classes.statusContainer} onClick={openHistoryCommentPopup}>
              <HelpIcon className={classes.questionMarkIcon}></HelpIcon>
              <span className={classes.rejectionMessage}>{getLastCommentElement()}</span>
            </div>
            <StatusMessageDialog
              isOpen={isStatusMessageBoxOpen}
              comment={state?.rejectionReason}
              commentHistoryMap={state?.rejectionReasonHistory}
              commentHistoryKeys={commentHistoryKeys}
              isRejectionMessage={false}
              onCancelClick={closeHistoryCommentPopup}
            />
          </>
        );
      case StateType.cloud :
        return (
          <>
            <div className={classes.statusContainer} onClick={openHistoryCommentPopup}>
              {state?.rejectionMessage || state?.rejectionReasonHistory ? <CancelIcon className={classes.cancelIcon} /> : <></>}
              {state?.rejectionMessage || state?.rejectionReasonHistory ? (
                <span className={classes.rejectionMessage}>{getLastCommentElement()}</span>
              ) : (
                <></>
              )}
            </div>
            {state?.rejectionMessage || state?.rejectionReasonHistory ? (
              <StatusMessageDialog
                isOpen={isStatusMessageBoxOpen}
                comment={state?.rejectionReason}
                commentHistoryMap={state?.rejectionReasonHistory}
                commentHistoryKeys={commentHistoryKeys}
                isRejectionMessage={true}
                onCancelClick={closeHistoryCommentPopup}
              />
            ) : (
              <></>
            )}
          </>
        );
      default :
        return state?.name === StateType.easydorRejected ?
          <CancelIcon className={ classes.cancelIcon }/> :
          <CheckCircleIcon className={ classes.checkIcon }/>;
    }
  };

  if (
    state?.name === StateType.cloud ||
    state?.name === StateType.rejected ||
    state?.name === StateType.approved ||
    state?.name === StateType.easydor ||
    state?.name === StateType.enquiry ||
    state?.name === StateType.easydorInProgress ||
    state?.name === StateType.easydorLocked ||
    state?.name === StateType.easydorRejected
  ) {
    return renderedContent();
  } else {
    return <></>;
  }
};
