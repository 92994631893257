import { Grid } from '@material-ui/core';
import React, { useEffect, useMemo } from 'react';
import { useMwiMeasuresStyles } from './Styles';
import { useTranslation } from 'react-i18next';
import RadioButtons from '../../../Buttons/RadioButtons';
import DialogButton from '../../../Dialogs/DialogButton';
import MwiResearchStudy from '../../../../sdk/com/apiomat/frontend/missio/MwiResearchStudy';
import DropDown from '../../../Selection/DropDown';
import { countries } from '../../../../value-maps/Countries';
import { subjects } from '../../../../value-maps/Subjects';
import { graduations } from '../../../../value-maps/Graduations';
import { alphabeticalSort } from '../../../../utils/sort.util';
import { DropDownItem } from '../../../Selection/DropDownItem';
import { useFormik } from 'formik';
import { object, date, string, number } from 'yup';
import { useOfferFormContext } from '../../context/OfferFormContext';
import FormDatePicker from '../../../DatePicker/FormDatePicker';
import FormTextField from '../../InputFields/FormTextField';

export interface ResearchStudyProps {
  readOnlyMode: boolean;
  data: any;
  isReport?: boolean;
}

const ResearchStudy = (props: ResearchStudyProps) => {
  const { state, dispatch } = useOfferFormContext();
  const { readOnlyMode, data, isReport } = props;
  const classes = useMwiMeasuresStyles();
  const { t } = useTranslation();

  const {
    start,
    end,
    measureDescription,
    university,
    location,
    country,
    subject,
    graduation,
    subjectReason,
    subjectRelation,
    supervisor,
    subjectWork,
    topicMeaning,
    relevance,
    expert1,
    isScholarshipApplicationElsewhere,
    hasReadAdvertisingStatement,
    hasReadConditions,
  } = data.measure as MwiResearchStudy;

  const validationSchema = useMemo(
    () =>
      object({
        start: date().required(t('mandatory_field')),
        end: date().required(t('mandatory_field')),
        measureDescription: string().required(t('mandatory_field')),

        university: string(),
        location: string(),
        country: string(),
        subject: string(),
        graduation: string(),
        subjectReason: isReport ? string() : string().required(t('mandatory_field')),
        subjectRelation: isReport ? string() : string().required(t('mandatory_field')),
        supervisor: string(),
        subjectWork: string(),
        topicMeaning: string(),
        relevance: string(),
        expert1: string(),

        isScholarshipApplicationElsewhere: isReport ? string() : string().required(t('mandatory_field')),
        hasReadAdvertisingStatement: isReport ? number() : number().equals([1], t('mandatory_field')),
        hasReadConditions: isReport ? number() : number().equals([1], t('mandatory_field')),
      }),
    [t]
  );

  const formik = useFormik({
    initialValues: {
      start,
      end,
      measureDescription,

      university,
      location,
      country,
      subject,
      graduation,
      subjectReason,
      subjectRelation,
      supervisor,
      subjectWork,
      topicMeaning,
      relevance,
      expert1,

      isScholarshipApplicationElsewhere,
      hasReadAdvertisingStatement,
      hasReadConditions,
    },
    enableReinitialize: true,
    validationSchema,
    validateOnMount: true,
    onSubmit: () => {},
  });

  useEffect(() => {
    if (!readOnlyMode) {
      if (!formik.isValidating) {
        dispatch({ subFormId: 'measure', errors: formik.errors, values: formik.values });
      }
    }
  }, [formik.errors, formik.values, dispatch, formik.isValidating, readOnlyMode, state]);

  const countryItems: DropDownItem[] = useMemo(() => countries.map(el => ({ key: el.key, value: t(el.key) })).sort(alphabeticalSort), [t]);
  const subjectItems: DropDownItem[] = useMemo(() => subjects.map(el => ({ key: el.key, value: t(el.key) })).sort(alphabeticalSort), [t]);
  const graduationItems: DropDownItem[] = useMemo(() => graduations.map(el => ({ key: el.key, value: t(el.key) })).sort(alphabeticalSort), [
    t,
  ]);

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <FormDatePicker
            id="start"
            name="start"
            label={t('input:label:measure:planned-project-start')}
            value={formik.values.start}
            onChange={(date: Date) => formik.setFieldValue('start', date)}
            disabled={readOnlyMode}
            errorMessage={formik.errors.start}
          />
        </Grid>
        <Grid item xs={6}>
          <FormDatePicker
            id="end"
            name="end"
            label={t('input:label:measure:planned-project-end')}
            value={formik.values.end}
            minDate={formik.values.start}
            onChange={(date: Date) => formik.setFieldValue('end', date)}
            disabled={readOnlyMode}
            errorMessage={formik.errors.end}
          />
        </Grid>
        <Grid item xs={12}>
          <FormTextField
            id="measureDescription"
            name="measureDescription"
            disabled={readOnlyMode}
            labelKey="input:label:measure:measure-description"
            value={formik.values.measureDescription}
            multiline
            rows={3}
            onChange={formik.handleChange}
            errorMessage={formik.errors.measureDescription}
          />
        </Grid>
        <>
          <Grid item xs={12}>
            <FormTextField
              id="university"
              name="university"
              disabled={readOnlyMode}
              labelKey="input:label:measure:mwi:research:university-of-research"
              descriptionKey="info:measure:mwi:research:university-of-research"
              value={formik.values.university}
              multiline
              rows={3}
              onChange={formik.handleChange}
              errorMessage={formik.errors.university}
            />
          </Grid>
          <Grid item xs={12}>
            <FormTextField
              id="location"
              name="location"
              disabled={readOnlyMode}
              labelKey="input:label:measure:mwi:research:research-places"
              descriptionKey="info:measure:mwi:research:research-places"
              value={formik.values.location}
              onChange={formik.handleChange}
              errorMessage={formik.errors.location}
            />
          </Grid>
          {!isReport && (
            <>
              <Grid item xs={12}>
                <DropDown
                  labelKey="input:label:measure:mwi:research:country"
                  disabled={readOnlyMode}
                  selectedItemKey={formik.values.country}
                  items={countryItems}
                  onSelectedItemChange={(key: string) => formik.setFieldValue('country', key)}
                  errorMessage={formik.errors.country}
                />
              </Grid>
              <Grid item xs={12}>
                <DropDown
                  labelKey="input:label:measure:mwi:research:subject"
                  helperTextKey="info:measure:mwi:research:subject"
                  disabled={readOnlyMode}
                  selectedItemKey={formik.values.subject}
                  items={subjectItems}
                  onSelectedItemChange={(key: string) => formik.setFieldValue('subject', key)}
                  errorMessage={formik.errors.subject}
                />
              </Grid>
              <Grid item xs={12}>
                <DropDown
                  labelKey="input:label:measure:mwi:research:graduation"
                  helperTextKey="info:measure:mwi:research:graduation"
                  disabled={readOnlyMode}
                  selectedItemKey={formik.values.graduation}
                  items={graduationItems}
                  onSelectedItemChange={(key: string) => formik.setFieldValue('graduation', key)}
                  errorMessage={formik.errors.graduation}
                />
              </Grid>
              <Grid item xs={12}>
                <FormTextField
                  id="subjectReason"
                  name="subjectReason"
                  disabled={readOnlyMode}
                  labelKey="input:label:measure:mwi:research:subject-reason"
                  descriptionKey="info:measure:mwi:research:subject-reason"
                  multiline
                  rows={3}
                  value={formik.values.subjectReason}
                  onChange={formik.handleChange}
                  errorMessage={formik.errors.subjectReason}
                />
              </Grid>
              <Grid item xs={12}>
                <FormTextField
                  id="subjectRelation"
                  name="subjectRelation"
                  disabled={readOnlyMode}
                  labelKey="input:label:measure:mwi:research:subject-relation"
                  descriptionKey="info:measure:mwi:research:subject-relation"
                  multiline
                  rows={3}
                  value={formik.values.subjectRelation}
                  onChange={formik.handleChange}
                  errorMessage={formik.errors.subjectRelation}
                />
              </Grid>
              <Grid item xs={12}>
                <FormTextField
                  id="supervisor"
                  name="supervisor"
                  disabled={readOnlyMode}
                  labelKey="input:label:measure:mwi:research:supervisor-name"
                  descriptionKey="info:measure:mwi:research:supervisor-name"
                  value={formik.values.supervisor}
                  onChange={formik.handleChange}
                  errorMessage={formik.errors.supervisor}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <FormTextField
              id="subjectWork"
              name="subjectWork"
              disabled={readOnlyMode}
              labelKey="input:label:measure:mwi:research:topic-research"
              descriptionKey="info:measure:mwi:research:topic-research"
              multiline
              rows={3}
              value={formik.values.subjectWork}
              onChange={formik.handleChange}
              errorMessage={formik.errors.subjectWork}
            />
          </Grid>
        </>
        <Grid item xs={12}>
          <FormTextField
            id="topicMeaning"
            name="topicMeaning"
            disabled={readOnlyMode}
            labelKey="input:label:measure:mwi:research:topic-meaning-and-relation"
            descriptionKey="info:measure:mwi:research:topic-meaning-and-relation"
            multiline
            rows={3}
            value={formik.values.topicMeaning}
            onChange={formik.handleChange}
            errorMessage={formik.errors.topicMeaning}
          />
        </Grid>
        <Grid item xs={12}>
          <FormTextField
            id="relevance"
            name="relevance"
            disabled={readOnlyMode}
            labelKey="input:label:measure:mwi:research:relevance-on-scholarship-research"
            descriptionKey="info:measure:mwi:research:relevance-on-scholarship-research"
            multiline
            rows={3}
            value={formik.values.relevance}
            onChange={formik.handleChange}
            errorMessage={formik.errors.relevance}
          />
        </Grid>
        {!isReport && (
          <>
            <Grid item xs={12}>
              <FormTextField
                id="expert1"
                name="expert1"
                disabled={readOnlyMode}
                labelKey="input:label:measure:mwi:research:experts"
                descriptionKey="info:measure:mwi:research:experts"
                multiline
                rows={3}
                value={formik.values.expert1}
                onChange={formik.handleChange}
                errorMessage={formik.errors.expert1}
              />
            </Grid>
            <div className={classes.questionContainer}>
              <div className={classes.questionScholarship}>
                <Grid item xs={3}>
                  <RadioButtons
                    title={t('question:label:measure:mwi:library:scholarship-application')}
                    value={formik.values.isScholarshipApplicationElsewhere}
                    disabled={readOnlyMode}
                    onSelectedValueChanged={(value: number) => formik.setFieldValue('isScholarshipApplicationElsewhere', value)}
                    errorMessage={formik.errors.isScholarshipApplicationElsewhere}
                  />
                </Grid>
              </div>
              <div className={classes.questionOne}>
                <Grid item xs={3}>
                  <RadioButtons
                    title={t('question:label:mwi:advertisement')}
                    disabled={readOnlyMode}
                    value={formik.values.hasReadAdvertisingStatement}
                    onSelectedValueChanged={(value: number) => formik.setFieldValue('hasReadAdvertisingStatement', value)}
                    errorMessage={formik.errors.hasReadAdvertisingStatement}
                  />
                </Grid>
                <DialogButton
                  className={classes.dialogButton}
                  textId={'button:label:mwi:measure:research:advertisement'}
                  title={'button:label:mwi:advertisement'}
                />
              </div>
              <div className={classes.questionTwo}>
                <Grid item xs={3}>
                  <RadioButtons
                    title={t('question:label:mwi:conditions')}
                    disabled={readOnlyMode}
                    value={formik.values.hasReadConditions}
                    onSelectedValueChanged={(value: number) => formik.setFieldValue('hasReadAdvertisingStatement', value)}
                    errorMessage={formik.errors.hasReadAdvertisingStatement}
                  />
                </Grid>
                <DialogButton
                  className={classes.dialogButton}
                  textId={'button:label:mwi:measure:research:conditions'}
                  title={'button:label:mwi:conditions'}
                />
              </div>
            </div>
          </>
        )}
      </Grid>
    </div>
  );
};

export default ResearchStudy;
