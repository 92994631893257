import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { DefaultTextField } from '../../TextInputs/TextInputs';
import Survey from '../../../sdk/com/apiomat/frontend/missio/Survey';
import DropDown from '../../Selection/DropDown';
import { surveyDropDownItems } from '../../../value-maps/SurveyDropDownItems';
import { alphabeticalSort } from '../../../utils/sort.util';

export const useStyles = makeStyles(() => ({
  textField: {
    paddingTop: 5,
    marginTop: 30,
  },
  contentContainer: {
    paddingBottom: 30,
    paddingTop: 30,
  },
}));

export interface SurveyFormProps {
  readOnlyMode: boolean;
  survey: Survey;
  updateSurvey: (survey: Survey) => void;
}

const SurveyForm = (props: SurveyFormProps) => {
  const { readOnlyMode, survey, updateSurvey } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const dropDownItems = surveyDropDownItems
    .map(el => ({
      key: el.key,
      value: t(el.value as string),
    }))
    .sort(alphabeticalSort);

  return (
    <div className={ classes.contentContainer }>
      <Grid container spacing={ 3 }>
        <Grid item xs={ 12 }>
          <DefaultTextField
            className={ classes.textField }
            disabled={ true }
            labelKey="input:label:survey-requirement"
            value={ survey.surveyRequirement.replace(/<\/?[^>]+(>|$)/g, '') }
            multiline
            rows={ 3 }
            maxRows={ 10 }
          />
        </Grid>
        <Grid item xs={ 12 }>
          <DropDown
            labelKey="input:label:survey-result"
            disabled={ readOnlyMode }
            items={ dropDownItems }
            selectedItemKey={ survey.result }
            onSelectedItemChange={ (key: string) => {
              survey.result = key;
              updateSurvey(survey);
            } }
          />
        </Grid>
        <Grid item xs={ 12 }>
          <DefaultTextField
            disabled={ readOnlyMode }
            labelKey="input:label:survey-result-obligations"
            value={ survey.resultWithObligation }
            multiline
            rows={ 3 }
            maxRows={ 10 }
            onChange={ event => {
              survey.resultWithObligation = event.target.value;
              updateSurvey(survey);
            } }
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default SurveyForm;
