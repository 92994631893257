import React, { useEffect, useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Conference from '../../../../sdk/com/apiomat/frontend/missio/Conference';
import { useFormik } from 'formik';
import { object, string, date } from 'yup';
import FormTextField from '../../InputFields/FormTextField';
import { useOfferFormContext } from '../../context/OfferFormContext';
import FormDatePicker from '../../../DatePicker/FormDatePicker';

export interface ConferenceProps {
  readOnlyMode: boolean;
  isReport?: boolean;
  data: any;
}

export default (props: ConferenceProps) => {
  const { state, dispatch } = useOfferFormContext();
  const { readOnlyMode, data } = props;
  const { start, end, measureDescription, location } = data.measure as Conference;
  const { t } = useTranslation();

  const validationSchema = useMemo(
    () =>
      object({
        start: date().required(t('mandatory_field')),
        end: date().required(t('mandatory_field')),
        measureDescription: string().required(t('mandatory_field')),
        location: string().required(t('mandatory_field')),
      }),
    [t]
  );

  const formik = useFormik({
    initialValues: {
      start,
      end,
      measureDescription,
      location,
    },
    enableReinitialize: true,
    validationSchema,
    validateOnMount: true,
    onSubmit: () => {},
  });

  useEffect(() => {
    if (!readOnlyMode) {
      if (!formik.isValidating) {
        dispatch({ subFormId: 'measure', errors: formik.errors, values: formik.values });
      }
    }
  }, [formik.errors, formik.values, dispatch, formik.isValidating, readOnlyMode, state]);

  return (
    <form>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <FormDatePicker
            id="start"
            name="start"
            label={t('input:label:measure:planned-project-start')}
            value={formik.values.start}
            disabled={readOnlyMode}
            onChange={value => formik.setFieldValue('start', value)}
            errorMessage={formik.errors.start}
          />
        </Grid>
        <Grid item xs={6}>
          <FormDatePicker
            id="end"
            name="end"
            label={t('input:label:measure:planned-project-end')}
            value={formik.values.end}
            minDate={formik.values.start}
            disabled={readOnlyMode}
            onChange={value => formik.setFieldValue('end', value)}
            errorMessage={formik.errors.end}
          />
        </Grid>
        <Grid item xs={12}>
          <FormTextField
            id="measureDescription"
            name="measureDescription"
            disabled={readOnlyMode}
            labelKey="input:label:measure:measure-description"
            value={formik.values.measureDescription}
            multiline
            rows={3}
            onChange={formik.handleChange}
            errorMessage={formik.errors.measureDescription}
          />
        </Grid>
        <Grid item xs={12}>
          <FormTextField
            id="location"
            name="location"
            disabled={readOnlyMode}
            labelKey="input:label:measure:conference-location"
            descriptionKey="info:measure:conference:conference-location"
            value={formik.values.location}
            multiline
            onChange={formik.handleChange}
            errorMessage={formik.errors.location}
          />
        </Grid>
      </Grid>
    </form>
  );
};
