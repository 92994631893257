import React, { ReactNode } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';

export interface DefaultDialogProps {
  actionComponent: ReactNode;
  children: ReactNode;
  title: string | ReactNode;
  isOpen: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
}

const DefaultDialog = (props: DefaultDialogProps) => {
  const { isOpen, title, children, actionComponent, maxWidth } = props;
  return (
    <Dialog open={isOpen} fullWidth maxWidth={maxWidth || 'xl'}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>{actionComponent}</DialogActions>
    </Dialog>
  );
};

export default DefaultDialog;
