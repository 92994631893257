import { Grid } from '@material-ui/core';
import React, { useEffect, useMemo } from 'react';
import { useMwiMeasuresStyles } from './Styles';
import { useTranslation } from 'react-i18next';
import DialogButton from '../../../Dialogs/DialogButton';
import RadioButtons from '../../../Buttons/RadioButtons';
import MwiTranslation from '../../../../sdk/com/apiomat/frontend/missio/MwiTranslation';
import { subjects } from '../../../../value-maps/Subjects';
import DropDown from '../../../Selection/DropDown';
import { alphabeticalSort } from '../../../../utils/sort.util';
import { DropDownItem } from '../../../Selection/DropDownItem';
import { useFormik } from 'formik';
import { object, date, string, number } from 'yup';
import { useOfferFormContext } from '../../context/OfferFormContext';
import FormDatePicker from '../../../DatePicker/FormDatePicker';
import FormTextField from '../../InputFields/FormTextField';

export interface ITranslationProps {
  readOnlyMode: boolean;
  data: any;
  isReport?: boolean;
}

const Translation = (props: ITranslationProps) => {
  const { state, dispatch } = useOfferFormContext();
  const { readOnlyMode, data, isReport } = props;
  const classes = useMwiMeasuresStyles();
  const { t } = useTranslation();

  const {
    start,
    end,
    measureDescription,
    subject,
    topic,
    meaning,
    relevanceToLocalChurch,
    originalAuthor,
    isScholarshipApplicationElsewhere,
    hasReadAdvertisingStatement,
    hasReadConditions,
  } = data.measure as MwiTranslation;

  const validationSchema = useMemo(
    () =>
      object({
        start: date().required(t('mandatory_field')),
        end: date().required(t('mandatory_field')),
        measureDescription: isReport ? string() : string().required(t('mandatory_field')),
        subject: string().required(t('mandatory_field')),
        topic: string().required(t('mandatory_field')),
        meaning: string().required(t('mandatory_field')),
        relevanceToLocalChurch: string().required(t('mandatory_field')),
        originalAuthor: string(),
        isScholarshipApplicationElsewhere: isReport ? string() : string().required(t('mandatory_field')),
        hasReadAdvertisingStatement: isReport ? number() : number().equals([1], t('mandatory_field')),
        hasReadConditions: isReport ? number() : number().equals([1], t('mandatory_field')),
      }),
    [t]
  );

  const formik = useFormik({
    initialValues: {
      start,
      end,
      measureDescription,

      subject,
      topic,
      meaning,
      relevanceToLocalChurch,
      originalAuthor,

      isScholarshipApplicationElsewhere,
      hasReadAdvertisingStatement,
      hasReadConditions,
    },
    enableReinitialize: true,
    validationSchema,
    validateOnMount: true,
    onSubmit: () => {},
  });

  useEffect(() => {
    if (!readOnlyMode) {
      if (!formik.isValidating) {
        dispatch({ subFormId: 'measure', errors: formik.errors, values: formik.values });
      }
    }
  }, [formik.errors, formik.values, dispatch, formik.isValidating, readOnlyMode, state]);

  const subjectItems: DropDownItem[] = useMemo(() => subjects.map(el => ({ key: el.key, value: t(el.key) })).sort(alphabeticalSort), [t]);

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <FormDatePicker
            id="start"
            name="start"
            label={t('input:label:measure:planned-project-start')}
            value={formik.values.start}
            onChange={(date: Date) => formik.setFieldValue('start', date)}
            disabled={readOnlyMode}
            errorMessage={formik.errors.start}
          />
        </Grid>
        <Grid item xs={6}>
          <FormDatePicker
            id="end"
            name="end"
            label={t('input:label:measure:planned-project-end')}
            value={formik.values.end}
            minDate={formik.values.start}
            onChange={(date: Date) => formik.setFieldValue('end', date)}
            disabled={readOnlyMode}
            errorMessage={formik.errors.end}
          />
        </Grid>
        {!isReport && (
          <Grid item xs={12}>
            <FormTextField
              id="measureDescription"
              name="measureDescription"
              disabled={readOnlyMode}
              labelKey="input:label:measure:measure-description"
              value={formik.values.measureDescription}
              multiline
              rows={3}
              onChange={formik.handleChange}
              errorMessage={formik.errors.measureDescription}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <DropDown
            labelKey={'input:label:measure:mwi:translations:subject'}
            helperTextKey={'info:measure:mwi:translations:subject'}
            disabled={readOnlyMode}
            selectedItemKey={formik.values.subject}
            items={subjectItems}
            onSelectedItemChange={(key: string) => formik.setFieldValue('subject', key)}
            errorMessage={formik.errors.subject}
          />
        </Grid>
        <Grid item xs={12}>
          <FormTextField
            id="topic"
            name="topic"
            disabled={readOnlyMode}
            labelKey="input:label:measure:mwi:translations:topic-work"
            descriptionKey="info:measure:mwi:translations:topic-work"
            multiline
            rows={3}
            value={formik.values.topic}
            onChange={formik.handleChange}
            errorMessage={formik.errors.topic}
          />
        </Grid>
        <Grid item xs={12}>
          <FormTextField
            id="meaning"
            name="meaning"
            disabled={readOnlyMode}
            labelKey="input:label:measure:mwi:translations:topic-meaning"
            multiline
            rows={3}
            value={formik.values.meaning}
            onChange={formik.handleChange}
            errorMessage={formik.errors.meaning}
          />
        </Grid>
        <Grid item xs={12}>
          <FormTextField
            id="relevanceToLocalChurch"
            name="relevanceToLocalChurch"
            disabled={readOnlyMode}
            labelKey="input:label:measure:mwi:translations:meaning-local-church"
            descriptionKey="info:measure:mwi:translations:meaning-local-church"
            multiline
            rows={3}
            value={formik.values.relevanceToLocalChurch}
            onChange={formik.handleChange}
            errorMessage={formik.errors.relevanceToLocalChurch}
          />
        </Grid>
        {!isReport && (
          <>
            <Grid item xs={12}>
              <FormTextField
                id="originalAuthor"
                name="originalAuthor"
                disabled={readOnlyMode}
                labelKey="input:label:measure:mwi:translations:name-author"
                descriptionKey="info:measure:mwi:translations:name-author"
                value={formik.values.originalAuthor}
                onChange={formik.handleChange}
                errorMessage={formik.errors.originalAuthor}
              />
            </Grid>
            <div className={classes.questionContainer}>
              <div className={classes.questionScholarship}>
                <Grid item xs={3}>
                  <RadioButtons
                    title={t('question:label:measure:mwi:library:scholarship-application')}
                    value={formik.values.isScholarshipApplicationElsewhere}
                    disabled={readOnlyMode}
                    onSelectedValueChanged={(value: number) => formik.setFieldValue('isScholarshipApplicationElsewhere', value)}
                    errorMessage={formik.errors.isScholarshipApplicationElsewhere}
                  />
                </Grid>
              </div>
              <div className={classes.questionOne}>
                <Grid item xs={3}>
                  <RadioButtons
                    title={t('question:label:mwi:advertisement')}
                    disabled={readOnlyMode}
                    value={formik.values.hasReadAdvertisingStatement}
                    onSelectedValueChanged={(value: number) => formik.setFieldValue('hasReadAdvertisingStatement', value)}
                    errorMessage={formik.errors.hasReadAdvertisingStatement}
                  />
                </Grid>
                <DialogButton
                  className={classes.dialogButton}
                  textId={'button:label:mwi:measure:translation:advertisement'}
                  title={'button:label:mwi:advertisement'}
                />
              </div>
              <div className={classes.questionTwo}>
                <Grid item xs={3}>
                  <RadioButtons
                    title={t('question:label:mwi:conditions')}
                    disabled={readOnlyMode}
                    value={formik.values.hasReadConditions}
                    onSelectedValueChanged={(value: number) => formik.setFieldValue('hasReadConditions', value)}
                    errorMessage={formik.errors.hasReadConditions}
                  />
                </Grid>
                <DialogButton
                  className={classes.dialogButton}
                  textId={'button:label:mwi:measure:translation:conditions'}
                  title={'button:label:mwi:conditions'}
                />
              </div>
            </div>
          </>
        )}
      </Grid>
    </div>
  );
};

export default Translation;
