import React from 'react';
import { Grid, IconButton, makeStyles, Theme } from '@material-ui/core';
import SingleLineDataGrid from '../../DataGrid/SingleLineDataGrid';
import { DataGridColumn } from '../../DataGrid/DataGrid';
import DropDown from '../../Selection/DropDown';
import { useTranslation } from 'react-i18next';
import { DefaultNumberField, DefaultTextField } from '../../TextInputs/TextInputs';
import DeleteIcon from '@material-ui/icons/Delete';
import { financialPlanTypes } from '../../../value-maps/FinancialPlanTypes';
import { financialDecisions } from '../../../value-maps/FinancialDecisions';
import { coFinancierTypes } from '../../../value-maps/CoFinancierTypes';
import FinancialPlanItem from '../../../sdk/com/apiomat/frontend/missio/FinancialPlanItem';
import CostPlan from '../../../sdk/com/apiomat/frontend/missio/CostPlan';
import { currencies } from '../../../value-maps/Currencies';
import { alphabeticalSort } from '../../../utils/sort.util';

const useStyles = makeStyles((theme: Theme) => ({
  dataGridContainer: {
    flex: 1,
    display: 'flex',
    overflowX: 'auto',
    overflowY: 'hidden',
    marginTop: theme.spacing(1),
  },
  description: {
    flex: 1,
    minWidth: 150,
  },
  textFields: {
    flex: 1,
    minWidth: 100,
  },
  numberFields: {
    flex: 1,
    paddingTop: 8,
    minWidth: 100,
  },
  dropDowns: {
    flex: 1,
    minWidth: 70,
  },
}));

export interface FinancialPlanDataGridProps {
  costPlan: CostPlan;
  isReadonly: boolean;
  updateCostPlan: (costPlan: CostPlan) => void;
}

const FinancialPlanDataGrid = (props: FinancialPlanDataGridProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isReadonly, costPlan, updateCostPlan } = props;

  const thirdPartyFundPlanType = financialPlanTypes.find(el => el.value === 'finance-plan:finance-plan-type:third-party-funds').key;

  const sortedCostPlanItems = costPlan.financialPlan.financialPlanItems.sort((a, b) => {
    let valueA: string | number = a.createdAt.getTime();
    let valueB: string | number = b.createdAt.getTime();

    if (a.ID && b.ID) {
      valueA = a.ID;
      valueB = b.ID;
    }

    return (valueA > valueB ? 1 : -1);
  });

  const selectedCurrencies = costPlan.currencyRates
    .filter(currencyRate => Boolean(currencyRate.currency))
    .map(currencyRate => currencies.find(currency => currency.key === currencyRate.currency));

  const onDeleteButtonClicked = (dataRecord: FinancialPlanItem) => {
    if (isReadonly) {
      return;
    }
    const index = costPlan.financialPlan.financialPlanItems.indexOf(dataRecord);
    costPlan.financialPlan.financialPlanItems.splice(index, 1);
    updateCostPlan(costPlan);
  };

  const columns: DataGridColumn<FinancialPlanItem>[] = [
    {
      header: t('label:financial-plan:type'),
      valueExtractor: dataRecord => dataRecord.key,
      component: dataRecord => (
        <div className={ classes.dropDowns }>
          <DropDown
            selectedItemKey={ dataRecord.planType }
            items={ financialPlanTypes.map(x => ({
              locale: x.locale,
              value: t(x.value),
              key: x.key
            })).sort(alphabeticalSort) }
            onSelectedItemChange={ (value: string) => {
              dataRecord.planType = value;
              if (dataRecord.planType !== thirdPartyFundPlanType.toString()) {
                dataRecord.cofinancer = undefined;
              }
              updateCostPlan(costPlan);
            } }
            disabled={ isReadonly }
            errorMessage={ !dataRecord.planType ? t('mandatory_field') : null }
          />
        </div>
      ),
    },
    {
      header: t('label:financial-plan:co-financial'),
      valueExtractor: dataRecord => dataRecord.cofinancer,
      component: dataRecord => (
        <div className={ classes.dropDowns }>
          <DropDown
            selectedItemKey={ dataRecord.cofinancer }
            items={ coFinancierTypes
              .map(x => ({ key: x.key, value: t(x.value) }))
              .sort((a, b) => a.value.localeCompare(b.value, 'en-US', { ignorePunctuation: true })) }
            onSelectedItemChange={ (value: string) => {
              dataRecord.cofinancer = value;
              updateCostPlan(costPlan);
            } }
            disabled={ isReadonly || dataRecord.planType !== thirdPartyFundPlanType.toString() }
          />
        </div>
      ),
    },
    {
      header: t('label:financial-plan:description'),
      valueExtractor: dataRecord => dataRecord.description,
      component: dataRecord => (
        <DefaultTextField
          className={ classes.description }
          fullWidth
          value={ dataRecord.description }
          onChange={ event => {
            dataRecord.description = event.target.value;
            updateCostPlan(costPlan);
          } }
          disabled={ isReadonly }
        />
      ),
    },
    {
      header: t('label:financial-plan:decision'),
      valueExtractor: dataRecord => dataRecord.decision,
      component: dataRecord => (
        <div className={ classes.dropDowns }>
          <DropDown
            selectedItemKey={ dataRecord.decision }
            items={ financialDecisions.map(x => ({
              locale: x.locale,
              value: t(x.value),
              key: x.key
            })).sort(alphabeticalSort) }
            onSelectedItemChange={ (value: string) => {
              dataRecord.decision = value;
              updateCostPlan(costPlan);
            } }
            disabled={ isReadonly }
            errorMessage={ !dataRecord.decision ? t('mandatory_field') : null }
          />
        </div>
      ),
    },
    {
      header: t('label:financial-plan:national-currency'),
      valueExtractor: dataRecord => dataRecord.currency,
      component: dataRecord => (
        <div className={ classes.dropDowns }>
          <DropDown
            selectedItemKey={ dataRecord.currency }
            items={ selectedCurrencies
              .map(x => ({ key: x.key, value: t(x.value) }))
              .sort((a, b) => a.value.localeCompare(b.value, 'en-US', { ignorePunctuation: true })) }
            onSelectedItemChange={ (value: string) => {
              dataRecord.currency = value;
              updateCostPlan(costPlan);
            } }
            disabled={ isReadonly }
            errorMessage={ !dataRecord.currency ? t('mandatory_field') : null }
          />
        </div>
      ),
    },
    {
      header: t('label:financial-plan:requested-amount'),
      valueExtractor: dataRecord => dataRecord.requestedAmount,
      component: dataRecord => (
        <DefaultNumberField
          className={ classes.numberFields }
          value={ dataRecord.requestedAmount }
          onValueChanged={ value => {
            dataRecord.requestedAmount = value;
            updateCostPlan(costPlan);
          } }
          disabled={ isReadonly }
          errorMessage={ dataRecord.decision !== '2' && !(dataRecord.requestedAmount > 0) ? t('mandatory_field') : null }
        />
      ),
    },
    {
      header: t('label:financial-plan:approved-amount'),
      valueExtractor: dataRecord => dataRecord.approvedAmount,
      component: dataRecord => (
        <DefaultNumberField
          className={ classes.numberFields }
          value={ dataRecord.approvedAmount }
          onValueChanged={ value => {
            dataRecord.approvedAmount = value;
            updateCostPlan(costPlan);
          } }
          disabled={ isReadonly }
          errorMessage={ dataRecord.decision === '2' && !(dataRecord.approvedAmount > 0) ? t('mandatory_field') : null }
        />
      ),
    },
    {
      header: t('label:financial-plan:amount-euro'),
      valueExtractor: dataRecord => dataRecord.totalAmount,
      component: dataRecord => <DefaultTextField className={ classes.textFields } value={ dataRecord.totalAmount }
                                                 disabled/>,
    },
    {
      header: '',
      valueExtractor: dataRecord => dataRecord.ID,
      component: dataRecord =>
        !isReadonly && (
          <IconButton onClick={ () => onDeleteButtonClicked(dataRecord) }>
            <DeleteIcon color="primary"/>
          </IconButton>
        ),
    },
  ];

  return (
    <div className={ classes.dataGridContainer }>
      <Grid container>
        <SingleLineDataGrid
          columns={ columns }
          datasource={ sortedCostPlanItems }
          emptyMessage={ { title: '', component: columns.map(x => x.component) } }
        />
      </Grid>
    </div>
  );
};

export default FinancialPlanDataGrid;
