import moment from 'moment';

const dateFormat: string = 'DD.MM.YYYY HH:mm:ss.SSS';

export const getSortedCommentHistoryKeys = (commentHistoryKeys: string[], asc: boolean = false): string[] => {
  const commentHistoryDates = commentHistoryKeys.map(item => moment(item, dateFormat));
  const sortedHistoryDates = commentHistoryDates.sort((a, b) => asc ? b.diff(a) : b.diff(a));

  return sortedHistoryDates.map(date => date.format(dateFormat));
};
