import React from 'react';
import { CardMedia, makeStyles, Theme, CardMediaProps } from '@material-ui/core';
import MissioImage from '../../assets/img/logos/missio-logo.svg';
import MWIImage from '../../assets/img/logos/mwi-logo.png';
import EasyImage from '../../assets/img/logos/easy-logo.svg';
import DEFlag from '../../assets/img/de.svg';
import USFlag from '../../assets/img/us.svg';
import FRFlag from '../../assets/img/fr.svg';
import PTFlag from '../../assets/img/pt.svg';

const useStyles = makeStyles((theme: Theme) => ({
  missioLogo: {
    height: 90,
    backgroundSize: 'contain',
  },
  mwiLogo: {
    height: 90,
    backgroundSize: 'contain',
  },
  easyLogo: {
    height: 25,
    minWidth: 150,
    backgroundSize: 'contain',
    marginTop: 32,
  },
  appBarLogo: {
    height: 44,
    marginTop: 10,
    marginBottom: 10,
    width: 149,
    backgroundSize: 'contain',
    backgroundColor: '#fff'
  },
  flags: {
    border: `1px solid ${theme.palette.common.black}`,
    marginRight: 8,
    height: 16,
    width: 25,
  },
}));

const Logo = (props: CardMediaProps) => {
  return <CardMedia {...props} />;
};

export const MissioLogo = () => {
  const classes = useStyles();
  return <Logo className={classes.missioLogo} image={MissioImage} />;
};

export const EasyLogo = () => {
  const classes = useStyles();
  return <Logo className={classes.easyLogo} image={EasyImage} color={'white'} />;
};

export const AppBarLogo = () => {
  const classes = useStyles();
  return (
    <>
      <Logo className={classes.appBarLogo} image={MissioImage} />
      <Logo className={classes.appBarLogo} image={MWIImage} />
    </>
  );
};

export const MwiLogo = () => {
  const classes = useStyles();
  return <Logo className={classes.mwiLogo} image={MWIImage} />;
};

export const FlagDE = () => {
  const classes = useStyles();
  return <Logo className={classes.flags} image={DEFlag} />;
}

export const FlagUS = () => {
  const classes = useStyles();
  return <Logo className={classes.flags} image={USFlag} />;
}

export const FlagFR = () => {
  const classes = useStyles();
  return <Logo className={classes.flags} image={FRFlag} />;
}

export const FlagPT = () => {
  const classes = useStyles();
  return <Logo className={classes.flags} image={PTFlag} />;
}

export default Logo;
