import FormTemplate from '../sdk/com/apiomat/frontend/missio/FormTemplate';
import { allAttachmentTypes } from '../value-maps/AttachmentTypes';
import { misMeasureDropDownItems } from '../value-maps/MisMeasureDropDownItems';
import { mwiMeasureDropDownItems } from '../value-maps/MwiMeasureDropDownItems';
import { otherMeasureDropDownItems } from '../value-maps/OtherMeasureDropDownItems';

export const getFormTemplatesByMeasure = (measure: string, formTemplates: FormTemplate[]): FormTemplate[] => {
  return formTemplates.filter(el => el.measureTypes?.includes(measure)).sort((a, b) => (a.name > b.name ? 1 : -1));
};

export const getAttachmentTypeName = (formTemplate: FormTemplate): string => {
  return allAttachmentTypes.find(el => el.key === formTemplate.attachmentType)?.value || '';
};

export const getMisMeasureNames = (formTemplate: FormTemplate): string[] => {
  const keys = misMeasureDropDownItems.map(el => el.key);
  return (formTemplate?.measureTypes || []).filter((el: string) => keys.includes(el));
};

export const getMwiMeasureNames = (formTemplate: FormTemplate): string[] => {
  const keys = mwiMeasureDropDownItems.map(el => el.key);
  return (formTemplate?.measureTypes || []).filter((el: string) => keys.includes(el));
};

export const getOtherMeasureNames = (formTemplate: FormTemplate): string[] => {
  const keys = otherMeasureDropDownItems.map(el => el.key);
  return (formTemplate?.measureTypes || []).filter((el: string) => keys.includes(el));
};
