import React from 'react';
import { useTranslation } from 'react-i18next';
import CostPlan from '../../../sdk/com/apiomat/frontend/missio/CostPlan';
import { Grid, makeStyles } from '@material-ui/core';
import { DefaultTextField, DeprecatedTextField } from '../../TextInputs/TextInputs';
import ActualCostPlanDataGrid from './ActualCostPlanDataGrid';
import moment from 'moment';
import { costPlanCategories } from '../../../value-maps/CostPlanCategories';
import { AppState } from '../../../store';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(() => ({
  tabContainer: {
    paddingTop: 20,
  },
  contentContainer: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  root: {
    '& $disabled': {
      color: 'black',
    },
  },
  disabled: {},
}));

export interface EditActualCostPlanFormProps {
  isReadonly: boolean;
  costPlan: CostPlan;
  updateCostPlan: (costPlan: CostPlan) => void;
}

const EditActualCostPlanForm = (props: EditActualCostPlanFormProps) => {
  const { isReadonly, costPlan, updateCostPlan } = props;
  const { currentDeviations } = useSelector((state: AppState) => state.report);

  const classes = useStyles();
  const { t } = useTranslation();

  const getCostsOfCategory = (categoryValue: string) => {
    const categoryKey = costPlanCategories.find(category => category.value === categoryValue).key;
    return costPlan.costPlanItems
      .filter(item => item.costPlanCategory === categoryKey)
      .map(item => item.totalSumEUR)
      .reduce((a, b) => a + b, 0);
  };

  const projectActivitiesCosts = getCostsOfCategory('cost-plan:cost-plan-category:project-activities') || 0;
  const investmentCosts = getCostsOfCategory('cost-plan:cost-plan-category:investments') || 0;
  const personnelCosts = getCostsOfCategory('cost-plan:cost-plan-category:personnel') || 0;
  const projectManagementCosts = getCostsOfCategory('cost-plan:cost-plan-category:project-management') || 0;

  return (
    <div className={ classes.tabContainer }>
      <Grid container spacing={ 3 } className={ classes.contentContainer }>
        <Grid item xs={ 4 }>
          <DefaultTextField labelKey="label:cost-plan:dialog:phase" value={ costPlan.phase } disabled={ true }/>
        </Grid>
        <Grid item xs={ 4 }>
          <DefaultTextField labelKey="label:cost-plan:dialog:from" value={ moment(costPlan.start).format('DD.MM.YYYY') }
                            disabled={ true }/>
        </Grid>
        <Grid item xs={ 4 }>
          <DefaultTextField labelKey="label:cost-plan:dialog:until" value={ moment(costPlan.end).format('DD.MM.YYYY') }
                            disabled={ true }/>
        </Grid>
        <Grid item xs={ 4 }>
          <DeprecatedTextField
            label={ `${ t('cost-plan:cost-plan-category:project-activities') } ${ t('label:cost-plan-table-total-amount') }` }
            value={ projectActivitiesCosts }
            disabled={ true }
            InputProps={ {
              classes: {
                root: classes.root,
                disabled: classes.disabled,
              },
            } }
          />
        </Grid>
        <Grid item xs={ 4 }>
          <DefaultTextField
            labelKey="label:cost-plan:difference"
            value={ new Intl.NumberFormat('de-DE', { style: 'percent', maximumFractionDigits: 2 }).format(
              currentDeviations.projectActivities
            ) }
            InputProps={ {
              classes: {
                root: classes.root,
                disabled: classes.disabled,
              },
            } }
            disabled={ true }
          />
        </Grid>
        <Grid item xs={ 4 }>
          <DefaultTextField
            labelKey="label:cost-plan:difference-reason"
            value={ costPlan.deviationReasonProjectActivities }
            disabled={ isReadonly }
            onChange={ event => {
              costPlan.deviationReasonProjectActivities = event.target.value;
              updateCostPlan(costPlan);
            } }
            InputProps={ {
              classes: {
                root: classes.root,
                disabled: classes.disabled,
              },
            } }
          />
        </Grid>
        <Grid item xs={ 4 }>
          <DeprecatedTextField
            label={ `${ t('cost-plan:cost-plan-category:investments') } ${ t('label:cost-plan-table-total-amount') }` }
            value={ investmentCosts }
            disabled={ true }
            InputProps={ {
              classes: {
                root: classes.root,
                disabled: classes.disabled,
              },
            } }
          />
        </Grid>
        <Grid item xs={ 4 }>
          <DefaultTextField
            labelKey="label:cost-plan:difference"
            value={ new Intl.NumberFormat('de-DE', {
              style: 'percent',
              maximumFractionDigits: 2
            }).format(currentDeviations.investments) }
            disabled={ true }
            InputProps={ {
              classes: {
                root: classes.root,
                disabled: classes.disabled,
              },
            } }
          />
        </Grid>
        <Grid item xs={ 4 }>
          <DefaultTextField
            labelKey="label:cost-plan:difference-reason"
            value={ costPlan.deviationReasonInvestment }
            disabled={ isReadonly }
            onChange={ event => {
              costPlan.deviationReasonInvestment = event.target.value;
              updateCostPlan(costPlan);
            } }
            InputProps={ {
              classes: {
                root: classes.root,
                disabled: classes.disabled,
              },
            } }
          />
        </Grid>
        <Grid item xs={ 4 }>
          <DeprecatedTextField
            label={ `${ t('cost-plan:cost-plan-category:personnel') } ${ t('label:cost-plan-table-total-amount') }` }
            value={ personnelCosts }
            disabled={ true }
            InputProps={ {
              classes: {
                root: classes.root,
                disabled: classes.disabled,
              },
            } }
          />
        </Grid>
        <Grid item xs={ 4 }>
          <DefaultTextField
            labelKey="label:cost-plan:difference"
            value={ new Intl.NumberFormat('de-DE', {
              style: 'percent',
              maximumFractionDigits: 2
            }).format(currentDeviations.personnel) }
            disabled={ true }
            InputProps={ {
              classes: {
                root: classes.root,
                disabled: classes.disabled,
              },
            } }
          />
        </Grid>
        <Grid item xs={ 4 }>
          <DefaultTextField
            labelKey="label:cost-plan:difference-reason"
            value={ costPlan.deviationReasonStaff }
            disabled={ isReadonly }
            onChange={ event => {
              costPlan.deviationReasonStaff = event.target.value;
              updateCostPlan(costPlan);
            } }
            InputProps={ {
              classes: {
                root: classes.root,
                disabled: classes.disabled,
              },
            } }
          />
        </Grid>
        <Grid item xs={ 4 }>
          <DeprecatedTextField
            label={ `${ t('cost-plan:cost-plan-category:project-management') } ${ t('label:cost-plan-table-total-amount') }` }
            value={ projectManagementCosts }
            disabled={ true }
            InputProps={ {
              classes: {
                root: classes.root,
                disabled: classes.disabled,
              },
            } }
          />
        </Grid>
        <Grid item xs={ 4 }>
          <DefaultTextField
            labelKey="label:cost-plan:difference"
            value={ new Intl.NumberFormat('de-DE', { style: 'percent', maximumFractionDigits: 2 }).format(
              currentDeviations.projectManagement
            ) }
            disabled={ true }
            InputProps={ {
              classes: {
                root: classes.root,
                disabled: classes.disabled,
              },
            } }
          />
        </Grid>
        <Grid item xs={ 4 }>
          <DefaultTextField
            labelKey="label:cost-plan:difference-reason"
            value={ costPlan.deviationReasonProjectManagement }
            disabled={ isReadonly }
            onChange={ event => {
              costPlan.deviationReasonProjectManagement = event.target.value;
              updateCostPlan(costPlan);
            } }
            InputProps={ {
              classes: {
                root: classes.root,
                disabled: classes.disabled,
              },
            } }
          />
        </Grid>
        <Grid item xs={ 12 }>
          <DefaultTextField
            labelKey="label:cost-plan:total-amount-eur"
            value={ new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(costPlan.sum) }
            disabled
            InputProps={ {
              classes: {
                root: classes.root,
                disabled: classes.disabled,
              },
            } }
          />
        </Grid>
      </Grid>
      <ActualCostPlanDataGrid isReadonly={ isReadonly } costPlan={ costPlan } updateCostPlan={ updateCostPlan }/>
    </div>
  );
};

export default EditActualCostPlanForm;
