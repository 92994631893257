import React, { useEffect, useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Evaluation from '../../../../sdk/com/apiomat/frontend/missio/Evaluation';
import FormDatePicker from '../../../DatePicker/FormDatePicker';
import { useFormik } from 'formik';
import { date, object, string } from 'yup';
import FormTextField from '../../InputFields/FormTextField';
import { useOfferFormContext } from '../../context/OfferFormContext';

export interface EvaluationProps {
  readOnlyMode: boolean;
  data: any;
  isReport?: boolean;
}

const EvaluationForm = (props: EvaluationProps) => {
  const { state, dispatch } = useOfferFormContext();
  const { readOnlyMode, data } = props;
  const { t } = useTranslation();

  const { start, end, evaluationDescription } = data.measure as Evaluation;

  const validationSchema = useMemo(
    () =>
      object({
        start: date().required(t('mandatory_field')),
        end: date().required(t('mandatory_field')),
        evaluationDescription: string().required(t('mandatory_field')),
      }),
    [t]
  );

  const formik = useFormik({
    initialValues: {
      start,
      end,
      evaluationDescription,
    },
    enableReinitialize: true,
    validationSchema,
    validateOnMount: true,
    onSubmit: () => {},
  });

  useEffect(() => {
    if (!readOnlyMode) {
      if (!formik.isValidating) {
        dispatch({ subFormId: 'measure', errors: formik.errors, values: formik.values });
      }
    }
  }, [formik.errors, formik.values, dispatch, formik.isValidating, readOnlyMode, state]);

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <FormDatePicker
            id="start"
            name="start"
            label={t('input:label:measure:planned-project-start')}
            value={formik.values.start}
            onChange={value => formik.setFieldValue('start', value)}
            disabled={readOnlyMode}
            errorMessage={formik.errors.start}
          />
        </Grid>
        <Grid item xs={6}>
          <FormDatePicker
            id="end"
            name="end"
            label={t('input:label:measure:planned-project-end')}
            value={formik.values.end}
            minDate={formik.values.start}
            onChange={value => formik.setFieldValue('end', value)}
            disabled={readOnlyMode}
            errorMessage={formik.errors.end}
          />
        </Grid>
        <Grid item xs={12}>
          <FormTextField
            id="evaluationDescription"
            name="evaluationDescription"
            disabled={readOnlyMode}
            labelKey="info:measure:mwi:evaluation:evaluation-description"
            value={formik.values.evaluationDescription}
            multiline
            rows={3}
            onChange={formik.handleChange}
            errorMessage={formik.errors.evaluationDescription}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default EvaluationForm;
