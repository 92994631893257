import React from 'react';
import { Dialog, DialogContent, DialogTitle, Typography, makeStyles, Theme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Close } from '@material-ui/icons';
import { IconButton } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
  dialogTitleContainer: {
    justifyContent: 'flex-end',
    flexDirection: 'row',
    textAlign: 'center',
    backgroundColor: theme.palette.primary.main,
    borderBottom: '1px solid #898989',
  },
  title: {
    color: '#f5f5f5',
    fontWeight: 600,
  },
}));

export interface InfoBoxDialogProps {
  isOpen: boolean;
  infoId: string;
  onCancelClick: () => void;
}

const InfoBoxDialog = (props: InfoBoxDialogProps) => {
  const { isOpen, infoId, onCancelClick } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div>
      <Dialog open={isOpen} fullWidth maxWidth="sm">
        <DialogTitle className={classes.dialogTitleContainer}>
          <IconButton
            aria-label="close"
            onClick={ onCancelClick }
            sx={ {
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[100]
            } }>
            <Close/>
          </IconButton>
          <Typography className={classes.title} component="span" variant="h5">
            {t('info:title:info')}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">{t(infoId)}</Typography>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default InfoBoxDialog;
