import React, { useEffect } from 'react';
import { List } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../store';
import { surveyActions } from '../../store/survey';
import GroupList from '../Lists/GroupList';
import isElectron from '../../utils/electron';
import AppVersion from './AppVersion';

const mainMenu = {
  key: 'main-menu',
  translationId: 'main-menu',
  items: [
    {
      key: '/assignment/my',
      translationId: 'my-assignments',
      icon: 'Assignment',
    },
    {
      key: '/tasks/my',
      translationId: 'my-tasks',
      icon: 'AssignmentTurnedIn',
    },
    {
      key: '/assignment/mis/new',
      translationId: 'create-assignment-mis',
      icon: 'NoteAdd',
    },
    {
      key: '/assignment/mwi/new',
      translationId: 'create-assignment-mwi',
      icon: 'NoteAdd',
    },
    {
      key: '/form-templates',
      translationId: 'form-templates',
      icon: 'LibraryBooks',
    },
  ],
};

const adminMenu = {
  key: 'admin-menu',
  translationId: 'admin-menu',
  items: [
    {
      key: '/form-templates-admin',
      translationId: 'form-templates-admin',
      icon: 'LibraryBooks',
    },
    {
      key: '/assignment/all',
      translationId: 'all-assignments',
      icon: 'Assignment',
    },
    {
      key: '/report/all',
      translationId: 'all-reports',
      icon: 'ListAlt',
    },
    {
      key: '/user-management',
      translationId: 'user-management',
      icon: 'Person',
    },
  ],
};

const electronMenu = {
  key: 'electron-menu',
  translationId: 'electron-menu',
  items: [
    {
      key: 'https://ers.apiomat.enterprises/releases/stable/live',
      translationId: 'electron:get:app',
      icon: 'GetApp',
    },
  ],
};

export default () => {
  const dispatch = useDispatch();

  const { isAdmin } = useSelector((state: AppState) => state.auth);

  const menuItemGroups = [mainMenu];
  if (isAdmin) {
    menuItemGroups.push(adminMenu);
  }

  useEffect(() => {
    dispatch(surveyActions.loadSurveys());
  }, [dispatch]);

  const { surveys } = useSelector((state: AppState) => state.survey);

  if (surveys.length !== 0 && !mainMenu.items.find(item => item.translationId === 'my-surveys')) {
    const surveyItem = {
      key: '/surveys/my',
      translationId: 'my-surveys',
      icon: 'Receipt',
    };
    mainMenu.items.push(surveyItem);
  }

  return (
    <List>
      {menuItemGroups.map(group => (
        <GroupList key={group.key} group={group} />
      ))}
      { !isElectron() ? <GroupList group={electronMenu} externalLink={true} /> : <></> }
      <AppVersion></AppVersion>
    </List>
  );
};
