import React from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from '../../Buttons/Buttons';
import CurrencyDataGrid from './CurrencyDataGrid';
import CostPlan from '../../../sdk/com/apiomat/frontend/missio/CostPlan';
import { Grid, makeStyles } from '@material-ui/core';
import { DefaultTextField } from '../../TextInputs/TextInputs';
import { currencies } from '../../../value-maps/Currencies';
import CurrencyConversion from '../../../sdk/com/apiomat/frontend/missio/CurrencyConversion';
import SimpleDropdown from '../../Selection/SimpleDropDown';

const useStyles = makeStyles(() => ({
  tabContainer: {
    paddingTop: 20,
  },
  contentContainer: {
    paddingTop: 10,
    paddingBottom: 30,
    alignItems: 'flex-end',
  },
  black: {
    color: 'black',
  },
  gridItem: {
    padding: 10,
  },
}));

export interface EditCurrencyTableFormProps {
  isReadonly: boolean;
  costPlan: CostPlan;
  updateCostPlan: (costPlan: CostPlan) => void;
}

const EditCurrencyTableForm = (props: EditCurrencyTableFormProps) => {
  const { isReadonly, costPlan, updateCostPlan } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  const handleAddButtonClick = () => {
    const newItem = new CurrencyConversion();
    (newItem as any).dao.createdAt = new Date();
    newItem.sourceAmount = 0;
    newItem.sourceCurrency = '';
    newItem.targetCurrency = '';
    newItem.exchangeRate = 0;
    newItem.conversionDate = new Date();
    newItem.exchangeFee = 0.0;

    costPlan.currencyConversionTable.currencyConversions.push(newItem);

    updateCostPlan(costPlan);
  };

  const sourceCurrencyRates = costPlan.currencyConversionTable.sourceCurrencyRates;
  const targetCurrencyRates = costPlan.currencyConversionTable.targetCurrencyRates;

  const nationalCurrencies = currencies
    .map(x => ({ key: x.key, value: t(x.value) }))
    .sort((a, b) => a.value.localeCompare(b.value, 'en-US', { ignorePunctuation: true }));

  const firstSourceCurrencyOptions = nationalCurrencies
    .filter(x => x.key !== sourceCurrencyRates[1].currency && x.key !== sourceCurrencyRates[2].currency)
    .sort((a, b) => a.value.localeCompare(b.value, 'en-US', { ignorePunctuation: true }));

  const secondSourceCurrencyOptions = nationalCurrencies
    .filter(x => x.key !== sourceCurrencyRates[2].currency && x.key !== sourceCurrencyRates[0].currency)
    .sort((a, b) => a.value.localeCompare(b.value, 'en-US', { ignorePunctuation: true }));

  const thirdSourceCurrencyOptions = nationalCurrencies
    .filter(x => x.key !== sourceCurrencyRates[0].currency && x.key !== sourceCurrencyRates[1].currency)
    .sort((a, b) => a.value.localeCompare(b.value, 'en-US', { ignorePunctuation: true }));

  const firstTargetCurrencyOptions = nationalCurrencies
    .filter(x => x.key !== targetCurrencyRates[1].currency && x.key !== targetCurrencyRates[2].currency)
    .sort((a, b) => a.value.localeCompare(b.value, 'en-US', { ignorePunctuation: true }));

  const secondTargetCurrencyOptions = nationalCurrencies
    .filter(x => x.key !== targetCurrencyRates[2].currency && x.key !== targetCurrencyRates[0].currency)
    .sort((a, b) => a.value.localeCompare(b.value, 'en-US', { ignorePunctuation: true }));

  const thirdTargetCurrencyOptions = nationalCurrencies
    .filter(x => x.key !== targetCurrencyRates[0].currency && x.key !== targetCurrencyRates[1].currency)
    .sort((a, b) => a.value.localeCompare(b.value, 'en-US', { ignorePunctuation: true }));

  const firstSourceAmount = costPlan.currencyConversionTable.currencyConversions
    .filter(item => item.sourceCurrency === costPlan.currencyConversionTable.sourceCurrencyRates[0].currency)
    .map(item => item.sourceAmount)
    .reduce((a, b) => a + b, 0);
  const secondSourceAmount = costPlan.currencyConversionTable.currencyConversions
    .filter(item => item.sourceCurrency === costPlan.currencyConversionTable.sourceCurrencyRates[1].currency)
    .map(item => item.sourceAmount)
    .reduce((a, b) => a + b, 0);
  const thirdSourceAmount = costPlan.currencyConversionTable.currencyConversions
    .filter(item => item.sourceCurrency === costPlan.currencyConversionTable.sourceCurrencyRates[2].currency)
    .map(item => item.sourceAmount)
    .reduce((a, b) => a + b, 0);

  const firstTargetAmount = costPlan.currencyConversionTable.currencyConversions
    .filter(item => item.targetCurrency === costPlan.currencyConversionTable.targetCurrencyRates[0].currency)
    .map(item => item.sourceAmount * (item.exchangeRate || 0) - (item.exchangeFee || 0) || 0)
    .reduce((a, b) => a + b, 0);
  const secondTargetAmount = costPlan.currencyConversionTable.currencyConversions
    .filter(item => item.targetCurrency === costPlan.currencyConversionTable.targetCurrencyRates[1].currency)
    .map(item => item.sourceAmount * (item.exchangeRate || 0) - (item.exchangeFee || 0) || 0)
    .reduce((a, b) => a + b, 0);
  const thirdTargetAmount = costPlan.currencyConversionTable.currencyConversions
    .filter(item => item.targetCurrency === costPlan.currencyConversionTable.targetCurrencyRates[2].currency)
    .map(item => item.sourceAmount * (item.exchangeRate || 0) - (item.exchangeFee || 0) || 0)
    .reduce((a, b) => a + b, 0);

  return (
    <div className={ classes.tabContainer }>
      <Grid container spacing={ 3 } className={ classes.contentContainer }>
        <Grid item xs={ 2 }>
          <div>
            <SimpleDropdown
              items={ firstSourceCurrencyOptions }
              selectedItemKey={ costPlan.currencyConversionTable.sourceCurrencyRates[0].currency }
              onSelectedItemChange={ (value: string) => {
                costPlan.currencyConversionTable.sourceCurrencyRates[0].currency = value;
                updateCostPlan(costPlan);
              } }
              title={ `${ t('label:currency-table:dialog:source-currency') } 1` }
              disabled={ isReadonly }
            />
          </div>
        </Grid>
        <Grid item xs={ 2 }>
          <DefaultTextField
            labelKey="label:currency-table:dialog:total"
            value={ new Intl.NumberFormat().format(firstSourceAmount) }
            disabled={ true }
            className={ classes.black }
          />
        </Grid>
        <Grid item xs={ 2 }>
          <div>
            <SimpleDropdown
              items={ secondSourceCurrencyOptions }
              selectedItemKey={ costPlan.currencyConversionTable.sourceCurrencyRates[1].currency }
              onSelectedItemChange={ (value: string) => {
                costPlan.currencyConversionTable.sourceCurrencyRates[1].currency = value;
                updateCostPlan(costPlan);
              } }
              title={ `${ t('label:currency-table:dialog:source-currency') } 2` }
              disabled={ isReadonly }
            />
          </div>
        </Grid>
        <Grid item xs={ 2 }>
          <DefaultTextField
            labelKey="label:currency-table:dialog:total"
            value={ new Intl.NumberFormat().format(secondSourceAmount) }
            disabled={ true }
            className={ classes.black }
          />
        </Grid>
        <Grid item xs={ 2 }>
          <div>
            <SimpleDropdown
              items={ thirdSourceCurrencyOptions }
              selectedItemKey={ costPlan.currencyConversionTable.sourceCurrencyRates[2].currency }
              onSelectedItemChange={ (value: string) => {
                costPlan.currencyConversionTable.sourceCurrencyRates[2].currency = value;
                updateCostPlan(costPlan);
              } }
              title={ `${ t('label:currency-table:dialog:source-currency') } 3` }
              disabled={ isReadonly }
            />
          </div>
        </Grid>
        <Grid item xs={ 2 }>
          <DefaultTextField
            labelKey="label:currency-table:dialog:total"
            value={ new Intl.NumberFormat().format(thirdSourceAmount) }
            disabled={ true }
            className={ classes.black }
          />
        </Grid>
        <Grid item xs={ 2 }>
          <div>
            <SimpleDropdown
              items={ firstTargetCurrencyOptions }
              selectedItemKey={ costPlan.currencyConversionTable.targetCurrencyRates[0].currency }
              onSelectedItemChange={ (value: string) => {
                costPlan.currencyConversionTable.targetCurrencyRates[0].currency = value;
                updateCostPlan(costPlan);
              } }
              title={ `${ t('label:currency-table:dialog:target-currency') } 1` }
              disabled={ isReadonly }
            />
          </div>
        </Grid>
        <Grid item xs={ 2 }>
          <DefaultTextField
            labelKey="label:currency-table:dialog:total"
            value={ new Intl.NumberFormat().format(firstTargetAmount) }
            disabled={ true }
            className={ classes.black }
          />
        </Grid>
        <Grid item xs={ 2 }>
          <div>
            <SimpleDropdown
              items={ secondTargetCurrencyOptions }
              selectedItemKey={ costPlan.currencyConversionTable.targetCurrencyRates[1].currency }
              onSelectedItemChange={ (value: string) => {
                costPlan.currencyConversionTable.targetCurrencyRates[1].currency = value;
                updateCostPlan(costPlan);
              } }
              title={ `${ t('label:currency-table:dialog:target-currency') } 2` }
              disabled={ isReadonly }
            />
          </div>
        </Grid>
        <Grid item xs={ 2 }>
          <DefaultTextField
            labelKey="label:currency-table:dialog:total"
            value={ new Intl.NumberFormat().format(secondTargetAmount) }
            disabled={ true }
            className={ classes.black }
          />
        </Grid>
        <Grid item xs={ 2 }>
          <div>
            <SimpleDropdown
              items={ thirdTargetCurrencyOptions }
              selectedItemKey={ costPlan.currencyConversionTable.targetCurrencyRates[2].currency }
              onSelectedItemChange={ (value: string) => {
                costPlan.currencyConversionTable.targetCurrencyRates[2].currency = value;
                updateCostPlan(costPlan);
              } }
              title={ `${ t('label:currency-table:dialog:target-currency') } 3` }
              disabled={ isReadonly }
            />
          </div>
        </Grid>
        <Grid item xs={ 2 }>
          <DefaultTextField
            labelKey="label:currency-table:dialog:total"
            value={ new Intl.NumberFormat().format(thirdTargetAmount) }
            disabled={ true }
            className={ classes.black }
          />
        </Grid>
      </Grid>
      <PrimaryButton onClick={ handleAddButtonClick } disabled={ isReadonly }>
        { t('dialog:button:label:add-new-line') }
      </PrimaryButton>
      <CurrencyDataGrid isReadonly={ isReadonly } costPlan={ costPlan } updateCostPlan={ updateCostPlan }/>
    </div>
  );
};

export default EditCurrencyTableForm;
