import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { EmailTextField } from '../../TextInputs/TextInputs';
import { PrimaryButton } from '../../Buttons/Buttons';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../store';
import { shareReportActions } from '../../../store/shareReport';
import LoadingIndicator from '../../Loading/LoadingIndicator';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    marginTop: '50px',
  },
  textField: {
    backgroundColor: '#fff',
    flex: 1,
    display: 'flex',
    marginTop: '10px',
    marginBottom: '10px',
    paddingTop: '5px',
    paddingLeft: '5px',
    paddingRight: '5px',
  },
  buttonContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: '50px',
  },
  shareButton: {
    maxWidth: 150,
    margin: 5,
  },
  cancelButton: {
    margin: 5,
    maxWidth: 150,
  },
}));

export interface ShareReportFormProps {
  onCancelButtonClick: () => void;
}

const ShareReportForm = (props: ShareReportFormProps) => {
  const classes = useStyles();
  const { onCancelButtonClick } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { currentSharedReport, loadingSharedReport } = useSelector((state: AppState) => state.shareReport);
  const [email, setEmail] = useState('');

  const onShareReport = () => {
    const report = currentSharedReport.report;
    const sharedWith = report.sharedWith || [];
    sharedWith.push(email);
    report.sharedWith = sharedWith;
    dispatch(shareReportActions.shareReport(report));
    setEmail('');
  };
  if (loadingSharedReport === 'pending') {
    return <LoadingIndicator />;
  }
  return (
    <div>
      <div className={classes.container}>
        <EmailTextField className={classes.textField} value={email} onChange={e => setEmail(e.target.value)} />
      </div>
      <div className={classes.buttonContainer}>
        <PrimaryButton className={classes.cancelButton} fullWidth variant="outlined" disabled={false} onClick={onCancelButtonClick}>
          {t('button:label:cancel')}
        </PrimaryButton>
        <PrimaryButton className={classes.shareButton} fullWidth disabled={false} onClick={onShareReport}>
          {t('button:label:share')}
        </PrimaryButton>
      </div>
    </div>
  );
};

export default ShareReportForm;
