import React, { useEffect, useMemo } from 'react';
import { useMwiMeasuresStyles } from './Styles';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import RadioButtons from '../../../Buttons/RadioButtons';
import DialogButton from '../../../Dialogs/DialogButton';
import MwiScholarship from '../../../../sdk/com/apiomat/frontend/missio/MwiScholarship';
import { countries } from '../../../../value-maps/Countries';
import DropDown from '../../../Selection/DropDown';
import { subjects } from '../../../../value-maps/Subjects';
import { graduations } from '../../../../value-maps/Graduations';
import { alphabeticalSort } from '../../../../utils/sort.util';
import { DropDownItem } from '../../../Selection/DropDownItem';
import { useOfferFormContext } from '../../context/OfferFormContext';
import { useFormik } from 'formik';
import { object, date, string, number } from 'yup';
import FormDatePicker from '../../../DatePicker/FormDatePicker';
import FormTextField from '../../InputFields/FormTextField';

export interface ScholarshipProps {
  readOnlyMode: boolean;
  data: any;
  isReport?: boolean;
}

const Scholarship = (props: ScholarshipProps) => {
  const { state, dispatch } = useOfferFormContext();
  const { readOnlyMode, data, isReport } = props;
  const classes = useMwiMeasuresStyles();
  const { t } = useTranslation();

  const {
    start,
    end,
    measureDescription,
    priorScholars,
    studyDescription,
    studyReason,
    university,
    location,
    country,
    locationReason,
    subject,
    graduation,
    subjectReason,
    subjectRelation,
    thesisTitle,
    subjectWorkRelation,
    deployment,
    deploymentInstitution,
    deploymentDescription,
    supervisor,
    thesisTopic,
    topicSignificance,
    pastoralSideActivities,
    isScholarshipApplicationElsewhere,
    hasReadAdvertisingStatement,
    hasReadConditions,
  } = data.measure as MwiScholarship;

  const validationSchema = useMemo(
    () =>
      object({
        start: date().required(t('mandatory_field')),
        end: date().required(t('mandatory_field')),
        measureDescription: string().required(t('mandatory_field')),
        priorScholars: isReport ? string() : string().required(t('mandatory_field')),
        studyDescription: string().required(t('mandatory_field')),
        studyReason: isReport ? string() : string().required(t('mandatory_field')),
        university: string().required(t('mandatory_field')),
        location: isReport ? string() : string().required(t('mandatory_field')),
        country: isReport ? string() : string().required(t('mandatory_field')),
        locationReason: isReport ? string() : string().required(t('mandatory_field')),
        subject: string().required(t('mandatory_field')),
        graduation: string().required(t('mandatory_field')),
        subjectReason: isReport ? string() : string().required(t('mandatory_field')),
        subjectRelation: isReport ? string() : string().required(t('mandatory_field')),
        thesisTitle: string().required(t('mandatory_field')),
        subjectWorkRelation: isReport ? string() : string().required(t('mandatory_field')),
        deployment: isReport ? string() : string().required(t('mandatory_field')),
        deploymentInstitution: isReport ? string() : string().required(t('mandatory_field')),
        deploymentDescription: isReport ? string() : string().required(t('mandatory_field')),
        supervisor: string(),
        thesisTopic: string().required(t('mandatory_field')),
        topicSignificance: isReport ? string() : string().required(t('mandatory_field')),
        pastoralSideActivities: string().required(t('mandatory_field')),
        isScholarshipApplicationElsewhere: isReport ? string() : string().required(t('mandatory_field')),
        hasReadAdvertisingStatement: isReport ? number() : number().equals([1], t('mandatory_field')),
        hasReadConditions: isReport ? number() : number().equals([1], t('mandatory_field')),
      }),
    [t]
  );

  const formik = useFormik({
    initialValues: {
      start,
      end,
      measureDescription,
      priorScholars,
      studyDescription,
      studyReason,
      university,
      location,
      country,
      locationReason,
      subject,
      graduation,
      subjectReason,
      subjectRelation,
      thesisTitle,
      subjectWorkRelation,
      deployment,
      deploymentInstitution,
      deploymentDescription,
      supervisor,
      thesisTopic,
      topicSignificance,
      pastoralSideActivities,
      isScholarshipApplicationElsewhere,
      hasReadAdvertisingStatement,
      hasReadConditions,
    },
    enableReinitialize: true,
    validationSchema,
    validateOnMount: true,
    onSubmit: () => {},
  });

  useEffect(() => {
    if (!readOnlyMode) {
      if (!formik.isValidating) {
        dispatch({ subFormId: 'measure', errors: formik.errors, values: formik.values });
      }
    }
  }, [formik.errors, formik.values, dispatch, formik.isValidating, readOnlyMode, state]);

  const countryItems: DropDownItem[] = useMemo(() => countries.map(el => ({ key: el.key, value: t(el.key) })).sort(alphabeticalSort), [t]);
  const subjectItems: DropDownItem[] = useMemo(() => subjects.map(el => ({ key: el.key, value: t(el.key) })).sort(alphabeticalSort), [t]);
  const graduationItems: DropDownItem[] = useMemo(() => graduations.map(el => ({ key: el.key, value: t(el.key) })).sort(alphabeticalSort), [
    t,
  ]);

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <FormDatePicker
            id="start"
            name="start"
            label={t('input:label:measure:planned-project-start')}
            value={formik.values.start}
            onChange={(date: Date) => formik.setFieldValue('start', date)}
            disabled={readOnlyMode}
            errorMessage={formik.errors.start}
          />
        </Grid>
        <Grid item xs={6}>
          <FormDatePicker
            id="end"
            name="end"
            label={t('input:label:measure:planned-project-end')}
            value={formik.values.end}
            minDate={formik.values.start}
            onChange={(date: Date) => formik.setFieldValue('end', date)}
            disabled={readOnlyMode}
            errorMessage={formik.errors.end}
          />
        </Grid>
        <Grid item xs={12}>
          <FormTextField
            id="measureDescription"
            name="measureDescription"
            disabled={readOnlyMode}
            labelKey="input:label:measure:measure-description"
            value={formik.values.measureDescription}
            multiline
            rows={3}
            onChange={formik.handleChange}
            errorMessage={formik.errors.measureDescription}
          />
        </Grid>
        {!isReport && (
          <Grid item xs={12}>
            <FormTextField
              id="priorScholars"
              name="priorScholars"
              disabled={readOnlyMode}
              labelKey="info:measure:mwi:scholarship:previous-scholarships"
              descriptionKey="input:label:measure:mwi:scholarship:previous-scholarships"
              value={formik.values.priorScholars}
              multiline
              rows={3}
              onChange={formik.handleChange}
              errorMessage={formik.errors.priorScholars}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <FormTextField
            id="studyDescription"
            name="studyDescription"
            disabled={readOnlyMode}
            labelKey="info:measure:mwi:scholarship:scholarship-description"
            descriptionKey="input:label:measure:mwi:scholarship:scholarship-description"
            value={formik.values.studyDescription}
            multiline
            rows={3}
            onChange={formik.handleChange}
            errorMessage={formik.errors.studyDescription}
          />
        </Grid>
        {!isReport && (
          <Grid item xs={12}>
            <FormTextField
              id="studyReason"
              name="studyReason"
              disabled={readOnlyMode}
              labelKey="info:measure:mwi:scholarship:scholarship-reason"
              descriptionKey="input:label:measure:mwi:scholarship:scholarship-reason"
              value={formik.values.studyReason}
              multiline
              rows={3}
              onChange={formik.handleChange}
              errorMessage={formik.errors.studyReason}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <FormTextField
            id="university"
            name="university"
            disabled={readOnlyMode}
            labelKey="info:measure:mwi:scholarship:university"
            descriptionKey="input:label:measure:mwi:scholarship:university"
            value={formik.values.university}
            onChange={formik.handleChange}
            errorMessage={formik.errors.university}
          />
        </Grid>
        {!isReport && (
          <>
            <Grid item xs={12}>
              <FormTextField
                id="location"
                name="location"
                disabled={readOnlyMode}
                labelKey="info:measure:mwi:scholarship:study-place"
                descriptionKey="input:label:measure:mwi:scholarship:study-place"
                value={formik.values.location}
                onChange={formik.handleChange}
                errorMessage={formik.errors.location}
              />
            </Grid>
            <Grid item xs={12}>
              <DropDown
                labelKey="input:label:measure:mwi:scholarship:study-country"
                disabled={readOnlyMode}
                selectedItemKey={formik.values.country}
                items={countryItems}
                onSelectedItemChange={(key: string) => formik.setFieldValue('country', key)}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                id="locationReason"
                name="locationReason"
                disabled={readOnlyMode}
                labelKey="info:measure:mwi:scholarship:reason-place-of-study"
                descriptionKey="input:label:measure:mwi:scholarship:reason-place-of-study"
                multiline
                rows={3}
                value={formik.values.locationReason}
                onChange={formik.handleChange}
                errorMessage={formik.errors.locationReason}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <DropDown
            labelKey="input:label:measure:mwi:scholarship:subject"
            disabled={readOnlyMode}
            selectedItemKey={formik.values.subject}
            items={subjectItems}
            onSelectedItemChange={(key: string) => formik.setFieldValue('subject', key)}
            errorMessage={formik.errors.subject}
          />
        </Grid>
        <Grid item xs={12}>
          <DropDown
            labelKey="info:measure:mwi:scholarship:approached-graduation"
            helperTextKey="input:label:measure:mwi:scholarship:approached-graduation"
            disabled={readOnlyMode}
            selectedItemKey={formik.values.graduation}
            items={graduationItems}
            onSelectedItemChange={(key: string) => formik.setFieldValue('graduation', key)}
            errorMessage={formik.errors.graduation}
          />
        </Grid>
        {!isReport && (
          <>
            <Grid item xs={12}>
              <FormTextField
                id="subjectReason"
                name="subjectReason"
                disabled={readOnlyMode}
                labelKey="info:measure:mwi:scholarship:subject-justification"
                descriptionKey="input:label:measure:mwi:scholarship:subject-justification"
                multiline
                rows={3}
                value={formik.values.subjectReason}
                onChange={formik.handleChange}
                errorMessage={formik.errors.subjectReason}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                id="subjectRelation"
                name="subjectRelation"
                disabled={readOnlyMode}
                labelKey="info:measure:mwi:scholarship:subject-relation"
                descriptionKey="input:label:measure:mwi:scholarship:subject-relation"
                multiline
                rows={3}
                value={formik.values.subjectRelation}
                onChange={formik.handleChange}
                errorMessage={formik.errors.subjectRelation}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <FormTextField
            id="thesisTitle"
            name="thesisTitle"
            disabled={readOnlyMode}
            labelKey="info:measure:mwi:scholarship:thesis-title"
            descriptionKey="input:label:measure:mwi:scholarship:thesis-title"
            value={formik.values.thesisTitle}
            onChange={formik.handleChange}
            errorMessage={formik.errors.thesisTitle}
          />
        </Grid>
        {!isReport && (
          <>
            <Grid item xs={12}>
              <FormTextField
                id="subjectWorkRelation"
                name="subjectWorkRelation"
                disabled={readOnlyMode}
                labelKey="input:label:measure:mwi:scholarship:career-and-subject-relation"
                multiline
                rows={3}
                value={formik.values.subjectWorkRelation}
                onChange={formik.handleChange}
                errorMessage={formik.errors.subjectWorkRelation}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                id="deployment"
                name="deployment"
                disabled={readOnlyMode}
                labelKey="input:label:measure:mwi:scholarship:later-place-of-deployment"
                value={formik.values.deployment}
                onChange={formik.handleChange}
                errorMessage={formik.errors.deployment}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                id="deploymentInstitution"
                name="deploymentInstitution"
                disabled={readOnlyMode}
                labelKey="input:label:measure:mwi:scholarship:later-deployment-installation"
                value={formik.values.deploymentInstitution}
                onChange={formik.handleChange}
                errorMessage={formik.errors.deploymentInstitution}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                id="deploymentDescription"
                name="deploymentDescription"
                disabled={readOnlyMode}
                labelKey="input:label:measure:mwi:scholarship:place-of-installation-description"
                multiline
                rows={3}
                value={formik.values.deploymentDescription}
                onChange={formik.handleChange}
                errorMessage={formik.errors.deploymentDescription}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <FormTextField
            id="supervisor"
            name="supervisor"
            disabled={readOnlyMode}
            labelKey="info:measure:mwi:scholarship:supervisor"
            descriptionKey="input:label:measure:mwi:scholarship:supervisor"
            value={formik.values.supervisor}
            onChange={formik.handleChange}
            errorMessage={formik.errors.supervisor}
          />
        </Grid>
        <Grid item xs={12}>
          <FormTextField
            id="thesisTopic"
            name="thesisTopic"
            disabled={readOnlyMode}
            labelKey="info:measure:mwi:scholarship:topic-work"
            descriptionKey="input:label:measure:mwi:scholarship:topic-work"
            multiline
            rows={3}
            value={formik.values.thesisTopic}
            onChange={formik.handleChange}
            errorMessage={formik.errors.thesisTopic}
          />
        </Grid>
        {!isReport && (
          <Grid item xs={12}>
            <FormTextField
              id="topicSignificance"
              name="topicSignificance"
              disabled={readOnlyMode}
              labelKey="info:measure:mwi:scholarship:meaning-of-the-topic"
              descriptionKey="input:label:measure:mwi:scholarship:meaning-of-the-topic"
              multiline
              rows={3}
              value={formik.values.topicSignificance}
              onChange={formik.handleChange}
              errorMessage={formik.errors.topicSignificance}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <FormTextField
            id="pastoralSideActivities"
            name="pastoralSideActivities"
            disabled={readOnlyMode}
            labelKey="input:label:measure:mwi:scholarship:planned-pastoral-side-job"
            value={formik.values.pastoralSideActivities}
            multiline
            rows={3}
            onChange={formik.handleChange}
            errorMessage={formik.errors.pastoralSideActivities}
          />
        </Grid>
        {!isReport && (
          <>
            <div className={classes.questionContainer}>
              <div className={classes.questionScholarship}>
                <Grid item xs={3}>
                  <RadioButtons
                    title={t('question:label:measure:mwi:library:scholarship-application')}
                    value={formik.values.isScholarshipApplicationElsewhere}
                    disabled={readOnlyMode}
                    onSelectedValueChanged={(value: number) => formik.setFieldValue('isScholarshipApplicationElsewhere', value)}
                    errorMessage={formik.errors.isScholarshipApplicationElsewhere}
                  />
                </Grid>
              </div>
              <div className={classes.questionOne}>
                <Grid item xs={3}>
                  <RadioButtons
                    title={t('question:label:mwi:advertisement')}
                    disabled={readOnlyMode}
                    value={formik.values.hasReadAdvertisingStatement}
                    onSelectedValueChanged={(value: number) => formik.setFieldValue('hasReadAdvertisingStatement', value)}
                    errorMessage={formik.errors.hasReadAdvertisingStatement}
                  />
                </Grid>
                <DialogButton
                  className={classes.dialogButton}
                  textId={'button:label:mwi:measure:scholarship:advertisement'}
                  title={'button:label:mwi:advertisement'}
                />
              </div>
              <div className={classes.questionTwo}>
                <Grid item xs={3}>
                  <RadioButtons
                    title={t('question:label:mwi:conditions')}
                    disabled={readOnlyMode}
                    value={formik.values.hasReadConditions}
                    onSelectedValueChanged={(value: number) => formik.setFieldValue('hasReadConditions', value)}
                    errorMessage={formik.errors.hasReadConditions}
                  />
                </Grid>
                <DialogButton
                  className={classes.dialogButton}
                  textId={'button:label:mwi:measure:scholarship:conditions'}
                  title={'button:label:mwi:conditions'}
                />
              </div>
            </div>
          </>
        )}
      </Grid>
    </div>
  );
};

export default Scholarship;
