import { AxiosRequestConfig } from 'axios';

export type RetryRequestConfig = AxiosRequestConfig & { meta?: { isRetry?: boolean; [key: string]: unknown } };

export const updateBearerToken = (config: AxiosRequestConfig, token: string): AxiosRequestConfig => {
  return {
    ...config,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${token}`,
    },
  };
};

export const applyRetryFlag = (config: RetryRequestConfig): RetryRequestConfig => {
  const currentMeta = config.meta ?? {};
  return {
    ...config,
    meta: {
      ...currentMeta,
      isRetry: true,
    },
  };
};

export const isRetryRequest = (config: RetryRequestConfig): boolean => {
  return config.meta?.isRetry === true;
};

export const isBearerRequest = (config: RetryRequestConfig): boolean => {
  return config.headers?.Authorization?.startsWith('Bearer') ?? false;
};

export const isAuthenticationRequest = (config: RetryRequestConfig): boolean => {
  return config.url.endsWith('/models/me') || config.url.endsWith('/oauth/token');
};
