import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { put, call, all, takeLatest, select } from 'redux-saga/effects';
import { notificationActions } from './notification';
import i18n from '../utils/i18n';
import { AppState } from '.';
import FormTemplate from '../sdk/com/apiomat/frontend/missio/FormTemplate';
import { getFileTypeName } from '../utils/file.utils';

/** STATE */
export interface FormTemplateState {
  formTemplates: FormTemplate[];
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
  loadingPost: 'idle' | 'pending' | 'succeeded' | 'failed';
}

const initialState: FormTemplateState = {
  formTemplates: [],
  loading: 'idle',
  loadingPost: 'idle',
};

/** TYPES */
export interface FileData {
  fileBuffer?: string | ArrayBuffer | null;
  fileURL?: string;
  name: string;
}

export interface FormTemplateData {
  formTemplate: FormTemplate;
  file?: FileData;
}

/** SLICE */
const formTemplateSlice = createSlice({
  name: 'formTemplate',
  initialState,
  reducers: {
    loadFormTemplates: state => {
      state.loading = 'pending';
    },
    loadFormTemplatesSuccess: (state, action: PayloadAction<FormTemplate[]>) => {
      state.loading = 'succeeded';
      state.formTemplates = action.payload;
    },
    loadFormTemplatesFailure: state => {
      state.loading = 'failed';
    },
    saveFormTemplate: (state, action: PayloadAction<FormTemplateData>) => {
      state.loadingPost = 'pending';
    },
    saveFormTemplateSuccess: state => {
      state.loadingPost = 'succeeded';
    },
    saveFormTemplateFailure: state => {
      state.loadingPost = 'failed';
    },
    deleteFormTemplate: (state, action: PayloadAction<FormTemplate>) => {
      state.loadingPost = 'pending';
    },
    deleteFormTemplateSuccess: state => {
      state.loadingPost = 'succeeded';
    },
    deleteFormTemplateFailure: state => {
      state.loadingPost = 'failed';
    },
  },
});

export const formTemplateActions = formTemplateSlice.actions;
export const formTemplateReducer = formTemplateSlice.reducer;

/** SAGAS */
function* onLoadFormTemplates() {
  const isOnline: boolean = yield select((state: AppState) => state.offline.isOnline);

  if (isOnline === false) {
    yield put(notificationActions.showError(i18n.t('you-are-offline')));
    return;
  }

  try {
    const rows = yield call(() => FormTemplate.getFormTemplates());
    yield put(formTemplateActions.loadFormTemplatesSuccess(rows));
  } catch (error) {
    yield put(notificationActions.showError(error));
    yield put(formTemplateActions.loadFormTemplatesFailure());
  }
}

function* onSaveFormTemplate(action: PayloadAction<FormTemplateData>) {
  const { formTemplate, file } = action.payload;

  try {
    yield call(() => formTemplate.save());
    if (file?.fileBuffer) {
      const { fileType, fileName } = getFileTypeName(file.name);
      yield call(() => formTemplate.postFile(file.fileBuffer, fileName, fileType));
    }
    yield put(formTemplateActions.saveFormTemplateSuccess());
  } catch (error) {
    yield put(notificationActions.showError(error));
    yield put(formTemplateActions.saveFormTemplateFailure());
  } finally {
    yield put(formTemplateActions.loadFormTemplates());
  }
}

function* onDeleteFormTemplate(action: PayloadAction<FormTemplate>) {
  const formTemplate = action.payload;

  try {
    yield call(() => formTemplate.delete());
    yield put(formTemplateActions.saveFormTemplateSuccess());
  } catch (error) {
    yield put(notificationActions.showError(error));
    yield put(formTemplateActions.saveFormTemplateFailure());
  } finally {
    yield put(formTemplateActions.loadFormTemplates());
  }
}

export function* formTemplateSaga() {
  yield all([
    takeLatest(formTemplateActions.loadFormTemplates, onLoadFormTemplates),
    takeLatest(formTemplateActions.saveFormTemplate, onSaveFormTemplate),
    takeLatest(formTemplateActions.deleteFormTemplate, onDeleteFormTemplate),
  ]);
}
