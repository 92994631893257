import React, { useState } from 'react';
import { makeStyles, Tab, Tabs } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  button: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'row',
  },
  tabContainer: {
    paddingBottom: 20,
    height: '100%',
  },
  tab: {
    color: 'rgb(79, 195, 247)',
    fontSize: 18,
  },
  tabContent: {
    marginTop: '20px',
  },
}));

export interface TabItem {
  key: string;
  label: string;
  value: React.ReactNode;
}

interface TabViewProps {
  tabs: TabItem[];
}

const TabView = (props: TabViewProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { tabs } = props;
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);

  return (
    <div className={classes.tabContainer}>
      <Tabs variant="fullWidth" value={activeTabIndex} onChange={(_event, tabIndex) => setActiveTabIndex(tabIndex)}>
        {tabs.map(tab => (
          <Tab fullWidth key={tab.key} className={classes.tab} disableRipple label={t(tab.label)} />
        ))}
      </Tabs>
      <div className={classes.tabContent}>{tabs[activeTabIndex].value}</div>
    </div>
  );
};

export default TabView;
