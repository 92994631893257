import React, { Fragment } from 'react';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { offlineActions } from '../../store/offline';
import { notificationActions } from '../../store/notification';

export const SyncActions = ({ key }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <Fragment>
      <Button onClick={() => dispatch(offlineActions.uploadQueue())}>{t('Sync')}</Button>
      <Button
        onClick={() => {
          dispatch(notificationActions.closeSnackbar({ key, dismissAll: true }));
          dispatch(offlineActions.uploadQueueSuccess());
        }}
      >
        Close
      </Button>
    </Fragment>
  );
};
