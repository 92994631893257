import React from 'react';
import { Button, makeStyles, Menu } from '@material-ui/core';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface ActionMenuProps {
  menuId: string;
  icon?: any;
  children?: any;
  disabled?: boolean;
}

const useStyles = makeStyles(() => ({
  menuButton: {
    '& > .MuiButton-label': {
      gap: '10px'
    }
  },
  listMenu: {
    '& > div > ul': {
      paddingBottom: 0
    }
  }
}));

export default (props: ActionMenuProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { menuId, disabled = false } = props;

  const [open, setOpen] = useState<boolean>(false);
  const [anchorElement, setAnchorElement] = useState<HTMLElement>();

  const openMenu = (anchorElement: HTMLElement) => {
    setOpen(true);
    setAnchorElement(anchorElement);
  };

  const closeMenu = () => {
    setOpen(false);
    setAnchorElement(null);
  };

  return (
    <div>
      <Button
        aria-controls={open ? menuId : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        color="primary"
        variant="contained"
        onClick={e => openMenu(e.currentTarget)}
        className={classes.menuButton}
        disabled={disabled}
      >
        {props.icon}
        {t('action-menu:label')}
      </Button>
      <Menu
        id={menuId}
        anchorEl={anchorElement}
        open={open}
        onClose={closeMenu}
        className={classes.listMenu}
      >
        {props.children}
      </Menu>
    </div>
  );
};
