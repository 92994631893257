import React from 'react';
import DefaultDialog from './DefaultDialog';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from '../Buttons/Buttons';
import { InvalidResult } from '../../validators/OfferValidation';

export enum ValidationType {
  fieldNotEmpty = 'fieldNotEmpty',
  biggerThanZero = 'biggerThanZero',
}

export interface MissingFieldsDialogProps {
  invalidResults: InvalidResult[];
  onCancelButtonClick: () => void;
}

const MissingFieldsDialog = (props: MissingFieldsDialogProps) => {
  const { t } = useTranslation();
  const { invalidResults, onCancelButtonClick } = props;

  return (
    <DefaultDialog
      isOpen={true}
      children={invalidResults.map(x => (
        <div>{t(x.key)}</div>
      ))}
      actionComponent={
        <div>
          <PrimaryButton onClick={onCancelButtonClick}>{t('button:label:cancel')}</PrimaryButton>
        </div>
      }
      title={t('missing-fields:form:dialog-title')}
    />
  );
};

export default MissingFieldsDialog;
