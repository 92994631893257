import React, { useEffect, useState } from 'react';
import {
  makeStyles,
  Theme,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  List,
  ListItem,
  ListItemIcon,
  Typography,
} from '@material-ui/core';
import { PrimaryButton } from '../../Buttons/Buttons';
import { useTranslation } from 'react-i18next';
import { DeleteButton } from '../../Buttons/DeleteButton';
import EditIcon from '@material-ui/icons/Edit';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../../../store';
import { offerActions } from '../../../store/offers';
import moment from 'moment';
import ReportCostPlanFormDialog from './ActualCostPlanFormDialog';
import MissingFieldsDialog from '../../../components/Dialogs/MissingFieldsDialog';
import CostPlan from '../../../sdk/com/apiomat/frontend/missio/CostPlan';
import CurrencyRate from '../../../sdk/com/apiomat/frontend/missio/CurrencyRate';
import FinancialPlan from '../../../sdk/com/apiomat/frontend/missio/FinancialPlan';
import CostPlanItem from '../../../sdk/com/apiomat/frontend/missio/CostPlanItem';
import FinancialPlanItem from '../../../sdk/com/apiomat/frontend/missio/FinancialPlanItem';
import SaveConfirmationDialog from '../../Dialogs/SaveConfirmationDialog';
import CostPlanFormDialog from './CostPlanFormDialog';
import { validateReportCostPlan } from '../../../validators/ReportValidation';
import { InvalidResult } from '../../../validators/OfferValidation';
import { reportActions } from '../../../store/report';
import { calculateDeviations } from '../../../utils/report.utils';
import CurrencyConversionTable from '../../../sdk/com/apiomat/frontend/missio/CurrencyConversionTable';
import CurrencyConversion from '../../../sdk/com/apiomat/frontend/missio/CurrencyConversion';
import { Action, useOfferFormContext } from '../context/OfferFormContext';
import { Warning } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) => ({
  contentContainer: {
    paddingBottom: 30,
    paddingTop: 30,
    backgroundColor: '#f8f8f8',
  },
  addButtonContainer: {
    display: 'flex',
    flex: 1,
    padding: 5,
    justifyContent: 'flex-end',
  },
  addButton: {
    borderRadius: 0,
  },
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
}));

export interface CostPlanFormProps {
  readOnlyMode: boolean;
  isReport?: boolean;
}

interface DialogObject {
  isVisible: boolean;
  costPlan: CostPlan;
  oldCostPlan: CostPlan;
}

const CostPlanForm = (props: CostPlanFormProps) => {
  const { readOnlyMode, isReport } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isInfoDialogOpen, setIsInfoDialogOpen] = useState(false);
  const [isMissingReportFieldsDialogActive, setMissingReportFieldsDialogActive] = useState<boolean>(false);
  const [missingReportFields, setMissingReportFields] = useState<InvalidResult[]>([]);
  const { state, dispatch: offerContextDispatch } = useOfferFormContext();

  const { currentOffer } = useSelector((state: AppState) => state.offer);
  const { currentReport, currentDeviations, deviationThreshold } = useSelector((state: AppState) => state.report);
  const costPlans = isReport ? [currentReport?.report?.costPlan] : currentOffer?.offer?.costPlans;

  const [activeDialog, setActiveDialog] = useState<DialogObject>({
    isVisible: false,
    costPlan: null,
    oldCostPlan: null,
  });

  const actionObject: Action = {
    subFormId: 'costPlan',
    errors: {},
    values: {},
  };
  // one costplan is mandatory
  if (costPlans.length === 0) {
    actionObject.errors['costPlan'] = 'cost plan is mandatory';
  } else {
    // one costplan item is mandatory
    costPlans.forEach((costPlan, index) => {
      if (costPlan.costPlanItems.length === 0) {
        actionObject.errors[`costPlanItem-${index}`] = 'cost plan item is mandatory';
      }
    });
    // one financialplan item is mandatory
    costPlans.forEach((costPlan, index) => {
      if (costPlan.financialPlan.financialPlanItems.length === 0) {
        actionObject.errors[`financialPlanItem-${index}`] = 'financial plan item is mandatory';
      }
    });
  }
  useEffect(() => {
    if (!readOnlyMode) {
      if (!('costPlan' in state) || ('costPlan' in state && state['costPlan']['errorCount'] !== Object.keys(actionObject.errors).length)) {
        offerContextDispatch(actionObject);
      }
    }
  }, [actionObject, costPlans, costPlans.length, dispatch, offerContextDispatch, readOnlyMode, state]);

  const onAddButtonPressed = () => {
    const newCostPlan = new CostPlan();
    newCostPlan.sum = 0;
    newCostPlan.start = new Date();
    newCostPlan.end = new Date();
    (newCostPlan as any).hashmap.costPlanItems = [];
    (newCostPlan as any).dao.costPlanItems = newCostPlan.costPlanItems;

    /** currency rates */
    const firstCurrencyRate = new CurrencyRate();
    firstCurrencyRate.listId = 0;
    const secondCurrencyRate = new CurrencyRate();
    secondCurrencyRate.listId = 1;
    const thirdCurrencyRate = new CurrencyRate();
    thirdCurrencyRate.listId = 2;
    thirdCurrencyRate.currency = 'EUR';
    thirdCurrencyRate.rate = 1;
    (newCostPlan as any).hashmap.currencyRates = [firstCurrencyRate, secondCurrencyRate, thirdCurrencyRate];
    (newCostPlan as any).dao.currencyRates = [firstCurrencyRate, secondCurrencyRate, thirdCurrencyRate];

    if (!isReport) {
      /** financial plan */
      (newCostPlan as any).hashmap.financialPlan = new FinancialPlan();
      (newCostPlan as any).dao.financialPlan = newCostPlan.financialPlan;
      newCostPlan.financialPlan.sumEuro = 0;
      (newCostPlan.financialPlan as any).hashmap.financialPlanItems = [];
      (newCostPlan.financialPlan as any).dao.financialPlanItems = newCostPlan.financialPlan.financialPlanItems;
    }

    setActiveDialog({
      isVisible: true,
      costPlan: newCostPlan,
      oldCostPlan: null,
    });
  };

  const copyCostPlan = (costPlan: CostPlan): CostPlan => {
    const copy = new CostPlan();
    copy.fromJson(costPlan.toJson());

    /** cost plan */
    (copy as any).hashmap.costPlanItems = costPlan.costPlanItems.map(item => {
      const copyItem = new CostPlanItem();
      copyItem.fromJson(item.toJson());
      return copyItem;
    });
    (copy as any).dao.costPlanItems = copy.costPlanItems;

    /** currency rates */
    (copy as any).hashmap.currencyRates = costPlan.currencyRates.map(item => {
      const copyItem = new CurrencyRate();
      copyItem.fromJson(item.toJson());
      return copyItem;
    });
    (copy as any).dao.currencyRates = copy.currencyRates;

    /** financial plan */
    const newFinancialPlan = new FinancialPlan();
    newFinancialPlan.fromJson(costPlan.financialPlan.toJson());
    (copy as any).hashmap.financialPlan = newFinancialPlan;
    (copy as any).dao.financialPlan = copy.financialPlan;

    (copy.financialPlan as any).hashmap.financialPlanItems = costPlan.financialPlan.financialPlanItems.map(item => {
      const copyItem = new FinancialPlanItem();
      copyItem.fromJson(item.toJson());
      return copyItem;
    });
    (copy.financialPlan as any).dao.financialPlanItems = copy.financialPlan.financialPlanItems;

    /** currency conversion table */
    if (isReport) {
      const newCurrencyConversionTable = new CurrencyConversionTable();
      newCurrencyConversionTable.fromJson(costPlan.currencyConversionTable.toJson());
      (copy as any).hashmap.currencyConversionTable = newCurrencyConversionTable;
      (copy as any).dao.currencyConversionTable = copy.currencyConversionTable;

      (copy.currencyConversionTable as any).hashmap.sourceCurrencyRates = costPlan.currencyConversionTable.sourceCurrencyRates.map(item => {
        const copyItem = new CurrencyRate();
        copyItem.fromJson(item.toJson());
        return copyItem;
      });
      (copy.currencyConversionTable as any).dao.sourceCurrencyRates = copy.currencyConversionTable.sourceCurrencyRates;

      (copy.currencyConversionTable as any).hashmap.targetCurrencyRates = costPlan.currencyConversionTable.targetCurrencyRates.map(item => {
        const copyItem = new CurrencyRate();
        copyItem.fromJson(item.toJson());
        return copyItem;
      });
      (copy.currencyConversionTable as any).dao.targetCurrencyRates = copy.currencyConversionTable.targetCurrencyRates;

      (copy.currencyConversionTable as any).hashmap.currencyConversions = costPlan.currencyConversionTable.currencyConversions.map(item => {
        const copyItem = new CurrencyConversion();
        copyItem.fromJson(item.toJson());
        return copyItem;
      });
      (copy.currencyConversionTable as any).dao.currencyConversions = copy.currencyConversionTable.currencyConversions;
    }
    return copy;
  };

  const onEditButtonClick = (costPlan: CostPlan) => {
    setActiveDialog({
      isVisible: true,
      costPlan: copyCostPlan(costPlan),
      oldCostPlan: costPlan,
    });
  };

  const onDeleteButtonClick = (index: number, costPlanToDelete: CostPlan) => {
    costPlans.splice(index, 1);
    (currentOffer.offer as any).dao.costPlans = costPlans;
    (currentOffer.offer as any).hashmap.costPlans = costPlans;

    // add property costplans to delete only if costplan saved in apiomat
    if (Boolean(costPlanToDelete.ID)) {
      const costPlansToDelete = currentOffer.offer.costPlansToDelete || [];
      costPlansToDelete.push(costPlanToDelete);
      currentOffer.offer.costPlansToDelete = costPlansToDelete;
    }

    dispatch(offerActions.updateCurrentOffer(currentOffer.offer));
  };

  const onSaveConfirmed = () => {
    setIsInfoDialogOpen(false);
    saveCostPlan();
  };

  const onCancel = () => {
    setIsInfoDialogOpen(false);
  };

  const onSaveButtonClick = () => {
    const financialPlanItems: FinancialPlanItem[] = activeDialog.costPlan?.financialPlan?.financialPlanItems;
    const missingReportFields = validateReportCostPlan(activeDialog.costPlan, currentDeviations, deviationThreshold);

    if (financialPlanItems.length === 0) {
      setIsInfoDialogOpen(true);
    } else if (missingReportFields.length > 0 && isReport) {
      setMissingReportFieldsDialogActive(true);
      setMissingReportFields(missingReportFields);
    } else {
      saveCostPlan();
    }
  };

  const saveCostPlan = () => {
    if (!activeDialog.oldCostPlan) {
      costPlans.push(activeDialog.costPlan);
    } else {
      const index = costPlans.indexOf(activeDialog.oldCostPlan);
      costPlans[index] = activeDialog.costPlan;
    }
    if (isReport) {
      (currentReport.report as any).dao.costPlan = activeDialog.costPlan;
      (currentReport.report as any).hashmap.costPlan = activeDialog.costPlan;
      dispatch(reportActions.updateCurrentReport(currentReport.report));
    } else {
      dispatch(offerActions.updateCurrentOffer(currentOffer.offer));
    }

    setActiveDialog({
      isVisible: false,
      costPlan: null,
      oldCostPlan: null,
    });
  };

  const onCancelButtonClick = () => {
    if (isReport) {
      dispatch(reportActions.updateCurrentDeviations(calculateDeviations(activeDialog.oldCostPlan)));
    }
    setActiveDialog({
      isVisible: false,
      costPlan: null,
      oldCostPlan: null,
    });
  };

  return (
    <>
      <div className={classes.contentContainer}>
        <div className={classes.contentContainer}>
          {!readOnlyMode && !isReport && !currentOffer?.offer?.isAmendment && (
            <div className={classes.addButtonContainer}>
              <PrimaryButton className={classes.addButton} onClick={() => onAddButtonPressed()}>
                {t('button:label:add-new-costplan')}
              </PrimaryButton>
            </div>
          )}
          <div />
          {Object.keys(actionObject.errors).length > 0 && (
            <>
              <Typography variant="subtitle1">{t('mandatory_fields')}</Typography>
              <List>
                {costPlans.length === 0 ? (
                  <ListItem key={'mandatoryCostPlan'}>
                    <ListItemIcon>
                      <Warning />
                    </ListItemIcon>
                    {t(`mandatory_costplan`)}
                  </ListItem>
                ) : (
                  <>
                    {costPlans.map((costPlan, index) => {
                      return costPlan.costPlanItems.length === 0 ? (
                        <ListItem key={`mandatoryCostPlanItem-${index}`}>
                          <ListItemIcon>
                            <Warning />
                          </ListItemIcon>
                          {t(`mandatory_costplanitem`, { phaseId: costPlan.phase })}
                        </ListItem>
                      ) : null;
                    })}
                    {costPlans.map((costPlan, index) => {
                      return costPlan.financialPlan.financialPlanItems.length === 0 ? (
                        <ListItem key={`mandatoryCostPlanItem-${index}`}>
                          <ListItemIcon>
                            <Warning />
                          </ListItemIcon>
                          {t(`mandatory_financialplanitem`, { phaseId: costPlan.phase })}
                        </ListItem>
                      ) : null;
                    })}
                  </>
                )}
              </List>
            </>
          )}

          <Paper className={classes.root}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>{t('label:cost-plan-table-phase')}</TableCell>
                  <TableCell align="right">{t('label:cost-plan-table-from')}</TableCell>
                  <TableCell align="right">{t('label:cost-plan-table-until')}</TableCell>
                  <TableCell align="right">{t('label:cost-plan-table-total-amount')}</TableCell>
                  <TableCell align="right">{t('label:cost-plan-table-actions')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {costPlans?.map((item, i) => (
                  <TableRow key={i}>
                    <TableCell component="th" scope="row">
                      {item.phase}
                    </TableCell>
                    <TableCell align="right">{item.start ? moment(item.start).format('DD.MM.YYYY') : ''}</TableCell>
                    <TableCell align="right">{item.end ? moment(item.end).format('DD.MM.YYYY') : ''}</TableCell>
                    <TableCell align="right">{new Intl.NumberFormat().format(item.sum)}</TableCell>
                    <TableCell align="right">
                      {readOnlyMode || isReport || currentOffer?.offer?.isAmendment ? (
                        <Button onClick={() => onEditButtonClick(item)}>
                          <EditIcon color="primary" />
                        </Button>
                      ) : (
                        <div>
                          <Button onClick={() => onEditButtonClick(item)}>
                            <EditIcon color="primary" />
                          </Button>
                          <DeleteButton onDeleteConfirmed={() => onDeleteButtonClick(i, item)} type="icon" />
                        </div>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </div>
      </div>
      {!isReport ? (
        <CostPlanFormDialog
          isOpen={activeDialog.isVisible}
          isReadonly={readOnlyMode}
          costPlan={activeDialog.costPlan}
          costPlanPhases={costPlans.map(costPlan => costPlan.phase)}
          updateCostPlan={costPlan => setActiveDialog({ ...activeDialog, costPlan })}
          onCancelButtonClick={onCancelButtonClick}
          onSaveButtonClick={onSaveButtonClick}
        />
      ) : (
        <ReportCostPlanFormDialog
          isOpen={activeDialog.isVisible}
          isReadonly={readOnlyMode}
          costPlan={activeDialog.costPlan}
          updateCostPlan={costPlan => setActiveDialog({ ...activeDialog, costPlan })}
          onCancelButtonClick={onCancelButtonClick}
          onSaveButtonClick={onSaveButtonClick}
        />
      )}
      {isReport && isMissingReportFieldsDialogActive && (
        <MissingFieldsDialog invalidResults={missingReportFields} onCancelButtonClick={() => setMissingReportFieldsDialogActive(false)} />
      )}
      <SaveConfirmationDialog isOpen={isInfoDialogOpen} onSaveConfirmed={onSaveConfirmed} onCancelClick={onCancel}></SaveConfirmationDialog>
    </>
  );
};

export default CostPlanForm;
