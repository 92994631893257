import React from 'react';
import { makeStyles, CssBaseline, Drawer, Theme, Toolbar } from '@material-ui/core';
import DefaultAppBar from './DefaultAppBar';
import AppMenu from './AppMenu';

export const drawerWidth = 275;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    width: '100vh',
    flexGrow: 1,
    backgroundColor: '#f7f7f7',
    padding: theme.spacing(3),
    minHeight: '100vh',
  },
  contentOffset: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  drawerContainer: {
    overflow: 'auto',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: '#333333',
  },
}));
export default ({ children, isAuthenticated }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <DefaultAppBar />
      {isAuthenticated && (
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <Toolbar />
          <div className={classes.drawerContainer}>
            <AppMenu />
          </div>
        </Drawer>
      )}
      <main className={classes.content}>
        <div className={classes.contentOffset} />
        {children}
      </main>
    </div>
  );
};
