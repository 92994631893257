import { TFunction } from 'i18next';
import { DropDownItem } from '../components/Selection/DropDownItem';
import { allAttachmentTypes } from '../value-maps/AttachmentTypes';
import { GeneralValueMap } from '../value-maps/GeneralValueMap';
import { alphabeticalSort } from './sort.util';

export const mandatorySort = (valueMap: GeneralValueMap[], fieldName: string, measureName: string, t: TFunction): DropDownItem[] => {
  const mandatoryAttachments = valueMap
    .filter(item => item[fieldName]?.includes(measureName))
    .map(el => ({
      key: el.key,
      value: t(el.value) + ` (${t('attachments:mandatory')})`,
    }))
    .sort(alphabeticalSort);

  const attachments = valueMap
    .filter(item => !item[fieldName]?.includes(measureName))
    .map(el => ({
      key: el.key,
      value: t(el.value),
    }))
    .sort(alphabeticalSort);

  return [...mandatoryAttachments, ...attachments];
};

export const getMandatoryAttachmentKeys = (valueMap: GeneralValueMap[], fieldName: string, measureName: string): string[] => {
  return valueMap.filter(attachment => attachment[fieldName]?.includes(measureName)).map(filtered => filtered.key);
};

export const getTranslatedSortedAttachmentTypes = (t: TFunction): DropDownItem[] => {
  return allAttachmentTypes
    .map(el => ({
      key: el.key,
      value: t(el.value as string),
    }))
    .sort(alphabeticalSort);
};
