import { makeStyles } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import LoadingIndicator from '../Loading/LoadingIndicator';
import { Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    justifyContent: 'flex-end',
    display: 'flex',
  },
  primaryButton: {
    paddingLeft: theme.spacing(1),
    '& svg': {
      marginRight: theme.spacing(1),
    }
  }
}));

export interface ExportButtonProps {
  isDisabled: boolean;
  loading: boolean;
  onExportClick: () => void;
}

export const ExportButton = (props: ExportButtonProps) => {
  const classes = useStyles();
  const { isDisabled, loading, onExportClick } = props;

  const { t } = useTranslation();

  return (
    <div className={ classes.buttonContainer }>
      { loading ? (
        <LoadingIndicator size={ 20 }/>
      ) : (
        <Tooltip title={ t('button:label:export') }>
          <IconButton color="primary" disabled={ !isDisabled } component="label" onClick={ onExportClick }>
            <CloudDownloadIcon/>
          </IconButton>
        </Tooltip>
      ) }
    </div>
  );
};

export default ExportButton;
