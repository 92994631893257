import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { AppState } from '.';
import Datastore from '../sdk/com/apiomat/frontend/Datastore';
import { notificationActions } from './notification';
import { offlineActions } from './offline';
import { push } from 'connected-react-router';
import i18n from '../utils/i18n';
import { createTransform } from 'redux-persist';
import { auditToJson, auditFromJson } from '../utils/transforms';
import Audit from '../sdk/com/apiomat/frontend/missio/Audit';
import { saveAudit } from '../utils/audit.utils';
import { StateType } from '../enums/StateType';

export interface AuditState {
  audits: Audit[];
  currentAudit: { audit: Audit } | null;
  loadingAudits: 'idle' | 'pending' | 'succeeded' | 'failed' | 'loading-more';
  loadingCurrentAudit: 'idle' | 'pending' | 'succeeded' | 'failed';
}

const initialState: AuditState = {
  audits: [],
  currentAudit: null,
  loadingAudits: 'idle',
  loadingCurrentAudit: 'idle',
};

/** SLICE */
const auditSlice = createSlice({
  name: 'audit',
  initialState,
  reducers: {
    loadAudits: (state, _action: PayloadAction<string>) => {
      state.loadingAudits = 'pending';
    },
    loadAuditsSuccess: (state, action: PayloadAction<Audit[]>) => {
      state.loadingAudits = 'succeeded';
      state.audits = action.payload;
    },
    loadAuditsFailure: state => {
      state.loadingAudits = 'failed';
    },
    loadCurrentAudit: (state, _action: PayloadAction<string>) => {
      state.loadingAudits = 'pending';
    },
    loadCurrentAuditSuccess: (state, action: PayloadAction<Audit>) => {
      state.loadingAudits = 'succeeded';

      const audit = action.payload;
      state.currentAudit = { audit: audit };
    },
    loadCurrentAuditFailure: state => {
      state.loadingAudits = 'failed';
    },
    updateCurrentAudit: (state, _action: PayloadAction<Audit>) => {
      state.currentAudit = { audit: _action.payload };
    },
    saveCurrentAudit: (state, _action: PayloadAction<StateType>) => {
      state.loadingCurrentAudit = 'pending';
    },
    saveCurrentAuditSuccess: (state, action: PayloadAction<Audit>) => {
      const audit = action.payload;
      state.loadingCurrentAudit = 'succeeded';
      state.audits = [...state.audits.filter(el => el.ID !== audit.ID), audit];
    },
    saveCurrentAuditFailure: state => {
      state.loadingCurrentAudit = 'failed';
    },
  },
});

export const auditActions = auditSlice.actions;
export const auditReducer = auditSlice.reducer;

/** SAGAS */
function* onLoadAudits(action: PayloadAction<string>) {
  const isOnline: boolean = yield select((state: AppState) => state.offline.isOnline);
  const query = action.payload || '';

  const localAudits: Audit[] = yield select((state: AppState) => state.audit.audits);
  if (isOnline === false) {
    yield put(auditActions.loadAuditsSuccess(localAudits || []));
    return;
  }
  try {
    const audits: Audit[] = yield call(() => Audit.getAudits(`${query} order by lastModifiedAt DESC`));
    yield all([...audits.map(audit => call(() => audit.loadOffer()))]);
    yield all([...audits.map(audit => call(() => audit.loadAttachments()))]);

    yield put(auditActions.loadAuditsSuccess(audits));
  } catch (e) {
    yield put(auditActions.loadAuditsFailure());
  }
}

function* onLoadCurrentAudit(action: PayloadAction<any>) {
  const isOnline: boolean = yield select((state: AppState) => state.offline.isOnline);
  const id = action.payload;

  if (isOnline === false) {
    const audits: Audit[] = yield select((state: AppState) => state.audit.audits);
    yield put(auditActions.loadCurrentAuditSuccess(audits.find(el => el.ID === id)));
    return;
  }

  try {
    const audit: Audit = new Audit();
    const href = `${Datastore.Instance.createModelHref(audit)}/${id}`;

    yield call(() => audit.load(href));
    if (audit) {
      yield call(() => audit.loadOffer());
      yield call(() => audit.loadAttachments());
    }

    yield put(auditActions.loadCurrentAuditSuccess(audit));
  } catch (e) {
    yield put(notificationActions.showError('No entry found'));
    yield put(auditActions.loadCurrentAuditFailure());
  }
}

function* onSaveCurrentAudit(action: PayloadAction<StateType>) {
  const audit: Audit = yield select((state: AppState) => state.audit.currentAudit.audit);
  const isOnline: boolean = yield select((state: AppState) => state.offline.isOnline);

  const isCreation = Boolean(audit.ID) === false;
  const newStatus = action.payload;

  if (isOnline === false) {
    yield put(offlineActions.addAuditIntoQueue({ audit: audit, newStatus, mutationType: isCreation ? 'create' : 'save' }));
    audit.state.name = StateType.unsavedChanges;

    if (isCreation) {
      yield put(push(`/tasks/audit/new/${audit.ID}`));
    }

    yield put(notificationActions.showSuccessMessage(i18n.t('assignment:status:offline')));
    yield put(auditActions.saveCurrentAuditSuccess(audit));

    return;
  }

  try {
    const updatedAudit = yield call(() => saveAudit(audit, newStatus));
    yield put(auditActions.updateCurrentAudit(updatedAudit));

    yield put(notificationActions.showSuccessMessage(i18n.t('use-cases:new-assignment:root:saved-notification:title')));
    yield put(auditActions.saveCurrentAuditSuccess(updatedAudit));
    yield put(push(`/tasks/my`));
  } catch (e) {
    audit.state.name = StateType.unsavedChanges;
    yield put(auditActions.updateCurrentAudit(audit));
    yield put(notificationActions.showError(i18n.t('use-cases:new-assignment:root:saving-failed-notification:body')));

    yield put(auditActions.saveCurrentAuditFailure());
  }
}

export function* auditSaga() {
  yield all([takeLatest(auditActions.loadAudits, onLoadAudits)]);
  yield all([takeLatest(auditActions.loadCurrentAudit, onLoadCurrentAudit)]);
  yield all([takeLatest(auditActions.saveCurrentAudit, onSaveCurrentAudit)]);
}

/** TRANSFORMS */
export const auditTransform = createTransform(
  (state: AuditState) => {
    return {
      ...state,
      audits: state.audits.map(auditToJson),
      currentAudit: state.currentAudit?.audit ? auditToJson(state.currentAudit?.audit) : null,
    };
  },
  json => {
    return {
      ...json,
      audits: json.audits && json.audits.map(auditFromJson),
      currentAudit: null,
      loadingAudits: 'idle',
      loadingCurrentAudit: 'idle',
    };
  },
  { whitelist: ['audit'] }
);
