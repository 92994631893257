import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardActions, CardContent, CardMedia, makeStyles, Typography } from '@material-ui/core';
import { EasyLogo, MissioLogo, MwiLogo } from '../components/Logo/Logo';
import { PrimaryButton } from '../components/Buttons/Buttons';
import { EmailTextField } from '../components/TextInputs/TextInputs';
import { useTranslation } from 'react-i18next';
import { authActions } from '../store/auth';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../store';
import { useFormik } from 'formik';
import { object, string } from 'yup';

const useStyles = makeStyles(theme => ({
  fullHeightContainer: {
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundSize: 'cover',
    backgroundColor: theme.palette.grey['50'],
  },
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    gap: '20px',
  },
  divider: {
    marginBottom: 15,
  },
  cardContainer: {
    maxWidth: 350,
    minWidth: 300,
    backgroundColor: theme.palette.common.white,
  },
  cardMediaContainer: {
    paddingTop: 25
  },
  formText: {
    maxWidth: 250,
    padding: '20px 8px 10px 8px',
    marginBlockEnd: 0,
    marginBlockStart: 0,
  },
  buttonContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  resetButton: {
    margin: 5,
  },
  cancelButton: {
    margin: 5,
  },
}));

export default () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const loading = useSelector((state: AppState) => state.auth.loading);

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: object({
      email: string()
        .email(t('invalid_email_address'))
        .required(t('mandatory_field')),
    }),
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: values => {
      dispatch(authActions.resetPassword(values.email));
    },
  });

  return (
    <div className={ classes.fullHeightContainer }>
      <div>
        <Card className={ classes.cardContainer }>
          <CardMedia className={ classes.cardMediaContainer }>
            <MissioLogo/>
            <div className={ classes.divider }/>
            <MwiLogo/>
            <CardContent>
              <form onSubmit={ formik.handleSubmit }>
                <Typography className={ classes.formText } color={'textSecondary'} variant="body2">
                  { t('reset-email-message') }
                </Typography>
                <div className={ classes.container }>
                  <EmailTextField
                    id="email"
                    name="email"
                    value={ formik.values.email }
                    onChange={ formik.handleChange }
                    error={ Boolean(formik.errors.email) }
                    helperText={ formik.errors.email }
                  />
                </div>
                <CardActions className={ classes.buttonContainer }>
                  <PrimaryButton type="submit" className={ classes.resetButton } disabled={ loading === 'pending' }
                                 fullWidth>
                    { t('button:label:reset') }
                  </PrimaryButton>
                  <PrimaryButton className={ classes.cancelButton } fullWidth component={ Link } to="/login">
                    { t('button:label:cancel') }
                  </PrimaryButton>
                </CardActions>
              </form>
              <EasyLogo/>
            </CardContent>
          </CardMedia>
        </Card>
      </div>
    </div>
  );
};
