import React, { useState } from 'react';
import { InputAdornment, makeStyles, TextField, Theme } from '@material-ui/core';
import { Search } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'absolute',
    right: '100px',
    background: '#f8f8f8',
  },
  searchInputTextField: {
    color: theme.palette.common.black,
    background: '#f8f8f8',
    paddingBottom: 8,
    minWidth: '300px',
  },
  searchIcon: {
    opacity: 0.5,
    paddingLeft: 8,
    background: '#f8f8f8',
  },
}));

export interface SearchBarProps {
  placeholder?: string;
  onChange?: (searchTerm: string) => void;
}

const SearchBar = (props: SearchBarProps) => {
  const classes = useStyles();
  const { placeholder, onChange } = props;

  const [searchTerm, setSearchTerm] = useState<string>('');

  return (
    <div className={classes.container}>
      <TextField
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
          classes: { input: classes.searchInputTextField, adornedStart: classes.searchIcon },
        }}
        placeholder={placeholder}
        value={searchTerm}
        onChange={event => {
          const currentSearchTerm = event.target.value;
          setSearchTerm(currentSearchTerm);
          onChange(event.target.value);
        }}
      />
    </div>
  );
};

export default SearchBar;
