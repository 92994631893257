import React, { useEffect } from 'react';
import { makeStyles, Theme, Typography, Button, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../store/index';
import LoadingIndicator from '../../components/Loading/LoadingIndicator';
import { misMeasureDropDownItems } from '../../value-maps/MisMeasureDropDownItems';
import { mwiMeasureDropDownItems } from '../../value-maps/MwiMeasureDropDownItems';
import { useParams, Link } from 'react-router-dom';
import DataGrid, { DataGridColumn } from '../../components/DataGrid/DataGrid';
import moment from 'moment';
import GetAppIcon from '@material-ui/icons/GetApp';
import { formTemplateActions } from '../../store/formTemplate';
import FormTemplate from '../../sdk/com/apiomat/frontend/missio/FormTemplate';
import { getFormTemplatesByMeasure, getAttachmentTypeName } from '../../utils/form-templates.utils';
import { onDownloadFile } from '../../utils/file.utils';
import { otherMeasureDropDownItems } from '../../value-maps/OtherMeasureDropDownItems';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    marginBottom: theme.spacing(2),
  },
}));

export default () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { measureKey } = useParams<{ measureKey: string | undefined }>();

  const { formTemplates, loading } = useSelector((state: AppState) => state.formTemplate);

  const measure = [...misMeasureDropDownItems, ...mwiMeasureDropDownItems, ...otherMeasureDropDownItems].find(el => el.key === measureKey);

  useEffect(() => {
    dispatch(formTemplateActions.loadFormTemplates());
  }, [dispatch]);

  const columns: DataGridColumn<FormTemplate>[] = [
    {
      header: <Typography color="primary">{t('form-templates:table:name')}</Typography>,
      valueExtractor: x => x.name,
    },
    {
      header: <Typography color="primary">{t('form-templates:table:attachment-type')}</Typography>,
      valueExtractor: x => t<string>(getAttachmentTypeName(x)),
    },
    {
      header: <Typography color="primary">{t('form-templates:table:last-modified')}</Typography>,
      valueExtractor: x => moment(x.lastModifiedAt).format('DD.MM.YYYY'),
    },
    {
      header: null,
      valueExtractor: x => x.ID,
      component: x => (
        <Button variant="contained" color="primary" onClick={() => onDownloadFile(x, 'file')} startIcon={<GetAppIcon />}>
          {t('form-templates:table:download')}
        </Button>
      ),
    },
  ];

  return loading === 'pending' ? (
    <LoadingIndicator />
  ) : (
    <>
      <Grid container justify="space-between" alignItems="center">
        <Typography variant="h5" className={classes.header}>
          {t(measure?.value as string)}
        </Typography>
        <Link component={Button} to="/form-templates">
          {t('form-templates:button:back')}
        </Link>
      </Grid>

      <DataGrid
        datasource={getFormTemplatesByMeasure(measure.key, formTemplates)}
        columns={columns}
        emptyMessage={{ title: t('data-grid:empty-message-title'), component: '' }}
      />
    </>
  );
};
