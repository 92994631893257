import React, { useEffect } from 'react';
import { Link, Redirect, useLocation } from 'react-router-dom';
import { Container, Typography, Button, makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from '../store/auth';
import { AppState } from '../store';
import LoadingIndicator from '../components/Loading/LoadingIndicator';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  homeButton: {
    marginTop: theme.spacing(4),
  },
}));

export default () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();

  const urlParams = new URLSearchParams(location.search);
  const email = urlParams.get('email');
  const token = urlParams.get('verificationToken');

  const { loading, isVerified } = useSelector((state: AppState) => state.auth);

  useEffect(() => {
    dispatch(authActions.verifyToken({ email, token }));
  }, [location, email, token, dispatch]);

  return (
    <Container component="main" maxWidth="sm" className={classes.container}>
      {loading === 'pending' ? (
        <LoadingIndicator />
      ) : (
        <div>
          {isVerified ? (
            <Redirect to="/login" />
          ) : (
            <div>
              <Typography component="h4" variant="h4">
                {t('email-verify:failure')}
              </Typography>
              <Button component={Link} to="/" variant="contained" color="primary" className={classes.homeButton}>
                Home
              </Button>
            </div>
          )}
        </div>
      )}
    </Container>
  );
};
