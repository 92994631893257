import React, { useEffect, useState } from 'react';
import { ListItemIcon, makeStyles, Theme } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ReasonDialog from '../../components/Dialogs/ReasonDialog';
import ApproveOfferDialog from '../Dialogs/ApproveOfferDialog';
import { ApplicationNewState } from '../../utils/application-state';
import { getFilledContactsFromOffer, setContactsOnOffer } from '../../utils/offer.utils';
import { copyContact } from '../../utils/contact.utils';
import { offerActions } from '../../store/offers';
import { StateType } from '../../enums/StateType';
import { referentActions } from '../../store/referent';
import { AppState } from '../../store';
import Contact from '../../sdk/com/apiomat/frontend/missio/Contact';
import { MenuItem } from '@mui/material';
import {
  Close,
  CreateNewFolder,
  DeleteForever,
  Done,
  Edit,
  Euro,
  PlaylistAddCheck,
  Visibility
} from '@material-ui/icons';
import { ContentCopy, SettingsSuggest } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { OfferType } from '../../enums/OfferType';
import ActionMenu from './ActionMenu';
import { DeleteConfirmationDialog } from '../Buttons/DeleteButton';

interface ActionMenuMyAssignmentProps {
  menuId: string;
  offer: any;
  isVisible: boolean;
  isReadOnly: boolean;
  isProposalAllowed: boolean;
  openChangeAssignmentDialog: (offer: any) => void;
  openCopyAssignmentDialog: (offer: any) => void;
  isDeleteAllowed: boolean;
  openDeleteDialog: () => void;
  deleteOffer: (offer: any) => void;
  isDeleteDialogOpen: boolean;
  cancelDeleteDialog: () => void;
  disabled: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  deleteButton: {
    color: theme.palette.error.main,
    '& svg': {
      color: theme.palette.error.main
    }
  }
}));

export default (props: ActionMenuMyAssignmentProps) => {
  const {
    menuId,
    offer,
    isVisible,
    isReadOnly,
    isProposalAllowed,
    openChangeAssignmentDialog,
    openCopyAssignmentDialog,
    isDeleteAllowed,
    openDeleteDialog,
    deleteOffer,
    isDeleteDialogOpen,
    cancelDeleteDialog,
    disabled = false,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isAdmin } = useSelector((state: AppState) => state.auth);
  const { referents } = useSelector((state: AppState) => state.referent);
  const [ isRejectionDialogOpen, setIsRejectionDialogOpen ] = useState(false);
  const [ rejectionMessage, setRejectionMessage ] = useState('');
  const [ isApprovalDialogOpen, setIsApprovalDialogOpen ] = useState<boolean>(false);

  useEffect(() => {
    dispatch(referentActions.loadReferents());
  }, [ dispatch ]);

  const contacts = getFilledContactsFromOffer(offer);

  const onApprove = (contacts: Contact[]) => {
    if (isAdmin) {
      setContactsOnOffer(offer, contacts);
      dispatch(offerActions.updateCurrentOffer(offer));
      const newStatus: ApplicationNewState = { message: '', status: StateType.approved };
      dispatch(offerActions.updateOfferStatus(newStatus));
      cancelApproval();
    } else {
      dispatch(offerActions.updateCurrentOffer(offer));
      const newStatus: ApplicationNewState = { message: '', status: StateType.proposalInProgressMissio };
      dispatch(offerActions.updateOfferStatus(newStatus));
    }
  };

  const openApprovalDialog = () => {
    if (isAdmin) {
      // closeMenu();
      setIsApprovalDialogOpen(true);
    } else {
      onApprove(null);
    }
  };

  const cancelApproval = () => {
    setIsApprovalDialogOpen(false);
  };

  const openRejectionDialog = () => {
    if (isAdmin) {
      // closeMenu();
      setIsRejectionDialogOpen(true);
    } else {
      onReject();
    }
  };

  const onReject = () => {
    if (isAdmin) {
      dispatch(offerActions.updateCurrentOffer(offer));
      const newStatus: ApplicationNewState = { message: rejectionMessage, status: StateType.rejected };
      dispatch(offerActions.updateOfferStatus(newStatus));
    } else {
      dispatch(offerActions.updateCurrentOffer(offer));
      const newStatus: ApplicationNewState = { message: rejectionMessage, status: StateType.proposalRejectedUser };
      dispatch(offerActions.updateOfferStatus(newStatus));
    }
  };

  const cancelRejection = () => {
    setIsRejectionDialogOpen(false);
  };

  return (
    <>
      <ActionMenu menuId={ menuId } icon={ <SettingsSuggest/> } disabled={disabled}>
        <MenuItem component={ Link }
                  to={ `/assignment/${ offer.measureType === OfferType.mis ? 'mis' : 'mwi' }/${ offer.ID }` }>
          <ListItemIcon>
            { isReadOnly ? <Visibility/> : <Edit/> }
          </ListItemIcon>
          { isReadOnly ? t('my-assignment:actions:view') : t('my-assignment:actions:edit') }
        </MenuItem>
        { isProposalAllowed && (
          <MenuItem onClick={ () => openChangeAssignmentDialog(offer) }>
            <ListItemIcon>
              <CreateNewFolder/>
            </ListItemIcon>
            { t('my-assignment:actions:proposal') }
          </MenuItem>
        ) }
        <MenuItem component={ Link } to={ `/assignment/cost-plan/${ offer.ID }` }>
          <ListItemIcon>
            <Euro/>
          </ListItemIcon>
          { t('my-assignment:actions:cost-overview') }
        </MenuItem>
        { offer.hasReports === 1 && (
          <MenuItem component={ Link } to={ `/tasks/my/filter/${ offer.ID }` }>
            <ListItemIcon>
              <PlaylistAddCheck/>
            </ListItemIcon>
            { t('my-assignment:actions:tasks') }
          </MenuItem>
        ) }
        { isVisible &&
          <MenuItem onClick={ () => openApprovalDialog() }>
            <ListItemIcon>
              <Done/>
            </ListItemIcon>
            { t('all-assignment:actions:approve') }
          </MenuItem>
        }
        { isVisible &&
          <MenuItem onClick={ () => openRejectionDialog() }>
            <ListItemIcon>
              <Close/>
            </ListItemIcon>
            { t('all-assignment:actions:reject') }
          </MenuItem>
        }
        <MenuItem onClick={ () => openCopyAssignmentDialog(offer) }>
          <ListItemIcon>
            <ContentCopy/>
          </ListItemIcon>
          { t('my-assignment:actions:copy') }
        </MenuItem>
        { isDeleteAllowed && (
          <>
            <MenuItem onClick={ openDeleteDialog }
                      className={ classes.deleteButton }>
              <ListItemIcon>
                <DeleteForever/>
              </ListItemIcon>
              { t('my-assignment:actions:delete') }
            </MenuItem>
            <DeleteConfirmationDialog
              onDeleteConfirmed={ () => deleteOffer(offer) }
              isOpen={ isDeleteDialogOpen }
              onCancelClick={ cancelDeleteDialog }
            />
          </>
        ) }
      </ActionMenu>
      <ReasonDialog
        isOpen={ isRejectionDialogOpen }
        onCancelButtonClick={ cancelRejection }
        onSaveButtonClick={ onReject }
        content={ t('reject-dialog:content') }
        onChange={ event => {
          setRejectionMessage(event.target.value);
        } }
        value={ rejectionMessage }
      />
      <ApproveOfferDialog
        isOpen={ isApprovalDialogOpen }
        onApprovalButtonClick={ contacts => onApprove(contacts) }
        onCancelButtonClick={ cancelApproval }
        contacts={ contacts.map(contact => copyContact(contact)) }
        contactLength={ contacts.length }
        referents={ referents }
        offer={ offer }
      />
    </>
  );
};
