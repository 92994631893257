/** STATE */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { put, call, all, takeLatest, select } from 'redux-saga/effects';
import Datastore from '../sdk/com/apiomat/frontend/Datastore';
import { notificationActions } from './notification';
import { StateType } from '../enums/StateType';
import { AppState } from './index';
import i18n from '../utils/i18n';
import { createTransform } from 'redux-persist';
import { offlineActions } from './offline';
import { push } from 'connected-react-router';
import { ContactObj } from './offers';
import Survey from '../sdk/com/apiomat/frontend/missio/Survey';
import { saveSurvey } from '../utils/survey.utils';
import { surveyFromJson, surveyToJson } from '../utils/transforms';

export interface SurveyState {
  surveys: Survey[];
  currentSurvey: { survey: Survey } | null;
  currentContacts: ContactObj[];
  loadingSurveys: 'idle' | 'pending' | 'succeeded' | 'failed' | 'loading-more';
  loadingCurrentSurvey: 'idle' | 'pending' | 'succeeded' | 'failed';
}

const initialState: SurveyState = {
  surveys: [],
  currentSurvey: null,
  currentContacts: [],
  loadingSurveys: 'idle',
  loadingCurrentSurvey: 'idle',
};

/** SLICE */
const surveySlice = createSlice({
  name: 'survey',
  initialState,
  reducers: {
    loadSurveys: (state, _action: PayloadAction<string>) => {
      state.loadingSurveys = 'pending';
    },
    loadSurveysSuccess: (state, action: PayloadAction<Survey[]>) => {
      state.loadingSurveys = 'succeeded';
      state.surveys = action.payload;
    },
    loadSurveysFailure: state => {
      state.loadingSurveys = 'failed';
    },
    loadCurrentSurvey: (state, _action: PayloadAction<string>) => {
      state.loadingSurveys = 'pending';
    },
    loadCurrentSurveySuccess: (state, action: PayloadAction<Survey>) => {
      state.loadingSurveys = 'succeeded';
      const survey = action.payload;
      state.currentSurvey = { survey: survey };

      const offer = survey.offer;
      if (offer) {
        const { contactOwner, contactCreator, contactRecipient, contactProjectOwner } = offer;
        state.currentContacts = [contactOwner, contactCreator, contactRecipient, contactProjectOwner]
          .filter(el => Boolean(el))
          .map(el => ({ contactObject: el }));
      }
    },
    loadCurrentSurveyFailure: state => {
      state.loadingSurveys = 'failed';
    },
    updateCurrentSurvey: (state, _action: PayloadAction<Survey>) => {
      state.currentSurvey = { survey: _action.payload };
    },
    saveCurrentSurvey: (state, _action: PayloadAction<StateType>) => {
      state.loadingCurrentSurvey = 'pending';
    },
    saveCurrentSurveySuccess: (state, action: PayloadAction<Survey>) => {
      const survey = action.payload;
      state.loadingCurrentSurvey = 'succeeded';
      state.surveys = [...state.surveys.filter(el => el.ID !== survey.ID), survey];
    },
    saveCurrentSurveyFailure: state => {
      state.loadingCurrentSurvey = 'failed';
    },
  },
});

export const surveyActions = surveySlice.actions;
export const surveyReducer = surveySlice.reducer;

/** SAGAS */
function* onLoadSurveys(action: PayloadAction<string>) {
  const isOnline: boolean = yield select((state: AppState) => state.offline.isOnline);
  const query = action.payload || '';

  if (isOnline === false) {
    const localSurveys: Survey[] = yield select((state: AppState) => state.survey.surveys);
    yield put(surveyActions.loadSurveysSuccess(localSurveys || []));
    return;
  }

  const surveys: Survey[] = yield call(() => Survey.getSurveys(`${query} order by lastModifiedAt DESC`));
  try {
    /* offer, measure and report only accessible before survey is uploaded to easydor */
    yield all([
      ...surveys
        .filter(survey => !(survey.state?.name === StateType.easydor || survey.state?.name === StateType.easydorError))
        .map(survey => call(() => survey.loadOffer())),
    ]);
    yield all([
      ...surveys
        .filter(survey => !(survey.state?.name === StateType.easydor || survey.state?.name === StateType.easydorError))
        .map(survey => call(() => survey.offer.loadMeasure())),
    ]);
    yield all([
      ...surveys
        .filter(survey => !(survey.state?.name === StateType.easydor || survey.state?.name === StateType.easydorError))
        .map(survey => call(() => survey.loadReports())),
    ]);
    yield all([...surveys.map(survey => call(() => survey.loadAttachments()))]);

    yield put(surveyActions.loadSurveysSuccess(surveys));
  } catch (err) {
    yield put(surveyActions.loadSurveysFailure());
  }
}

function* onLoadCurrentSurvey(action: PayloadAction<any>) {
  const isOnline: boolean = yield select((state: AppState) => state.offline.isOnline);
  const id = action.payload;

  if (isOnline === false) {
    const surveys: Survey[] = yield select((state: AppState) => state.survey.surveys);
    yield put(surveyActions.loadCurrentSurveySuccess(surveys.find(el => el.ID === id)));
    return;
  }

  try {
    const survey: Survey = new Survey();
    const href = `${Datastore.Instance.createModelHref(survey)}/${id}`;

    yield call(() => survey.load(href));
    yield call(() => survey.loadAttachments());
    /* offer and report only accessible before survey is uploaded to easydor */
    if (!(survey.state?.name === StateType.easydor || survey.state?.name === StateType.easydorError)) {
      yield call(() => survey.loadOffer());
      yield call(() => survey.loadReports());
    }

    yield put(surveyActions.loadCurrentSurveySuccess(survey));
  } catch (e) {
    console.error(e);
    yield put(notificationActions.showError('No entry found'));
    yield put(surveyActions.loadCurrentSurveyFailure());
  }
}

function* onSaveCurrentSurvey(action: PayloadAction<StateType>) {
  const survey: Survey = yield select((state: AppState) => state.survey.currentSurvey.survey);
  const isOnline: boolean = yield select((state: AppState) => state.offline.isOnline);

  const isCreation = Boolean(survey.ID) === false;
  const newStatus = action.payload;

  if (isOnline === false) {
    yield put(offlineActions.addSurveyIntoQueue({ survey: survey, newStatus, mutationType: isCreation ? 'create' : 'save' }));
    survey.state.name = StateType.unsavedChanges;

    if (isCreation) {
      yield put(push(`/survey/new/${survey.ID}`));
    }

    yield put(notificationActions.showSuccessMessage(i18n.t('assignment:status:offline')));
    yield put(surveyActions.saveCurrentSurveySuccess(survey));

    return;
  }

  try {
    const updatedSurvey = yield call(() => saveSurvey(survey, newStatus));
    yield put(surveyActions.updateCurrentSurvey(updatedSurvey));

    yield put(notificationActions.showSuccessMessage(i18n.t('use-cases:new-assignment:root:saved-notification:title')));
    yield put(surveyActions.saveCurrentSurveySuccess(updatedSurvey));
    yield put(push(`/surveys/my`));
  } catch (e) {
    survey.state.name = StateType.unsavedChanges;
    yield put(surveyActions.updateCurrentSurvey(survey));
    yield put(notificationActions.showError(i18n.t('use-cases:new-assignment:root:saving-failed-notification:body')));

    yield put(surveyActions.saveCurrentSurveyFailure());
  }
}

export function* surveySaga() {
  yield all([takeLatest(surveyActions.loadSurveys, onLoadSurveys)]);
  yield all([takeLatest(surveyActions.loadCurrentSurvey, onLoadCurrentSurvey)]);
  yield all([takeLatest(surveyActions.saveCurrentSurvey, onSaveCurrentSurvey)]);
}

/** TRANSFORMS */
export const surveyTransform = createTransform(
  (state: SurveyState) => {
    return {
      ...state,
      surveys: state.surveys.map(surveyToJson),
      currentSurvey: state.currentSurvey?.survey ? surveyToJson(state.currentSurvey?.survey) : null,
      currentContacts: [],
    };
  },
  json => {
    return {
      ...json,
      surveys: json.surveys && json.surveys.map(surveyFromJson),
      currentSurvey: null,
      currentContacts: [],
      loadingSurveys: 'idle',
      loadingCurrentSurvey: 'idle',
    };
  },
  { whitelist: ['survey'] }
);
