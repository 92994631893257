import React, { useEffect, useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import DropDown from '../../../Selection/DropDown';
import Property from '../../../../sdk/com/apiomat/frontend/missio/Property';
import { alphabeticalSort } from '../../../../utils/sort.util';
import { DropDownItem } from '../../../Selection/DropDownItem';
import { useFormik } from 'formik';
import { object, date, string, mixed } from 'yup';
import { useOfferFormContext } from '../../context/OfferFormContext';
import FormDatePicker from '../../../DatePicker/FormDatePicker';
import FormTextField from '../../InputFields/FormTextField';

export interface RealEstateProps {
  readOnlyMode: boolean;
  data: any;
  isReport?: boolean;
}

const RealEstate = (props: RealEstateProps) => {
  const { state, dispatch } = useOfferFormContext();
  const { readOnlyMode, data, isReport } = props;
  const { t } = useTranslation();

  const {
    start,
    end,
    measureDescription,
    propertyDescription,
    squareMetersOfTheProperty,
    constructionStage,
    constructionState,
    constructionExecution,
    propertyOwner,
    buildingOwner,
  }: Property = data.measure as Property;

  const validationSchema = useMemo(
    () =>
      object({
        start: isReport ? date() : date().required(t('mandatory_field')),
        end: date().required(t('mandatory_field')),
        measureDescription: string().required(t('mandatory_field')),
        propertyDescription: isReport ?
          mixed().oneOf(['building', 'finalization', 'renovation']) :
          mixed().oneOf(['building', 'finalization', 'renovation']).required(t('mandatory_field')),
        squareMetersOfTheProperty: isReport ? string() : string().required(t('mandatory_field')),
        constructionStage: string(),
        constructionState: isReport ? string() : string().required(t('mandatory_field')),
        constructionExecution: isReport ? string() : string().required(t('mandatory_field')),
        propertyOwner: isReport ? string(): string().required(t('mandatory_field')),
        buildingOwner: isReport ? string(): string().required(t('mandatory_field')),
      }),
    [t]
  );

  const formik = useFormik({
    initialValues: {
      start,
      end,
      measureDescription,
      propertyDescription: propertyDescription ?? 'building',
      squareMetersOfTheProperty,
      constructionStage,
      constructionState,
      constructionExecution,
      propertyOwner,
      buildingOwner,
    },
    enableReinitialize: true,
    validationSchema,
    validateOnMount: true,
    onSubmit: () => {},
  });

  const realEstateApplicationDescription: DropDownItem[] = useMemo(
    () =>
      [
        {
          key: 'building',
          value: t('mis:measure:real-estate:dropdown:building'),
        },
        {
          key: 'finalization',
          value: t('mis:measure:real-estate:dropdown:finalization'),
        },
        {
          key: 'renovation',
          value: t('mis:measure:real-estate:dropdown:renovation'),
        },
      ].sort(alphabeticalSort),
    [t]
  );

  useEffect(() => {
    if (!readOnlyMode) {
      if (!formik.isValidating) {
        dispatch({ subFormId: 'measure', errors: formik.errors, values: formik.values });
      }
    }
  }, [formik.errors, formik.values, dispatch, formik.isValidating, readOnlyMode, state]);

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <FormDatePicker
            id="start"
            name="start"
            label={t('input:label:measure:planned-project-start')}
            value={formik.values.start}
            onChange={value => formik.setFieldValue('start', value)}
            disabled={readOnlyMode}
            errorMessage={formik.errors.start}
          />
        </Grid>
        <Grid item xs={6}>
          <FormDatePicker
            id="end"
            name="end"
            label={t('input:label:measure:planned-project-end')}
            value={formik.values.end}
            minDate={formik.values.start}
            onChange={value => formik.setFieldValue('end', value)}
            disabled={readOnlyMode}
            errorMessage={formik.errors.end}
          />
        </Grid>
        <Grid item xs={12}>
          <FormTextField
            id="measureDescription"
            name="measureDescription"
            disabled={readOnlyMode}
            labelKey="input:label:measure:measure-description"
            value={formik.values.measureDescription}
            multiline
            rows={3}
            onChange={formik.handleChange}
            errorMessage={formik.errors.measureDescription}
          />
        </Grid>
        {!isReport && (
          <Grid item xs={12}>
            <DropDown
              labelKey="input:label:measure:real-estate-application-description"
              selectedItemKey={formik.values.propertyDescription}
              disabled={readOnlyMode}
              items={realEstateApplicationDescription}
              onSelectedItemChange={(value: string) => formik.setFieldValue('propertyDescription', value)}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <FormTextField
            id="squareMetersOfTheProperty"
            name="squareMetersOfTheProperty"
            type="number"
            disabled={readOnlyMode}
            labelKey="input:label:measure:mis:real-estate:square-metre-of-building"
            value={formik.values.squareMetersOfTheProperty}
            onChange={formik.handleChange}
            errorMessage={formik.errors.squareMetersOfTheProperty}
          />
        </Grid>
        <Grid item xs={12}>
          <FormTextField
            id="constructionStage"
            name="constructionStage"
            disabled={readOnlyMode}
            labelKey="input:label:measure:mis:real-estate:stage-possible"
            value={formik.values.constructionStage}
            onChange={formik.handleChange}
            errorMessage={formik.errors.constructionStage}
          />
        </Grid>
        <Grid item xs={12}>
          <FormTextField
            id="constructionExecution"
            name="constructionExecution"
            disabled={readOnlyMode}
            labelKey="input:label:measure:mis:real-estate:construction-execution"
            value={formik.values.constructionExecution}
            onChange={formik.handleChange}
            errorMessage={formik.errors.constructionExecution}
          />
        </Grid>
        <Grid item xs={12}>
          <FormTextField
            id="constructionState"
            name="constructionState"
            disabled={readOnlyMode}
            labelKey="input:label:measure:mis:real-estate:construction-state"
            value={formik.values.constructionState}
            onChange={formik.handleChange}
            errorMessage={formik.errors.constructionState}
          />
        </Grid>
        {!isReport && (
          <>
            <Grid item xs={12}>
              <FormTextField
                id="propertyOwner"
                name="propertyOwner"
                disabled={readOnlyMode}
                labelKey="input:label:measure:mis:real-estate:legal-owner-of-the-property"
                value={formik.values.propertyOwner}
                onChange={formik.handleChange}
                errorMessage={formik.errors.propertyOwner}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                id="buildingOwner"
                name="buildingOwner"
                disabled={readOnlyMode}
                labelKey="input:label:measure:mis:real-estate:legal-owner-of-the-building"
                value={formik.values.buildingOwner}
                onChange={formik.handleChange}
                errorMessage={formik.errors.buildingOwner}
              />
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
};

export default RealEstate;
