import { Grid } from '@material-ui/core';
import React, { useEffect, useMemo } from 'react';
import { useMwiMeasuresStyles } from './Styles';
import { useTranslation } from 'react-i18next';
import RadioButtons from '../../../Buttons/RadioButtons';
import DialogButton from '../../../Dialogs/DialogButton';
import MwiPublicationElectronic from '../../../../sdk/com/apiomat/frontend/missio/MwiPublicationElectronic';
import { subjects } from '../../../../value-maps/Subjects';
import DropDown from '../../../Selection/DropDown';
import { alphabeticalSort } from '../../../../utils/sort.util';
import { DropDownItem } from '../../../Selection/DropDownItem';
import { useFormik } from 'formik';
import { object, date, string, number } from 'yup';
import { useOfferFormContext } from '../../context/OfferFormContext';
import FormDatePicker from '../../../DatePicker/FormDatePicker';
import FormTextField from '../../InputFields/FormTextField';

export interface PublicationElectronicProps {
  readOnlyMode: boolean;
  data: any;
  isReport?: boolean;
}

const PublicationElectronic = (props: PublicationElectronicProps) => {
  const { state, dispatch } = useOfferFormContext();
  const { readOnlyMode, data, isReport } = props;
  const classes = useMwiMeasuresStyles();
  const { t } = useTranslation();

  const {
    start,
    end,
    measureDescription,
    description,
    title,
    topicMeaning,
    bookTitle,
    authorName,
    authorProfession,
    authorInfo,
    subject,
    expert1,
    studiesSponsor,
    finalGradeOfDissertation,
    website,
    websiteOperator,
    marketing,
    characterCount,
    publicationsPerYear,
    authorsShare,
    printingPress,
    publisher,
    isScholarshipApplicationElsewhere,
    hasReadAdvertisingStatement,
    hasReadConditions,
  } = data.measure as MwiPublicationElectronic;

  const validationSchema = useMemo(
    () =>
      object({
        start: date().required(t('mandatory_field')),
        end: date().required(t('mandatory_field')),
        measureDescription: string().required(t('mandatory_field')),

        description: string(),
        title: string(),
        topicMeaning: string(),
        bookTitle: string(),
        authorName: string(),
        authorProfession: string(),
        authorInfo: string(),
        subject: string(),
        expert1: string(),
        studiesSponsor: string(),
        finalGradeOfDissertation: string(),
        website: string().required(t('mandatory_field')),
        websiteOperator: string().required(t('mandatory_field')),
        marketing: string().required(t('mandatory_field')),
        characterCount: isReport ? string() : string().required(t('mandatory_field')),
        publicationsPerYear: string(),
        authorsShare: isReport ? string() : string().required(t('mandatory_field')),
        printingPress: string(),
        publisher: string(),

        isScholarshipApplicationElsewhere: isReport ? string() : string().required(t('mandatory_field')),
        hasReadAdvertisingStatement: isReport ? number() : number().equals([1], t('mandatory_field')),
        hasReadConditions: isReport ? number() : number().equals([1], t('mandatory_field')),
      }),
    [t]
  );

  const formik = useFormik({
    initialValues: {
      start,
      end,
      measureDescription,
      description,
      title,
      topicMeaning,
      bookTitle,
      authorName,
      authorProfession,
      authorInfo,
      subject,
      expert1,
      studiesSponsor,
      finalGradeOfDissertation,
      website,
      websiteOperator,
      marketing,
      characterCount,
      publicationsPerYear,
      authorsShare,
      printingPress,
      publisher,
      isScholarshipApplicationElsewhere,
      hasReadAdvertisingStatement,
      hasReadConditions,
    },
    enableReinitialize: true,
    validationSchema,
    validateOnMount: true,
    onSubmit: () => {},
  });

  useEffect(() => {
    if (!readOnlyMode) {
      if (!formik.isValidating) {
        dispatch({ subFormId: 'measure', errors: formik.errors, values: formik.values });
      }
    }
  }, [formik.errors, formik.values, dispatch, formik.isValidating, readOnlyMode, state]);

  const subjectItems: DropDownItem[] = useMemo(() => subjects.map(el => ({ key: el.key, value: t(el.key) })).sort(alphabeticalSort), [t]);

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <FormDatePicker
            id="start"
            name="start"
            label={t('input:label:measure:planned-project-start')}
            value={formik.values.start}
            onChange={(date: Date) => formik.setFieldValue('start', date)}
            disabled={readOnlyMode}
            errorMessage={formik.errors.start}
          />
        </Grid>
        <Grid item xs={6}>
          <FormDatePicker
            id="end"
            name="end"
            label={t('input:label:measure:planned-project-end')}
            value={formik.values.end}
            minDate={formik.values.start}
            onChange={(date: Date) => formik.setFieldValue('end', date)}
            disabled={readOnlyMode}
            errorMessage={formik.errors.end}
          />
        </Grid>
        <Grid item xs={12}>
          <FormTextField
            id="measureDescription"
            name="measureDescription"
            disabled={readOnlyMode}
            labelKey="input:label:measure:measure-description"
            value={formik.values.measureDescription}
            multiline
            rows={3}
            onChange={formik.handleChange}
            errorMessage={formik.errors.measureDescription}
          />
        </Grid>
        {!isReport && (
          <Grid item xs={12}>
            <FormTextField
              id="description"
              name="description"
              disabled={readOnlyMode}
              labelKey="input:label:measure:mwi:publication:publication-description"
              descriptionKey="info:measure:mwi:publication:publication-description"
              value={formik.values.description}
              multiline
              rows={3}
              onChange={formik.handleChange}
              errorMessage={formik.errors.description}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <FormTextField
            id="title"
            name="title"
            disabled={readOnlyMode}
            labelKey="input:label:measure:mwi:publication:topic-title"
            value={formik.values.title}
            onChange={formik.handleChange}
            errorMessage={formik.errors.title}
          />
        </Grid>
        {!isReport && (
          <Grid item xs={12}>
            <FormTextField
              id="topicMeaning"
              name="topicMeaning"
              disabled={readOnlyMode}
              labelKey="input:label:measure:mwi:publication:topic-meaning"
              descriptionKey="info:measure:mwi:publication:topic-meaning"
              value={formik.values.topicMeaning}
              multiline
              rows={3}
              onChange={formik.handleChange}
              errorMessage={formik.errors.topicMeaning}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <FormTextField
            id="bookTitle"
            name="bookTitle"
            disabled={readOnlyMode}
            labelKey="input:label:measure:mwi:publication:book-title"
            descriptionKey="info:measure:mwi:publication:book-title"
            value={formik.values.bookTitle}
            onChange={formik.handleChange}
            errorMessage={formik.errors.bookTitle}
          />
        </Grid>
        {!isReport && (
          <>
            <Grid item xs={12}>
              <FormTextField
                id="authorName"
                name="authorName"
                disabled={readOnlyMode}
                labelKey="input:label:measure:mwi:publication:author-name"
                descriptionKey="info:measure:mwi:publication:author-name"
                value={formik.values.authorName}
                onChange={formik.handleChange}
                errorMessage={formik.errors.authorName}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                id="authorProfession"
                name="authorProfession"
                disabled={readOnlyMode}
                labelKey="input:label:measure:mwi:publication:author-profession"
                descriptionKey="info:measure:mwi:publication:author-profession"
                value={formik.values.authorProfession}
                onChange={formik.handleChange}
                errorMessage={formik.errors.authorProfession}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                id="authorInfo"
                name="authorInfo"
                disabled={readOnlyMode}
                labelKey="input:label:measure:mwi:publication:author-info"
                descriptionKey="info:measure:mwi:publication:author-info"
                multiline
                rows={3}
                value={formik.values.authorInfo}
                onChange={formik.handleChange}
                errorMessage={formik.errors.authorInfo}
              />
            </Grid>
            <Grid item xs={12}>
              <DropDown
                labelKey="input:label:measure:mwi:publication:subject"
                helperTextKey="info:measure:mwi:publication:subject"
                disabled={readOnlyMode}
                selectedItemKey={formik.values.subject}
                items={subjectItems}
                onSelectedItemChange={(key: string) => formik.setFieldValue('subject', key)}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                id="expert1"
                name="expert1"
                disabled={readOnlyMode}
                labelKey="input:label:measure:mwi:publication:name-of-possible-appraisers"
                descriptionKey="info:measure:mwi:publication:name-of-possible-appraisers"
                multiline
                rows={3}
                value={formik.values.expert1}
                onChange={formik.handleChange}
                errorMessage={formik.errors.expert1}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                id="studiesSponsor"
                name="studiesSponsor"
                disabled={readOnlyMode}
                labelKey="input:label:measure:mwi:publication:promotion-of-study"
                multiline
                rows={3}
                value={formik.values.studiesSponsor}
                onChange={formik.handleChange}
                errorMessage={formik.errors.studiesSponsor}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                id="finalGradeOfDissertation"
                name="finalGradeOfDissertation"
                disabled={readOnlyMode}
                labelKey="input:label:measure:mwi:publication:grade-of-dissertation"
                descriptionKey="info:measure:mwi:publication:grade-of-dissertation"
                multiline
                rows={3}
                value={formik.values.finalGradeOfDissertation}
                onChange={formik.handleChange}
                errorMessage={formik.errors.finalGradeOfDissertation}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <FormTextField
            id="website"
            name="website"
            disabled={readOnlyMode}
            labelKey="input:label:measure:mwi:publication:website"
            descriptionKey="info:measure:mwi:publication:website"
            value={formik.values.website}
            onChange={formik.handleChange}
            errorMessage={formik.errors.website}
          />
        </Grid>
        <Grid item xs={12}>
          <FormTextField
            id="websiteOperator"
            name="websiteOperator"
            disabled={readOnlyMode}
            labelKey="input:label:measure:mwi:publication:operator-of-the-website"
            descriptionKey="info:measure:mwi:publication:operator-of-the-website"
            value={formik.values.websiteOperator}
            onChange={formik.handleChange}
            errorMessage={formik.errors.websiteOperator}
          />
        </Grid>
        <Grid item xs={12}>
          <FormTextField
            id="marketing"
            name="marketing"
            disabled={readOnlyMode}
            labelKey="input:label:measure:mwi:publication:marketing"
            descriptionKey="info:measure:mwi:publication:marketing"
            value={formik.values.marketing}
            onChange={formik.handleChange}
            errorMessage={formik.errors.marketing}
          />
        </Grid>
        <Grid item xs={12}>
          <FormTextField
            type="number"
            id="characterCount"
            name="characterCount"
            disabled={readOnlyMode}
            labelKey="input:label:measure:mwi:publication:specify-characters-including-spaces"
            value={formik.values.characterCount}
            onChange={formik.handleChange}
            errorMessage={formik.errors.characterCount}
          />
        </Grid>
        <Grid item xs={12}>
          <FormTextField
            id="publicationsPerYear"
            name="publicationsPerYear"
            type="number"
            disabled={readOnlyMode}
            labelKey="input:label:measure:mwi:publication:number-of-publications-per-year"
            value={formik.values.publicationsPerYear}
            onChange={formik.handleChange}
            errorMessage={formik.errors.publicationsPerYear}
          />
        </Grid>
        <Grid item xs={12}>
          <FormTextField
            id="authorsShare"
            name="authorsShare"
            disabled={readOnlyMode}
            labelKey="input:label:measure:mwi:publication:participation-author-sales"
            descriptionKey="info:measure:mwi:publication:participation-author-sales"
            multiline
            rows={3}
            value={formik.values.authorsShare}
            onChange={formik.handleChange}
            errorMessage={formik.errors.authorsShare}
          />
        </Grid>
        {!isReport && (
          <>
            <Grid item xs={12}>
              <FormTextField
                id="printingPress"
                name="printingPress"
                disabled={readOnlyMode}
                labelKey="input:label:measure:mwi:publication:printing-house-name-and-address"
                descriptionKey="info:measure:mwi:publication:printing-house-name-and-address"
                multiline
                rows={3}
                value={formik.values.printingPress}
                onChange={formik.handleChange}
                errorMessage={formik.errors.printingPress}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                id="publisher"
                name="publisher"
                disabled={readOnlyMode}
                labelKey="input:label:measure:mwi:publication:publisher-name-and-address"
                descriptionKey="info:measure:mwi:publication:publisher-name-and-address"
                multiline
                rows={3}
                value={formik.values.publisher}
                onChange={formik.handleChange}
                errorMessage={formik.errors.publisher}
              />
            </Grid>
            <div className={classes.questionContainer}>
              <div className={classes.questionScholarship}>
                <Grid item xs={3}>
                  <RadioButtons
                    title={t('question:label:measure:mwi:library:scholarship-application')}
                    value={formik.values.isScholarshipApplicationElsewhere}
                    disabled={readOnlyMode}
                    onSelectedValueChanged={(value: number) => formik.setFieldValue('isScholarshipApplicationElsewhere', value)}
                    errorMessage={formik.errors.isScholarshipApplicationElsewhere}
                  />
                </Grid>
              </div>
              <div className={classes.questionOne}>
                <Grid item xs={3}>
                  <RadioButtons
                    disabled={readOnlyMode}
                    title={t('question:label:mwi:advertisement')}
                    value={formik.values.hasReadAdvertisingStatement}
                    onSelectedValueChanged={(value: number) => formik.setFieldValue('hasReadAdvertisingStatement', value)}
                    errorMessage={formik.errors.hasReadAdvertisingStatement}
                  />
                </Grid>
                <DialogButton
                  className={classes.dialogButton}
                  textId={'button:label:mwi:measure:publication:advertisement'}
                  title={'button:label:mwi:advertisement'}
                />
              </div>
              <div className={classes.questionTwo}>
                <Grid item xs={3}>
                  <RadioButtons
                    disabled={readOnlyMode}
                    title={t('question:label:mwi:conditions')}
                    value={formik.values.hasReadConditions}
                    onSelectedValueChanged={(value: number) => formik.setFieldValue('hasReadConditions', value)}
                    errorMessage={formik.errors.hasReadConditions}
                  />
                </Grid>
                <DialogButton
                  className={classes.dialogButton}
                  textId={'button:label:mwi:measure:publication:conditions'}
                  title={'button:label:mwi:conditions'}
                />
              </div>
            </div>
          </>
        )}
      </Grid>
    </div>
  );
};

export default PublicationElectronic;
