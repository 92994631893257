import React from 'react';
import DropDown from '../../Selection/DropDown';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import { ContactType } from '../../../enums/ContactType';
import OwnerContactForm from './OwnerContactForm';
import GeneralContactForm from './GeneralContactForm';
import Contact from '../../../sdk/com/apiomat/frontend/missio/Contact';
import { contactTypeDropdown } from '../../../utils/contact.utils';

const useStyles = makeStyles(() => ({
  contentContainer: {
    padding: 20,
  },
  dropdownContainer: {
    margin: 40,
  },
}));

export interface EditContactFormProps {
  isReadonly: boolean;
  contact: Contact;
  updateContact: (contact: Contact, isValid: boolean) => void;
}

const EditContactForm = (props: EditContactFormProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { isReadonly, contact, updateContact } = props;

  const createContactFormContent = () => {
    switch (contact.contactType) {
      case ContactType.owner:
      case ContactType.beneficiaryInstitue:
        return <OwnerContactForm isReadonly={isReadonly} contact={contact} updateContact={updateContact} />;
      case ContactType.applicant:
      case ContactType.beneficiaryPrivate:
      case ContactType.projectManager:
      case ContactType.recommendedBy:
        return <GeneralContactForm isReadonly={isReadonly} contact={contact} updateContact={updateContact} />;
      default:
        return null;
    }
  };

  return (
    <div className={classes.contentContainer}>
      <DropDown
        labelKey="dropdown/contact-type"
        items={contactTypeDropdown(t)}
        disabled={isReadonly}
        selectedItemKey={contact.contactType}
        onSelectedItemChange={(value: string) => {
          contact.contactType = value;
          updateContact(contact, false);
        }}
      />
      <div className={classes.dropdownContainer}>{createContactFormContent()}</div>
    </div>
  );
};

export default EditContactForm;
