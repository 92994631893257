import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { DefaultTextField } from '../TextInputs/TextInputs';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import { alphabeticalSort } from '../../utils/sort.util';
import Contact from '../../sdk/com/apiomat/frontend/missio/Contact';
import DropDown from '../Selection/DropDown';

const useStyles = makeStyles(() => ({
  navIdColumn: {
    maxWidth: 200,
  },
  navId: {
    display: 'flex',
  },
}));

export interface NavContactProps {
  contact: Contact;
  setDialogContacts: (contacts: Contact[]) => void;
  dialogContacts: Contact[];
  isNavIdSaved: boolean[];
  toggleNavIdBox: (contact: Contact) => void;
  isSaved: (contact: Contact) => boolean;
}

const NavContactComponent = ({ contact, setDialogContacts, dialogContacts, isNavIdSaved, toggleNavIdBox, isSaved }: NavContactProps) => {
  const classes = useStyles();
  return (
    <>
      {contact.potentialNavContactIds?.length > 1 ? (
        <div className={classes.navIdColumn}>
          <DropDown
            items={contact.potentialNavContactIds.map((contactId: string) => ({ key: contactId, value: contactId })).sort(alphabeticalSort)}
            selectedItemKey={contact.navContactId}
            onSelectedItemChange={(value: string) => {
              contact.navContactId = value;
              setDialogContacts([...dialogContacts]);
            }}
          />
        </div>
      ) : (
        <div className={classes.navId}>
          <DefaultTextField
            key={contact.ID}
            className={classes.navIdColumn}
            value={contact.navContactId || ''}
            disabled={isNavIdSaved[dialogContacts?.indexOf(contact)]}
            onChange={event => {
              contact.navContactId = event.target.value;
              setDialogContacts([...dialogContacts]);
            }}
          />
          <Button onClick={() => toggleNavIdBox(contact)}>
            {isSaved(contact) ? <EditIcon color="primary" /> : <SaveIcon color="primary" />}
          </Button>
        </div>
      )}
    </>
  );
};

export default NavContactComponent;
