import { Grid } from '@material-ui/core';
import React, { useEffect, useMemo } from 'react';
import { useMwiMeasuresStyles } from './Styles';
import { useTranslation } from 'react-i18next';
import Offers from '../../../../sdk/com/apiomat/frontend/missio/Offers';
import MwiSpecialProject from '../../../../sdk/com/apiomat/frontend/missio/MwiSpecialProject';
import RadioButtons from '../../../Buttons/RadioButtons';
import DialogButton from '../../../Dialogs/DialogButton';
import { useFormik } from 'formik';
import { object, date, string, number } from 'yup';
import { useOfferFormContext } from '../../context/OfferFormContext';
import FormDatePicker from '../../../DatePicker/FormDatePicker';
import FormTextField from '../../InputFields/FormTextField';

export interface SpecialProjectProps {
  readOnlyMode: boolean;
  data: Offers;
  isReport?: boolean;
}

const SpecialProject = (props: SpecialProjectProps) => {
  const { state, dispatch } = useOfferFormContext();
  const { readOnlyMode, data, isReport } = props;
  const classes = useMwiMeasuresStyles();
  const { t } = useTranslation();

  const {
    start,
    end,
    measureDescription,
    isScholarshipApplicationElsewhere,
    hasReadAdvertisingStatement,
    hasReadConditions,
  } = data.measure as MwiSpecialProject;

  const validationSchema = useMemo(
    () =>
      object({
        start: date().required(t('mandatory_field')),
        end: date().required(t('mandatory_field')),
        measureDescription: string().required(t('mandatory_field')),
        isScholarshipApplicationElsewhere: isReport ? string() : string().required(t('mandatory_field')),
        hasReadAdvertisingStatement: isReport ? number() : number().equals([1], t('mandatory_field')),
        hasReadConditions: isReport ? number() : number().equals([1], t('mandatory_field')),
      }),
    [t, isReport]
  );

  const formik = useFormik({
    initialValues: {
      start,
      end,
      measureDescription,
      isScholarshipApplicationElsewhere,
      hasReadAdvertisingStatement,
      hasReadConditions,
    },
    enableReinitialize: true,
    validationSchema,
    validateOnMount: true,
    onSubmit: () => {},
  });

  useEffect(() => {
    if (!readOnlyMode) {
      if (!formik.isValidating) {
        dispatch({ subFormId: 'measure', errors: formik.errors, values: formik.values });
      }
    }
  }, [formik.errors, formik.values, dispatch, formik.isValidating, readOnlyMode, state]);

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <FormDatePicker
            id="start"
            name="start"
            label={t('input:label:measure:planned-project-start')}
            value={formik.values.start}
            onChange={(date: Date) => formik.setFieldValue('start', date)}
            disabled={readOnlyMode}
            errorMessage={formik.errors.start}
          />
        </Grid>
        <Grid item xs={6}>
          <FormDatePicker
            id="end"
            name="end"
            label={t('input:label:measure:planned-project-end')}
            value={formik.values.end}
            minDate={formik.values.start}
            onChange={(date: Date) => formik.setFieldValue('end', date)}
            disabled={readOnlyMode}
            errorMessage={formik.errors.end}
          />
        </Grid>
        <Grid item xs={12}>
          <FormTextField
            id="measureDescription"
            name="measureDescription"
            disabled={readOnlyMode}
            labelKey="input:label:measure:measure-description"
            value={formik.values.measureDescription}
            multiline
            rows={3}
            onChange={formik.handleChange}
            errorMessage={formik.errors.measureDescription}
          />
        </Grid>
        {!isReport && (
          <>
            <div className={classes.questionContainer}>
              <div className={classes.questionScholarship}>
                <Grid item xs={3}>
                  <RadioButtons
                    title={t('question:label:measure:mwi:library:scholarship-application')}
                    value={formik.values.isScholarshipApplicationElsewhere}
                    disabled={readOnlyMode}
                    onSelectedValueChanged={(value: number) => formik.setFieldValue('isScholarshipApplicationElsewhere', value)}
                    errorMessage={formik.errors.isScholarshipApplicationElsewhere}
                  />
                </Grid>
              </div>
              <div className={classes.questionOne}>
                <Grid item xs={3}>
                  <RadioButtons
                    disabled={readOnlyMode}
                    title={t('question:label:mwi:advertisement')}
                    value={formik.values.hasReadAdvertisingStatement}
                    onSelectedValueChanged={(value: number) => formik.setFieldValue('hasReadAdvertisingStatement', value)}
                    errorMessage={formik.errors.hasReadAdvertisingStatement}
                  />
                </Grid>
                <DialogButton
                  className={classes.dialogButton}
                  textId={'button:label:mwi:measure:library:advertisement'}
                  title={'button:label:mwi:advertisement'}
                />
              </div>
              <div className={classes.questionTwo}>
                <Grid item xs={3}>
                  <RadioButtons
                    disabled={readOnlyMode}
                    title={t('question:label:mwi:conditions')}
                    value={formik.values.hasReadConditions}
                    onSelectedValueChanged={(value: number) => formik.setFieldValue('hasReadConditions', value)}
                    errorMessage={formik.errors.hasReadConditions}
                  />
                </Grid>
                <DialogButton
                  className={classes.dialogButton}
                  textId={'button:label:mwi:measure:library:conditions'}
                  title={'button:label:mwi:conditions'}
                />
              </div>
            </div>
          </>
        )}
      </Grid>
    </div>
  );
};

export default SpecialProject;
