import React, { ChangeEvent } from 'react';
import PublishIcon from '@material-ui/icons/Publish';
import { Button, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

export interface DocumentPickerButtonProps {
  fileName?: string;
  onDocumentSelected: (file: string | ArrayBuffer | null, name: string, index?: number) => void;
}

export const DocumentPickerButton = (props: DocumentPickerButtonProps) => {
  const { onDocumentSelected, fileName } = props;

  const { t } = useTranslation();

  const reader = new FileReader();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.persist();
    if (event.target.files !== undefined) {
      reader.onloadend = () => {
        if (event.target.files !== undefined) {
          onDocumentSelected(reader.result, event.target.files[0].name);
        }
      };
      if (event.target.files.length !== 0) {
        reader.readAsArrayBuffer(event.target.files[0]);
      }
    }
  };

  return (
    <Button fullWidth color="primary" variant="outlined" component="label">
      <input type="file" hidden onChange={handleChange} />
      <Grid container justify="space-between" alignItems="center">
        <Grid item>
          <Typography>{fileName || t('attachments:button:upload')}</Typography>
        </Grid>
        <Grid item>
          <PublishIcon style={{ marginTop: 7 }} />
        </Grid>
      </Grid>
    </Button>
  );
};
