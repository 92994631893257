import React from 'react';
import { Dialog, DialogContent, DialogTitle, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { IconButton } from '@mui/material';
import { Close } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) => ({
  dialogTitleContainer: {
    justifyContent: 'flex-end',
    flexDirection: 'row',
    textAlign: 'center',
    borderBottom: '1px solid #dddddd',
  },
  rejectionMessage: {
    backgroundColor: theme.palette.error.main,
  },
  questionMessage: {
    backgroundColor: theme.palette.warning.main,
  },
  title: {
    color: '#f5f5f5',
  },
}));

export interface StatusMessageDialogProps {
  isOpen: boolean;
  comment: string;
  commentHistoryMap: object;
  commentHistoryKeys: string[];
  onCancelClick: () => void;
  isRejectionMessage?: boolean;
}

const StatusMessageDialog = (props: StatusMessageDialogProps) => {
  const { isOpen, comment, commentHistoryMap, commentHistoryKeys, onCancelClick, isRejectionMessage } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const styleOfTitleColor = isRejectionMessage ? classes.rejectionMessage : classes.questionMessage;
  const title = isRejectionMessage ? t('reject-dialog:title') : t('enquiry-dialog:title');

  const dialogGridContent = () => {
    if (!!comment && !commentHistoryMap) {
      return (
        <Grid item xs={ 12 }>
          <Typography variant="body1">{ comment }</Typography>
        </Grid>
      );
    } else if (!!commentHistoryMap) {
      return commentHistoryKeys.map(key => (
        <Grid container item justify="flex-start" alignItems="baseline" spacing={ 3 } key={ key }>
          <Grid item xs={ 3 }>
            <Typography variant="body2">{ key.slice(0, key.lastIndexOf(':')) }</Typography>
          </Grid>
          <Grid item xs={ 9 }>
            <Typography variant="body1">{ commentHistoryMap[key] }</Typography>
          </Grid>
        </Grid>
      ));
    }
  };
  return (
    <div>
      <Dialog open={ isOpen } fullWidth maxWidth="sm">
        <DialogTitle className={ classNames(classes.dialogTitleContainer, styleOfTitleColor) }>
          <IconButton
            aria-label="close"
            onClick={ onCancelClick }
            sx={ {
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[100]
            } }>
            <Close/>
          </IconButton>
          <Typography className={ classes.title } component="span" variant="h6">
            { title }
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container justify="flex-start" alignItems="center" spacing={ 3 }>
            { dialogGridContent() }
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default StatusMessageDialog;
