import { DropDownItem } from '../components/Selection/DropDownItem';
import { MwiMeasureTypes } from '../enums/MeasureTypes';

export const mwiMeasureDropDownItems: DropDownItem[] = [
  {
    key: MwiMeasureTypes.conferenceParticipation,
    value: 'label:measure:dropdown:general-conference-participation',
  },
  {
    key: MwiMeasureTypes.conference,
    value: 'label:measure:dropdown:general-conference',
  },
  {
    key: MwiMeasureTypes.facultyExchange,
    value: 'label:measure:dropdown:general-faculty-exchange',
  },
  {
    key: MwiMeasureTypes.library,
    value: 'label:measure:dropdown:general-library',
  },
  {
    key: MwiMeasureTypes.publicationElectronic,
    value: 'label:measure:dropdown:electronic-publication',
  },
  {
    key: MwiMeasureTypes.publicationPrint,
    value: 'label:measure:dropdown:print-publication',
  },
  {
    key: MwiMeasureTypes.researchStudy,
    value: 'label:measure:dropdown:mwi-research:study',
  },
  {
    key: MwiMeasureTypes.researchPerson,
    value: 'label:measure:dropdown:mwi-research:person',
  },
  {
    key: MwiMeasureTypes.researchInstitution,
    value: 'label:measure:dropdown:mwi-research:institution',
  },
  {
    key: MwiMeasureTypes.scholarship,
    value: 'label:measure:dropdown:general-scholarship',
  },
  {
    key: MwiMeasureTypes.translation,
    value: 'label:measure:dropdown:general-translation',
  },
  {
    key: MwiMeasureTypes.specialProject,
    value: 'label:measure:dropdown:special-project',
  },
];
