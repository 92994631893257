import React, { FunctionComponent, useMemo, useState } from 'react';
import { makeStyles, Tabs } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Action, useOfferFormContext } from '../../components/Forms/context/OfferFormContext';
import FormTab from '../../components/Forms/FormTab';
import { Box } from '@mui/material';
import MissingFieldsDialog from '../../components/Dialogs/MissingFieldsDialog';
import FormHead from '../../components/Forms/FormHead';
import { StateType } from '../../enums/StateType';
import { reportActions } from '../../store/report';
import MeasureForm from '../../components/Forms/Measures/MeasureForm';
import ContactsForm from '../../components/Forms/Contacts/ContactsForm';
import AchievementForm from '../../components/Forms/AssignmentForm/AchievementForm';
import EnvironmentForm from '../../components/Forms/AssignmentForm/EnvironmentForm';
import FormationForm from '../../components/Forms/AssignmentForm/FormationForm';
import ChildProtectionForm from '../../components/Forms/AssignmentForm/ChildProtectionForm';
import MonitoringForm from '../../components/Forms/AssignmentForm/MonitoringForm';
import CostPlanForm from '../../components/Forms/CostPlans/CostPlanForm';
import { Reports } from '../../sdk/com/apiomat/frontend/missio';
import { isOfferTypeMis } from '../../guards/OfferTypeGuard';
import { allAttachmentTypes, commonAttachmentTypes } from '../../value-maps/AttachmentTypes';
import { getMandatoryAttachmentKeys, mandatorySort } from '../../utils/attachment.util';
import { MandatoryAttachments } from '../../enums/MandatoryAttachments';
import { validateReport } from '../../validators/ReportValidation';
import { useDispatch } from 'react-redux';
import { InvalidResult } from '../../validators/OfferValidation';
import { isEmpty } from 'lodash';
import { ContactObj } from '../../store/offers';
import AttachmentForm from '../../components/Forms/AttachmentForm';

const useStyles = makeStyles(() => ({
  titleContainer: {
    flex: 1,
    display: 'flex',
    paddingTop: 15,
    paddingBottom: 15,
  },
  buttonsContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    justifyItems: 'space-between',
  },
  saveButton: {
    width: window.innerWidth / 12,
    display: 'flex',
    borderRadius: 0,
  },
  separator: {
    paddingLeft: 5,
    paddingRight: 5,
  },
  uploadButton: {
    width: window.innerWidth / 12,
    display: 'flex',
    borderRadius: 0,
  },
  titleTextFieldContainer: {
    flex: 1,
    display: 'flex',
    paddingTop: 10,
    paddingBottom: 10,
  },
  tabContainer: {
    paddingTop: 20,
    paddingBottom: 20,
  },
}));

type Props = {
  report: Reports;
  isSharedReport: boolean;
  currentContacts: ContactObj[];
  measureName: string;
  measureType: string;
  readOnly: boolean;
  title: string;
  onTitleChange: (string) => void;
};

interface TabItem {
  key: string;
  label: string;
  content?: React.ReactNode;
}

const NewReportTabs: FunctionComponent<Props> = ({
  report,
  isSharedReport,
  currentContacts,
  measureName,
  measureType,
  readOnly,
  title,
  onTitleChange,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { dispatch: formDispatch } = useOfferFormContext();

  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const [isMissingFieldsDialogActive, setMissingFieldsDialogActive] = useState<boolean>(false);
  const [missingFields, setMissingFields] = useState<InvalidResult[]>([]);

  const { state } = useOfferFormContext();

  /** for attachment form */
  const currentMeasureClassName: string = report?.measure?.modelName;
  const attachmentTypes = isOfferTypeMis(report) ? commonAttachmentTypes : allAttachmentTypes;
  const mandatoryAttachmentKeys = useMemo(
    () => getMandatoryAttachmentKeys(attachmentTypes, MandatoryAttachments.report, currentMeasureClassName),
    [attachmentTypes, currentMeasureClassName]
  );
  const sortedAttachmentItems = useMemo(() => mandatorySort(attachmentTypes, MandatoryAttachments.report, currentMeasureClassName, t), [
    attachmentTypes,
    currentMeasureClassName,
    t,
  ]);

  let tabs: TabItem[] = useMemo(
    () => [
      {
        key: 'measure',
        label: 'tab:title:measure',
        content: (
          <MeasureForm
            data={report}
            report={report}
            isReport={true}
            readOnlyMode={readOnly}
            measureName={report.measure?.name}
            measureType={report.measureType}
            onMeasureChange={function(name: string): void {
              throw new Error('Function not implemented.');
            }}
          />
        ),
      },
      {
        key: 'contacts',
        label: 'tab:title:contacts',
        content: <ContactsForm readOnlyMode={true} currentContacts={currentContacts} />,
      },
      {
        key: 'target',
        label: 'tab:title:achievement',
        content: <AchievementForm isReport={true} data={report} readOnlyMode={readOnly} />,
      },
      {
        key: 'environment',
        label: 'tab:title:environment',
        content: <EnvironmentForm readOnlyMode={readOnly} isReport={true} data={report} />,
      },
      {
        key: 'formation',
        label: 'tab:title:formation',
        content: <FormationForm readOnlyMode={readOnly} isReport={true} data={report} formation={report || ''} />,
      },
      {
        key: 'childProtection',
        label: 'tab:title:child-protection',
        content: <ChildProtectionForm readOnlyMode={readOnly} isReport={true} data={report} />,
      },
      {
        key: 'monitoring',
        label: 'tab:title:monitoring',
        content: <MonitoringForm isReport={true} data={report} readOnlyMode={readOnly} />,
      },
      {
        key: 'costPlan',
        label: 'tab:title:cost-plan',
        content: <CostPlanForm isReport={true} readOnlyMode={readOnly} />,
      },
      {
        key: 'attachments',
        label: 'tab:title:attachments',
        content: (
          <AttachmentForm
            data={report}
            isReport={true}
            readOnlyMode={readOnly}
            items={sortedAttachmentItems}
            mandatoryKeys={mandatoryAttachmentKeys}
            showDropDown={!readOnly && currentMeasureClassName !== 'Measure'}
          />
        ),
      },
    ],
    [currentContacts, currentMeasureClassName, report, mandatoryAttachmentKeys, readOnly, sortedAttachmentItems]
  );

  if (isSharedReport) {
    tabs = tabs.filter(tab => tab.key !== 'contacts');
  }

  const onSave = () => {
    dispatch(reportActions.saveCurrentReport(StateType.cloud));
  };

  const handleTitleChange = (title: string) => {
    const formError = isEmpty(title);
    const actionObject: Action = formError
      ? {
          subFormId: 'title',
          errors: { title: 'missing title' },
          values: {},
        }
      : {
          subFormId: 'title',
          errors: {},
          values: {},
        };
    formDispatch(actionObject);
    onTitleChange(title);
  };

  const onAccept = () => {};

  const openRejectionDialog = () => {};

  const onUpload = (sendAgain?: boolean) => {
    const missingFields = validateReport(report);
    if (missingFields.length > 0) {
      setMissingFieldsDialogActive(true);
      setMissingFields(missingFields);
    } else {
      const newStatus = sendAgain ? StateType.approved : StateType.completed;
      /*
      if (report.state?.name === StateType.enquiry) {
        newStatus = StateType.enquiryAnswered;
      }
      */
      dispatch(reportActions.saveCurrentReport(newStatus));
    }
  };

  return (
    <>
      <FormHead
        mode={readOnly ? 'review-admin' : 'edit'}
        title={report?.offerName}
        isReadonly={readOnly}
        showAdminButtons={false}
        showUserButtons={false}
        isEasydorError={report?.state?.name === StateType.easydorError}
        onTitleChange={handleTitleChange}
        titleDescription={t('create-report')}
        onSave={onSave}
        onUpload={onUpload}
        onAccept={onAccept}
        onReject={openRejectionDialog}
        isAssignment={false}
      />
      <div className={classes.tabContainer}>
        <Tabs variant="scrollable" scrollButtons="on" value={activeTabIndex} onChange={(event, tabIndex) => setActiveTabIndex(tabIndex)}>
          {tabs.map((tab, index) => (
            <FormTab
              key={tab.key}
              disabled={measureName == null && index !== activeTabIndex}
              hideIcon={tab.key === 'contacts' ? true : readOnly}
              disableRipple
              label={t(tab.label)}
              errorCount={state[tab.key]?.errorCount ?? undefined}
            />
          ))}
        </Tabs>
        {tabs.map((tab, index) => (
          <Box key={`${tab.key}-${title}`} sx={{ display: index !== activeTabIndex ? 'none' : 'initial' }}>
            {tab.content}
          </Box>
        ))}
        {isMissingFieldsDialogActive && (
          <MissingFieldsDialog invalidResults={missingFields} onCancelButtonClick={() => setMissingFieldsDialogActive(false)} />
        )}
      </div>
    </>
  );
};

export default NewReportTabs;
