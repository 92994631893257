import React from 'react';
import { Divider, List, ListSubheader, makeStyles } from '@material-ui/core';
import packageInfo from '../../../package.json';

const useStyles = makeStyles(theme => ({
  versionHeader: {
    paddingRight: 16,
    paddingLeft: 16,
    color: 'rgba(255,255,255,1)',
    fontSize: '0.7rem',
    lineHeight: '3.5rem',
  },
}));

const AppVersion = () => {
  const classes = useStyles();

  return (
    <>
      <List
        key={ 'version-menu' }
        subheader={
          <ListSubheader className={ classes.versionHeader } component="div">
            { `v${ packageInfo.version }` }
          </ListSubheader>
        }
      >
        <Divider/>
      </List>
    </>
  );
};

export default AppVersion;
