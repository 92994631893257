export enum ErrorMessage {
  STATE_WRITE_UNAUTHORIZED = 'STATE_WRITE_UNAUTHORIZED',
  ATTRIBUTE_VALIDATION_ERROR = 'model-validation-error',
  EMAIL_VALIDATION_ERROR = 'email-validation-error',
  USER_NOT_FOUND = 'user-not-found',
  USER_IS_INACTIVE = 'login-failed-inactive',
  ACCOUNT_UNVERIFIED = 'account-unverified',
  UNSUPPORTED_STATE_TRANSITION = 'UNSUPPORTED_STATE_TRANSITION',
  DOCUMENTS_TRANSFER_FAILED = 'DOCUMENTS_TRANSFER_FAILED',
  MEASURE_DOES_NOT_EXIST = 'MEASURE_DOES_NOT_EXIST',
}
