import React from 'react';
import { FormControl, InputLabel, makeStyles, MenuItem, Select } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { FlagDE, FlagFR, FlagPT, FlagUS } from '../Logo/Logo';

const useStyles = makeStyles({
  languageSelectorMenuItem: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
});

export default () => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  return (
    <FormControl fullWidth>
      <InputLabel htmlFor="culture-select">{t('select:label:language')}</InputLabel>
      <Select
        value={i18n.language}
        onChange={e => i18n.changeLanguage(e.target.value as string)}
        inputProps={{
          name: 'culture-select',
          id: 'culture-select',
        }}
      >
        <MenuItem value="de-DE">
          <div className={classes.languageSelectorMenuItem}>
            <FlagDE />{t('text:culture-de_de')}
          </div>
        </MenuItem>
        <MenuItem value="en-US">
          <div className={classes.languageSelectorMenuItem}>
            <FlagUS />{t('text:culture-en_us')}
          </div>
        </MenuItem>
        <MenuItem value="fr-FR">
          <div className={classes.languageSelectorMenuItem}>
            <FlagFR />{t('text:culture-fr_fr')}
          </div>
        </MenuItem>
        <MenuItem value="pt-PT">
          <div className={classes.languageSelectorMenuItem}>
            <FlagPT />{t('text:culture-pt_pt')}
          </div>
        </MenuItem>
      </Select>
    </FormControl>
  );
};
