import { CurrenciesValueMap } from './CurrencyValueMap';

export const currencies: CurrenciesValueMap[] = [
  { key: 'AED', value: 'currencies:afd' },
  { key: 'AFN', value: 'currencies:afn' },
  { key: 'ALL', value: 'currencies:all' },
  { key: 'AMD', value: 'currencies:amd' },
  { key: 'ANG', value: 'currencies:ang' },
  { key: 'AOA', value: 'currencies:aoa' },
  { key: 'ARS', value: 'currencies:ars' },
  { key: 'AUD', value: 'currencies:aud' },
  { key: 'AWG', value: 'currencies:awg' },
  { key: 'AZN', value: 'currencies:azn' },
  { key: 'BAM', value: 'currencies:bam' },
  { key: 'BBD', value: 'currencies:bbd' },
  { key: 'BDT', value: 'currencies:bdt' },
  { key: 'BGL', value: 'currencies:bgl' },
  { key: 'BHD', value: 'currencies:bhd' },
  { key: 'BIF', value: 'currencies:bif' },
  { key: 'BMD', value: 'currencies:bmd' },
  { key: 'BND', value: 'currencies:bnd' },
  { key: 'BOB', value: 'currencies:bob' },
  { key: 'BRL', value: 'currencies:brl' },
  { key: 'BSD', value: 'currencies:bsd' },
  { key: 'BTN', value: 'currencies:btn' },
  { key: 'BWP', value: 'currencies:bwp' },
  { key: 'BYN', value: 'currencies:byn' },
  { key: 'BZD', value: 'currencies:bzd' },
  { key: 'CAD', value: 'currencies:cad' },
  { key: 'CDF', value: 'currencies:cdf' },
  { key: 'CHF', value: 'currencies:chf' },
  { key: 'CLP', value: 'currencies:clp' },
  { key: 'CNY', value: 'currencies:cny' },
  { key: 'COP', value: 'currencies:cop' },
  { key: 'CRC', value: 'currencies:crc' },
  { key: 'CUP', value: 'currencies:cup' },
  { key: 'CVE', value: 'currencies:cve' },
  { key: 'CZK', value: 'currencies:czk' },
  { key: 'DJF', value: 'currencies:djf' },
  { key: 'DKK', value: 'currencies:dkk' },
  { key: 'DOP', value: 'currencies:dop' },
  { key: 'DZD', value: 'currencies:dzd' },
  { key: 'EGP', value: 'currencies:egp' },
  { key: 'ERN', value: 'currencies:ern' },
  { key: 'ETB', value: 'currencies:etb' },
  { key: 'EUR', value: 'currencies:eur' },
  { key: 'FJD', value: 'currencies:fjd' },
  { key: 'FKP', value: 'currencies:fkp' },
  { key: 'GBP', value: 'currencies:gbp' },
  { key: 'GEL', value: 'currencies:gel' },
  { key: 'GHS', value: 'currencies:ghs' },
  { key: 'GIP', value: 'currencies:gip' },
  { key: 'GMD', value: 'currencies:gmd' },
  { key: 'GNF', value: 'currencies:gnf' },
  { key: 'GTQ', value: 'currencies:gtq' },
  { key: 'GYD', value: 'currencies:gyd' },
  { key: 'HKD', value: 'currencies:hkd' },
  { key: 'HNL', value: 'currencies:hnl' },
  { key: 'HRK', value: 'currencies:hrk' },
  { key: 'HTG', value: 'currencies:htg' },
  { key: 'HUF', value: 'currencies:huf' },
  { key: 'IDR', value: 'currencies:idr' },
  { key: 'ILS', value: 'currencies:ils' },
  { key: 'INR', value: 'currencies:inr' },
  { key: 'IQD', value: 'currencies:iqd' },
  { key: 'IRR', value: 'currencies:irr' },
  { key: 'ISK', value: 'currencies:isk' },
  { key: 'JMD', value: 'currencies:jmd' },
  { key: 'JOD', value: 'currencies:jod' },
  { key: 'JPY', value: 'currencies:jpy' },
  { key: 'KES', value: 'currencies:kes' },
  { key: 'KGS', value: 'currencies:kgs' },
  { key: 'KHR', value: 'currencies:khr' },
  { key: 'KMF', value: 'currencies:kmf' },
  { key: 'KPW', value: 'currencies:kpw' },
  { key: 'KRW', value: 'currencies:krw' },
  { key: 'KWD', value: 'currencies:kwd' },
  { key: 'KYD', value: 'currencies:kyd' },
  { key: 'KZT', value: 'currencies:kzt' },
  { key: 'LAK', value: 'currencies:lak' },
  { key: 'LBP', value: 'currencies:lbp' },
  { key: 'LKR', value: 'currencies:lkr' },
  { key: 'LRD', value: 'currencies:lrd' },
  { key: 'LSL', value: 'currencies:lsl' },
  { key: 'LYD', value: 'currencies:lyd' },
  { key: 'MAD', value: 'currencies:mad' },
  { key: 'MGA', value: 'currencies:mag' },
  { key: 'MDL', value: 'currencies:mdl' },
  { key: 'MMK', value: 'currencies:mmk' },
  { key: 'MNT', value: 'currencies:mnt' },
  { key: 'MOP', value: 'currencies:mop' },
  { key: 'MRU', value: 'currencies:mru' },
  { key: 'MUR', value: 'currencies:mur' },
  { key: 'MVR', value: 'currencies:mvr' },
  { key: 'MWK', value: 'currencies:mwk' },
  { key: 'MXN', value: 'currencies:mxn' },
  { key: 'MYR', value: 'currencies:myr' },
  { key: 'MZN', value: 'currencies:mzn' },
  { key: 'NAD', value: 'currencies:nad' },
  { key: 'NGN', value: 'currencies:ngn' },
  { key: 'NIO', value: 'currencies:nio' },
  { key: 'NOK', value: 'currencies:nok' },
  { key: 'NPR', value: 'currencies:npr' },
  { key: 'NZD', value: 'currencies:nzd' },
  { key: 'OMR', value: 'currencies:omr' },
  { key: 'PAB', value: 'currencies:pab' },
  { key: 'PEN', value: 'currencies:pen' },
  { key: 'PGK', value: 'currencies:pgk' },
  { key: 'PHP', value: 'currencies:php' },
  { key: 'PKR', value: 'currencies:pkr' },
  { key: 'PLN', value: 'currencies:pln' },
  { key: 'PYG', value: 'currencies:pyg' },
  { key: 'QAR', value: 'currencies:qar' },
  { key: 'RON', value: 'currencies:ron' },
  { key: 'RUB', value: 'currencies:rub' },
  { key: 'RWF', value: 'currencies:rwf' },
  { key: 'SAR', value: 'currencies:sar' },
  { key: 'SBD', value: 'currencies:sbd' },
  { key: 'SCR', value: 'currencies:scr' },
  { key: 'SDG', value: 'currencies:sdg' },
  { key: 'SEK', value: 'currencies:sek' },
  { key: 'SGD', value: 'currencies:sgd' },
  { key: 'SHP', value: 'currencies:shp' },
  { key: 'SLL', value: 'currencies:sll' },
  { key: 'SOS', value: 'currencies:sos' },
  { key: 'SRD', value: 'currencies:srd' },
  { key: 'SSP', value: 'currencies:ssp' },
  { key: 'STN', value: 'currencies:stn' },
  { key: 'SYP', value: 'currencies:syp' },
  { key: 'SZL', value: 'currencies:szl' },
  { key: 'THB', value: 'currencies:thb' },
  { key: 'TJS', value: 'currencies:tjs' },
  { key: 'TMT', value: 'currencies:tmt' },
  { key: 'TND', value: 'currencies:tnd' },
  { key: 'TOP', value: 'currencies:top' },
  { key: 'TRY', value: 'currencies:try' },
  { key: 'TTD', value: 'currencies:ttd' },
  { key: 'TWD', value: 'currencies:twd' },
  { key: 'TZS', value: 'currencies:tzs' },
  { key: 'UAH', value: 'currencies:uah' },
  { key: 'UGX', value: 'currencies:ugx' },
  { key: 'USD', value: 'currencies:usd' },
  { key: 'UYU', value: 'currencies:uyu' },
  { key: 'UZS', value: 'currencies:uzs' },
  { key: 'VEF', value: 'currencies:vef' },
  { key: 'VND', value: 'currencies:vnd' },
  { key: 'VUV', value: 'currencies:vuv' },
  { key: 'WST', value: 'currencies:wst' },
  { key: 'XAF', value: 'currencies:xaf' },
  { key: 'XCD', value: 'currencies:xcd' },
  { key: 'XOF', value: 'currencies:xof' },
  { key: 'XPF', value: 'currencies:xpf' },
  { key: 'YER', value: 'currencies:yer' },
  { key: 'ZAR', value: 'currencies:zar' },
  { key: 'ZMW', value: 'currencies:zmw' },
]
  .filter(x => x.key !== undefined)
  .sort((a, b) => a.key.localeCompare(b.key));
