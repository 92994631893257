import React from 'react';
import { Grid, makeStyles, Theme } from '@material-ui/core';
import SingleLineDataGrid from '../../DataGrid/SingleLineDataGrid';
import { DataGridColumn } from '../../DataGrid/DataGrid';
import { useTranslation } from 'react-i18next';
import { DefaultTextField, DefaultNumberField } from '../../TextInputs/TextInputs';
import CostPlanItem from '../../../sdk/com/apiomat/frontend/missio/CostPlanItem';
import { costPlanCategories } from '../../../value-maps/CostPlanCategories';
import { costPlanTypes } from '../../../value-maps/CostPlanTypes';
import { currencies } from '../../../value-maps/Currencies';
import CostPlan from '../../../sdk/com/apiomat/frontend/missio/CostPlan';
import { createdAtSort } from "../../../utils/sort.util";

const useStyles = makeStyles((theme: Theme) => ({
  dataGridContainer: {
    flex: 1,
    display: 'flex',
    overflowX: 'auto',
    overflowY: 'hidden',
    marginTop: theme.spacing(1),
    padding: theme.spacing(0, 2),
  },
  textFields: {
    flex: 1,
    minWidth: 100,
    paddingTop: 8,
    marginBottom: 8,
  },
  numberFields: {
    flex: 1,
    minWidth: 100,
  },
  dropDowns: {
    flex: 1,
    minWidth: 100,
  },
  term: {
    minWidth: 150,
  },
  amount: {
    maxWidth: 100,
    minWidth: 100,
  },
  classic: {
    maxWidth: 120,
    minWidth: 100,
  },
  multiply: {
    maxWidth: 100,
    minWidth: 70,
  },
}));

export interface ActualCostPlanDataGridProps {
  costPlan: CostPlan;
  isReadonly: boolean;
  updateCostPlan: (costPlan: CostPlan) => void;
}

const ActualCostPlanDataGrid = (props: ActualCostPlanDataGridProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isReadonly, costPlan, updateCostPlan } = props;


  const sortedCostPlanItems = [...costPlan.costPlanItems].sort(createdAtSort);

  const columns: DataGridColumn<CostPlanItem>[] = [
    {
      header: t('label:cost-plan:category'),
      valueExtractor: dataRecord => dataRecord.costPlanCategory,
      component: dataRecord => (
        <div className={classes.dropDowns}>
          <DefaultTextField
            fullWidth
            value={t(costPlanCategories.find(category => dataRecord.costPlanCategory === category.key)?.value || '')}
            disabled={true}
            className={classes.textFields}
          />
        </div>
      ),
    },
    {
      header: t('label:cost-plan:cost-type'),
      valueExtractor: dataRecord => dataRecord.costPlanType,
      component: dataRecord => (
        <div className={classes.dropDowns}>
          <DefaultTextField
            fullWidth
            value={t(costPlanTypes.find(type => dataRecord.costPlanType === type.key)?.value || '')}
            disabled={true}
            className={classes.dropDowns}
          />
        </div>
      ),
    },
    {
      header: (
        <div className={classes.term}>
          <div>{t('label:cost-plan:term')}</div>
          <div>{t('label:cost-plan:actual-term')}</div>
        </div>
      ),
      valueExtractor: dataRecord => dataRecord.description,
      component: dataRecord => (
        <div className={classes.term}>
          <DefaultTextField fullWidth value={dataRecord.description} disabled={true} />
          <DefaultTextField
            fullWidth
            value={dataRecord.actualDescription}
            onChange={event => {
              dataRecord.actualDescription = event.target.value;
              updateCostPlan(costPlan);
            }}
            disabled={isReadonly}
            className={classes.textFields}
          />
        </div>
      ),
    },
    {
      header: (
        <div className={classes.classic}>
          <div>{t('label:cost-plan:unit')}</div>
          <div>{t('label:cost-plan:actual-unit')}</div>
        </div>
      ),
      valueExtractor: dataRecord => dataRecord.unit,
      component: dataRecord => (
        <div className={classes.classic}>
          <DefaultTextField fullWidth value={dataRecord.unit} disabled={true} />
          <DefaultTextField
            fullWidth
            className={classes.textFields}
            value={dataRecord.actualUnit}
            onChange={event => {
              dataRecord.actualUnit = event.target.value;
              updateCostPlan(costPlan);
            }}
            disabled={isReadonly}
          />
        </div>
      ),
    },
    {
      header: (
        <div className={classes.classic}>
          <div>{t('label:cost-plan:amount')}</div>
          <div>{t('label:cost-plan:actual-amount')}</div>
        </div>
      ),
      valueExtractor: dataRecord => dataRecord.amount,
      component: dataRecord => (
        <div className={classes.classic}>
          <DefaultNumberField fullWidth value={dataRecord.amount} className={classes.numberFields} disabled={true} />
          <DefaultNumberField
            fullWidth
            value={dataRecord.actualAmount}
            className={classes.numberFields}
            onValueChanged={(value: number) => {
              dataRecord.actualAmount = value;
              updateCostPlan(costPlan);
            }}
            disabled={isReadonly}
          />
        </div>
      ),
    },
    {
      header: <div className={classes.multiply}>{t('label:cost-plan:unit-of-multiply')}</div>,
      valueExtractor: dataRecord => dataRecord.unitOfFactor,
      component: dataRecord => (
        <div className={classes.classic}>
          <DefaultTextField fullWidth value={dataRecord.unitOfFactor} disabled={true} />
          <DefaultTextField
            className={classes.textFields}
            fullWidth
            value={dataRecord.actualUnitOfFactor}
            onChange={event => {
              dataRecord.actualUnitOfFactor = event.target.value;
              updateCostPlan(costPlan);
            }}
            disabled={isReadonly}
          />
        </div>
      ),
    },
    {
      header: t('label:cost-plan:optional-multiply'),
      valueExtractor: dataRecord => dataRecord.optionalFactor,
      component: dataRecord => (
        <div className={classes.classic}>
          <DefaultNumberField fullWidth className={classes.numberFields} value={dataRecord.optionalFactor} disabled={true} />
          <DefaultNumberField
            fullWidth
            className={classes.numberFields}
            value={dataRecord.actualOptionalFactor}
            onValueChanged={(value: number) => {
              dataRecord.actualOptionalFactor = value;
              updateCostPlan(costPlan);
            }}
            disabled={isReadonly}
          />
        </div>
      ),
    },
    {
      header: t('label:cost-plan:cost-per-unit'),
      valueExtractor: dataRecord => dataRecord.costPerUnit,
      component: dataRecord => (
        <div className={classes.classic}>
          <DefaultNumberField fullWidth className={classes.numberFields} value={dataRecord.costPerUnit} disabled={true} />
          <DefaultNumberField
            fullWidth
            className={classes.numberFields}
            value={dataRecord.actualCostPerUnit}
            onValueChanged={(value: number) => {
              dataRecord.actualCostPerUnit = String(value);
              updateCostPlan(costPlan);
            }}
            disabled={isReadonly}
          />
        </div>
      ),
    },
    {
      header: t('label:cost-plan:national-currency-grid'),
      valueExtractor: dataRecord => dataRecord.nationalCurrencyGrid.name,
      component: dataRecord => (
        <div className={classes.term}>
          <DefaultTextField
            fullWidth
            value={t(currencies.find(currency => dataRecord.currency === currency.key)?.value || '')}
            disabled={true}
            className={classes.textFields}
          />
        </div>
      ),
    },
    {
      header: t('label:cost-plan:total-sum'),
      valueExtractor: dataRecord => dataRecord.totalSum,
      component: dataRecord => (
        <div className={classes.classic}>
          <DefaultTextField fullWidth disabled={true} value={new Intl.NumberFormat().format(dataRecord.totalSum)} />
          <DefaultTextField
            fullWidth
            disabled={isReadonly}
            value={new Intl.NumberFormat().format(dataRecord.actualTotalSum || 0)}
            className={classes.textFields}
          />
        </div>
      ),
    },
    {
      header: t('label:cost-plan:total-sum-eur'),
      valueExtractor: dataRecord => dataRecord.totalSumEUR,
      component: dataRecord => (
        <div className={classes.classic}>
          <DefaultTextField
            fullWidth
            value={new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(dataRecord.totalSumEUR)}
            disabled={true}
          />
          <DefaultTextField
            fullWidth
            value={new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(dataRecord.actualTotalSumEUR || 0)}
            disabled={isReadonly}
            className={classes.textFields}
          />
        </div>
      ),
    },
  ];

  return (
    <div className={classes.dataGridContainer}>
      <Grid container spacing={3}>
        <SingleLineDataGrid
          columns={columns}
          datasource={sortedCostPlanItems}
          emptyMessage={{
            title: '',
            component: columns.map(x => x.component),
          }}
        />
      </Grid>
    </div>
  );
};

export default ActualCostPlanDataGrid;
