import Reports from '../sdk/com/apiomat/frontend/missio/Reports';

export const shareReport = async (report: Reports): Promise<Reports> => {
  await report.save();
  return report;
};

export const deleteSharedUser = async (sharedUser: string, report: Reports): Promise<Reports> => {
  report.sharedWith = report.sharedWith.filter(item => item !== sharedUser);
  await report.save();
  return report;
};
