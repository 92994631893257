import React from 'react';
import { PrimaryButton } from '../../Buttons/Buttons';
import { useTranslation } from 'react-i18next';
import FinancialPlanDataGrid from './FinancialPlanDataGrid';
import CostPlan from '../../../sdk/com/apiomat/frontend/missio/CostPlan';
import { Grid, makeStyles, Theme } from '@material-ui/core';
import { DefaultTextField } from '../../TextInputs/TextInputs';
import FinancialPlanItem from '../../../sdk/com/apiomat/frontend/missio/FinancialPlanItem';

const useStyles = makeStyles((theme: Theme) => ({
  tabContainer: {
    paddingTop: 20,
  },
  contentContainer: {
    paddingTop: 10,
    paddingBottom: 30,
    alignItems: 'flex-end',
  },
  addNewLineBtn: {
    marginTop: theme.spacing(2)
  }
}));

export interface EditFinancialPlanFormProps {
  isReadonly: boolean;
  costPlan: CostPlan;
  updateCostPlan: (costPlan: CostPlan) => void;
}

const EditFinancialPlanForm = (props: EditFinancialPlanFormProps) => {
  const { t } = useTranslation();
  const { isReadonly, costPlan, updateCostPlan } = props;

  const classes = useStyles();

  const handleAddButtonClick = () => {
    const newItem = new FinancialPlanItem();
    (newItem as any).dao.createdAt = new Date();
    newItem.planType = '';
    newItem.totalAmount = 0;
    newItem.currency = '';
    newItem.approvedAmount = 0;
    newItem.decision = '';
    newItem.requestedAmount = 0;
    newItem.description = '';
    newItem.cofinancer = '';
    costPlan.financialPlan.financialPlanItems.push(newItem);

    updateCostPlan(costPlan);
  };

  return (
    <div className={classes.tabContainer}>
      <Grid container spacing={3} className={classes.contentContainer}>
        <Grid item xs={12}>
          <DefaultTextField
            labelKey="label:cost-plan:financial-plan:total-sum-eur"
            value={new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(costPlan.financialPlan.sumEuro)}
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <DefaultTextField
            labelKey="label:cost-plan:financial-plan:total-cost-eur"
            value={new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(costPlan.sum)}
            disabled
          />
        </Grid>
      </Grid>
      <PrimaryButton onClick={handleAddButtonClick} disabled={isReadonly} className={classes.addNewLineBtn}>
        {t('dialog:button:label:add-new-line')}
      </PrimaryButton>
      <FinancialPlanDataGrid isReadonly={isReadonly} costPlan={costPlan} updateCostPlan={updateCostPlan} />
    </div>
  );
};

export default EditFinancialPlanForm;
