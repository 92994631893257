import { makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import { StateType } from '../../enums/StateType';
import { CheckCircle, Help, Warning } from '@material-ui/icons';

const reportIconStatusStyles = makeStyles((theme: Theme) => ({
  statusContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  warningIcon: {
    color: theme.palette.warning.main,
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.warning.dark,
    },
  },
  helpIcon: {
    color: theme.palette.info.main,
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.info.dark,
    },
  },
  checkIcon: {
    color: theme.palette.success.main,
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.success.dark,
    },
  },
}));

export interface ReportIconStatusProps {
  state: StateType;
}

export default (props: ReportIconStatusProps) => {
  const { state } = props;
  const classes = reportIconStatusStyles();

  if (state === StateType.completed) {
    return <Warning className={classes.warningIcon} />
  } else if (
    state === StateType.easydor ||
    state === StateType.easydorInProgress ||
    state === StateType.approved
  ) {
    return <CheckCircle className={classes.checkIcon} />
  } else {
    return <Help className={classes.helpIcon} />
  }
};
