import Audit from '../sdk/com/apiomat/frontend/missio/Audit';
import { StateType } from '../enums/StateType';
import { postFile } from './file.utils';

export const saveAudit = async (audit: Audit, newStatus: StateType): Promise<Audit> => {
  /** Upload attachments first so that they all get transfered to easydor */
  const attachmentsToUpload = audit.attachments.filter(el => Boolean((el as any).dao.file) && Boolean((el as any).dao.isDeleted) === false);
  const attachmentsToDelete = audit.attachments.filter(el => Boolean((el as any).dao.isDeleted) && Boolean(el.ID));

  /** Upload new attachments */
  for (const attachment of attachmentsToUpload) {
    await postFile(attachment, audit);
  }

  /** Delete attachments that are marked as deleted **/
  await Promise.all(attachmentsToDelete.map(el => el.delete()));

  /** Update state */
  audit.state.name = newStatus;
  await audit.save();

  return audit;
};
