import ChatIcon from '@material-ui/icons/Chat';
import React, { useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import StatusMessageDialog from '../Dialogs/StatusMessageDialog';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';
import { isEmpty } from 'lodash';
import { State } from '../../sdk/com/apiomat/frontend/missio';
import { getSortedCommentHistoryKeys } from '../../utils/comment-history.utils';

const assignmentStatusStyles = makeStyles((theme: Theme) => ({
  statusContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer',
  },
  rejectionMessage: {
    marginLeft: 8,
    textAlign: 'left',
  },
  statusText: {
    fontWeight: 400,
    fontSize: '0.75rem',
    lineHeight: 1.66,
    fontStyle: 'italic',
    letterSpacing: '0.03333em',
    display: 'block',
  },
  dots: {
    color: theme.palette.primary.main,
  },
  chatIcon: {
    color: theme.palette.info.main,
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.info.dark,
    },
  },
}));

export interface CommentHistoryProps {
  state: State;
  displayLastComment: boolean;
}

export const CommentHistory = (props: CommentHistoryProps) => {
  const { state, displayLastComment } = props;
  const { t } = useTranslation();
  const [ isStatusMessageBoxOpen, setIsStatusMessageBoxOpen ] = useState(false);
  const classes = assignmentStatusStyles();

  const commentHistoryKeys = !state || !state?.rejectionReasonHistory ? [] : getSortedCommentHistoryKeys(Object.keys(state?.rejectionReasonHistory));
  const statusMessageText = state?.rejectionReason ? state?.rejectionReason :
    (commentHistoryKeys.length > 0 ? state?.rejectionReasonHistory[commentHistoryKeys[0]] : '');

  /**
   * Return tooltip element, which includes truncated string with '...' concatenated to its end.
   */
  const getLastCommentElement = () => {
    if (statusMessageText && statusMessageText.length <= 100) {
      return <span className={ classes.statusText }>{ statusMessageText }</span>;
    } else {
      return (
        <Tooltip title={ t('status-message-dialog:button:show-more') }>
          <span className={ classes.statusText }>
            { statusMessageText?.slice(0, 100) }
            <span className={ classes.dots }>
              ...
            </span>
          </span>
        </Tooltip>
      );
    }
  };

  const openHistoryCommentPopup = () => {
    setIsStatusMessageBoxOpen(true);
  };

  const closeHistoryCommentPopup = () => {
    setIsStatusMessageBoxOpen(false);
  };

  return (!isEmpty(statusMessageText) ?
      <>
        <div className={ classes.statusContainer } onClick={ openHistoryCommentPopup }>
          <ChatIcon className={ classes.chatIcon }/>
          { displayLastComment &&
            <span className={ classes.rejectionMessage }>
              { getLastCommentElement() }
            </span> }
        </div>
        <StatusMessageDialog
          isOpen={ isStatusMessageBoxOpen }
          comment={ state?.rejectionReason }
          commentHistoryMap={ state?.rejectionReasonHistory }
          commentHistoryKeys={ commentHistoryKeys }
          isRejectionMessage={ true }
          onCancelClick={ closeHistoryCommentPopup }
        />
      </> : <></>
  );
};
