import Survey from '../sdk/com/apiomat/frontend/missio/Survey';
import { StateType } from '../enums/StateType';
import { postFile } from './file.utils';

export const saveSurvey = async (survey: Survey, newStatus: StateType) => {
  /** Upload attachments first so that they all get transfered to easydor */
  const attachmentsToUpload = survey.attachments.filter(
    el => Boolean((el as any).dao.file) && Boolean((el as any).dao.isDeleted) === false
  );
  const attachmentsToDelete = survey.attachments.filter(el => Boolean((el as any).dao.isDeleted) && Boolean(el.ID));

  /** Upload new attachments */
  for (const attachment of attachmentsToUpload) {
    await postFile(attachment, survey);
  }

  /** Delete attachments that are marked as deleted **/
  await Promise.all(attachmentsToDelete.map(el => el.delete()));

  /** Update state */
  survey.state.name = newStatus;
  await survey.save();

  return survey;
};
