import { Check, Warning } from '@material-ui/icons';
import { Badge, Tab, TabProps } from '@mui/material';
import React, { FunctionComponent } from 'react';

// unions must be lowercase to match properties of Offer and Report
export type FormTabKeys =
  | 'measure'
  | 'contacts'
  | 'environment'
  | 'target'
  | 'formation'
  | 'childProtection'
  | 'monitoring'
  | 'costPlan'
  | 'attachments';

export interface FormTabItem {
  key: FormTabKeys;
  label: string;
  content: React.ReactNode;
}

const FormTab: FunctionComponent<{ errorCount: number | undefined; hideIcon: boolean } & TabProps> = props => {
  const { errorCount, hideIcon, ...tabProps } = props;
  return (
    <Tab
      {...tabProps}
      iconPosition="start"
      icon={
        tabProps.disabled || hideIcon ? (
          undefined
        ) : errorCount === undefined ? (
          <Warning />
        ) : errorCount === 0 ? (
          <Check />
        ) : (
          <Badge color="primary" badgeContent={errorCount}>
            <Warning />
          </Badge>
        )
      }
    />
  );
};

export default FormTab;
