import { DropDownItem } from '../components/Selection/DropDownItem';

export const alphabeticalSort = (a: DropDownItem, b: DropDownItem) => a.value?.toLowerCase()?.localeCompare(b.value.toLowerCase());

export const createdAtSort = <T extends {createdAt: Date}>(a: T, b: T) => {
  const timeA = a.createdAt.getTime();
  const timeB = b.createdAt.getTime();

  if (timeA === timeB) {
    return 0;
  }

  return timeA > timeB ? 1 : -1;
}
