import { StateType } from '../enums/StateType';
import OfferEvaluation from '../sdk/com/apiomat/frontend/missio/OfferEvaluation';
import { postFile } from './file.utils';

export const saveEvaluation = async (evaluation: OfferEvaluation, newStatus: StateType): Promise<OfferEvaluation> => {
  /** Upload attachments first so that they all get transfered to easydor */
  const attachmentsToUpload = evaluation.attachments.filter(
    el => Boolean((el as any).dao.file) && Boolean((el as any).dao.isDeleted) === false
  );
  const attachmentsToDelete = evaluation.attachments.filter(el => Boolean((el as any).dao.isDeleted) && Boolean(el.ID));

  /** Upload new attachments */
  for (const attachment of attachmentsToUpload) {
    await postFile(attachment, evaluation);
  }

  /** Delete attachments that are marked as deleted **/
  await Promise.all(attachmentsToDelete.map(el => el.delete()));

  /** Update state */
  evaluation.state.name = newStatus;
  await evaluation.save();

  return evaluation;
};
