import React, { useEffect, useState } from 'react';
import {
  makeStyles,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Theme,
  Button,
  List,
  ListItem,
  ListItemIcon,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from '../../Buttons/Buttons';
import { ContactButton } from '../../Buttons/ContactButton';
import { DeleteButton } from '../../Buttons/DeleteButton';
import { useDispatch, useSelector } from 'react-redux';
import { ContactType } from '../../../enums/ContactType';
import DefaultDialog from '../../Dialogs/DefaultDialog';
import EditContactForm from './EditContactForm';
import { ContactObj, offerActions } from '../../../store/offers';
import Contact from '../../../sdk/com/apiomat/frontend/missio/Contact';
import DefaultTranslatedLabel from '../../Lables/DefaultTranslatedLabel';
import { copyContact, getContactName } from '../../../utils/contact.utils';
import EditIcon from '@material-ui/icons/Edit';
import ContactImportDialog from '../../Dialogs/ContactImportDialog';
import { AppState } from '../../../store';
import { contactBookActions } from '../../../store/contactBook';
import { isEqual } from 'lodash';
import { Action, useOfferFormContext } from '../context/OfferFormContext';

import { Check, Warning } from '@material-ui/icons';
import { checkForDuplicate } from '../../../utils/array';

const useStyles = makeStyles((theme: Theme) => ({
  contentContainer: {
    paddingBottom: 30,
    paddingTop: 30,
  },
  addButtonContainer: {
    display: 'flex',
    flex: 1,
    padding: 5,
    justifyContent: 'flex-end',
  },
  addButton: {
    padding: 5,
    borderRadius: 0,
  },
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
  row: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
  },
}));

export interface ContactsFormProps {
  readOnlyMode: boolean;
  currentContacts?: ContactObj[];
}

interface DialogObject {
  isVisible: boolean;
  contact: Contact;
  oldContact: Contact;
  savedContact: Contact;
  isContactUpdate: boolean;
  isFormValid: boolean;
}

const mandatoryContactTypes = [ ContactType.owner, ContactType.projectManager, ContactType.applicant ];
const mandatoryBeneficiaryContactTypes = [ ContactType.beneficiaryPrivate, ContactType.beneficiaryInstitue ];

const ContactsForm = (props: ContactsFormProps) => {
  const { state, dispatch: offerContextDispatch } = useOfferFormContext();
  const { readOnlyMode, currentContacts } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { contactBook, loading } = useSelector((state: AppState) => state.contactBook);
  const contacts: Contact[] = currentContacts.map(el => el.contactObject);

  const duplicateContactTypes = checkForDuplicate(currentContacts.map(currentContact => currentContact.contactObject.contactType));

  useEffect(() => {
    if (!readOnlyMode) {
      // create error object for error count
      const errorObject = duplicateContactTypes ? { duplicates: 'duplicate contact types' } : {};

      mandatoryContactTypes.forEach(mandatoryContactType => {
        if (!currentContacts.some(currentContact => currentContact.contactObject.contactType === mandatoryContactType)) {
          errorObject[String(mandatoryContactType)] = 'missingContactType';
        }
      });

      if (!currentContacts.some(
        currentContact => mandatoryBeneficiaryContactTypes.some(mandatoryBeneficiaryContactType =>
          currentContact.contactObject.contactType === mandatoryBeneficiaryContactType))) {
        errorObject[String(mandatoryBeneficiaryContactTypes[0])] = 'missingContactType';
      }

      const actionObject: Action = {
        subFormId: 'contacts',
        errors: errorObject,
        values: {},
      };

      if (!('contacts' in state) ||
        ('contacts' in state && state['contacts']['errorCount'] !== Object.keys(actionObject.errors).length)) {
        offerContextDispatch(actionObject);
      }

      dispatch(contactBookActions.loadContactBook());
    }
  }, [currentContacts, dispatch, duplicateContactTypes, offerContextDispatch, readOnlyMode, state]);

  const [activeDialog, setActiveDialog] = useState<DialogObject>({
    isVisible: false,
    contact: null,
    oldContact: null,
    savedContact: null,
    isContactUpdate: false,
    isFormValid: false,
  });
  const [isContactImportDialogOpen, setIsContactImportDialogOpen] = useState<boolean>(false);

  const onAddButtonPressed = () => {
    const contact = new Contact();
    contact.contactType = ContactType.owner;
    setActiveDialog({
      isVisible: true,
      contact: contact,
      oldContact: null,
      savedContact: null,
      isContactUpdate: false,
      isFormValid: false,
    });
  };

  const onDuplicateButtonClick = (contact: Contact) => {
    setActiveDialog({
      isVisible: true,
      contact: copyContact(contact),
      oldContact: null,
      savedContact: null,
      isContactUpdate: false,
      isFormValid: false,
    });
  };

  const onEditButtonClick = (contact: Contact) => {
    setActiveDialog({
      isVisible: true,
      contact: copyContact(contact),
      oldContact: contact,
      savedContact: null,
      isContactUpdate: false,
      isFormValid: false,
    });
  };

  const onSaveButtonClick = () => {
    if (activeDialog.oldContact) {
      const index = contacts.indexOf(activeDialog.oldContact);
      contacts[index] = activeDialog.contact;
    } else {
      contacts.push(activeDialog.contact);
    }
    dispatch(offerActions.updateCurrentContacts(contacts));
    onCancelButtonClick();
  };

  const onDeleteConfirmed = (contact: Contact) => {
    const index = contacts.indexOf(contact);
    contacts.splice(index, 1);
    dispatch(offerActions.updateCurrentContacts(contacts));
  };

  const onCancelButtonClick = () => {
    setActiveDialog({
      isVisible: false,
      contact: null,
      oldContact: null,
      savedContact: null,
      isContactUpdate: false,
      isFormValid: false,
    });
  };

  const deleteContact = (contact: Contact) => {
    dispatch(contactBookActions.deleteContact(contact));
  };

  const onImportButtonClick = (contact: Contact, contactType: string) => {
    setIsContactImportDialogOpen(false);
    contact.contactType = contactType;

    setActiveDialog({
      isVisible: true,
      contact: copyContact(contact),
      oldContact: null,
      savedContact: contact,
      isContactUpdate: false,
      isFormValid: false,
    });
  };

  const onUpdateContactInContactBookClick = () => {
    dispatch(contactBookActions.updateContact(activeDialog.contact));
    setActiveDialog({
      isVisible: false,
      contact: copyContact(activeDialog.contact),
      oldContact: null,
      savedContact: activeDialog.contact,
      isContactUpdate: false,
      isFormValid: false,
    });
  };

  const onAddContactToContactBookClick = () => {
    dispatch(contactBookActions.addContact(activeDialog.contact));
    onSaveButtonClick();
  };

  const onEditContactButtonClick = (contact: Contact) => {
    setActiveDialog({
      isVisible: true,
      contact: copyContact(contact),
      oldContact: null,
      savedContact: contact,
      isContactUpdate: true,
      isFormValid: false,
    });
  };

  const isContactAlreadyInContactBook = contactBook?.contacts
    ?.map(contact => isEqual(contact?.toJson(), activeDialog?.contact?.toJson()))
    .includes(true);

  const isContactChanged = (first: Contact, second: Contact) => {
    return !isEqual(first?.toJson(), second?.toJson());
  };

  return (
    <>
      <div className={classes.contentContainer}>
        {!readOnlyMode && (
          <div className={classes.addButtonContainer}>
            <ContactButton
              label={t('button:label:import-from-contact-book')}
              onClick={() => setIsContactImportDialogOpen(true)}
              isDisabled={contactBook === null || contactBook?.contacts?.length === 0}
            />
            <PrimaryButton className={classes.addButton} onClick={onAddButtonPressed}>
              {t('button:label:add-new-contact')}
            </PrimaryButton>
          </div>
        )}
        <div>
          <Typography variant="subtitle1">{t('mandatory_fields')}</Typography>
          <List>
            {mandatoryContactTypes.map(mandatoryContactType => (
              <ListItem key={mandatoryContactType}>
                <ListItemIcon>
                  {currentContacts.some(
                    contact => contact.contactObject.contactType === mandatoryContactType) ? <Check /> : <Warning />}
                </ListItemIcon>
                {t(`edit-contact-type:${mandatoryContactType}`)}
              </ListItem>
            ))}
            <ListItem key={'beneficiary'}>
              <ListItemIcon>
                {currentContacts.some(contact =>
                  mandatoryBeneficiaryContactTypes.some(mandatoryBeneficiaryContactType =>
                  contact.contactObject.contactType === mandatoryBeneficiaryContactType)) ? <Check /> : <Warning />}
              </ListItemIcon>
              { mandatoryBeneficiaryContactTypes.map(item => t(`edit-contact-type:${item}`)).join(' / ') }
            </ListItem>
            {duplicateContactTypes && (
              <ListItem>
                <ListItemIcon>
                  <Warning />
                </ListItemIcon>
                {t('use-cases:new-assignment:root:duplicate-contact-type')}
              </ListItem>
            )}
          </List>

          <Paper className={classes.root}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>{t('label:contact-table-email')}</TableCell>
                  <TableCell align="right">{t('label:contact-table-name')}</TableCell>
                  <TableCell align="right">{t('label:contact-table-type')}</TableCell>
                  <TableCell align="right">{t('label:contact-table-actions')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {contacts.map((item, i) => (
                  <TableRow key={i}>
                    <TableCell component="th" scope="row">
                      {item.email}
                    </TableCell>
                    <TableCell align="right">{getContactName(item)}</TableCell>
                    <TableCell align="right">
                      {t(item.contactType === 'responsibleForProject' ? ContactType.projectManager : item.contactType)}
                    </TableCell>
                    <TableCell align="right">
                      <div>
                        {!readOnlyMode && (
                          <Button color="primary" onClick={() => onDuplicateButtonClick(item)}>
                            {t('contacts:button:copy')}
                          </Button>
                        )}
                        <Button onClick={() => onEditButtonClick(item)}>
                          <EditIcon color="primary" />
                        </Button>
                        {!readOnlyMode && <DeleteButton onDeleteConfirmed={() => onDeleteConfirmed(item)} type="icon" />}
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </div>
      </div>
      {activeDialog.isVisible && (
        <DefaultDialog
          isOpen={true}
          children={
            <EditContactForm
              isReadonly={readOnlyMode}
              contact={activeDialog.contact}
              updateContact={(contact, isValid) => setActiveDialog({ ...activeDialog, contact, isFormValid: isValid })}
            />
          }
          actionComponent={
            <div style={{ flex: 1, display: 'flex', flexDirection: 'row' }}>
              <div style={{ flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                {!activeDialog.isContactUpdate && (
                  <ContactButton
                    label={t('contact:form:dialog:button:new-contact')}
                    onClick={onAddContactToContactBookClick}
                    isDisabled={
                      loading === 'pending' ||
                      activeDialog.isContactUpdate ||
                      isContactAlreadyInContactBook ||
                      !isContactChanged(activeDialog?.contact, activeDialog?.savedContact) ||
                      !isContactChanged(activeDialog?.contact, activeDialog?.oldContact)
                    }
                  />
                )}
              </div>
              <div style={{ flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                <PrimaryButton onClick={onCancelButtonClick}>{t('button:label:cancel')}</PrimaryButton>
                <div style={{ padding: 5 }} />
                {activeDialog.isContactUpdate ? (
                  <ContactButton
                    label={t('contact:form:dialog:button:update-contact')}
                    onClick={onUpdateContactInContactBookClick}
                    isDisabled={!isContactChanged(activeDialog?.contact, activeDialog?.savedContact)}
                  />
                ) : (
                  <PrimaryButton onClick={onSaveButtonClick} disabled={!activeDialog.isFormValid}>
                    {Boolean(activeDialog.oldContact) ? t('contact:form:dialog:button:update') : t('contact:form:dialog:button:add')}
                  </PrimaryButton>
                )}
              </div>
            </div>
          }
          title={<DefaultTranslatedLabel infoId={'info:contact:form:dialog-title'} translationId={'contact:form:dialog-title'} />}
        />
      )}
      {isContactImportDialogOpen && (
        <ContactImportDialog
          isOpen={isContactImportDialogOpen}
          onImportButtonClick={onImportButtonClick}
          onCancelButtonClick={() => setIsContactImportDialogOpen(false)}
          onEditButtonClick={onEditContactButtonClick}
          deleteContact={deleteContact}
          contacts={contactBook?.contacts}
        />
      )}
    </>
  );
};

export default ContactsForm;
