import React from 'react';
import { useState } from 'react';
import { PrimaryButton } from '../Buttons/Buttons';
import { useTranslation } from 'react-i18next';
import InfoBoxDialog from './InfoBoxDialog';

export interface DialogButtonProps {
  textId: string;
  title: string;
  className?: string;
}

const DialogButton = (props: DialogButtonProps) => {
  const { textId, title, className } = props;
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div className={className ? className : ''}>
      <PrimaryButton onClick={() => setIsOpen(true)}>{t(title)}</PrimaryButton>
      <InfoBoxDialog isOpen={isOpen} infoId={textId} onCancelClick={() => setIsOpen(false)} />
    </div>
  );
};

export default DialogButton;
