import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Typography, Button, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  homeButton: {
    marginTop: theme.spacing(4),
  },
}));

export default () => {
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="sm" className={classes.container}>
      <Typography component="h4" variant="h4">
        404 - Not Found
      </Typography>
      <Button component={Link} to="/" variant="contained" color="primary" className={classes.homeButton}>
        Home
      </Button>
    </Container>
  );
};
