import React, { useEffect, useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Publication from '../../../../sdk/com/apiomat/frontend/missio/Publication';
import { useFormik } from 'formik';
import { object, date, string, number } from 'yup';
import { useOfferFormContext } from '../../context/OfferFormContext';
import FormDatePicker from '../../../DatePicker/FormDatePicker';
import FormTextField from '../../InputFields/FormTextField';

export interface PublicationProps {
  readOnlyMode: boolean;
  data: any;
  isReport?: boolean;
}

const PublicationForm = (props: PublicationProps) => {
  const { state, dispatch } = useOfferFormContext();
  const { readOnlyMode, data, isReport } = props;
  const { t } = useTranslation();

  const { start, end, measureDescription, authorInformation, salesInformation, numberOfCopies } = data.measure as Publication;

  const validationSchema = useMemo(
    () =>
      object({
        start: date().required(t('mandatory_field')),
        end: date().required(t('mandatory_field')),
        measureDescription: string().required(t('mandatory_field')),
        authorInformation: string().required(t('mandatory_field')),
        salesInformation: isReport ? string() : string().required(t('mandatory_field')),
        numberOfCopies: number().required(t('mandatory_field')),
      }),
    [t, isReport]
  );

  const formik = useFormik({
    initialValues: {
      start,
      end,
      measureDescription,
      authorInformation,
      salesInformation,
      numberOfCopies,
    },
    enableReinitialize: true,
    validationSchema,
    validateOnMount: true,
    onSubmit: () => {},
  });

  useEffect(() => {
    if (!readOnlyMode) {
      if (!formik.isValidating) {
        dispatch({ subFormId: 'measure', errors: formik.errors, values: formik.values });
      }
    }
  }, [formik.errors, formik.values, dispatch, formik.isValidating, readOnlyMode, state]);

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <FormDatePicker
            id="start"
            name="start"
            label={t('input:label:measure:planned-project-start')}
            value={formik.values.start}
            onChange={value => formik.setFieldValue('start', value)}
            disabled={readOnlyMode}
            errorMessage={formik.errors.start}
          />
        </Grid>
        <Grid item xs={6}>
          <FormDatePicker
            id="end"
            name="end"
            label={t('input:label:measure:planned-project-end')}
            value={formik.values.end}
            minDate={formik.values.start}
            onChange={value => formik.setFieldValue('end', value)}
            disabled={readOnlyMode}
            errorMessage={formik.errors.end}
          />
        </Grid>
        <Grid item xs={12}>
          <FormTextField
            id="measureDescription"
            name="measureDescription"
            disabled={readOnlyMode}
            labelKey="input:label:measure:measure-description"
            value={formik.values.measureDescription}
            multiline
            rows={3}
            onChange={formik.handleChange}
            errorMessage={formik.errors.measureDescription}
          />
        </Grid>
        <Grid item xs={12}>
          <FormTextField
            id="authorInformation"
            name="authorInformation"
            disabled={readOnlyMode}
            labelKey="input:label:measure:publication-author"
            descriptionKey="info:measure:publication:publication-author"
            value={formik.values.authorInformation}
            multiline
            rows={3}
            onChange={formik.handleChange}
            errorMessage={formik.errors.authorInformation}
          />
        </Grid>
        <Grid item xs={12}>
          <FormTextField
            id="salesInformation"
            name="salesInformation"
            disabled={readOnlyMode}
            labelKey="input:label:measure:publication-sales"
            descriptionKey="info:measure:publication:publication-sales"
            value={formik.values.salesInformation}
            multiline
            rows={3}
            onChange={formik.handleChange}
            errorMessage={formik.errors.salesInformation}
          />
        </Grid>
        <Grid item xs={12}>
          <FormTextField
            id="numberOfCopies"
            name="numberOfCopies"
            type="number"
            disabled={readOnlyMode}
            value={formik.values.numberOfCopies}
            labelKey="input:label:measure:publication-circulation"
            onChange={formik.handleChange}
            errorMessage={formik.errors.numberOfCopies}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default PublicationForm;
