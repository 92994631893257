import React, { FunctionComponent } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, DatePickerProps, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { FormikErrors } from 'formik';

const FormDatePicker: FunctionComponent<{
  id: string;
  name: string;
  errorMessage?: FormikErrors<Date>;
} & DatePickerProps> = props => {
  const { id, name, errorMessage, ...datePickerProps } = props;
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker
        id={id}
        name={name}
        {...datePickerProps}
        inputVariant="outlined"
        format={!datePickerProps.views ? 'dd/MM/yyyy' : 'yyyy'}
        fullWidth
        placeholder={'- - . - - . - - - - '}
        animateYearScrolling={false}
        value={datePickerProps.value === undefined ? null : datePickerProps.value}
        helperText={errorMessage}
        error={Boolean(errorMessage)}
        autoOk
      />
    </MuiPickersUtilsProvider>
  );
};

export default FormDatePicker;
