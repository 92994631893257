import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { all, call, put, select, takeLeading } from 'redux-saga/effects';
import { notificationActions } from './notification';
import MReferent from '../sdk/com/apiomat/frontend/missio/MReferent';
import { AppState } from '.';
import { createTransform } from 'redux-persist';

/** STATE */
export interface ReferentState {
  referents: MReferent[];
  loadingReferents: 'idle' | 'pending' | 'succeeded' | 'failed' | 'loading-more';
}

const initialState: ReferentState = {
  referents: [],
  loadingReferents: 'idle',
};

/** SLICE */
const referentSlice = createSlice({
  name: 'referent',
  initialState,
  reducers: {
    loadReferents: (state, _action: PayloadAction<string>) => {
      state.loadingReferents = 'pending';
    },
    loadReferentsSuccess: (state, action: PayloadAction<MReferent[]>) => {
      state.loadingReferents = 'succeeded';
      state.referents = action.payload;
    },
    loadReferentsFailure: state => {
      state.loadingReferents = 'failed';
    },
  },
});

export const referentActions = referentSlice.actions;
export const referentsReducer = referentSlice.reducer;

/** SAGAS */
function* onLoadReferents(action: PayloadAction<string>) {
  const isOnline: boolean = yield select((state: AppState) => state.offline.isOnline);
  const query = action.payload || '';

  const localReferents: MReferent[] = yield select((state: AppState) => state.referent.referents);
  if (isOnline === false) {
    yield put(referentActions.loadReferentsSuccess(localReferents || []));
    return;
  }

  try {
    const referents: MReferent[] = yield call(() => MReferent.getMReferents(query));

    yield put(referentActions.loadReferentsSuccess(referents));
  } catch (error) {
    yield put(notificationActions.showError(error));
    yield put(referentActions.loadReferentsFailure());
  }
}

export function* referentSaga() {
  yield all([takeLeading(referentActions.loadReferents, onLoadReferents)]);
}

/** TRANSFORMS */
export const offerTransform = createTransform(
  (state: ReferentState) => {
    return {
      ...state,
      referents: state.referents.map(referent => referent.toJson()),
    };
  },
  json => {
    return {
      ...json,
      referents: [],
      loadingReferents: 'idle',
    };
  },
  { whitelist: ['referent'] }
);
