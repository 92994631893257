import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, Tabs, Tab } from '@material-ui/core';
import DefaultDialog from './DefaultDialog';
import ShareReportForm from '../Forms/ShareReport/ShareReportForm';
import AlreadySharedReportList from '../Forms/ShareReport/AlreadySharedReportList';

const useStyles = makeStyles(() => ({
  tabContainer: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  tab: {
    color: 'rgb(79, 195, 247)',
    fontSize: 18,
  },
}));

export interface ShareTaskDialogProps {
  isOpen: boolean;
  onCancelButtonClick: () => void;
}

const ShareReportDialog = (props: ShareTaskDialogProps) => {
  const { isOpen, onCancelButtonClick } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);

  const tabs = [
    {
      key: 'cost-plan',
      label: 'tab:title:share-task-form',
      content: <ShareReportForm onCancelButtonClick={onCancelButtonClick} />,
    },
    {
      key: 'financial-plan',
      label: 'tab:title:already-shared-tasks',
      content: <AlreadySharedReportList onCancelButtonClick={onCancelButtonClick} />,
    },
  ];

  return (
    <DefaultDialog
      maxWidth="sm"
      isOpen={isOpen}
      actionComponent={<div style={{ flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}></div>}
      children={
        <div className={classes.tabContainer}>
          <Tabs variant="fullWidth" value={activeTabIndex} onChange={(_event, tabIndex) => setActiveTabIndex(tabIndex)}>
            {tabs.map(tab => (
              <Tab fullWidth key={tab.key} className={classes.tab} disableRipple label={t(tab.label)} />
            ))}
          </Tabs>
          {tabs[activeTabIndex].content}
        </div>
      }
      title={''}
    />
  );
};

export default ShareReportDialog;
