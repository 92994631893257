import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, DialogContentText, TextField, TextFieldProps } from '@material-ui/core';
import DefaultDialog from './DefaultDialog';

export interface ReasonDialogProps {
  onCancelButtonClick?: () => void;
  onSaveButtonClick?: () => void;
  isOpen: boolean;
  content?: string;
}

const ReasonDialog = (props: ReasonDialogProps & TextFieldProps) => {
  const { t } = useTranslation();
  const { isOpen, onCancelButtonClick, onSaveButtonClick, content } = props;
  const { onBlur, onChange, onFocus, variant, InputProps, inputProps} = props;
  return (
    <DefaultDialog
      isOpen={isOpen}
      maxWidth={'xs'}
      title={''}
      children={
        <>
          <DialogContentText>{content}</DialogContentText>
          <TextField multiline
                     rowsMax={7}
                     autoFocus
                     fullWidth
                     onBlur={onBlur}
                     onChange={onChange}
                     onFocus={onFocus}
                     variant={variant}
                     InputProps={InputProps}
                     inputProps={inputProps} />
        </>
      }
      actionComponent={
        <>
          <Button onClick={onCancelButtonClick} color="primary">
            {t('button:label:cancel')}
          </Button>
          <Button onClick={onSaveButtonClick} color="primary">
            {t('button:label:save')}
          </Button>
        </>
      }
    />
  );
};

export default ReasonDialog;
