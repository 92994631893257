import React, { useState } from 'react';
import { PrimaryButton } from '../components/Buttons/Buttons';
import { useTranslation } from 'react-i18next';
import { Grid, TextField, makeStyles } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../store/index';
import { notificationActions } from '../store/notification';
import { authActions } from '../store/auth';
import PasswordChange from '../components/Dialogs/PasswordChange';
import LanguageSelection from '../components/Localization/LanguageSelection';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  saveButton: {
    marginTop: 15,
    marginBottom: 15,
    width: window.innerWidth / 12,
    display: 'flex',
    borderRadius: 0,
  },
  textField: {
    backgroundColor: '#fff',
    flex: 1,
    display: 'flex',
    paddingTop: 2,
    paddingLeft: 2,
    paddingRight: 2,
  },
}));

export default () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { user, loading } = useSelector((state: AppState) => state.auth);

  const [email, setEmail] = useState<string>(user.email);
  const [firstName, setFirstName] = useState<string>(user.firstName);
  const [lastName, setLastName] = useState<string>(user.lastName);
  const [churchType, setChurchType] = useState<string>(user.church);
  const [churchCountry, setChurchCountry] = useState<string>(user.churchCountry);
  const [churchRole, setChurchRole] = useState<string>(user.churchRole);
  const [phoneNumber, setPhoneNumber] = useState<string>(user.phone);
  const [language, setLanguage] = useState<string>(user.language || '');
  const [oldPassword, setOldPassword] = useState<string>('');
  const [isPasswordChangeDialogOpen, setIsPasswordVerificationDailogOpen] = useState<boolean>(false);
  const [password, setPassword] = useState<string>('');
  const [passwordVerification, setPasswordVerification] = useState<string>('');

  const handleProfileSave = async () => {
    if (
      email === '' ||
      firstName === '' ||
      lastName === '' ||
      churchRole === '' ||
      churchType === '' ||
      churchCountry === '' ||
      phoneNumber === '' ||
      language === ''
    ) {
      dispatch(notificationActions.showError(t('empty-required-fields')));
    } else {
      dispatch(authActions.updateUser({ email, firstName, lastName, churchType, churchCountry, churchRole, phoneNumber, language }));
    }
  };

  const openPasswordVerificationDialog = () => {
    setIsPasswordVerificationDailogOpen(true);
  };

  const cancelPasswordVerificationDialog = () => {
    setIsPasswordVerificationDailogOpen(false);
  };

  const handlePasswordSave = () => {
    if (password !== passwordVerification || password === '') {
      return;
    }
    dispatch(authActions.changePassword({ password: password, oldPassword: oldPassword }));
  };

  return (
    <div className={classes.container}>
      <div className={classes.container}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              className={classes.textField}
              value={email}
              onChange={event => setEmail(event.target.value)}
              placeholder={t('input:placeholder:email')}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              className={classes.textField}
              value={firstName}
              onChange={event => setFirstName(event.target.value)}
              placeholder={t('input:placeholder:first-name')}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              className={classes.textField}
              value={lastName}
              onChange={event => setLastName(event.target.value)}
              placeholder={t('input:placeholder:last-name')}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className={classes.textField}
              value={churchType}
              onChange={event => setChurchType(event.target.value)}
              placeholder={t('input:placeholder:church-type')}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className={classes.textField}
              value={churchCountry}
              onChange={event => setChurchCountry(event.target.value)}
              placeholder={t('input:placeholder:church-country')}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className={classes.textField}
              value={churchRole}
              onChange={event => setChurchRole(event.target.value)}
              placeholder={t('input:placeholder:church-role')}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className={classes.textField}
              value={phoneNumber}
              onChange={event => setPhoneNumber(event.target.value)}
              placeholder={t('input:placeholder:phone-number')}
            />
          </Grid>
          <Grid item xs={12}>
            <LanguageSelection
              language={language}
              inputLabel={'select:label:language'}
              onChange={e => {
                setLanguage(e);
              }}
            />
          </Grid>
        </Grid>
      </div>
      <PrimaryButton className={classes.saveButton} disabled={loading === 'pending'} onClick={() => handleProfileSave()}>
        {t('button:label:save')}
      </PrimaryButton>
      <div className={classes.container}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              className={classes.textField}
              value={password}
              type={'password'}
              onChange={event => setPassword(event.target.value)}
              placeholder={t('input:placeholder:password')}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              className={classes.textField}
              value={passwordVerification}
              type={'password'}
              onChange={event => setPasswordVerification(event.target.value)}
              placeholder={t('input:placeholder:password-verification')}
            />
          </Grid>
        </Grid>
      </div>
      <PrimaryButton className={classes.saveButton} disabled={loading === 'pending'} onClick={() => openPasswordVerificationDialog()}>
        {t('button:label:save')}
      </PrimaryButton>
      <PasswordChange
        isOpen={isPasswordChangeDialogOpen}
        onCancelButtonClick={cancelPasswordVerificationDialog}
        onSaveButtonClick={handlePasswordSave}
        content={t('password-change-dialog:content')}
        onChange={event => {
          setOldPassword(event.target.value);
        }}
        value={oldPassword}
      />
    </div>
  );
};
