import { TFunction } from "i18next";

type TranslationFn = TFunction

export function createExtractErrorMessageFn(t: TranslationFn) {
  return (error: unknown) => extractErrorMessage(error, t);
}

function extractErrorMessage(error: unknown, t: TranslationFn): string {
  if (isFailedAuth(error)) {
    return t('error:authorization-failed');
  }

  if (hasMessage(error)) {
    let message = error.message;
    try {
      const errorObj = JSON.parse(error.message);

      if (isLocalisedError(errorObj) && t(errorObj.localisationKey)) {
        message = `${t(errorObj.localisationKey)}${errorObj.code ? ` (${errorObj.code})` : ''}`;
      }
    } catch {
      // ignored
    }

    return message;
  }

  if (typeof error === 'string') {
    return error;
  }

  return String(error);
}

function isFailedAuth(error: unknown): boolean {
  return typeof error === 'object' && error !== null && 'statusCode' in error && error['statusCode'] === 840
}

function hasMessage(error: unknown) : error is { message: string } {
  return error instanceof Error || typeof error === 'object' && error !== null && 'message' in error
}

function isLocalisedError(error: unknown): error is { localisationKey: string, code?: number } {
  return error && typeof error === 'object' && 'localisationKey' in error
}
