import { GeneralValueMap } from './GeneralValueMap';

export const costPlanTypes: GeneralValueMap[] = [
  {
    locale: 'lbl.costPlanTypeStaff',
    value: 'cost-plan:cost-type:personnel',
    key: '1',
  },
  {
    locale: 'lbl.costPlanTypeMaterial',
    value: 'cost-plan:cost-type:material',
    key: '2',
  },
  {
    locale: 'lbl.costPlanTypeFood',
    value: 'cost-plan:cost-type:meals',
    key: '3',
  },
  {
    locale: 'lbl.costPlanTypeAccommodation',
    value: 'cost-plan:cost-type:accomodation',
    key: '4',
  },
  {
    locale: 'lbl.costPlanTypeOthers',
    value: 'cost-plan:cost-type:other',
    key: '5',
  },
  {
    locale: 'lbl.costPlanTypeDues',
    value: 'cost-plan:cost-type:charges',
    key: '6',
  },
  {
    locale: 'lbl.costPlanTypeFee',
    value: 'cost-plan:cost-type:fee',
    key: '7',
  },
  {
    locale: 'lbl.costPlanTypeRental',
    value: 'cost-plan:cost-type:rent',
    key: '8',
  },
  {
    locale: 'lbl.costPlanTypeOperations',
    value: 'cost-plan:cost-type:operating-and-subsidiary-costs',
    key: '9',
  },
  {
    locale: 'lbl.costPlanTypeTravel',
    value: 'cost-plan:cost-type:travel-expenses',
    key: '10',
  },
  {
    locale: 'lbl.costPlanTypeCommunication',
    value: 'cost-plan:cost-type:communication',
    key: '11',
  },
  {
    locale: 'lbl.costPlanTypeProduction',
    value: 'cost-plan:cost-type:production-costs',
    key: '12',
  },
  {
    locale: 'lbl.costPlanTypeFlat',
    value: 'cost-plan:cost-type:allowance',
    key: '13',
  },
];
