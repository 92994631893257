import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import './App.css';
import configureStore from './store';
import { ConnectedRouter } from 'connected-react-router';
import { history } from './store';
import { PersistGate } from 'redux-persist/integration/react';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from './theme/theme';
import Notifier from './components/Notifier/Notifier';
import { LinearProgress } from '@material-ui/core';
import AppRouter from './routers/AppRouter';

export default () => {
  const { store, persistor } = configureStore();

  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider theme={theme}>
            <Suspense fallback={<LinearProgress />}>
              <SnackbarProvider
                maxSnack={3}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <Notifier />
                <AppRouter />
              </SnackbarProvider>
            </Suspense>
          </ThemeProvider>
        </PersistGate>
      </ConnectedRouter>
    </Provider>
  );
};
