import React from 'react';
import { CircularProgress, Typography, makeStyles, Theme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { EasyLogo } from '../Logo/Logo';

const useStyles = makeStyles((theme: Theme) => ({
  fullHeightContainer: {
    backgroundColor: theme.palette.primary.main,
    minHeight: '100vh',
    display: 'flex',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundSize: 'cover',
    flexDirection: 'column',
    zIndex: 10000,
  },
  content: {
    paddingTop: 36,
    flex: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  logoContainer: {
    width: 180,
    height: 50,
  },
  progress: {
    margin: theme.spacing(2),
    color: theme.palette.common.white
  },
  progressText: {
    color: theme.palette.common.white
  },
  container: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: 36,
  },
}));

const FullScreenLoadingIndicator = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.fullHeightContainer}>
      <div className={classes.content}>
        <div>
          <CircularProgress color="secondary" className={classes.progress} />
        </div>
        <Typography className={classes.progressText} variant="body2">
          {t('loading')}
        </Typography>
      </div>
      <div className={classes.container}>
        <div className={classes.logoContainer}>
          <EasyLogo />
        </div>
      </div>
    </div>
  );
};

export default FullScreenLoadingIndicator;
