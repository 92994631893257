import { MwiMeasureTypes, MisMeasureTypes } from '../enums/MeasureTypes';
import Conference from '../sdk/com/apiomat/frontend/missio/Conference';
import Vehicle from '../sdk/com/apiomat/frontend/missio/Vehicle';
import Evaluation from '../sdk/com/apiomat/frontend/missio/Evaluation';
import Media from '../sdk/com/apiomat/frontend/missio/Media';
import Publication from '../sdk/com/apiomat/frontend/missio/Publication';
import Scholarship from '../sdk/com/apiomat/frontend/missio/Scholarship';
import PastoralPlan from '../sdk/com/apiomat/frontend/missio/PastoralPlan';
import AcquisitionOfEquipment from '../sdk/com/apiomat/frontend/missio/AcquisitionOfEquipment';
import AcquisitionOfTechnicalDevice from '../sdk/com/apiomat/frontend/missio/AcquisitionOfTechnicalDevice';
import Property from '../sdk/com/apiomat/frontend/missio/Property';
import MwiConference from '../sdk/com/apiomat/frontend/missio/MwiConference';
import MwiConferenceAttendance from '../sdk/com/apiomat/frontend/missio/MwiConferenceAttendance';
import MwiScholarship from '../sdk/com/apiomat/frontend/missio/MwiScholarship';
import MwiProfessorExchange from '../sdk/com/apiomat/frontend/missio/MwiProfessorExchange';
import MwiLibrary from '../sdk/com/apiomat/frontend/missio/MwiLibrary';
import MwiTranslation from '../sdk/com/apiomat/frontend/missio/MwiTranslation';
import Apprenticeship from '../sdk/com/apiomat/frontend/missio/Apprenticeship';
import CollectiveScholarshipCongregation from '../sdk/com/apiomat/frontend/missio/CollectiveScholarshipCongregation';
import CollectiveScholarshipInstitution from '../sdk/com/apiomat/frontend/missio/CollectiveScholarshipInstitution';
import Upkeep from '../sdk/com/apiomat/frontend/missio/Upkeep';
import { OfferType } from '../enums/OfferType';
import MwiResearchStudy from '../sdk/com/apiomat/frontend/missio/MwiResearchStudy';
import MwiResearchPerson from '../sdk/com/apiomat/frontend/missio/MwiResearchPerson';
import MwiResearchInstitution from '../sdk/com/apiomat/frontend/missio/MwiResearchInstitution';
import MwiSpecialProject from '../sdk/com/apiomat/frontend/missio/MwiSpecialProject';
import MwiPublicationPrint from '../sdk/com/apiomat/frontend/missio/MwiPublicationPrint';
import MwiPublicationElectronic from '../sdk/com/apiomat/frontend/missio/MwiPublicationElectronic';

const updateClass = (data: any, clazz: any) => {
  const newMeasure = new clazz();
  newMeasure.name = data.measure.name;
  (data as any).dao.measure = newMeasure;
  (data as any).hashmap.measure = newMeasure;
};

export const updateMeasureClass = (data: any, name: string) => {
  if (data.measureType === OfferType.mis) {
    switch (name) {
      case MisMeasureTypes.conference:
        updateClass(data, Conference);
        break;
      case MisMeasureTypes.vehicle:
        updateClass(data, Vehicle);
        break;
      case MisMeasureTypes.equipmentPurchase:
        updateClass(data, AcquisitionOfEquipment);
        break;
      case MisMeasureTypes.evaluation:
        updateClass(data, Evaluation);
        break;
      case MisMeasureTypes.pastoralProgram:
        updateClass(data, PastoralPlan);
        break;
      case MisMeasureTypes.realEstate:
        updateClass(data, Property);
        break;
      case MisMeasureTypes.media:
        updateClass(data, Media);
        break;
      case MisMeasureTypes.trainingPrograms:
        updateClass(data, Apprenticeship);
        break;
      case MisMeasureTypes.publication:
        updateClass(data, Publication);
        break;
      case MisMeasureTypes.scholarshipCongregation:
        updateClass(data, CollectiveScholarshipCongregation);
        break;
      case MisMeasureTypes.scholarshipInstitute:
        updateClass(data, CollectiveScholarshipInstitution);
        break;
      case MisMeasureTypes.technologyPurchase:
        updateClass(data, AcquisitionOfTechnicalDevice);
        break;
      case MisMeasureTypes.scholarship:
        updateClass(data, Scholarship);
        break;
      case MisMeasureTypes.maintenance:
        updateClass(data, Upkeep);
        break;
      default:
        break;
    }
  } else {
    switch (name) {
      case MwiMeasureTypes.conference:
        updateClass(data, MwiConference);
        break;
      case MwiMeasureTypes.conferenceParticipation:
        updateClass(data, MwiConferenceAttendance);
        break;
      case MwiMeasureTypes.scholarship:
        updateClass(data, MwiScholarship);
        break;
      case MwiMeasureTypes.facultyExchange:
        updateClass(data, MwiProfessorExchange);
        break;
      case MwiMeasureTypes.library:
        updateClass(data, MwiLibrary);
        break;
      case MwiMeasureTypes.publicationElectronic:
        updateClass(data, MwiPublicationElectronic);
        break;
      case MwiMeasureTypes.publicationPrint:
        updateClass(data, MwiPublicationPrint);
        break;
      case MwiMeasureTypes.researchStudy:
        updateClass(data, MwiResearchStudy);
        break;
      case MwiMeasureTypes.researchPerson:
        updateClass(data, MwiResearchPerson);
        break;
      case MwiMeasureTypes.researchInstitution:
        updateClass(data, MwiResearchInstitution);
        break;
      case MwiMeasureTypes.translation:
        updateClass(data, MwiTranslation);
        break;
      case MwiMeasureTypes.specialProject:
        updateClass(data, MwiSpecialProject);
        break;
      default:
        break;
    }
  }
};
