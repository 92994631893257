/** STATE */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { put, call, all, takeLatest, select } from 'redux-saga/effects';
import Reports from '../sdk/com/apiomat/frontend/missio/Reports';
import Datastore from '../sdk/com/apiomat/frontend/Datastore';
import { notificationActions } from './notification';
import { StateType } from '../enums/StateType';
import { AppState } from './index';
import {
  initMissingAttributesInReport,
  prepareReportForSaving,
  saveReport,
  calculateDeviations,
  updateReportStatus,
  getSettings,
} from '../utils/report.utils';
import i18n from '../utils/i18n';
import { createTransform } from 'redux-persist';
import { reportFromJson, reportToJson } from '../utils/transforms';
import { offlineActions } from './offline';
import { push } from 'connected-react-router';
import { ContactObj } from './offers';
import { ApplicationNewState } from '../utils/application-state';
import { executeInSequence, LoadReferencedDataResult, tryLoad } from '../utils/load-references.utils';
import { updateOfferAttachments } from '../utils/offer.utils';

export interface ReportState {
  reports: Reports[];
  incompleteReports: { [id: string]: string[] | null };
  sharedReports: Reports[];
  currentReport: { report: Reports } | null;
  currentContacts: ContactObj[];
  currentDeviations: CostDeviations | null;
  deviationThreshold: number;
  isSharedReport: boolean;
  loadingReports: 'idle' | 'pending' | 'succeeded' | 'failed' | 'loading-more';
  loadingCurrentReport: 'idle' | 'pending' | 'succeeded' | 'failed';
  loadingThreshold: 'idle' | 'pending' | 'succeeded' | 'failed';
}

const initialDeviations: CostDeviations = {
  projectActivities: 0.0,
  investments: 0.0,
  personnel: 0.0,
  projectManagement: 0.0,
};

const initialState: ReportState = {
  reports: [],
  incompleteReports: {},
  sharedReports: [],
  currentReport: null,
  currentContacts: [],
  currentDeviations: initialDeviations,
  deviationThreshold: 0.0,
  isSharedReport: false,
  loadingReports: 'idle',
  loadingCurrentReport: 'idle',
  loadingThreshold: 'idle',
};

/** TYPES */
export interface CostDeviations {
  projectActivities: number;
  investments: number;
  personnel: number;
  projectManagement: number;
}

/** SLICE */
const reportSlice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    loadReports: (state, _action: PayloadAction<string>) => {
      state.loadingReports = 'pending';
    },
    loadReportsSuccess: (state, action: PayloadAction<Reports[]>) => {
      state.loadingReports = 'succeeded';
      state.reports = action.payload;
    },
    loadReportsFailure: state => {
      state.loadingReports = 'failed';
    },
    loadSharedReports: (state, _action: PayloadAction<any>) => {
      state.loadingReports = 'pending';
    },
    loadSharedReportsSuccess: (state, action: PayloadAction<Reports[]>) => {
      state.loadingReports = 'succeeded';
      state.sharedReports = action.payload;
    },
    loadSharedReportsFailure: state => {
      state.loadingReports = 'failed';
    },
    setIncompleteReports: (state, action: PayloadAction<LoadReferencedDataResult[]>) => {
      const data = action.payload.reduce((acc, curr) => {
        acc[curr.id] = curr.failures.length > 0 ? curr.failures : null;
        return acc;
      }, {});

      state.incompleteReports = { ...state.incompleteReports, ...data };
    },
    loadCurrentReport: (state, _action: PayloadAction<string>) => {
      state.loadingReports = 'pending';
    },
    loadCurrentReportSuccess: (state, action: PayloadAction<Reports>) => {
      state.loadingReports = 'succeeded';

      const report = initMissingAttributesInReport(action.payload);
      state.currentReport = { report };
      if (!state.isSharedReport) {
        const offer = report.offer;
        const { contactOwner, contactCreator, contactRecipient, contactProjectOwner } = offer;
        state.currentContacts = [contactOwner, contactCreator, contactRecipient, contactProjectOwner]
          .filter(el => Boolean(el))
          .map(el => ({ contactObject: el }));
      }

      const reportDeviations = calculateDeviations(report.costPlan);
      state.currentDeviations = reportDeviations;
    },
    loadCurrentReportFailure: state => {
      state.loadingReports = 'failed';
    },
    loadCurrentThreshold: state => {
      state.loadingThreshold = 'pending';
    },
    loadCurrentThresholdSuccess: (state, action: PayloadAction<number>) => {
      state.loadingThreshold = 'succeeded';
      state.deviationThreshold = action.payload;
    },
    loadCurrentThresholdFailure: state => {
      state.loadingThreshold = 'failed';
    },
    updateCurrentReport: (state, _action: PayloadAction<Reports>) => {
      state.currentReport = { report: _action.payload };
    },
    updateIsSharedReport: (state, _action: PayloadAction<boolean>) => {
      state.isSharedReport = _action.payload;
    },
    updateReportStatus: (state, _action: PayloadAction<ApplicationNewState>) => {
      state.loadingCurrentReport = 'pending';
    },
    updateReportStatusSuccess: (state, action: PayloadAction<Reports>) => {
      const report = action.payload;
      state.loadingCurrentReport = 'succeeded';
      state.reports = [...state.reports.filter(el => el.ID !== report.ID), report];
    },
    updateReportStatusFailure: state => {
      state.loadingCurrentReport = 'failed';
    },
    updateReportAttachments: state => {
      state.loadingReports = 'pending';
    },
    updateReportAttachmentsSuccess: (state, action: PayloadAction<Reports>) => {
      const report = action.payload;
      state.loadingReports = 'succeeded';
      state.reports = [...state.reports.filter(el => el.ID !== report.ID), report];
    },
    updateReportAttachmentsFailure: state => {
      state.loadingReports = 'failed';
    },
    saveCurrentReport: (state, _action: PayloadAction<StateType>) => {
      state.loadingCurrentReport = 'pending';
    },
    saveCurrentReportSuccess: (state, action: PayloadAction<Reports>) => {
      const report = action.payload;
      state.loadingCurrentReport = 'succeeded';
      state.reports = [...state.reports.filter(el => el.ID !== report.ID), report];
    },
    saveCurrentReportFailure: state => {
      state.loadingCurrentReport = 'failed';
    },
    updateCurrentDeviations: (state, action: PayloadAction<CostDeviations>) => {
      state.currentDeviations = action.payload;
    },
  },
});

export const reportActions = reportSlice.actions;
export const reportReducer = reportSlice.reducer;

/** SAGAS */
function* onLoadReports(action: PayloadAction<string>) {
  const isOnline: boolean = yield select((state: AppState) => state.offline.isOnline);

  const query = action.payload || '';
  if (isOnline === false) {
    const localReports: Reports[] = yield select((state: AppState) => state.report.reports);
    yield put(reportActions.loadReportsSuccess(localReports || []));
    return;
  }

  yield put(reportActions.loadCurrentThreshold());

  const reports: Reports[] = yield call(() => Reports.getReportss(`${query} order by dueDate ASC`));

  try {
    const loadRefs = async (report: Reports): Promise<LoadReferencedDataResult> => {
      const res = await executeInSequence(
        () => tryLoad(report.loadOffer(), 'failed to load offer'),
        () => tryLoad(report.offer?.loadMeasure(), 'failed to load offer measure'),
        () => tryLoad(report.loadMeasure(), 'failed to load measure'),
        () => tryLoad(report.loadCostPlan(), 'failed to load cost plan'),
        () => tryLoad(report.costPlan?.loadCurrencyConversionTable(), 'failed to load cost plan currency conversion table')
      );

      return { id: report.ID, failures: res.filter(it => !!it) };
    };

    const res = yield all(reports.map(report => call(() => loadRefs(report))));
    yield put(reportActions.setIncompleteReports(res));

    yield put(reportActions.loadReportsSuccess(reports));
  } catch (err) {
    yield put(reportActions.loadReportsFailure());
    if (err.message) {
      yield put(notificationActions.showError(err.message));
    }
  }
}

function* onLoadSharedReports(action: PayloadAction<string>) {
  const isOnline: boolean = yield select((state: AppState) => state.offline.isOnline);
  const query = action.payload || '';
  if (isOnline === false) {
    const localSharedReports: Reports[] = yield select((state: AppState) => state.report.sharedReports);
    yield put(reportActions.loadSharedReportsSuccess(localSharedReports || []));
    return;
  }

  const sharedReports: Reports[] = yield call(() => Reports.getReportss(`${query} order by lastModifiedAt DESC`));
  try {
    const loadRefs = async (report: Reports): Promise<LoadReferencedDataResult> => {
      const res = await executeInSequence(
        () => tryLoad(report.loadMeasure(), 'failed to load measure'),
        () => tryLoad(report.loadCostPlan(), 'failed to load cost plan'),
        () => tryLoad(report.costPlan?.loadCurrencyConversionTable(), 'failed to load cost plan currency conversion table')
      );

      return { id: report.ID, failures: res.filter(it => !!it) };
    };

    const res = yield all(sharedReports.map(report => call(() => loadRefs(report))));
    yield put(reportActions.setIncompleteReports(res));

    yield put(reportActions.loadSharedReportsSuccess(sharedReports));
  } catch (err) {
    yield put(reportActions.loadSharedReportsFailure());
    if (err.message) {
      yield put(notificationActions.showError(err.message));
    }
  }
}

function* onLoadCurrentThreshold() {
  try {
    const currentSettings = yield call(() => getSettings());
    if (currentSettings.length !== 0) {
      const costCategoryThreshold = currentSettings[0].costCategoryThreshold;
      yield put(reportActions.loadCurrentThresholdSuccess(costCategoryThreshold));
    } else {
      yield put(notificationActions.showError('No threshold saved'));
      yield put(reportActions.loadCurrentThresholdFailure());
    }
  } catch (err) {
    yield put(notificationActions.showError('Threshold could not be found'));
    yield put(reportActions.loadCurrentThresholdFailure());
  }
}

function* onLoadCurrentReport(action: PayloadAction<any>) {
  const isOnline: boolean = yield select((state: AppState) => state.offline.isOnline);
  const isSharedReport: boolean = yield select((state: AppState) => state.report.isSharedReport);
  const id = action.payload;

  if (isOnline === false) {
    const reports: Reports[] = isSharedReport
      ? yield select((state: AppState) => state.report.sharedReports)
      : yield select((state: AppState) => state.report.reports);
    yield put(reportActions.loadCurrentReportSuccess(reports.find(el => el.ID === id)));
    return;
  }

  try {
    const report: Reports = new Reports();
    const href = `${Datastore.Instance.createModelHref(report)}/${id}`;
    yield call(() => report.load(href));
    if (!isSharedReport) {
      yield call(() => report.loadOffer());
      yield call(() => report.offer.loadMeasure());
    }
    yield call(() => report.loadMeasure());
    yield call(() => report.loadCostPlan());
    yield call(() => report.loadAttachments());

    yield call(() => report.costPlan.loadCurrencyConversionTable());

    yield put(reportActions.loadCurrentReportSuccess(report));
  } catch (e) {
    yield put(notificationActions.showError('No entry found'));
    yield put(reportActions.loadCurrentReportFailure());
  }
}

function* onUpdateReportStatus(action: PayloadAction<ApplicationNewState>) {
  const report: Reports = yield select((state: AppState) => state.report.currentReport.report);
  const newStatus = action.payload;
  try {
    const updatedReport = yield call(() => updateReportStatus(report, newStatus));
    yield put(notificationActions.showSuccessMessage(i18n.t('use-cases:new-assignment:root:saved-notification:title')));
    yield put(reportActions.updateReportStatusSuccess(updatedReport));

    if (newStatus.status === StateType.approved || newStatus.status === StateType.rejected) {
      yield put(push(`/report/all`));
    }
  } catch (e) {
    report.state.name = StateType.unsavedChanges;
    yield put(notificationActions.showError(i18n.t('use-cases:new-assignment:root:saving-failed-notification:body')));
    yield put(reportActions.updateReportStatusFailure());
  }
}

function* onUpdateReportAttachments() {
  const report: Reports = yield select((state: AppState) => state.report.currentReport.report);
  (report as any).dao.attachments = report.attachments.map(attachment => attachment.toJson());

  try {
    const updatedReport = yield call(() => updateOfferAttachments(report));
    yield call(() => report.loadAttachments());
    yield put(notificationActions.showSuccessMessage(i18n.t('use-cases:new-assignment:root:saved-notification:title')));
    yield put(reportActions.updateReportAttachmentsSuccess(updatedReport));
  } catch (error) {
    yield put(notificationActions.showError(i18n.t('use-cases:new-assignment:root:saving-failed-notification:body')));
    yield put(reportActions.updateReportAttachmentsFailure());
  }
}

function* onSaveCurrentReport(action: PayloadAction<StateType>) {
  const report: Reports = yield select((state: AppState) => state.report.currentReport.report);
  const isOnline: boolean = yield select((state: AppState) => state.offline.isOnline);

  const isCreation = Boolean(report.ID) === false;
  const newStatus = action.payload;
  prepareReportForSaving(report);

  if (isOnline === false) {
    yield put(offlineActions.addReportIntoQueue({ report, newStatus, mutationType: isCreation ? 'create' : 'save' }));
    report.state.name = StateType.unsavedChanges;

    if (isCreation) {
      yield put(push(`/tasks/report/new/${report.ID}`));
    }

    yield put(notificationActions.showSuccessMessage(i18n.t('assignment:status:offline')));
    yield put(reportActions.saveCurrentReportSuccess(report));

    return;
  }

  try {
    const updatedReport = yield call(() => saveReport(report, newStatus));
    yield put(reportActions.updateCurrentReport(updatedReport));

    yield put(notificationActions.showSuccessMessage(i18n.t('use-cases:new-assignment:root:saved-notification:title')));
    yield put(reportActions.saveCurrentReportSuccess(updatedReport));
    yield put(push(`/tasks/my`));
  } catch (e) {
    report.state.name = StateType.unsavedChanges;
    yield put(reportActions.updateCurrentReport(report));
    yield put(notificationActions.showError(i18n.t('use-cases:new-assignment:root:saving-failed-notification:body')));

    yield put(reportActions.saveCurrentReportFailure());
  }
}

export function* reportSaga() {
  yield all([takeLatest(reportActions.loadReports, onLoadReports)]);
  yield all([takeLatest(reportActions.loadCurrentReport, onLoadCurrentReport)]);
  yield all([takeLatest(reportActions.saveCurrentReport, onSaveCurrentReport)]);
  yield all([takeLatest(reportActions.updateReportStatus, onUpdateReportStatus)]);
  yield all([takeLatest(reportActions.updateReportAttachments, onUpdateReportAttachments),]);
  yield all([takeLatest(reportActions.loadCurrentThreshold, onLoadCurrentThreshold)]);
  yield all([takeLatest(reportActions.loadSharedReports, onLoadSharedReports)]);
}

/** TRANSFORMS */
export const reportTransform = createTransform(
  (state: ReportState) => {
    return {
      ...state,
      reports: state.reports.map(reportToJson),
      currentReport: state.currentReport?.report ? reportToJson(state.currentReport?.report) : null,
      currentContacts: [],
    };
  },
  json => {
    return {
      ...json,
      reports: json.reports && json.reports.map(reportFromJson),
      incompleteReports: json.incompleteReports || {},
      currentReport: null,
      currentContacts: [],
      loadingReports: 'idle',
      loadingCurrentReport: 'idle',
    };
  },
  { whitelist: ['report'] }
);
