import React, { useState } from 'react';
import { makeStyles, Theme, Paper, Table, TableBody, TableCell, TableHead, TableRow, Button, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import DropDown from '../Selection/DropDown';
import { DeleteButton } from '../Buttons/DeleteButton';
import { DocumentPickerButton } from '../Buttons/DocumentPickerButton';
import MAttachment from '../../sdk/com/apiomat/frontend/missio/MAttachment';
import { onDownloadFile } from '../../utils/file.utils';
import { getAttachmentTypeName } from '../../utils/form-templates.utils';
import { DropDownItem } from '../Selection/DropDownItem';

const useStyles = makeStyles((theme: Theme) => ({
  contentContainer: {
    paddingBottom: 30,
    paddingTop: 30,
  },
  dropDownContainer: {
    paddingRight: 12,
  },
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
}));

export interface OldAttachmentFormProps {
  readOnlyMode: boolean;
  data: any;
  showDropDown: boolean;
  mandatoryKeys?: string[];
  items: DropDownItem[];
  update: (data: any) => void;
}

const OldAttachmentForm = (props: OldAttachmentFormProps) => {
  const { readOnlyMode, showDropDown, data, mandatoryKeys, items, update } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const [attachmentType, setAttachmentType] = useState<string>('');

  const attachments = data?.attachments;

  const onDocumentSelected = (file: string | ArrayBuffer | null, name: string) => {
    if (!file) {
      throw new Error('Error while selecting file');
    }

    const attachment = new MAttachment();
    attachment.attachmentType = attachmentType;
    attachment.name = name;
    (attachment as any).dao.file = file;
    (attachment as any).dao.isDeleted = false;
    attachments.push(attachment);

    update(data);
    setAttachmentType('');
  };

  const onDeleteAttachment = (index: number) => {
    if (Boolean(attachments[index].ID)) {
      (attachments[index] as any).dao.isDeleted = true;
    } else {
      attachments.splice(index, 1);
    }
    (data as any).dao.attachments = attachments;
    (data as any).hashmap.attachments = attachments;
    update(data);
  };

  return (
    <div className={classes.contentContainer}>
      {!readOnlyMode && showDropDown && (
        <div>
          <DropDown
            labelKey="attachments:attachment-type"
            items={items}
            selectedItemKey={attachmentType}
            onSelectedItemChange={(key: string) => setAttachmentType(key)}
            highlightedItemKeys={mandatoryKeys}
          />
          {Boolean(attachmentType) && <DocumentPickerButton onDocumentSelected={(file, name) => onDocumentSelected(file, name)} />}
        </div>
      )}
      <div>
        <Paper className={classes.root}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>{t('label:attachments-table-name')}</TableCell>
                <TableCell align="right">{t('label:attachments-table-type')}</TableCell>
                <TableCell align="right">{t('label:attachments-table-actions')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {attachments
                .filter(item => Boolean((item as any).dao.isDeleted) === false)
                .map((item, i) => (
                  <TableRow key={i}>
                    <TableCell component="th" scope="row">
                      {item.name}
                    </TableCell>
                    <TableCell align="right">{t(getAttachmentTypeName(item))}</TableCell>
                    <TableCell align="right">
                      <Grid container spacing={1} justify="flex-end">
                        {item.fileURL != null && (
                          <Grid item>
                            <Button color="primary" variant="outlined" onClick={() => onDownloadFile(item, 'file')}>
                              {t('view')}
                            </Button>
                          </Grid>
                        )}
                        {!readOnlyMode && (
                          <Grid item>
                            <DeleteButton onDeleteConfirmed={() => onDeleteAttachment(i)} type="icon" />
                          </Grid>
                        )}
                      </Grid>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Paper>
      </div>
    </div>
  );
};

export default OldAttachmentForm;
