import React, { useCallback, useEffect, useState } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { DefaultTextField } from '../../TextInputs/TextInputs';
import { useTranslation } from 'react-i18next';
import DatePicker from '../../DatePicker/DatePicker';
import Contact from '../../../sdk/com/apiomat/frontend/missio/Contact';
import DropDown from '../../Selection/DropDown';
import { countries } from '../../../value-maps/Countries';
import { alphabeticalSort } from '../../../utils/sort.util';
import LanguageSelection from '../../Localization/LanguageSelection';
import { isEmailValid } from '../../../utils/email.utils';
import moment from 'moment';
import { genderDropDownItem } from '../../../value-maps/GenderDropDown';
import { fromStringToDate } from '../../../utils/transforms';
import { DropDownItem } from '../../Selection/DropDownItem';
import FormTextField from '../InputFields/FormTextField';
import { isEmpty } from 'lodash';
import usePrevious from '../../../hooks/usePrevious';

const useStyles = makeStyles(() => ({
  contentContainer: {
    paddingLeft: 18,
    paddingRight: 18,
  },
  gridContainer: {
    paddingBottom: 16,
  },
}));

export interface GeneralContactFormProps {
  contact: Contact;
  updateContact: (contact: Contact, isValid: boolean) => void;
  isReadonly: boolean;
}

const GeneralContactForm = (props: GeneralContactFormProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isReadonly, contact, updateContact } = props;
  const [ contactType, setContactType ] = useState<string | undefined>(contact.contactType);
  const [ firstName, setFirstName ] = useState<string | undefined>(contact.firstName);
  const [ lastName, setLastName ] = useState<string | undefined>(contact.lastName);
  const prevFirstName = usePrevious(firstName);
  const prevLastName = usePrevious(lastName);

  const countryItems: DropDownItem[] = countries.map(el => ({ key: el.key, value: t(el.key) })).sort(alphabeticalSort);

  const genderDropDownItems: DropDownItem[] = genderDropDownItem
    .map(el => ({
      key: el.key,
      value: t(el.value as string),
    }))
    .sort(alphabeticalSort);

  const isEmailEmpty = (): boolean => {
    return isEmpty(contact.email);
  };

  const isEmailInvalid = (): boolean => {
    return !isEmpty(contact.email) && !isEmailValid(contact.email);
  };

  const isSecondEmailInvalid = (): boolean => {
    return !isEmpty(contact.secondEmail) && !isEmailValid(contact.secondEmail);
  };

  const isValid = useCallback(() => {
    return !isEmpty(firstName) && !isEmpty(lastName) && !(isEmailEmpty() || isEmailInvalid()) && !isSecondEmailInvalid() && !isEmpty(contact.street) && !isEmpty(contact.place) && !isEmpty(contact.country);
  }, [ firstName, lastName, contact.email, contact.secondEmail, contact.street, contact.place, contact.country ]);

  useEffect(() => {
    if (firstName !== prevFirstName || lastName !== prevLastName || contactType !== contact.contactType) {
      setContactType(contact.contactType);
      updateContact(contact, isValid());
    }
  }, [ contact, firstName, lastName, prevFirstName, prevLastName, updateContact ]);

  return (
    <div className={ classes.contentContainer }>
      <Grid container spacing={ 3 } alignItems="flex-end" className={ classes.gridContainer }>
        <Grid item xs={ 6 }>
          <DefaultTextField
            labelKey="input:label:contact-salutation"
            value={ contact.salutation }
            onChange={ event => {
              contact.salutation = event.target.value;
              updateContact(contact, isValid());
            } }
            disabled={ isReadonly }
          />
        </Grid>
        <Grid item xs={ 6 }>
          <DefaultTextField
            labelKey="input:label:contact-academic-title"
            value={ contact.academicTitle }
            onChange={ event => {
              contact.academicTitle = event.target.value;
              updateContact(contact, isValid());
            } }
            disabled={ isReadonly }
          />
        </Grid>
      </Grid>
      <Grid container spacing={ 3 } alignItems="flex-start" className={ classes.gridContainer }>
        <Grid item xs={ 4 }>
          <FormTextField
            id="firstName"
            name="firstName"
            labelKey="input:label:contact-first-name"
            value={ firstName }
            onChange={ event => {
              const value = event.target.value;
              setFirstName(value);
              contact.firstName = value;
              updateContact(contact, isValid());
            } }
            disabled={ isReadonly }
            errorMessage={ isEmpty(firstName) ? t('mandatory_field') : undefined }
          />
        </Grid>
        <Grid item xs={ 4 }>
          <DefaultTextField
            labelKey="input:label:contact-second-first-name"
            value={ contact.secondFirstName }
            onChange={ event => {
              contact.secondFirstName = event.target.value;
              updateContact(contact, isValid());
            } }
            disabled={ isReadonly }
          />
        </Grid>
        <Grid item xs={ 4 }>
          <FormTextField
            id="lastName"
            name="lastName"
            labelKey="input:label:contact-last-name"
            value={ lastName }
            onChange={ event => {
              const value = event.target.value;
              setLastName(value);
              contact.lastName = value;
              updateContact(contact, isValid());
            } }
            disabled={ isReadonly }
            errorMessage={ isEmpty(lastName) ? t('mandatory_field') : undefined }
          />
        </Grid>
      </Grid>
      <Grid container spacing={ 3 } alignItems="flex-end">
        <Grid item xs={ 4 }>
          <DefaultTextField
            labelKey="input:label:contact-title"
            value={ contact.title }
            onChange={ event => {
              contact.title = event.target.value;
              updateContact(contact, isValid());
            } }
            disabled={ isReadonly }
          />
        </Grid>
        <Grid item xs={ 4 }>
          <DefaultTextField
            labelKey="input:label:contact-title-after-last-name"
            value={ contact.titleAfterLastName }
            onChange={ event => {
              contact.titleAfterLastName = event.target.value;
              updateContact(contact, isValid());
            } }
            disabled={ isReadonly }
          />
        </Grid>
        <Grid item xs={ 4 }>
          <DefaultTextField
            labelKey="input:label:contact-religious-symbol"
            value={ contact.religiousSymbol }
            onChange={ event => {
              contact.religiousSymbol = event.target.value;
              updateContact(contact, isValid());
            } }
            disabled={ isReadonly }
          />
        </Grid>
      </Grid>
      <Grid container spacing={ 3 } alignItems="flex-end" className={ classes.gridContainer }>
        <Grid item xs={ 6 }>
          <DropDown
            labelKey="input:label:contact-gender"
            selectedItemKey={ contact.gender }
            items={ genderDropDownItems }
            onSelectedItemChange={ (key: string) => {
              contact.gender = key;
              updateContact(contact, isValid());
            } }
            disabled={ isReadonly }
          />
        </Grid>
        <Grid item xs={ 6 }>
          <LanguageSelection
            language={ contact.communicationLanguage }
            inputLabel={ 'input:label:contact-communication-language' }
            onChange={ (key: string) => {
              contact.communicationLanguage = key;
              updateContact(contact, isValid());
            } }
            isReadOnly={ isReadonly }
          />
        </Grid>
      </Grid>
      <Grid container spacing={ 3 } alignItems="flex-start" className={ classes.gridContainer }>
        <Grid item xs={ 6 }>
          <DefaultTextField
            labelKey="input:label:contact-email"
            value={ contact.email }
            onChange={ event => {
              contact.email = event.target.value;
              updateContact(contact, isValid());
            } }
            disabled={ isReadonly }
            isError={ isEmailEmpty() || isEmailInvalid() }
            helperTextKey={ isEmailEmpty() ? 'mandatory_field' : isEmailInvalid() ? 'invalid_email_address' : null }
          />
        </Grid>
        <Grid item xs={ 6 }>
          <DefaultTextField
            labelKey="input:label:contact-second-email"
            value={ contact.secondEmail }
            onChange={ event => {
              contact.secondEmail = event.target.value;
              updateContact(contact, isValid());
            } }
            disabled={ isReadonly }
            isError={ isSecondEmailInvalid() }
            helperTextKey={ isSecondEmailInvalid() ? 'invalid_email_address' : null }
          />
        </Grid>
      </Grid>
      <Grid container spacing={ 3 } alignItems="flex-start" className={ classes.gridContainer }>
        <Grid item xs={ 4 }>
          <DefaultTextField
            labelKey="input:label:contact-phone-number"
            value={ contact.phone }
            onChange={ event => {
              contact.phone = event.target.value;
              updateContact(contact, isValid());
            } }
            disabled={ isReadonly }
          />
        </Grid>
        <Grid item xs={ 4 }>
          <DefaultTextField
            labelKey="input:label:contact-second-phone-number"
            value={ contact.secondPhone }
            onChange={ event => {
              contact.secondPhone = event.target.value;
              updateContact(contact, isValid());
            } }
            disabled={ isReadonly }
          />
        </Grid>
        <Grid item xs={ 4 }>
          <DefaultTextField
            labelKey="input:label:contact-marital-status"
            value={ contact.maritalStatus }
            onChange={ event => {
              contact.maritalStatus = event.target.value;
              updateContact(contact, isValid());
            } }
            disabled={ isReadonly }
          />
        </Grid>
      </Grid>
      <Grid container spacing={ 3 } alignItems="flex-start">
        <Grid item xs={ 6 }>
          <DefaultTextField
            labelKey="input:label:contact-birth-name"
            value={ contact.birthName }
            onChange={ event => {
              contact.birthName = event.target.value;
              updateContact(contact, isValid());
            } }
            disabled={ isReadonly }
          />
        </Grid>
        <Grid item xs={ 6 }>
          <DatePicker
            label={ t('input:label:contact-birthday') }
            value={ contact.dateOfBirth }
            isReadonly={ isReadonly }
            onChange={ (date: Date) => {
              contact.dateOfBirth = date;
              updateContact(contact, isValid());
            } }
            disableFuture={ true }
          />
        </Grid>
      </Grid>
      <Grid container spacing={ 3 } alignItems="flex-end" className={ classes.gridContainer }>
        <Grid item xs={ 4 }>
          <DefaultTextField
            labelKey="input:label:contact-place-of-birth"
            value={ contact.placeOfBirth }
            onChange={ event => {
              contact.placeOfBirth = event.target.value;
              updateContact(contact, isValid());
            } }
            disabled={ isReadonly }
          />
        </Grid>
        <Grid item xs={ 4 }>
          <DropDown
            labelKey="input:label:contact-nationality"
            selectedItemKey={ contact.nationality }
            items={ countryItems }
            onSelectedItemChange={ (key: string) => {
              contact.nationality = key;
              updateContact(contact, isValid());
            } }
            disabled={ isReadonly }
          />
        </Grid>
        <Grid item xs={ 4 }>
          <DefaultTextField
            labelKey="input:label:contact-denomination"
            value={ contact.denomination }
            onChange={ event => {
              contact.denomination = event.target.value;
              updateContact(contact, isValid());
            } }
            disabled={ isReadonly }
          />
        </Grid>
      </Grid>
      <Grid container spacing={ 3 } alignItems="flex-start">
        <Grid item xs={ 4 }>
          <DatePicker
            label={ t('input:label:contact-priest-consecrate') }
            value={ fromStringToDate(contact.priestConsecrate) }
            onChange={ (date: Date) => {
              contact.priestConsecrate = moment(date)
                .startOf('year')
                .valueOf()
                .toString();
              updateContact(contact, isValid());
            } }
            isReadonly={ isReadonly }
            disableFuture={ true }
            views={ [ 'year' ] }
          />
        </Grid>
        <Grid item xs={ 4 }>
          <DatePicker
            label={ t('input:label:contact-professorship-year') }
            value={ fromStringToDate(contact.professorshipYear) }
            isReadonly={ isReadonly }
            onChange={ (date: Date) => {
              contact.professorshipYear = moment(date)
                .startOf('year')
                .valueOf()
                .toString();
              updateContact(contact, isValid());
            } }
            views={ [ 'year' ] }
            disableFuture={ true }
          />
        </Grid>
        <Grid item xs={ 4 }>
          <DefaultTextField
            labelKey="input:label:contact-current-position"
            value={ contact.currentPosition }
            onChange={ event => {
              contact.currentPosition = event.target.value;
              updateContact(contact, isValid());
            } }
            disabled={ isReadonly }
          />
        </Grid>
      </Grid>
      <Grid container spacing={ 3 } alignItems="flex-end" className={ classes.gridContainer }>
        <Grid item xs={ 3 }>
          <DropDown
            labelKey="input:label:contact-diocese-country"
            selectedItemKey={ contact.dioceseCountry }
            items={ countryItems }
            onSelectedItemChange={ (key: string) => {
              contact.dioceseCountry = key;
              updateContact(contact, isValid());
            } }
            disabled={ isReadonly }
          />
        </Grid>
        <Grid item xs={ 3 }>
          <DefaultTextField
            labelKey="input:label:contact-diocese"
            value={ contact.diocese }
            onChange={ event => {
              contact.diocese = event.target.value;
              updateContact(contact, isValid());
            } }
            disabled={ isReadonly }
          />
        </Grid>
        <Grid item xs={ 3 }>
          <DefaultTextField
            labelKey="input:label:contact-religious-name"
            value={ contact.religiousName }
            onChange={ event => {
              contact.religiousName = event.target.value;
              updateContact(contact, isValid());
            } }
            disabled={ isReadonly }
          />
        </Grid>
        <Grid item xs={ 3 }>
          <DefaultTextField
            labelKey="input:label:contact-province-of-religious"
            value={ contact.provinceOfReligious }
            onChange={ event => {
              contact.provinceOfReligious = event.target.value;
              updateContact(contact, isValid());
            } }
            disabled={ isReadonly }
          />
        </Grid>
      </Grid>
      <Grid container spacing={ 3 } alignItems="flex-start" className={ classes.gridContainer }>
        <Grid item xs={ 4 }>
          <DefaultTextField
            labelKey="input:label:contact-address"
            value={ contact.street }
            onChange={ event => {
              contact.street = event.target.value;
              updateContact(contact, isValid());
            } }
            disabled={ isReadonly }
            isError={ isEmpty(contact.street) }
            helperTextKey={ isEmpty(contact.street) ? 'mandatory_field' : null }
          />
        </Grid>
        <Grid item xs={ 4 }>
          <DefaultTextField
            labelKey="input:label:contact-postal-code"
            value={ contact.zipCode }
            onChange={ event => {
              contact.zipCode = event.target.value;
              updateContact(contact, isValid());
            } }
            disabled={ isReadonly }
          />
        </Grid>
        <Grid item xs={ 4 }>
          <DefaultTextField
            labelKey="input:label:contact-city"
            value={ contact.place }
            onChange={ event => {
              contact.place = event.target.value;
              updateContact(contact, isValid());
            } }
            disabled={ isReadonly }
            isError={ isEmpty(contact.place) }
            helperTextKey={ isEmpty(contact.place) ? 'mandatory_field' : null }
          />
        </Grid>
      </Grid>
      <Grid container spacing={ 3 } alignItems="flex-start">
        <Grid item xs={ 4 }>
          <DropDown
            labelKey="input:label:contact-country"
            outlinedTitle={ true }
            selectedItemKey={ contact.country }
            items={ countryItems }
            onSelectedItemChange={ (key: string) => {
              contact.country = key;
              updateContact(contact, isValid());
            } }
            disabled={ isReadonly }
            errorMessage={ isEmpty(contact.country) ? t('mandatory_field') : null }
          />
        </Grid>
        <Grid item xs={ 4 }>
          <DefaultTextField
            labelKey="input:label:contact-region"
            value={ contact.region }
            onChange={ event => {
              contact.region = event.target.value;
              updateContact(contact, isValid());
            } }
            disabled={ isReadonly }
          />
        </Grid>
        <Grid item xs={ 4 }>
          <DefaultTextField
            labelKey="input:label:contact-language"
            value={ contact.language }
            onChange={ event => {
              contact.language = event.target.value;
              updateContact(contact, isValid());
            } }
            disabled={ isReadonly }
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default GeneralContactForm;
