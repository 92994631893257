import React from 'react';
import { makeStyles, List, ListItem, ListItemIcon, ListItemText, Divider, ListSubheader } from '@material-ui/core';
import Assignment from '@material-ui/icons/Assignment';
import AssignmentInd from '@material-ui/icons/AssignmentInd';
import Person from '@material-ui/icons/Person';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { AssignmentTurnedIn, LibraryBooks, ListAlt, NoteAdd, Receipt, GetApp } from '@material-ui/icons';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { GroupListItem } from '../../value-maps/GroupListItem';

const useStyles = makeStyles(theme => ({
  itemIcon: {
    color: theme.palette.primary.light,
  },
  groupHeaderOpen: {
    paddingRight: 16,
    paddingLeft: 16,
    color: 'rgba(255,255,255,1)',
  },
}));

export interface DefaultGroupListProps {
  group: { key: string; translationId: string; items: GroupListItem[] };
  externalLink?: boolean;
}

const GroupList = (props: DefaultGroupListProps) => {
  const { group, externalLink } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const getIconByName = (name: string): JSX.Element => {
    switch (name) {
      case 'Assignment':
        return <Assignment className={classes.itemIcon} />;
      case 'AssignmentInd':
        return <AssignmentInd className={classes.itemIcon} />;
      case 'Person':
        return <Person className={classes.itemIcon} />;
      case 'InsertDriveFile':
        return <InsertDriveFileIcon className={classes.itemIcon} />;
      case 'AssignmentTurnedIn':
        return <AssignmentTurnedIn className={classes.itemIcon} />;
      case 'NoteAdd':
        return <NoteAdd className={classes.itemIcon} />;
      case 'LibraryBooks':
        return <LibraryBooks className={classes.itemIcon} />;
      case 'ListAlt':
        return <ListAlt className={classes.itemIcon} />;
      case 'Receipt':
        return <Receipt className={classes.itemIcon} />;
      case 'GetApp':
        return <GetApp className={classes.itemIcon} />;
      default:
        return <Assignment className={classes.itemIcon} />;
    }
  };

  return (
    <List
      key={group.key}
      subheader={
        <ListSubheader className={classes.groupHeaderOpen} component="div">
          {t(group.translationId)}
        </ListSubheader>
      }
    >
      <Divider />
      <List disablePadding>
        {group.items.map(item => (
          <ListItem
            key={item.key}
            button
            component={externalLink ? 'a' : Link}
            to={item.key}
            href={item.key}
            target={externalLink ? '_blank' : ''}
            selected={pathname === item.key}
          >
            <ListItemIcon>{getIconByName(item.icon)}</ListItemIcon>
            <ListItemText>{t(item.translationId)}</ListItemText>
          </ListItem>
        ))}
      </List>
    </List>
  );
};

export default GroupList;
