import React, { FunctionComponent } from 'react';
import { FormControl, FormHelperText, FormLabel, MenuItem, Select, Theme } from '@mui/material';
import { DropDownItem } from './DropDownItem';
import { InputLabel, makeStyles } from '@material-ui/core';
import { v4 as uuid } from 'uuid';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& .MuiOutlinedInput-root': {
      backgroundColor: 'white',
    },
  },
  formControl: {
    marginBottom: 8,
    minWidth: 100,
  },
  highlighted: {
    fontWeight: 'bold',
  },
  spacer: {
    marginTop: 20,
  },
  errorText: {
    '&.MuiFormHelperText-root.Mui-error': {
      color: 'orange',
    },
  },
  formLabel: {
    marginLeft: 14,
    marginRight: 14,
    marginBottom: 8,
    '&.MuiFormLabel-root': {
      fontWeight: 400,
      fontSize: '0.75rem',
      lineHeight: '1.66',
      letterSpacing: '0.03333em',
    },
    '&.MuiFormLabel-root.Mui-error': {
      color: 'orange',
    },
  },
  outlinedTitle: {
    transform: 'translate(14px, -6px) scale(0.75)',
    top: 0,
    left: 0,
    position: 'absolute',
    zIndex: 1,
    background: 'white',
  }
}));

interface DropDownProps {
  title?: string;
  helperText?: string;
  selectedItemKey: string;
  disabled?: boolean;
  items: DropDownItem[];
  highlightedItemKeys?: string[];
  onSelectedItemChange: (key: string) => void;
  errorMessage?: string;
  outlinedTitle?: boolean;
}

const SimpleDropdown: FunctionComponent<DropDownProps> = (
  {
    title,
    helperText,
    selectedItemKey,
    disabled,
    items,
    onSelectedItemChange,
    highlightedItemKeys,
    errorMessage,
    outlinedTitle,
  }
) => {
  const classes = useStyles();
  const labelId = uuid();

  return (
    <FormControl aria-expanded="false" fullWidth error={ Boolean(errorMessage) } variant="outlined"
                 className={ `${ classes.root } ${ title && classes.formControl }` }>
      { title && !outlinedTitle && <FormLabel classes={ { root: classes.formLabel } }>{ title }</FormLabel> }
      { title && outlinedTitle && <InputLabel id={ labelId } classes={ { root: classes.outlinedTitle } }>{ title }</InputLabel> }
      <Select
        labelId={ labelId }
        label={ title && outlinedTitle ? outlinedTitle : '' }
        value={ selectedItemKey || '' }
        variant="outlined"
        disabled={ disabled }
        onChange={ e => onSelectedItemChange(e.target.value as string) }>
        { items.map(item => (
          <MenuItem
            className={ highlightedItemKeys && highlightedItemKeys.includes(item.key) ? classes.highlighted : '' }
            selected={ item.key === selectedItemKey }
            key={ item.key }
            value={ item.key }>
            { item.value }
          </MenuItem>
        )) }
      </Select>
      { helperText && <FormHelperText>{ helperText }</FormHelperText> }
      { errorMessage && <FormHelperText classes={ { root: classes.errorText } }>{ errorMessage }</FormHelperText> }
    </FormControl>
  );
};

export default SimpleDropdown;
