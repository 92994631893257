import React, { useState, useEffect } from 'react';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import Login from '../containers/Login';
import NotFoundPage from '../containers/NotFoundPage';
import VerifyEmailPage from '../containers/VerifyEmailPage';
import Register from '../containers/Register';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../store/index';
import { authActions } from '../store/auth';
import PrivateRoute from '../components/PrivateRoute/PrivateRoute';
import ForgotPassword from '../containers/ForgotPassword';
import FullScreenLoadingIndicator from '../components/Loading/FullScreenLoadingIndicator';
import EditProfile from '../containers/EditProfile';
import UserManagement from '../containers/UserManagement';
import DefaultLayout from '../components/Layout/DefaultLayout';
import { offlineActions } from '../store/offline';
import NewAssignmentMis from '../containers/Assignment/NewAssignmentMis';
import NewAssignmentMwi from '../containers/Assignment/NewAssignmentMwi';
import MyAssignment from '../containers/Assignment/MyAssignment';
import AllAssignments from '../containers/Assignment/AllAssignments';
import FormTemplatesView from '../containers/Templates/FormTemplatesView';
import FormTemplates from '../containers/Templates/FormTemplates';
import AllFormTemplates from '../containers/Templates/AllFormTemplates';
import CostPlansOverview from '../containers/Assignment/CostPlansOverview';
import AllReports from '../containers/Report/AllReports';
import Tasks from '../containers/Tasks/Tasks';
import NewReport from '../containers/Tasks/NewReport';
import NewEvaluation from '../containers/Tasks/NewEvaluation';
import NewAudit from '../containers/Tasks/NewAudit';
import MySurveys from '../containers/Survey/MySurveys';
import NewSurvey from '../containers/Survey/NewSurvey';
import MyTasks from '../containers/Tasks/MyTasks';

const AppRouter = () => {
  const { token, isAdmin, loadingByToken, isAuthenticated } = useSelector((state: AppState) => state.auth);
  const { loading } = useSelector((state: AppState) => state.offline);
  const dispatch = useDispatch();

  const [firstLoading, setFirstLoading] = useState<boolean>(true);

  useEffect(() => {
    dispatch(offlineActions.changeOnlineState(navigator.onLine));
    window.addEventListener('online', () => dispatch(offlineActions.changeOnlineState(true)));
    window.addEventListener('offline', () => dispatch(offlineActions.changeOnlineState(false)));

    if (token) {
      dispatch(authActions.loginWithToken());
    }
    setFirstLoading(false);
  }, [dispatch, token]);

  if ((token && (firstLoading || loadingByToken === 'pending')) || loading === 'pending') {
    return <FullScreenLoadingIndicator />;
  }

  return (
    <HashRouter>
      <Switch>
        <Route exact path="/" render={() => <Redirect to={isAuthenticated ? '/assignment/my' : '/login'} />} />
        <Route exact path={'/login'} component={Login} />
        <Route exact path={'/register'} component={Register} />
        <Route exact path={'/forgot-password'} component={ForgotPassword} />
        <Route exact path={'/verifyEmail'} component={VerifyEmailPage} />
        <DefaultLayout isAuthenticated={isAuthenticated}>
          <Switch>
            <PrivateRoute exact path={'/assignment/mis/new'} isAuthenticated={isAuthenticated}>
              <NewAssignmentMis />
            </PrivateRoute>
            <PrivateRoute exact path={'/assignment/mis/:id'} isAuthenticated={isAuthenticated}>
              <NewAssignmentMis />
            </PrivateRoute>
            <PrivateRoute exact path={'/assignment/mis/:mode/:id'} isAuthenticated={isAuthenticated}>
              <NewAssignmentMis />
            </PrivateRoute>
            <PrivateRoute exact path={'/assignment/mwi/new'} isAuthenticated={isAuthenticated}>
              <NewAssignmentMwi />
            </PrivateRoute>
            <PrivateRoute exact path={'/assignment/mwi/:id'} isAuthenticated={isAuthenticated}>
              <NewAssignmentMwi />
            </PrivateRoute>
            <PrivateRoute exact path={'/assignment/mwi/:mode/:id'} isAuthenticated={isAuthenticated}>
              <NewAssignmentMwi />
            </PrivateRoute>
            <PrivateRoute exact path={'/assignment/my'} isAuthenticated={isAuthenticated} component={MyAssignment} />
            <PrivateRoute exact path={'/report/all'} isAuthenticated={isAuthenticated} component={AllReports} />
            <PrivateRoute exact path={'/tasks/my'} isAuthenticated={isAuthenticated} component={Tasks} />
            <PrivateRoute exact path={'/tasks/my/filter/:offerId'} isAuthenticated={isAuthenticated} component={Tasks} />
            <PrivateRoute exact path={'/survey/reports/:offerId/:isSurvey'} isAuthenticated={isAuthenticated} component={MyTasks} />
            <PrivateRoute exact path={'/tasks/report/new/:id'} isAuthenticated={isAuthenticated} component={NewReport} />
            <PrivateRoute exact path={'/tasks/evaluation/new/:id'} isAuthenticated={isAuthenticated} component={NewEvaluation} />
            <PrivateRoute exact path={'/tasks/audit/new/:id'} isAuthenticated={isAuthenticated} component={NewAudit} />
            <PrivateRoute exact path={'/surveys/my'} isAuthenticated={isAuthenticated} component={MySurveys} />
            <PrivateRoute exact path={'/survey/new/:id'} isAuthenticated={isAuthenticated} component={NewSurvey} />
            <PrivateRoute exact path={'/assignment/cost-plan/:id'} isAuthenticated={isAuthenticated} component={CostPlansOverview} />
            <PrivateRoute exact path={'/assignment/all'} isAuthenticated={isAdmin} component={AllAssignments} />
            <PrivateRoute exact path={'/form-templates'} isAuthenticated={isAuthenticated} component={FormTemplates} />
            <PrivateRoute exact path={'/form-templates/:measureKey'} isAuthenticated={isAuthenticated} component={FormTemplatesView} />
            <PrivateRoute exact path={'/form-templates-admin'} isAuthenticated={isAdmin} component={AllFormTemplates} />
            <PrivateRoute exact path={'/user-management'} isAuthenticated={isAdmin} component={UserManagement} />
            <PrivateRoute exact path={'/my-account'} isAuthenticated={isAuthenticated} component={EditProfile} />
          </Switch>
        </DefaultLayout>
        <Route component={NotFoundPage} />
      </Switch>
    </HashRouter>
  );
};

export default AppRouter;
