import React from 'react';
import { MisMeasureTypes } from '../../../enums/MeasureTypes';
import ConferenceForm from './mis-measures/ConferenceForm';
import EquipmentPurchase from './mis-measures/EquipmentPurchase';
import Vehicle from './mis-measures/Vehicle';
import EvaluationForm from './mis-measures/EvaluationForm';
import PastoralProgram from './mis-measures/PastoralProgram';
import RealEstate from './mis-measures/RealEstate';
import MediaForm from './mis-measures/MediaForm';
import TrainingPrograms from './mis-measures/TrainingPrograms';
import PublicationForm from './mis-measures/PublicationForm';
import ScholarshipCongregation from './mis-measures/ScholarshipCongregation';
import ScholarshipInstitute from './mis-measures/ScholarshipInstitute';
import TechnologyPurchase from './mis-measures/TechnologyPurchase';
import ScholarshipForm from './mis-measures/ScholarshipForm';
import Maintenance from './mis-measures/Maintenance';

export interface MisMeasureFieldsProps {
  measureName: string;
  data: any;
  readOnlyMode: boolean;
  isReport?: boolean;
}

const MisMeasureFields = (props: MisMeasureFieldsProps) => {
  const { readOnlyMode, measureName, data, isReport } = props;
  switch (measureName) {
    case MisMeasureTypes.conference:
      return <ConferenceForm isReport={isReport} data={data} readOnlyMode={readOnlyMode} />;
    case MisMeasureTypes.vehicle:
      return <Vehicle isReport={isReport} data={data} readOnlyMode={readOnlyMode} />;
    case MisMeasureTypes.equipmentPurchase:
      return <EquipmentPurchase isReport={isReport} data={data} readOnlyMode={readOnlyMode} />;
    case MisMeasureTypes.evaluation:
      return <EvaluationForm isReport={isReport} data={data} readOnlyMode={readOnlyMode} />;
    case MisMeasureTypes.pastoralProgram:
      return <PastoralProgram isReport={isReport} data={data} readOnlyMode={readOnlyMode} />;
    case MisMeasureTypes.realEstate:
      return <RealEstate isReport={isReport} data={data} readOnlyMode={readOnlyMode} />;
    case MisMeasureTypes.media:
      return <MediaForm isReport={isReport} data={data} readOnlyMode={readOnlyMode} />;
    case MisMeasureTypes.trainingPrograms:
      return <TrainingPrograms isReport={isReport} data={data} readOnlyMode={readOnlyMode} />;
    case MisMeasureTypes.publication:
      return <PublicationForm isReport={isReport} data={data} readOnlyMode={readOnlyMode} />;
    case MisMeasureTypes.scholarshipCongregation:
      return <ScholarshipCongregation isReport={isReport} data={data} readOnlyMode={readOnlyMode} />;
    case MisMeasureTypes.scholarshipInstitute:
      return <ScholarshipInstitute isReport={isReport} data={data} readOnlyMode={readOnlyMode} />;
    case MisMeasureTypes.technologyPurchase:
      return <TechnologyPurchase isReport={isReport} data={data} readOnlyMode={readOnlyMode} />;
    case MisMeasureTypes.scholarship:
      return <ScholarshipForm isReport={isReport} data={data} readOnlyMode={readOnlyMode} />;
    case MisMeasureTypes.maintenance:
      return <Maintenance isReport={isReport} data={data} readOnlyMode={readOnlyMode} />;
    default:
      return <div />;
  }
};

export default MisMeasureFields;
