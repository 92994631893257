import { createStyles, makeStyles } from '@material-ui/core';

export const useMwiMeasuresStyles = makeStyles(() =>
  createStyles({
    textField: {
      paddingBottom: 25,
      paddingTop: 5,
    },
    questionContainer: {
      flex: 1,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    questionScholarship: {
      flex: 1,
      display: 'flex',
      justifyContent: 'space-between',
      maxWidth: '200px',
    },
    questionOne: {
      flex: 1,
      display: 'flex',
      justifyContent: 'space-around',
      maxWidth: '500px',
    },
    questionTwo: {
      flex: 1,
      display: 'flex',
      justifyContent: 'space-around',
      maxWidth: '400px',
    },
    dialogButton: {
      alignSelf: 'center',
    },
  })
);
