import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DefaultDialog from '../../Dialogs/DefaultDialog';
import { PrimaryButton } from '../../Buttons/Buttons';
import { makeStyles, Tab, Tabs } from '@material-ui/core';
import EditActualCostPlanForm from './EditActualCostPlanForm';
import CostPlan from '../../../sdk/com/apiomat/frontend/missio/CostPlan';
import { reportActions } from '../../../store/report';
import { useDispatch, useSelector } from 'react-redux';
import { calculateDeviations } from '../../../utils/report.utils';
import { AppState } from '../../../store';
import EditCurrencyTableForm from './EditCurrencyTableForm';

const useStyles = makeStyles(() => ({
  tabContainer: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  tab: {
    color: 'rgb(79, 195, 247)',
    fontSize: 18,
  },
}));

export interface ReportCostPlanFormDialogProps {
  isOpen: boolean;
  isReadonly: boolean;
  costPlan: CostPlan;
  updateCostPlan?: (costPlan: CostPlan) => void;
  onSaveButtonClick?: () => void;
  onCancelButtonClick: () => void;
  tabIndex?: number;
}

const ReportCostPlanFormDialog = (props: ReportCostPlanFormDialogProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isOpen, costPlan, isReadonly, onCancelButtonClick, updateCostPlan, onSaveButtonClick, tabIndex } = props;
  const { currentReport, deviationThreshold } = useSelector((state: AppState) => state.report);

  const [activeTabIndex, setActiveTabIndex] = useState<number>(tabIndex ? tabIndex : 0);

  if (!costPlan) {
    return null;
  }

  const getExchangeRate = (currencyName: string): number => {
    const currencyRate = costPlan.currencyRates.find(currencyRate => currencyRate.currency === currencyName);
    return currencyRate?.rate || 1;
  };

  const onUpdateCostPlan = (costPlan: CostPlan) => {
    /** Reculculate sums */

    /** cost plan items */
    costPlan.costPlanItems.forEach(item => {
      item.actualTotalSum = item.actualAmount * item.actualOptionalFactor * Number(item.actualCostPerUnit);
      item.actualTotalSumEUR = item.actualTotalSum / getExchangeRate(item.currency);
    });

    updateCostPlan(costPlan);
    const deviations = calculateDeviations(costPlan);

    dispatch(reportActions.updateCurrentDeviations(deviations));
    /* check if deviations are exceeding threshold to set flag */
    if (
      deviations.investments > deviationThreshold ||
      deviations.personnel > deviationThreshold ||
      deviations.projectActivities > deviationThreshold ||
      deviations.projectManagement > deviationThreshold
    ) {
      currentReport.report.hasCostPlanDeviations = 1;
    } else {
      currentReport.report.hasCostPlanDeviations = 0;
    }
    dispatch(reportActions.updateCurrentReport(currentReport.report));
  };

  const tabs = [
    {
      key: 'cost-plan',
      label: 'tab:title:cost-plan',
      content: <EditActualCostPlanForm costPlan={costPlan} updateCostPlan={onUpdateCostPlan} isReadonly={isReadonly} />,
    },
    {
      key: 'currency-table',
      label: 'tab:title:currency-table',
      content: (
        <EditCurrencyTableForm costPlan={costPlan} updateCostPlan={onUpdateCostPlan} isReadonly={isReadonly}></EditCurrencyTableForm>
      ),
    },
  ];

  return (
    <DefaultDialog
      isOpen={isOpen}
      actionComponent={
        <div style={{ flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
          <PrimaryButton onClick={onCancelButtonClick}>{t('button:label:cancel')}</PrimaryButton>
          <div style={{ padding: 5 }} />
          {!isReadonly && <PrimaryButton onClick={onSaveButtonClick}>{t('button:label:save')}</PrimaryButton>}
        </div>
      }
      children={
        <div className={classes.tabContainer}>
          <Tabs variant="fullWidth" value={activeTabIndex} onChange={(_event, tabIndex) => setActiveTabIndex(tabIndex)}>
            {tabs.map(tab => (
              <Tab fullWidth key={tab.key} className={classes.tab} disableRipple label={t(tab.label)} />
            ))}
          </Tabs>
          {tabs[activeTabIndex].content}
        </div>
      }
      title={t('dialog:default:cost-plan-report')}
    />
  );
};

export default ReportCostPlanFormDialog;
