import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CollectiveScholarshipInstitution from '../../../../sdk/com/apiomat/frontend/missio/CollectiveScholarshipInstitution';
import { useOfferFormContext } from '../../context/OfferFormContext';
import { useFormik } from 'formik';
import FormDatePicker from '../../../DatePicker/FormDatePicker';
import FormTextField from '../../InputFields/FormTextField';
import { object, date, string } from 'yup';

export interface ScholarshipInstituteProps {
  readOnlyMode: boolean;
  data: any;
  isReport?: boolean;
}

const ScholarshipInstitute = (props: ScholarshipInstituteProps) => {
  const { state, dispatch } = useOfferFormContext();
  const { readOnlyMode, data, isReport } = props;
  const { t } = useTranslation();

  const { start, end, measureDescription, fieldsOfStudy, selectionCriteria } = data.measure as CollectiveScholarshipInstitution;

  const validationSchema = object({
    start: date().required(t('mandatory_field')),
    end: date().required(t('mandatory_field')),
    measureDescription: string().required(t('mandatory_field')),
    fieldsOfStudy: string(),
    selectionCriteria: string(),
  });

  const formik = useFormik({
    initialValues: {
      start,
      end,
      measureDescription,
      fieldsOfStudy,
      selectionCriteria,
    },
    enableReinitialize: true,
    validationSchema,
    validateOnMount: true,
    onSubmit: () => {},
  });

  useEffect(() => {
    if (!readOnlyMode) {
      if (!formik.isValidating) {
        dispatch({ subFormId: 'measure', errors: formik.errors, values: formik.values });
      }
    }
  }, [formik.errors, formik.values, dispatch, formik.isValidating, readOnlyMode, state]);

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <FormDatePicker
            id="start"
            name="start"
            label={t('input:label:measure:planned-project-start')}
            value={formik.values.start}
            onChange={value => formik.setFieldValue('start', value)}
            disabled={readOnlyMode}
            errorMessage={formik.errors.start}
          />
        </Grid>
        <Grid item xs={6}>
          <FormDatePicker
            id="end"
            name="end"
            label={t('input:label:measure:planned-project-end')}
            value={formik.values.end}
            minDate={formik.values.start}
            onChange={value => formik.setFieldValue('end', value)}
            disabled={readOnlyMode}
            errorMessage={formik.errors.end}
          />
        </Grid>
        <Grid item xs={12}>
          <FormTextField
            id="measureDescription"
            name="measureDescription"
            disabled={readOnlyMode}
            labelKey="input:label:measure:measure-description"
            value={formik.values.measureDescription}
            multiline
            rows={3}
            onChange={formik.handleChange}
            errorMessage={formik.errors.measureDescription}
          />
        </Grid>
        {!isReport && (
          <>
            <Grid item xs={12}>
              <FormTextField
                id="fieldsOfStudy"
                name="fieldsOfStudy"
                disabled={readOnlyMode}
                labelKey="input:label:measure:offered-courses"
                descriptionKey="info:measure:scholarship-institute:offered-courses"
                value={formik.values.fieldsOfStudy}
                multiline
                rows={3}
                onChange={formik.handleChange}
                errorMessage={formik.errors.fieldsOfStudy}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                id="selectionCriteria"
                name="selectionCriteria"
                disabled={readOnlyMode}
                labelKey="input:label:measure:award-criteria"
                descriptionKey="info:measure:scholarship-institute:award-criteria"
                value={formik.values.selectionCriteria}
                multiline
                rows={3}
                onChange={formik.handleChange}
                errorMessage={formik.errors.selectionCriteria}
              />
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
};

export default ScholarshipInstitute;
