import React, { useEffect, useState } from 'react';
import LoadingIndicator from '../../components/Loading/LoadingIndicator';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { offerActions } from '../../store/offers';
import { StateType } from '../../enums/StateType';
import { AppState } from '../../store';
import { Checkbox, makeStyles } from '@material-ui/core';
import MUIDataTable, { MUIDataTableColumn } from 'mui-datatables';
import { Offers, State } from '../../sdk/com/apiomat/frontend/missio';
import { OfferType } from '../../enums/OfferType';
import FormControlLabel from '@mui/material/FormControlLabel';
import { CommentHistory } from '../../components/CommentHistory/CommentHistory';
import { pdfExportActions } from '../../store/pdfExport';
import ExportButton from '../../components/Buttons/ExportButton';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ActionMenuOpenAssignments from '../../components/ActionMenu/ActionMenuOpenAssignments';
import { Warning } from '@mui/icons-material';

interface TableAssignment {
  indicatorIcon: JSX.Element;
  measureType: string;
  name: string;
  creator: string;
  status: string;
  commentHistory: State;
  actions: Offers;
}

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    '& .MuiPaper-root > div[class*="MUIDataTableFilterList"]': {
      display: 'none',
    },
  },
  actionButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  firstFilterCheckbox: {
    paddingLeft: 16,
  },
}));

const getMuiDatatableTheme = () =>
  createTheme({
    components: {
      // @ts-ignore
      MUIDataTableToolbar: {
        styleOverrides: {
          actions: {
            display: 'flex',
            flex: 0,
            justifyItems: 'flex-end',
          },
        },
      },
    },
  });

export default () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { offers, loading, loadingOffer, incompleteOffers } = useSelector((state: AppState) => state.offer);
  const { exporting, offersToExport } = useSelector((state: AppState) => state.pdfExport);
  const { isOnline } = useSelector((state: AppState) => state.offline);

  const isIncomplete = (offer: Offers) =>
    offer.state?.name !== StateType.unsavedChanges && offer.state?.name !== StateType.cloud && !!incompleteOffers[offer.id];

  const [misMeasure, setMisMeasure] = useState<boolean>(true);
  const [mwiMeasure, setMwiMeasure] = useState<boolean>(true);

  const [rowsSelected, setRowsSelected] = useState<Array<number>>([]);
  const [columns, setColumns] = useState<Array<MUIDataTableColumn>>([
    {
      name: 'indicatorIcon',
      label: ' ',
      options: {
        display: true,
        searchable: false,
      },
    },
    {
      name: 'measureType',
      options: {
        display: false,
        filterList: [OfferType.mis, OfferType.mwi],
        searchable: false,
      },
    },
    {
      name: 'name',
      label: t('all-assignment:header:name'),
      options: {
        searchable: false,
      },
    },
    {
      name: 'creator',
      label: t('all-assignment:header:creator'),
      options: {
        searchable: true,
      },
    },
    {
      name: 'status',
      label: t('all-assignment:header:status'),
      options: {
        searchable: false,
      },
    },
    {
      name: 'commentHistory',
      label: ' ',
      options: {
        customBodyRender: value => {
          return <CommentHistory state={value} displayLastComment={true} />;
        },
        searchable: false,
      },
    },
    {
      name: 'actions',
      label: t('all-assignment:header:actions'),
      options: {
        customBodyRender: (value, tableMeta) => {
          const id = `action-menu-${tableMeta.rowIndex}`;
          const incomplete = isIncomplete(value);

          return (
            <div className={classes.actionButton}>
              <ActionMenuOpenAssignments
                menuId={id}
                offer={value}
                isVisible={value.state?.name === StateType.completed}
                disabled={value.state?.name !== StateType.completed || incomplete}
              />
            </div>
          );
        },
        filterList: [],
        searchable: false,
        sort: false,
        viewColumns: false,
      },
    },
  ]);

  const query = `state.name=="${StateType.completed}" OR state.name=="${StateType.easydorError}"`;

  useEffect(() => {
    dispatch(offerActions.loadOffers(query));
  }, [dispatch, query]);

  // delete selected rows when download was finished
  useEffect(() => {
    if (exporting !== 'pending') {
      setRowsSelected([]);
      dispatch(pdfExportActions.setOrDeleteAllOffers([]));
    }
  }, [exporting, dispatch]);

  const muiDatatableToggleAllItemsSelection = (allRowsSelected: any[]) => {
    const items = [];
    if (allRowsSelected.length === 0) {
      setRowsSelected([]);
    } else {
      dataSource.forEach(item => items.push(item.actions));
      setRowsSelected(allRowsSelected.map(row => row.dataIndex));
    }
    dispatch(pdfExportActions.setOrDeleteAllOffers(items));
  };

  const muiDatatableToggleItemSelection = (currentRowsSelected: any[], allRowsSelected: any[]) => {
    const item = dataSource[currentRowsSelected[0].dataIndex].actions;
    setRowsSelected(allRowsSelected.map(row => row.dataIndex));
    dispatch(pdfExportActions.addOrDeleteOfferToExport(item));
  };

  const dataSource: Array<TableAssignment> = offers.map(offer => {
    return {
      indicatorIcon: isIncomplete(offer) ? <Warning color={'warning'} /> : <></>,
      measureType: offer.measureType,
      name: offer.name,
      creator: offer.creator,
      status: t(`assignment:status:${offer.state?.name}`),
      commentHistory: offer.state,
      actions: offer,
    };
  });

  const filterData = (misMeasure, mwiMeasure) => {
    const cols: Array<MUIDataTableColumn> = [...columns];
    const el = cols.find(item => item.name === 'measureType');

    el.options.filterList = [];

    if (misMeasure) {
      el.options.filterList.push(OfferType.mis);
    }

    if (mwiMeasure) {
      el.options.filterList.push(OfferType.mwi);
    }

    if (el.options.filterList.length === 0) {
      el.options.filterList.push(' ');
    }

    setColumns(cols);
  };

  const toggleMisCheckbox = () => {
    muiDatatableToggleAllItemsSelection([]);
    setMisMeasure(prevState => {
      const checkboxValue = !prevState;
      filterData(checkboxValue, mwiMeasure);
      return checkboxValue;
    });
  };

  const toggleMwiCheckbox = () => {
    muiDatatableToggleAllItemsSelection([]);
    setMwiMeasure(prevState => {
      const checkboxValue = !prevState;
      filterData(misMeasure, checkboxValue);
      return checkboxValue;
    });
  };

  const FilterHeaderCheckboxes = () => (
    <>
      <FormControlLabel
        className={classes.firstFilterCheckbox}
        control={<Checkbox checked={misMeasure} onChange={toggleMisCheckbox} />}
        label="Missio"
      />
      <FormControlLabel control={<Checkbox checked={mwiMeasure} onChange={toggleMwiCheckbox} />} label="MWI" />
      <ExportButton
        isDisabled={offersToExport?.length !== 0 && exporting !== 'pending' && isOnline}
        onExportClick={() => dispatch(pdfExportActions.exportOffers())}
        loading={exporting === 'pending'}
      />
    </>
  );

  return loading === 'pending' || loadingOffer === 'pending' ? (
    <LoadingIndicator />
  ) : (
    <div className={classes.container}>
      <ThemeProvider theme={getMuiDatatableTheme()}>
        <MUIDataTable
          title={t('all-assignments')}
          data={dataSource}
          columns={columns}
          options={{
            selectableRows: 'multiple',
            selectableRowsHeader: true,
            selectToolbarPlacement: 'none',
            rowsSelected: rowsSelected,
            onRowSelectionChange: (currentRowsSelected, allRowsSelected) => {
              if (exporting !== 'pending') {
                if (currentRowsSelected.length === 1) {
                  muiDatatableToggleItemSelection(currentRowsSelected, allRowsSelected);
                } else {
                  muiDatatableToggleAllItemsSelection(allRowsSelected);
                }
              }
            },
            isRowSelectable: () => exporting !== 'pending',
            print: false,
            download: false,
            filter: false,
            sort: false,
            customToolbar: () => FilterHeaderCheckboxes(),
            viewColumns: false,
            search: true,
          }}
        />
      </ThemeProvider>
    </div>
  );
};
