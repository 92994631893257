import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DefaultDialog from '../../Dialogs/DefaultDialog';
import { PrimaryButton } from '../../Buttons/Buttons';
import { makeStyles, Tab, Tabs } from '@material-ui/core';
import EditCostPlanForm from './EditCostPlanForm';
import EditFinancialPlanForm from './EditFinancialPlanForm';
import CostPlan from '../../../sdk/com/apiomat/frontend/missio/CostPlan';
import DefaultTranslatedLabel from '../../Lables/DefaultTranslatedLabel';

const useStyles = makeStyles(() => ({
  tabContainer: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  tab: {
    color: 'rgb(79, 195, 247)',
    fontSize: 18,
  },
}));

export interface CostPlanFormDialogProps {
  isOpen: boolean;
  isReadonly: boolean;
  costPlan: CostPlan;
  costPlanPhases: Array<number>;
  updateCostPlan?: (costPlan: CostPlan) => void;
  onSaveButtonClick?: () => void;
  onCancelButtonClick: () => void;
  tabIndex?: number;
}

const CostPlanFormDialog = (props: CostPlanFormDialogProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    isOpen,
    costPlan,
    costPlanPhases,
    isReadonly,
    onCancelButtonClick,
    updateCostPlan,
    onSaveButtonClick,
    tabIndex
  } = props;

  const [ activeTabIndex, setActiveTabIndex ] = useState<number>(tabIndex ? tabIndex : 0);
  const [ isSaveBtnDisabled, setIsSaveBtnDisabled ] = useState<boolean>(false);

  if (!costPlan) {
    return null;
  }

  const getExchangeRate = (currencyName: string): number => {
    const currencyRate = costPlan.currencyRates.find(currencyRate => currencyRate.currency === currencyName);
    return currencyRate?.rate || 1;
  };

  const onUpdateCostPlan = (costPlan: CostPlan) => {
    /** Reculculate sums */

    /** financial plan items */
    costPlan.financialPlan.financialPlanItems.forEach(
      item => (item.totalAmount = Number((item.requestedAmount / getExchangeRate(item.currency)).toFixed(4)))
    );

    /** financial plan */
    costPlan.financialPlan.sumEuro = costPlan.financialPlan.financialPlanItems.map(item => item.totalAmount).reduce((a, b) => a + b, 0);

    /** cost plan items */
    costPlan.costPlanItems.forEach(item => {
      item.totalSum = item.amount * item.optionalFactor * item.costPerUnit;
      item.totalSumEUR = item.totalSum / getExchangeRate(item.currency);
    });

    /** cost plan */
    costPlan.sum = costPlan.costPlanItems.map(item => item.totalSumEUR).reduce((a, b) => a + b, 0);

    validateCostPlanAndFinancialPlan();
    updateCostPlan(costPlan);
  };

  /**
   * Validation method for cost plans items and financial plan items.
   *
   * Cost plans:
   * 1) Category - mandatory
   * 2) Cost type - mandatory
   * 3) Cost per unit - higher than 0 and mandatory
   * 4) Currency - mandatory
   *
   * Financial plans:
   * 1) Type - mandatory
   * 2) Decision - mandatory
   * 3) National Currency - mandatory
   * 4) When Decision is 'Requested', 'No status' or 'Declined' (key != 2) - mandatory amount in the field 'Applied'
   * 5) When Decision is 'Approved' (key == 2) - mandatory amount in the field 'Approved'
   *
   */
  const validateCostPlanAndFinancialPlan = () => {
    let isValid = true;
    for (let i = 0; i < costPlan.costPlanItems.length; i++) {
      const currentItem = costPlan.costPlanItems[i];
      if (!currentItem.costPlanCategory || !currentItem.costPlanType || !(currentItem.costPerUnit > 0) ||
        !currentItem.currency) {
        isValid = false;
        break;
      }
    }

    // omit if there is invalid cost plan
    if (isValid) {
      for (let i = 0; i < costPlan.financialPlan.financialPlanItems.length; i++) {
        const currentItem = costPlan.financialPlan.financialPlanItems[i];
        if (!currentItem.planType || !currentItem.decision || !currentItem.currency ||
          (currentItem.decision !== '2' && !(currentItem.requestedAmount > 0)) ||
          (currentItem.decision === '2' && !(currentItem.approvedAmount > 0))) {
          isValid = false;
          break;
        }
      }
    }

    setIsSaveBtnDisabled(!isValid);
  };

  const tabs = [
    {
      key: 'cost-plan',
      label: 'tab:title:cost-plan',
      content: (
        <EditCostPlanForm costPlan={ costPlan } updateCostPlan={ onUpdateCostPlan } isReadonly={ isReadonly }
                          costPlanPhases={ costPlanPhases }/>
      ),
    },
    {
      key: 'financial-plan',
      label: 'tab:title:financial-plan',
      content: <EditFinancialPlanForm costPlan={ costPlan } updateCostPlan={ onUpdateCostPlan }
                                      isReadonly={ isReadonly }/>,
    },
  ];

  return (
    <DefaultDialog
      isOpen={ isOpen }
      actionComponent={
        <div style={ { flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' } }>
          <PrimaryButton onClick={ onCancelButtonClick }>{ t('button:label:cancel') }</PrimaryButton>
          <div style={ { padding: 5 } }/>
          { !isReadonly && <PrimaryButton onClick={ onSaveButtonClick }
                                          disabled={ isSaveBtnDisabled }>{ t('button:label:save') }</PrimaryButton> }
        </div>
      }
      children={
        <div className={ classes.tabContainer }>
          <Tabs variant="fullWidth" value={ activeTabIndex }
                onChange={ (_event, tabIndex) => setActiveTabIndex(tabIndex) }>
            { tabs.map(tab => (
              <Tab fullWidth key={ tab.key } className={ classes.tab } disableRipple label={ t(tab.label) }/>
            )) }
          </Tabs>
          { tabs[activeTabIndex].content }
        </div>
      }
      title={
        <DefaultTranslatedLabel
          infoId={ 'info:cost-plan:dialog:cost-and-finance-plan-description' }
          translationId={ 'dialog:default:cost-plan' }
        />
      }
    />
  );
};

export default CostPlanFormDialog;
