import { makeStyles } from '@material-ui/styles';
import { FormControl, FormLabel, RadioGroup, FormControlLabel, FormHelperText, Radio } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

export interface RadioButtonsProps {
  title: string | React.ReactNode;
  value: number;
  disabled: boolean;
  onSelectedValueChanged: (value: number) => void;
  errorMessage?: string;
}

const useStyles = makeStyles(() => ({
  root: {
    minWidth: '200px',
    justifyContent: 'center',
  },
  title: {
    padding: 5,
    textAlign: 'center',
    '&.MuiFormLabel-root.Mui-error': {
      color: 'orange',
    },
  },
  errorText: {
    '&.MuiFormHelperText-root.Mui-error': {
      color: 'orange',
    },
  },
}));

const RadioButtons = (props: RadioButtonsProps) => {
  const { title, value, disabled, onSelectedValueChanged, errorMessage } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <FormControl error={Boolean(errorMessage)}>
      <FormLabel
        classes={{
          root: classes.title,
        }}
        error={Boolean(errorMessage)}
      >
        {title}
      </FormLabel>
      <RadioGroup
        classes={{
          root: classes.root,
        }}
        value={value?.toString()}
        onChange={event => onSelectedValueChanged(parseInt(event.target.value))}
        row
      >
        <FormControlLabel
          disabled={disabled}
          value="1"
          labelPlacement="top"
          label={t('label:radio-button:yes')}
          control={<Radio color="primary" />}
        />
        <FormControlLabel
          disabled={disabled}
          value="0"
          labelPlacement="top"
          label={t('label:radio-button:no')}
          control={<Radio color="primary" />}
        />
      </RadioGroup>
      {errorMessage && <FormHelperText classes={{ root: classes.errorText }}>{errorMessage}</FormHelperText>}
    </FormControl>
  );
};

export default RadioButtons;
