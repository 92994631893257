import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { put, call, all, takeLatest, select } from 'redux-saga/effects';
import { notificationActions } from './notification';
import MUser from '../sdk/com/apiomat/frontend/missio/MUser';
import Datastore from '../sdk/com/apiomat/frontend/Datastore';
import i18n from '../utils/i18n';
import { push } from 'connected-react-router';
import { AppState } from '.';
import Role from '../sdk/com/apiomat/frontend/basics/Role';
import { Roles } from '../enums/Roles';
import { SortItem } from '../interfaces/ConfigItem';
import { orderBy } from 'lodash';

/** STATE */
export interface UserState {
  users: MUser[];
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
  loadingUser: 'idle' | 'pending' | 'succeeded' | 'failed';
}

const initialState: UserState = {
  users: [],
  loading: 'idle',
  loadingUser: 'idle',
};

/** TYPES */
export interface UserRegistration {
  email: string;
  firstName: string;
  lastName: string;
  churchType: string;
  churchCountry: string;
  churchRole: string;
  phoneNumber: string;
  password: string;
  language: string;
}

export interface UserActivationChange {
  user: MUser;
  isActive: 0 | 1;
}
export interface UserContactIDChange {
  user: MUser;
  navContactId: string;
}

/** SLICE */
const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    loadUsers: state => {
      state.loading = 'pending';
    },
    loadUsersSuccess: (state, action: PayloadAction<MUser[]>) => {
      state.loading = 'succeeded';
      state.users = action.payload;
    },
    loadUsersFailure: state => {
      state.loading = 'failed';
    },
    registerUser: (state, _action: PayloadAction<UserRegistration>) => {
      state.loadingUser = 'pending';
    },
    registerUserSuccess: state => {
      state.loadingUser = 'succeeded';
    },
    registerUserFailure: state => {
      state.loadingUser = 'failed';
    },
    deleteUser: (state, _action: PayloadAction<MUser>) => {
      state.loadingUser = 'pending';
    },
    deleteUserSuccess: (state, action: PayloadAction<string>) => {
      state.loadingUser = 'succeeded';
      state.users = state.users.filter(user => user.ID !== action.payload);
    },
    deleteUserFailure: state => {
      state.loadingUser = 'failed';
    },
    changeUserActivation: (state, _action: PayloadAction<UserActivationChange>) => {
      state.loadingUser = 'pending';
    },
    changeUserActivationSuccess: (state, action: PayloadAction<MUser>) => {
      state.loadingUser = 'succeeded';
      state.users = state.users.map(user => (user.ID !== action.payload.ID ? user : action.payload));
    },
    changeUserActivationFailure: state => {
      state.loadingUser = 'failed';
    },
    changeUserContactID: (state, _action: PayloadAction<UserContactIDChange>) => {
      state.loadingUser = 'pending';
    },
    changeUserContactIDSuccess: (state, action: PayloadAction<MUser>) => {
      state.loadingUser = 'succeeded';
      state.users = state.users.map(user => (user.ID !== action.payload.ID ? user : action.payload));
    },
    changeUserContactIDFailure: state => {
      state.loadingUser = 'failed';
    },
    sortUsers: (state, action: PayloadAction<SortItem>) => {
      state.users = orderBy(state.users, user => user[action.payload.key]?.toLowerCase(), [action.payload.direction]);
    },
    searchUsers: (state, action: PayloadAction<string>) => {
      state.loading = 'pending';
    },
    searchUsersSuccess: (state, action: PayloadAction<MUser[]>) => {
      state.loading = 'succeeded';
      state.users = action.payload;
    },
    searchUsersFailure: state => {
      state.loading = 'failed';
    },
    addAdminRole: (state, _action: PayloadAction<MUser>) => {
      state.loadingUser = 'pending';
    },
    addAdminRoleSuccess: (state, action: PayloadAction<MUser>) => {
      state.loadingUser = 'succeeded';
      state.users = state.users.map(user => (user.ID !== action.payload.ID ? user : action.payload));
    },
    addAdminRoleFailure: state => {
      state.loadingUser = 'failed';
    },
    deleteAdminRole: (state, _action: PayloadAction<MUser>) => {
      state.loadingUser = 'pending';
    },
    deleteAdminRoleSuccess: (state, action: PayloadAction<MUser>) => {
      state.loadingUser = 'succeeded';
      state.users = state.users.map(user => (user.ID !== action.payload.ID ? user : action.payload));
    },
    deleteAdminRoleFailure: state => {
      state.loadingUser = 'failed';
    },
  },
});

export const userActions = userSlice.actions;
export const userReducer = userSlice.reducer;

/** SAGAS */
function* onRegisterUser(action: PayloadAction<UserRegistration>) {
  const { email, firstName, lastName, churchType, churchCountry, churchRole, phoneNumber, password, language } = action.payload;
  try {
    Datastore.configureAsGuest();
    const user = new MUser();
    user.userName = email;
    user.email = email;
    user.password = password;
    user.firstName = firstName;
    user.lastName = lastName;
    user.church = churchType;
    user.churchCountry = churchCountry;
    user.churchRole = churchRole;
    user.phone = phoneNumber;
    user.language = language;

    yield call(() => user.save(false));
    yield put(userActions.registerUserSuccess());
    yield put(notificationActions.showSuccessMessage(i18n.t('registration-completed')));
    yield put(push('/'));
  } catch (error) {
    yield put(notificationActions.showError(i18n.t('register-failed')));
    yield put(userActions.registerUserFailure());
  }
}

function* onLoadCustomers() {
  const isOnline: boolean = yield select((state: AppState) => state.offline.isOnline);

  if (isOnline === false) {
    yield put(notificationActions.showError(i18n.t('you-are-offline')));
    return;
  }

  try {
    const rows = yield call(() => MUser.getMUsers());
    yield put(userActions.loadUsersSuccess(rows));
  } catch (error) {
    yield put(notificationActions.showError(error));
    yield put(userActions.loadUsersFailure());
  }
}

function* onDeleteUser(action: PayloadAction<MUser>) {
  const isOnline: boolean = yield select((state: AppState) => state.offline.isOnline);

  if (isOnline === false) {
    yield put(notificationActions.showError(i18n.t('you-are-offline')));
    return;
  }

  const user = action.payload;
  try {
    yield call(() => user.delete());
    yield put(userActions.deleteUserSuccess(user.ID));
  } catch (error) {
    yield put(notificationActions.showError(error));
    yield put(userActions.deleteUserFailure());
  }
}

function* onChangeUserActivation(action: PayloadAction<UserActivationChange>) {
  const isOnline: boolean = yield select((state: AppState) => state.offline.isOnline);

  if (isOnline === false) {
    yield put(notificationActions.showError(i18n.t('you-are-offline')));
    return;
  }

  const { user, isActive } = action.payload;
  try {
    user.isActive = isActive;
    yield call(() => user.save());
    yield put(userActions.changeUserActivationSuccess(user));
  } catch (error) {
    yield put(notificationActions.showError(error));
    yield put(userActions.changeUserActivationFailure());
  }
}

function* onChangeUserContactID(action: PayloadAction<UserContactIDChange>) {
  const isOnline: boolean = yield select((state: AppState) => state.offline.isOnline);

  if (isOnline === false) {
    yield put(notificationActions.showError(i18n.t('you-are-offline')));
    return;
  }

  const { user, navContactId } = action.payload;
  try {
    user.navContactId = navContactId;
    yield call(() => user.save());
    yield put(userActions.changeUserContactIDSuccess(user));
  } catch (error) {
    yield put(notificationActions.showError(error));
    yield put(userActions.changeUserContactIDFailure());
  }
}

function* onAddAdminRole(action: PayloadAction<MUser>) {
  const isOnline: boolean = yield select((state: AppState) => state.offline.isOnline);

  if (isOnline === false) {
    yield put(notificationActions.showError(i18n.t('you-are-offline')));
    return;
  }

  const user = action.payload;

  try {
    const query = `name == "${Roles.ADMIN}" limit 1`;
    const roles = yield call(() => Role.getRoles(query));
    if (roles.length === 1) {
      const adminRole = roles[0];
      adminRole.members = [...adminRole.members, user.userName];
      yield call(() => adminRole.save());
      user.roles = [...user.roles, Roles.ADMIN];
      yield call(() => user.save());
      yield put(userActions.addAdminRoleSuccess(user));
    }
  } catch (error) {
    yield put(notificationActions.showError(error));
    yield put(userActions.addAdminRoleFailure());
  }
}

function* onDeleteAdminRole(action: PayloadAction<MUser>) {
  const isOnline: boolean = yield select((state: AppState) => state.offline.isOnline);

  if (isOnline === false) {
    yield put(notificationActions.showError(i18n.t('you-are-offline')));
    return;
  }

  const user = action.payload;

  try {
    const query = `name == "${Roles.ADMIN}" limit 1`;
    const roles = yield call(() => Role.getRoles(query));
    if (roles.length === 1) {
      const adminRole = roles[0];
      adminRole.members = adminRole.members.filter((userName: string) => userName !== user.userName);
      yield call(() => adminRole.save());
      user.roles = user.roles.filter((role: string) => role !== Roles.ADMIN);
      yield call(() => user.save());
      yield put(userActions.deleteAdminRoleSuccess(user));
    }
  } catch (error) {
    yield put(notificationActions.showError(error));
    yield put(userActions.deleteAdminRoleFailure());
  }
}

function* onSearchUsers(action: PayloadAction<string>) {
  const isOnline: boolean = yield select((state: AppState) => state.offline.isOnline);

  if (isOnline === false) {
    yield put(notificationActions.showError(i18n.t('you-are-offline')));
    return;
  }

  try {
    const users = yield call(() => MUser.getMUsers(action.payload));
    yield put(userActions.loadUsersSuccess(users));
  } catch (error) {
    yield put(notificationActions.showError(error));
    yield put(userActions.searchUsersFailure());
  }
}

export function* userSaga() {
  yield all([
    takeLatest(userActions.loadUsers, onLoadCustomers),
    takeLatest(userActions.registerUser, onRegisterUser),
    takeLatest(userActions.deleteUser, onDeleteUser),
    takeLatest(userActions.changeUserActivation, onChangeUserActivation),
    takeLatest(userActions.changeUserContactID, onChangeUserContactID),
    takeLatest(userActions.searchUsers, onSearchUsers),
    takeLatest(userActions.addAdminRole, onAddAdminRole),
    takeLatest(userActions.deleteAdminRole, onDeleteAdminRole),
  ]);
}
