import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import Audit from '../../../sdk/com/apiomat/frontend/missio/Audit';
import { DefaultTextField } from '../../TextInputs/TextInputs';

export const useStyles = makeStyles(() => ({
  contentContainer: {
    paddingBottom: 30,
    paddingTop: 30,
  },
  textField: {
    paddingBottom: 25,
    paddingTop: 5,
    marginTop: 30,
  },
}));

export interface AuditFormProps {
  readOnlyMode: boolean;
  audit: Audit;
  updateAudit: (audit: Audit) => void;
}

const AuditForm = (props: AuditFormProps) => {
  const { readOnlyMode, audit, updateAudit } = props;
  const classes = useStyles();

  return (
    <Grid container spacing={ 3 } className={ classes.contentContainer }>
      <Grid item xs={ 12 }>
        <DefaultTextField
          className={ classes.textField }
          disabled={ true }
          labelKey="input:label:audit-description"
          value={ audit.auditDescription }
          multiline
          rows={ 3 }
          maxRows={ 10 }
        />
      </Grid>
      <Grid item xs={ 12 }>
        <DefaultTextField
          className={ classes.textField }
          disabled={ readOnlyMode }
          labelKey="input:label:audit-feedback"
          value={ audit.auditFeedback }
          multiline
          rows={ 3 }
          maxRows={ 10 }
          onChange={ event => {
            audit.auditFeedback = event.target.value;
            updateAudit(audit);
          } }
        />
      </Grid>
    </Grid>
  );
};

export default AuditForm;
