import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AppState } from '../../store';
import LoadingIndicator from '../../components/Loading/LoadingIndicator';
import FullScreenLoadingIndicator from '../../components/Loading/FullScreenLoadingIndicator';
import FormHead from '../../components/Forms/FormHead';
import { InvalidResult } from '../../validators/OfferValidation';
import { makeStyles, Tab, Tabs } from '@material-ui/core';
import MissingFieldsDialog from '../../components/Dialogs/MissingFieldsDialog';
import { useTranslation } from 'react-i18next';
import OfferEvaluation from '../../sdk/com/apiomat/frontend/missio/OfferEvaluation';
import { evaluationActions } from '../../store/evaluation';
import OfferEvaluationForm from '../../components/Forms/Evaluation/OfferEvaluationForm';
import { StateType } from '../../enums/StateType';
import { getTranslatedSortedAttachmentTypes } from '../../utils/attachment.util';
import OldAttachmentForm from '../../components/Forms/OldAttachmentForm';

const useStyles = makeStyles(() => ({
  titleContainer: {
    flex: 1,
    display: 'flex',
    paddingTop: 15,
    paddingBottom: 15,
  },
  buttonsContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    justifyItems: 'space-between',
  },
  saveButton: {
    width: window.innerWidth / 12,
    display: 'flex',
    borderRadius: 0,
  },
  separator: {
    paddingLeft: 5,
    paddingRight: 5,
  },
  uploadButton: {
    width: window.innerWidth / 12,
    display: 'flex',
    borderRadius: 0,
  },
  titleTextFieldContainer: {
    flex: 1,
    display: 'flex',
    paddingTop: 10,
    paddingBottom: 10,
  },
  tabContainer: {
    paddingTop: 20,
    paddingBottom: 20,
  },
}));

interface TabItem {
  key: string;
  label: string;
  content?: React.ReactNode;
}

export default () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = useParams<{ id: string | undefined }>();

  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const [isMissingFieldsDialogActive, setMissingFieldsDialogActive] = useState<boolean>(false);
  const [missingFields] = useState<InvalidResult[]>([]);

  useEffect(() => {
    if (id) {
      dispatch(evaluationActions.loadCurrentEvaluation(id));
    }
  }, [dispatch, id]);

  const { currentEvaluation, loadingEvaluations, loadingCurrentEvaluation } = useSelector((state: AppState) => state.evaluation);

  const evaluation = currentEvaluation?.evaluation;
  const offer = evaluation?.offer;

  const readOnly = evaluation?.state?.name === StateType.easydor || evaluation?.state?.name === StateType.easydorError;

  if (loadingCurrentEvaluation === 'pending') {
    return <FullScreenLoadingIndicator />;
  }

  if (loadingEvaluations === 'pending') {
    return <LoadingIndicator />;
  }
  if (!evaluation) {
    return null;
  }

  const attachmentTypes = getTranslatedSortedAttachmentTypes(t);

  const updateEvaluation = (evaluation: OfferEvaluation) => dispatch(evaluationActions.updateCurrentEvaluation(evaluation));

  const tabs: TabItem[] = [
    {
      key: 'evaluation',
      label: 'tab:title:evaluation',
      content: (
        <OfferEvaluationForm updateEvaluation={updateEvaluation} evaluation={currentEvaluation.evaluation} readOnlyMode={readOnly} />
      ),
    },
    {
      key: 'attachments',
      label: 'tab:title:attachments',
      content: (
        <OldAttachmentForm
          data={evaluation}
          readOnlyMode={readOnly}
          showDropDown={!readOnly}
          items={attachmentTypes}
          update={updateEvaluation}
        />
      ),
    },
  ];

  const onSave = () => {
    dispatch(evaluationActions.saveCurrentEvaluation(StateType.cloud));
  };

  const onUpload = () => {
    dispatch(evaluationActions.saveCurrentEvaluation(StateType.completed));
  };

  return (
    <>
      <FormHead
        mode={readOnly ? 'review-admin' : 'edit'}
        // TODO: enum for Selbstevalation/Fremdevaluation if we know more
        title={`${offer.name} - ${evaluation.evaluationType}`}
        isReadonly={true}
        showAdminButtons={false}
        showUserButtons={false}
        isEasydorError={evaluation?.state?.name === StateType.easydorError}
        onTitleChange={() => {}}
        titleDescription={t('create-evaluation')}
        onSave={onSave}
        onUpload={onUpload}
        isAssignment={false}
      />
      <div className={classes.tabContainer}>
        <Tabs variant="scrollable" scrollButtons="on" value={activeTabIndex} onChange={(_event, tabIndex) => setActiveTabIndex(tabIndex)}>
          {tabs.map(tab => (
            <Tab fullWidth key={tab.key} disableRipple label={t(tab.label)} />
          ))}
        </Tabs>
        {tabs[activeTabIndex].content}
        {isMissingFieldsDialogActive && (
          <MissingFieldsDialog invalidResults={missingFields} onCancelButtonClick={() => setMissingFieldsDialogActive(false)} />
        )}
      </div>
    </>
  );
};
