import React, { useEffect, useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Media from '../../../../sdk/com/apiomat/frontend/missio/Media';
import { useOfferFormContext } from '../../context/OfferFormContext';
import { object, string, date } from 'yup';
import { useFormik } from 'formik';
import FormDatePicker from '../../../DatePicker/FormDatePicker';
import FormTextField from '../../InputFields/FormTextField';

export interface MediaProps {
  readOnlyMode: boolean;
  data: any;
  isReport?: boolean;
}

const MediaForm = (props: MediaProps) => {
  const { state, dispatch } = useOfferFormContext();
  const { readOnlyMode, data, isReport } = props;
  const { t } = useTranslation();

  const {
    start,
    end,
    measureDescription,
    mediaExisting,
    mediaLawPreposition,
    mediaDescription1,
    mediaDescription2,
  } = data.measure as Media;

  const validationSchema = useMemo(
    () =>
      object({
        start: date().required(t('mandatory_field')),
        end: date().required(t('mandatory_field')),
        measureDescription: string().required(t('mandatory_field')),
        mediaExisting: string(),
        mediaLawPreposition: isReport ? string() : string().required(t('mandatory_field')),
        mediaDescription1: string().required(t('mandatory_field')),
        mediaDescription2: string().required(t('mandatory_field')),
      }),
    [t, isReport]
  );

  const formik = useFormik({
    initialValues: {
      start,
      end,
      measureDescription,
      mediaExisting,
      mediaLawPreposition,
      mediaDescription1,
      mediaDescription2,
    },
    enableReinitialize: true,
    validationSchema,
    validateOnMount: true,
    onSubmit: () => {},
  });

  useEffect(() => {
    if (!readOnlyMode) {
      if (!formik.isValidating) {
        dispatch({ subFormId: 'measure', errors: formik.errors, values: formik.values });
      }
    }
  }, [formik.errors, formik.values, dispatch, formik.isValidating, readOnlyMode, state]);

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <FormDatePicker
            id="start"
            name="start"
            label={t('input:label:measure:planned-project-start')}
            value={formik.values.start}
            onChange={value => formik.setFieldValue('start', value)}
            disabled={readOnlyMode}
            errorMessage={formik.errors.start}
          />
        </Grid>
        <Grid item xs={6}>
          <FormDatePicker
            id="end"
            name="end"
            label={t('input:label:measure:planned-project-end')}
            value={formik.values.end}
            minDate={formik.values.start}
            onChange={value => formik.setFieldValue('end', value)}
            disabled={readOnlyMode}
            errorMessage={formik.errors.end}
          />
        </Grid>
        <Grid item xs={12}>
          <FormTextField
            id="measureDescription"
            name="measureDescription"
            disabled={readOnlyMode}
            labelKey="input:label:measure:measure-description"
            value={formik.values.measureDescription}
            multiline
            rows={3}
            onChange={formik.handleChange}
            errorMessage={formik.errors.measureDescription}
          />
        </Grid>
        {!isReport && (
          <>
            <Grid item xs={12}>
              <FormTextField
                id="mediaExisting"
                name="mediaExisting"
                disabled={readOnlyMode}
                labelKey="input:label:measure:media-availability"
                descriptionKey="info:measure:media:media-availability"
                value={formik.values.mediaExisting}
                multiline
                rows={3}
                onChange={formik.handleChange}
                errorMessage={formik.errors.mediaExisting}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                id="mediaLawPreposition"
                name="mediaLawPreposition"
                disabled={readOnlyMode}
                labelKey="input:label:measure:media-law-requirements"
                descriptionKey="info:measure:media:media-law-requirements"
                value={formik.values.mediaLawPreposition}
                multiline
                rows={3}
                onChange={formik.handleChange}
                errorMessage={formik.errors.mediaLawPreposition}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <FormTextField
            id="mediaDescription1"
            name="mediaDescription1"
            disabled={readOnlyMode}
            labelKey="input:label:measure:media-first-description"
            descriptionKey="info:measure:media:media-first-description"
            value={formik.values.mediaDescription1}
            multiline
            rows={3}
            onChange={formik.handleChange}
            errorMessage={formik.errors.mediaDescription1}
          />
        </Grid>
        <Grid item xs={12}>
          <FormTextField
            id="mediaDescription2"
            name="mediaDescription2"
            disabled={readOnlyMode}
            labelKey="input:label:measure:media-second-description"
            descriptionKey="info:measure:media:media-second-description"
            value={formik.values.mediaDescription2}
            multiline
            rows={3}
            onChange={formik.handleChange}
            errorMessage={formik.errors.mediaDescription2}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default MediaForm;
