import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker as MuiDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

export interface DatePickerProps {
  value?: Date;
  onChange: (date: Date) => void;
  is?: boolean;
  min?: Date;
  max?: Date;
  label?: string | React.ReactNode;
  placeholder?: string;
  isReadonly?: boolean;
  views?: ('year' | 'month' | 'date')[];
  disableFuture?: boolean;
}

const DatePicker = (props: DatePickerProps) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <MuiDatePicker
        inputVariant="outlined"
        label={props.label}
        format={!props.views ? 'dd/MM/yyyy' : 'yyyy'}
        fullWidth
        minDate={props.min}
        disabled={props.isReadonly}
        maxDate={props.max}
        placeholder={'- - . - - . - - - - '}
        // tslint:disable-next-line:no-null-keyword
        value={props.value === undefined ? null : props.value}
        onChange={(date: Date) => props.onChange(date)}
        animateYearScrolling={false}
        disableFuture={props.disableFuture}
        views={props.views}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DatePicker;
