import React from 'react';
import { Card, TextField, makeStyles, CardMedia, CardContent, CardActions, Grid } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { useState } from 'react';
import { AppState } from '../store/index';
import { notificationActions } from '../store/notification';
import { useTranslation } from 'react-i18next';
import { MissioLogo, MwiLogo } from '../components/Logo/Logo';
import { PrimaryButton } from '../components/Buttons/Buttons';
import { Link } from 'react-router-dom';
import { userActions } from '../store/users';
import LanguageSelection from '../components/Localization/LanguageSelection';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import PasswordGrid from '../components/DataGrid/PasswordGrid';

const useStyles = makeStyles(theme => ({
  fullHeightContainer: {
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundSize: 'cover',
  },
  cardContainer: {
    maxWidth: 700,
    backgroundColor: '#ffffffcf',
  },
  buttonContainer: {
    borderTop: '1px solid ' + theme.palette.primary.dark,
    display: 'flex',
    flex: 1,
    backgroundColor: '#fff',
    justifyContent: 'space-between',
  },
  registerButton: {
    maxWidth: 150,
    justifySelf: 'flex-start',
    margin: 5,
  },
  cancelButton: {
    color: 'black',
    backgroundColor: 'white',
    maxWidth: 150,
    justifySelf: 'flex-end',
    margin: 5,
  },
  content: {
    paddingBottom: 60,
  },
  logo: {
    borderBottom: '1px solid ' + theme.palette.primary.dark,
  },
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  textField: {
    backgroundColor: '#fff',
    flex: 1,
    display: 'flex',
    paddingTop: 2,
    paddingLeft: 2,
    paddingRight: 2,
  },
  invalidEmail: {
    backgroundColor: '#fff',
    flex: 1,
    display: 'flex',
    paddingTop: 2,
    paddingLeft: 2,
    paddingRight: 2,
    borderBottom: '3px solid #ff0000',
  },
}));

export default () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isLoading = useSelector((state: AppState) => state.user.loadingUser);

  const [email, setEmail] = useState('');
  const [emailVerification, setEmailVerification] = useState('');
  const [isEmailIdentical, setIsEmailIdentical] = useState(true);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [churchType, setChurchType] = useState('');
  const [churchCountry, setChurchCountry] = useState('');
  const [churchRole, setChurchRole] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [language, setLanguage] = useState('EN');
  const [passwordVerification, setPasswordVerification] = useState('');
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const onRegisterUser = () => {
    if (password !== passwordVerification) {
      dispatch(notificationActions.showError(t('passwords-do-not-match')));
    } else if (email !== emailVerification) {
      dispatch(notificationActions.showError(t('emails-do-not-match')));
    } else if (
      email === '' ||
      password === '' ||
      firstName === '' ||
      lastName === '' ||
      churchRole === '' ||
      churchType === '' ||
      churchCountry === '' ||
      phoneNumber === '' ||
      language === ''
    ) {
      dispatch(notificationActions.showError(t('empty-required-fields')));
    } else {
      dispatch(
        userActions.registerUser({ email, firstName, lastName, churchType, churchCountry, churchRole, phoneNumber, password, language })
      );
    }
  };

  return (
    <div className={classes.fullHeightContainer}>
      <div>
        <Card className={classes.cardContainer}>
          <CardMedia className={classes.logo}>
            <MissioLogo />
            <MwiLogo />
          </CardMedia>
          <CardContent className={classes.content}>
            <div className={classes.container}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    className={classes.textField}
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    placeholder={t('input:placeholder:email')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    className={isEmailIdentical ? classes.textField : classes.invalidEmail}
                    value={emailVerification}
                    onChange={e => {
                      setEmailVerification(e.target.value);
                      setIsEmailIdentical(email === e.target.value);
                    }}
                    placeholder={t('input:placeholder:email-verification')}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    className={classes.textField}
                    value={firstName}
                    onChange={e => setFirstName(e.target.value)}
                    placeholder={t('input:placeholder:first-name')}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    className={classes.textField}
                    value={lastName}
                    onChange={e => setLastName(e.target.value)}
                    placeholder={t('input:placeholder:last-name')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    className={classes.textField}
                    value={churchType}
                    onChange={e => setChurchType(e.target.value)}
                    placeholder={t('input:placeholder:church-type')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    className={classes.textField}
                    value={churchCountry}
                    onChange={e => setChurchCountry(e.target.value)}
                    placeholder={t('input:placeholder:church-country')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    className={classes.textField}
                    value={churchRole}
                    onChange={e => setChurchRole(e.target.value)}
                    placeholder={t('input:placeholder:church-role')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    className={classes.textField}
                    value={phoneNumber}
                    onChange={e => setPhoneNumber(e.target.value)}
                    placeholder={t('input:placeholder:phone-number')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <LanguageSelection
                    language={language}
                    inputLabel={'select:label:language'}
                    onChange={e => {
                      setLanguage(e);
                    }}
                  />
                </Grid>
                <PasswordGrid
                  password={password}
                  onPasswordChange={setPassword}
                  passwordVerification={passwordVerification}
                  onPasswordVerificationChange={setPasswordVerification}
                  visibilityButton={isPasswordVisible ? <VisibilityOff color="disabled" /> : <Visibility color="disabled" />}
                  onVisibilityButtonClick={() => setIsPasswordVisible(!isPasswordVisible)}
                  isPasswordVisible={isPasswordVisible}
                />
              </Grid>
            </div>
          </CardContent>
          <CardActions className={classes.buttonContainer}>
            <PrimaryButton className={classes.cancelButton} fullWidth component={Link} to="/login">
              {t('button:label:cancel')}
            </PrimaryButton>
            <PrimaryButton className={classes.registerButton} disabled={isLoading === 'pending'} onClick={onRegisterUser} fullWidth>
              {t('button:label:register')}
            </PrimaryButton>
          </CardActions>
        </Card>
      </div>
    </div>
  );
};
