import { GeneralValueMap } from './GeneralValueMap';

export const coFinancierTypes: GeneralValueMap[] = [
  {
    locale: '41213018',
    value: 'label:financial-plan:dropdown:italian-bishop-conference',
    key: 'Conferenza Episcopale Italiana',
  },
  {
    locale: '41206388',
    value: 'label:financial-plan:dropdown:dka-austria',
    key: 'Dreikönigsaktion der Katholischen Jungschar  - DKA',
  },
  {
    locale: '41206797',
    value: 'label:financial-plan:dropdown:franciscan-mission-headquarter',
    key: 'Missionszentrale der Franziskaner',
  },
  {
    locale: '41221472',
    value: 'label:financial-plan:dropdown:usccb',
    key: 'United States Conference of Catholic Bishops  - USCCB',
  },
  {
    locale: '41207931',
    value: 'label:financial-plan:dropdown:achdiocese-paderborn',
    key: 'Erzdiözese Paderborn',
  },
  {
    locale: '41208511',
    value: 'label:financial-plan:dropdown:bread-for-the-world',
    key: 'Brot für die Welt - Evangelischer Entwicklungsdienst',
  },
  {
    locale: '41208542',
    value: 'label:financial-plan:dropdown:archdiocese-munich',
    key: 'Erzdiözese München u. Freising',
  },
  {
    locale: '41209955',
    value: 'label:financial-plan:dropdown:diocese-muenster',
    key: 'Diözese Münster',
  },
  {
    locale: 'de',
    value: 'label:financial-plan:dropdown:swiss-catholic',
    key: 'Fastenopfer der Schweizer Katholiken',
  },
  {
    locale: '41206389',
    value: 'label:financial-plan:dropdown:cnewa',
    key: 'Catholic Near East Welfare Association  - CNEWA',
  },
  {
    locale: '1',
    value: 'label:financial-plan:dropdown:other',
    key: 'andere (Bitte unter Beschreibung angeben.)',
  },
  {
    locale: '41206385',
    value: 'label:financial-plan:dropdown:church-in-need',
    key: 'Kirche in Not',
  },
  {
    locale: '41207832',
    value: 'label:financial-plan:dropdown:popf',
    key: 'Pontificium Opus a Propagatione Fidei  - POPF',
  },
  {
    locale: '41207011',
    value: 'label:financial-plan:dropdown:pospa',
    key: 'Pontificium Opus a S. Petro Apostolo  - POSPA',
  },
  {
    locale: '41206384',
    value: 'label:financial-plan:dropdown:missio-munich',
    key: 'missio München',
  },
  {
    locale: '41206383',
    value: 'label:financial-plan:dropdown:misereor',
    key: 'MISEREOR',
  },
  {
    locale: '41208725',
    value: 'label:financial-plan:dropdown:diocese-rottenburg-stuttgart',
    key: 'Diözese Rottenburg-Stuttgart',
  },
  {
    locale: '41206387',
    value: 'label:financial-plan:dropdown:children-missionary',
    key: 'Kindermissionswerk',
  },
  {
    locale: '41222163',
    value: 'label:financial-plan:dropdown:porticus',
    key: 'Stichting Porticus',
  },
  {
    locale: '41206819',
    value: 'label:financial-plan:dropdown:orient',
    key: "Oeuvre d'Orient",
  },
];
