import React from 'react';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from '../Buttons/Buttons';
import FormTextField from './InputFields/FormTextField';
import { isEmpty } from 'lodash';

const useStyles = makeStyles((theme: Theme) => ({
  titleContainer: {
    flex: 1,
    display: 'flex',
    paddingTop: 15,
    paddingBottom: 15,
  },
  buttonsContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    justifyItems: 'space-between',
    marginBottom: theme.spacing(2)
  },
  defaultButton: {
    display: 'flex',
    borderRadius: 0,
  },
  separator: {
    paddingLeft: 5,
    paddingRight: 5,
  },
  titleTextFieldContainer: {
    flex: 1,
    display: 'flex',
    paddingTop: 10,
    paddingBottom: 10,
  },
}));

export interface FormHeadProps {
  mode: 'review-admin' | 'edit';
  title: string;
  onTitleChange: (value: string) => void;
  onSave: () => void;
  onUpload: (sendAgain?: boolean) => void;
  onAccept?: () => void;
  onReject?: () => void;
  isReadonly: boolean;
  measureName?: string;
  showAdminButtons: boolean;
  showUserButtons: boolean;
  isEasydorError: boolean;
  titleDescription?: string;
  isAssignment?: boolean;
}

const FormHead = (props: FormHeadProps) => {
  const {
    title,
    onTitleChange,
    isReadonly,
    measureName,
    showAdminButtons,
    showUserButtons,
    isEasydorError,
    mode,
    onSave,
    onUpload,
    onAccept,
    onReject,
    titleDescription,
    isAssignment,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const disabled = isAssignment ? isEmpty(title) || isEmpty(measureName) : isEmpty(title);

  return (
    <div>
      <div className={ classes.titleContainer }>
        <Typography variant="h6">
          { !titleDescription ? '' : `${ titleDescription } - ` }
          { !title ? '' : `${ title }` }
        </Typography>
      </div>
      { mode === 'edit' && (
        <div className={ classes.buttonsContainer }>
          <PrimaryButton className={ classes.defaultButton } disabled={ disabled } onClick={ () => onSave() }>
            { t('button:label:save') }
          </PrimaryButton>
          <div className={ classes.separator }/>
          <PrimaryButton className={ classes.defaultButton } onClick={ () => onUpload() }>
            { t('button:label:upload') }
          </PrimaryButton>
        </div>
      ) }
      { mode === 'review-admin' && isEasydorError && !showAdminButtons && (
        <div className={ classes.buttonsContainer }>
          <PrimaryButton className={ classes.defaultButton } onClick={ () => onUpload(true) }>
            { t('form-head:button:send-again') }
          </PrimaryButton>
        </div>
      ) }
      { showAdminButtons && mode === 'review-admin' && (
        <div className={ classes.buttonsContainer }>
          <PrimaryButton className={ classes.defaultButton } onClick={ () => onAccept() }>
            { t(isEasydorError ? 'form-head:button:send-again' : 'form-head:button:accept') }
          </PrimaryButton>
          { isEasydorError === false && (
            <>
              <div className={ classes.separator }/>
              <PrimaryButton className={ classes.defaultButton } onClick={ () => onReject() }>
                { t('form-head:button:decline') }
              </PrimaryButton>
            </>
          ) }
        </div>
      ) }
      { showUserButtons && (
        <div className={ classes.buttonsContainer }>
          { isEasydorError === false && (
            <>
              <PrimaryButton className={ classes.defaultButton } onClick={ () => onAccept() }>
                { t('form-head:button:accept') }
              </PrimaryButton>
              <div className={ classes.separator }/>
              <PrimaryButton className={ classes.defaultButton } onClick={ () => onReject() }>
                { t('form-head:button:decline') }
              </PrimaryButton>
            </>
          ) }
        </div>
      ) }
      <FormTextField
        id="title"
        name="title"
        value={ title }
        labelKey="input:label:project-title"
        onChange={ e => {
          onTitleChange(e.target.value);
        } }
        disabled={ isReadonly }
        errorMessage={ isEmpty(title) ? t('mandatory_field') : undefined }
      />
    </div>
  );
};

export default FormHead;
