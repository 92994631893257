import React, { useCallback, useEffect, useState } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { DefaultTextField } from '../../TextInputs/TextInputs';
import { useTranslation } from 'react-i18next';
import DatePicker from '../../DatePicker/DatePicker';
import Contact from '../../../sdk/com/apiomat/frontend/missio/Contact';
import DropDown from '../../Selection/DropDown';
import { countries } from '../../../value-maps/Countries';
import { alphabeticalSort } from '../../../utils/sort.util';
import LanguageSelection from '../../Localization/LanguageSelection';
import { isEmailValid } from '../../../utils/email.utils';
import { DropDownItem } from '../../Selection/DropDownItem';
import FormTextField from '../InputFields/FormTextField';
import { isEmpty } from 'lodash';
import usePrevious from '../../../hooks/usePrevious';
import { fromStringToDate } from '../../../utils/transforms';

const useStyles = makeStyles(() => ({
  contentContainer: {
    paddingLeft: 18,
    paddingRight: 18,
  },
  gridContainer: {
    paddingBottom: 16,
  },
}));

export interface OwnerContactFormProps {
  contact: Contact;
  updateContact: (contact: Contact, isValid: boolean) => void;
  isReadonly: boolean;
}

const OwnerContactForm = (props: OwnerContactFormProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isReadonly } = props;
  const { contact, updateContact } = props;

  const [ contactType, setContactType ] = useState<string | undefined>(contact.contactType);

  const [ instituteName, setInstitutionName ] = useState<string | undefined>(contact.instituteName);
  const prevInstituteName = usePrevious(instituteName);

  const countryItems: DropDownItem[] = countries.map(el => ({ key: el.key, value: t(el.key) })).sort(alphabeticalSort);

  const isEmailEmpty = (): boolean => {
    return isEmpty(contact.email);
  };

  const isEmailInvalid = (): boolean => {
    return !isEmpty(contact.email) && !isEmailValid(contact.email);
  };

  const isSecondEmailInvalid = (): boolean => {
    return !isEmpty(contact.secondEmail) && !isEmailValid(contact.secondEmail);
  };

  const isValid = useCallback(() => {
    return !isEmpty(instituteName) && !(isEmailEmpty() || isEmailInvalid()) && !isSecondEmailInvalid() && !isEmpty(contact.street) && !isEmpty(contact.place) && !isEmpty(contact.federalState);
  }, [ instituteName, contact.email, contact.secondEmail, contact.street, contact.place, contact.federalState ]);

  useEffect(() => {
    if (instituteName !== prevInstituteName || contactType !== contact.contactType) {
      setContactType(contact.contactType);
      updateContact(contact, isValid());
    }
  }, [ contact, instituteName, prevInstituteName, updateContact ]);

  return (
    <div className={ classes.contentContainer }>
      <Grid container spacing={ 3 } alignItems="flex-start">
        <Grid item xs={ 6 }>
          <FormTextField
            id="instituteName"
            name="instituteName"
            labelKey="input:label:contact-institution-name"
            value={ instituteName }
            onChange={ event => {
              const value = event.target.value;
              setInstitutionName(value);
              contact.instituteName = value;
              updateContact(contact, isValid());
            } }
            disabled={ isReadonly }
            errorMessage={ isEmpty(instituteName) ? t('mandatory_field') : undefined }
          />
        </Grid>
        <Grid item xs={ 6 }>
          <DefaultTextField
            labelKey="input:label:contact-second-institution-name"
            value={ contact.secondInstituteName }
            onChange={ event => {
              contact.secondInstituteName = event.target.value;
              updateContact(contact, isValid());
            } }
            disabled={ isReadonly }
          />
        </Grid>
      </Grid>
      <Grid container spacing={ 3 } alignItems="flex-end" className={ classes.gridContainer }>
        <Grid item xs={ 6 }>
          <DropDown
            labelKey="input:label:contact-country"
            selectedItemKey={ contact.country }
            items={ countryItems }
            onSelectedItemChange={ (key: string) => {
              contact.country = key;
              updateContact(contact, isValid());
            } }
            disabled={ isReadonly }
          />
        </Grid>
        <Grid item xs={ 6 }>
          <DefaultTextField
            labelKey="input:label:contact-diocese"
            value={ contact.diocese }
            onChange={ event => {
              contact.diocese = event.target.value;
              updateContact(contact, isValid());
            } }
            disabled={ isReadonly }
          />
        </Grid>
      </Grid>
      <Grid container spacing={ 3 } alignItems="flex-start" className={ classes.gridContainer }>
        <Grid item xs={ 4 }>
          <DatePicker
            label={ t('input:label:contact-pastoral-plan-start-date') }
            value={ fromStringToDate(contact.startDatePastoralPlan) }
            isReadonly={ isReadonly }
            onChange={ date => {
              contact.startDatePastoralPlan = date.valueOf().toString();
              updateContact(contact, isValid());
            } }
          />
        </Grid>
        <Grid item xs={ 4 }>
          <DatePicker
            label={ t('input:label:contact-pastoral-plan-end-date') }
            value={ contact.endDatePastoralPlan }
            isReadonly={ isReadonly }
            onChange={ date => {
              contact.endDatePastoralPlan = date;
              updateContact(contact, isValid());
            } }
          />
        </Grid>
        <Grid item xs={ 4 }>
          <LanguageSelection
            language={ contact.communicationLanguage }
            inputLabel={ 'input:label:contact-communication-language' }
            onChange={ (key: string) => {
              contact.communicationLanguage = key;
              updateContact(contact, isValid());
            } }
            isReadOnly={ isReadonly }
          />
        </Grid>
      </Grid>
      <Grid container spacing={ 3 } alignItems="flex-start" className={ classes.gridContainer }>
        <Grid item xs={ 4 }>
          <DefaultTextField
            labelKey="input:label:contact-email"
            value={ contact.email }
            onChange={ event => {
              contact.email = event.target.value;
              updateContact(contact, isValid());
            } }
            disabled={ isReadonly }
            isError={ isEmailEmpty() || isEmailInvalid() }
            helperTextKey={ isEmailEmpty() ? 'mandatory_field' : isEmailInvalid() ? 'invalid_email_address' : null }
          />
        </Grid>
        <Grid item xs={ 4 }>
          <DefaultTextField
            labelKey="input:label:contact-second-email"
            value={ contact.secondEmail }
            onChange={ event => {
              contact.secondEmail = event.target.value;
              updateContact(contact, isValid());
            } }
            disabled={ isReadonly }
            isError={ isSecondEmailInvalid() }
            helperTextKey={ isSecondEmailInvalid() ? 'invalid_email_address' : null }
          />
        </Grid>
        <Grid item xs={ 4 }>
          <DefaultTextField
            labelKey="input:label:contact-phone-number"
            value={ contact.phone }
            onChange={ event => {
              contact.phone = event.target.value;
              updateContact(contact, isValid());
            } }
            disabled={ isReadonly }
          />
        </Grid>
      </Grid>
      <Grid container spacing={ 3 } alignItems="flex-start" className={ classes.gridContainer }>
        <Grid item xs={ 4 }>
          <DefaultTextField
            labelKey="input:label:contact-second-phone-number"
            value={ contact.secondPhone }
            onChange={ event => {
              contact.secondPhone = event.target.value;
              updateContact(contact, isValid());
            } }
            disabled={ isReadonly }
          />
        </Grid>
        <Grid item xs={ 4 }>
          <DefaultTextField
            labelKey="input:label:contact-address"
            value={ contact.street }
            onChange={ event => {
              contact.street = event.target.value;
              updateContact(contact, isValid());
            } }
            disabled={ isReadonly }
            isError={ isEmpty(contact.street) }
            helperTextKey={ isEmpty(contact.street) ? 'mandatory_field' : null }
          />
        </Grid>
        <Grid item xs={ 4 }>
          <DefaultTextField
            labelKey="input:label:contact-postal-code"
            value={ contact.zipCode }
            onChange={ event => {
              contact.zipCode = event.target.value;
              updateContact(contact, isValid());
            } }
            disabled={ isReadonly }
          />
        </Grid>
      </Grid>
      <Grid container spacing={ 3 } alignItems="flex-start" className={ classes.gridContainer }>
        <Grid item xs={ 4 }>
          <DefaultTextField
            labelKey="input:label:contact-city"
            value={ contact.place }
            onChange={ event => {
              contact.place = event.target.value;
              updateContact(contact, isValid());
            } }
            disabled={ isReadonly }
            isError={ isEmpty(contact.place) }
            helperTextKey={ isEmpty(contact.place) ? 'mandatory_field' : null }
          />
        </Grid>
        <Grid item xs={ 4 }>
          <DropDown
            labelKey="input:label:contact-federal-contact"
            selectedItemKey={ contact.federalState }
            outlinedTitle={ true }
            items={ countryItems }
            onSelectedItemChange={ (key: string) => {
              contact.federalState = key;
              updateContact(contact, isValid());
            } }
            disabled={ isReadonly }
            errorMessage={ isEmpty(contact.federalState) ? t('mandatory_field') : null }
          />
        </Grid>
        <Grid item xs={ 4 }>
          <DefaultTextField
            labelKey="input:label:contact-region"
            value={ contact.region }
            onChange={ event => {
              contact.region = event.target.value;
              updateContact(contact, isValid());
            } }
            disabled={ isReadonly }
          />
        </Grid>
      </Grid>
      <Grid container spacing={ 3 } alignItems="flex-start">
        <Grid item xs={ 12 }>
          <DefaultTextField
            labelKey="input:label:contact-institution-profile"
            multiline={ true }
            value={ contact.instituteProfile }
            onChange={ event => {
              contact.instituteProfile = event.target.value;
              updateContact(contact, isValid());
            } }
            disabled={ isReadonly }
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default OwnerContactForm;
