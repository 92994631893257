import React from 'react';
import { List, ListItem, ListItemText, makeStyles, ListItemSecondaryAction, IconButton, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../store';
import { shareReportActions } from '../../../store/shareReport';
import LoadingIndicator from '../../Loading/LoadingIndicator';
import { PrimaryButton } from '../../Buttons/Buttons';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  container: {
    marginTop: '20px',
  },
  buttonContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: '50px',
  },
  cancelButton: {
    margin: 5,
    maxWidth: 150,
  },
}));
export interface AlreadySharedReportListProps {
  onCancelButtonClick: () => void;
}
const AlreadySharedReportList = (props: AlreadySharedReportListProps) => {
  const classes = useStyles();
  const { onCancelButtonClick } = props;
  const { currentSharedReport, loadingSharedReport } = useSelector((state: AppState) => state.shareReport);
  const alreadySharedUsers = currentSharedReport.report.sharedWith || [];
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const deleteSharedUser = sharedUser => {
    dispatch(shareReportActions.deleteSharedUser(sharedUser));
  };

  if (loadingSharedReport === 'pending') {
    return <LoadingIndicator />;
  }

  return (
    <div className={classes.container}>
      <List dense>
        {alreadySharedUsers.map(item => (
          <ListItem key={item} button>
            <ListItemText>
              <Typography variant="body1" color="primary">
                {item}
              </Typography>
            </ListItemText>
            <ListItemSecondaryAction>
              <IconButton edge="end" aria-label="delete" onClick={() => deleteSharedUser(item)}>
                <DeleteIcon color="error" />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
      <div className={classes.buttonContainer}>
        <PrimaryButton className={classes.cancelButton} fullWidth variant="outlined" disabled={false} onClick={onCancelButtonClick}>
          {t('button:label:cancel')}
        </PrimaryButton>
      </div>
    </div>
  );
};

export default AlreadySharedReportList;
