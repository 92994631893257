import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from '../../Buttons/Buttons';
import CostPlanDataGrid, { POSITION_ARRAY_ELEMENTS } from './CostPlanDataGrid';
import CostPlan from '../../../sdk/com/apiomat/frontend/missio/CostPlan';
import { Grid, makeStyles, Theme } from '@material-ui/core';
import { DefaultNumberField, DefaultTextField } from '../../TextInputs/TextInputs';
import DatePicker from '../../DatePicker/DatePicker';
import DropDown from '../../Selection/DropDown';
import { currencies } from '../../../value-maps/Currencies';
import CostPlanItem from '../../../sdk/com/apiomat/frontend/missio/CostPlanItem';

const useStyles = makeStyles((theme: Theme) => ({
  tabContainer: {
    paddingTop: 20,
  },
  contentContainer: {
    paddingTop: 10,
    paddingBottom: 30,
    alignItems: 'flex-end',
  },
  addNewLineBtn: {
    marginTop: theme.spacing(2)
  }
}));

const PHASE_ITEMS = [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15 ];

export interface EditCostPlanFormProps {
  isReadonly: boolean;
  costPlan: CostPlan;
  costPlanPhases: Array<number>;
  updateCostPlan: (costPlan: CostPlan) => void;
}

const EditCostPlanForm = (props: EditCostPlanFormProps) => {
  const { isReadonly, costPlan, costPlanPhases, updateCostPlan } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  const handleAddButtonClick = () => {
    const newItem = new CostPlanItem();
    (newItem as any).dao.createdAt = new Date();

    newItem.totalSumEUR = 0;
    newItem.amount = 1;
    newItem.comment = '';
    newItem.costPerUnit = 0;
    newItem.costPlanCategory = '';
    newItem.costPlanType = '';
    newItem.currency = '';
    newItem.optionalFactor = 1;
    newItem.positionOne = POSITION_ARRAY_ELEMENTS.find(
      element => !costPlan.costPlanItems.map(costPlanItem => costPlanItem.positionOne).includes(element)
    );
    newItem.positionTwo = '';
    newItem.description = '';
    newItem.totalSum = 0;
    newItem.unit = '';
    newItem.unitOfFactor = '';

    costPlan.costPlanItems.push(newItem);

    updateCostPlan(costPlan);
  };

  const nationalCurrencies = useMemo(
    () =>
      currencies
        .map(x => ({ key: x.key, value: t(x.value) }))
        .sort((a, b) => a.value.localeCompare(b.value, 'en-US', { ignorePunctuation: true })),
    [ t ]
  );

  const nationalCurrencyOptions = useMemo(
    () =>
      nationalCurrencies
        .filter(x => x.key !== costPlan.currencyRates[1].currency && x.key !== costPlan.currencyRates[2].currency)
        .sort((a, b) => a.value.localeCompare(b.value, 'en-US', { ignorePunctuation: true })),
    [ costPlan.currencyRates, nationalCurrencies ]
  );

  const secondNationalCurrencyOptions = useMemo(
    () =>
      nationalCurrencies
        .filter(x => x.key !== costPlan.currencyRates[2].currency && x.key !== costPlan.currencyRates[0].currency)
        .sort((a, b) => a.value.localeCompare(b.value, 'en-US', { ignorePunctuation: true })),
    [ costPlan.currencyRates, nationalCurrencies ]
  );

  const thirdNationlCurrencyOptions = useMemo(
    () =>
      nationalCurrencies
        .filter(x => x.key !== costPlan.currencyRates[0].currency && x.key !== costPlan.currencyRates[1].currency)
        .sort((a, b) => a.value.localeCompare(b.value, 'en-US', { ignorePunctuation: true })),
    [ costPlan.currencyRates, nationalCurrencies ]
  );

  const phaseItems = PHASE_ITEMS.filter(phaseItem => !costPlanPhases.includes(phaseItem))
    .concat([ costPlan.phase ])
    .map(phaseItem => {
      return {
        key: String(phaseItem),
        value: String(phaseItem),
      };
    });

  useEffect(() => {
    if (!Boolean(costPlan.phase)) {
      costPlan.phase = Number(phaseItems[0].value);
      updateCostPlan(costPlan);
    }
  }, [ costPlan, phaseItems, updateCostPlan ]);

  return (
    <div className={ classes.tabContainer }>
      <Grid container spacing={ 3 } className={ classes.contentContainer }>
        <Grid item xs={ 4 }>
          <DropDown
            labelKey="label:cost-plan:dialog:phase"
            disabled={ isReadonly }
            items={ phaseItems }
            selectedItemKey={ String(costPlan.phase) }
            onSelectedItemChange={ value => {
              costPlan.phase = Number(value);
              updateCostPlan(costPlan);
            } }
          />
        </Grid>
        <Grid item xs={ 4 }>
          <DatePicker
            label={ t('label:cost-plan:dialog:from') }
            value={ costPlan.start }
            isReadonly={ isReadonly }
            onChange={ (date: Date) => {
              costPlan.start = date;
              updateCostPlan(costPlan);
            } }
          />
        </Grid>
        <Grid item xs={ 4 }>
          <DatePicker
            label={ t('label:cost-plan:dialog:until') }
            value={ costPlan.end }
            isReadonly={ isReadonly }
            min={ costPlan.start }
            onChange={ (date: Date) => {
              costPlan.end = date;
              updateCostPlan(costPlan);
            } }
          />
        </Grid>
        <Grid item xs={ 6 }>
          <div>
            <DropDown
              items={ nationalCurrencyOptions }
              selectedItemKey={ costPlan.currencyRates[0].currency }
              onSelectedItemChange={ (value: string) => {
                costPlan.currencyRates[0].currency = value;
                updateCostPlan(costPlan);
              } }
              labelKey="label:cost-plan:dialog:national-currency"
              disabled={ isReadonly }
            />
          </div>
        </Grid>
        <Grid item xs={ 6 }>
          <DefaultNumberField
            labelKey="label:cost-plan:dialog:exchange-rate"
            value={ costPlan.currencyRates[0].rate }
            onValueChanged={ value => {
              costPlan.currencyRates[0].rate = value;
              updateCostPlan(costPlan);
            } }
            disabled={ isReadonly }
          />
        </Grid>
        <Grid item xs={ 6 }>
          <DropDown
            items={ secondNationalCurrencyOptions }
            selectedItemKey={ costPlan.currencyRates[1].currency }
            onSelectedItemChange={ (value: string) => {
              costPlan.currencyRates[1].currency = value;
              updateCostPlan(costPlan);
            } }
            labelKey="label:cost-plan:dialog:national-currency-optional"
            disabled={ isReadonly }
          />
        </Grid>
        <Grid item xs={ 6 }>
          <DefaultNumberField
            labelKey="label:cost-plan:dialog:exchange-rate"
            value={ costPlan.currencyRates[1].rate }
            onValueChanged={ value => {
              costPlan.currencyRates[1].rate = value;
              updateCostPlan(costPlan);
            } }
            disabled={ isReadonly }
          />
        </Grid>
        <Grid item xs={ 6 }>
          <DropDown
            items={ thirdNationlCurrencyOptions }
            selectedItemKey={ costPlan.currencyRates[2].currency }
            onSelectedItemChange={ (value: string) => {
              costPlan.currencyRates[2].currency = value;
              updateCostPlan(costPlan);
            } }
            labelKey="label:cost-plan:dialog:national-currency"
            disabled
          />
        </Grid>
        <Grid item xs={ 6 }>
          <DefaultNumberField labelKey="label:cost-plan:dialog:exchange-rate" value={ costPlan.currencyRates[2].rate }
                              disabled/>
        </Grid>
        <Grid item xs={ 12 }>
          <DefaultTextField
            labelKey="label:cost-plan:total-amount-eur"
            value={ new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(costPlan.sum) }
            disabled
          />
        </Grid>
      </Grid>
      <PrimaryButton onClick={ handleAddButtonClick } disabled={ isReadonly } className={ classes.addNewLineBtn }>
        { t('dialog:button:label:add-new-line') }
      </PrimaryButton>
      <CostPlanDataGrid isReadonly={ isReadonly } costPlan={ costPlan } updateCostPlan={ updateCostPlan }/>
    </div>
  );
};

export default EditCostPlanForm;
