import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles, Theme, Paper, Table, TableBody, TableCell, TableHead, TableRow, Button, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import DropDown from '../Selection/DropDown';
import { DeleteButton } from '../Buttons/DeleteButton';
import { DocumentPickerButton } from '../Buttons/DocumentPickerButton';
import MAttachment from '../../sdk/com/apiomat/frontend/missio/MAttachment';
import { onDownloadFile } from '../../utils/file.utils';
import { getAttachmentTypeName } from '../../utils/form-templates.utils';
import { DropDownItem } from '../Selection/DropDownItem';
import { List, ListItem, ListItemIcon, Typography } from '@mui/material';
import { Check, Warning } from '@material-ui/icons';
import { Action, useOfferFormContext } from './context/OfferFormContext';
import { allAttachmentTypes } from '../../value-maps/AttachmentTypes';
import { useDispatch } from 'react-redux';
import { offerActions } from '../../store/offers';
import { reportActions } from '../../store/report';

const useStyles = makeStyles((theme: Theme) => ({
  contentContainer: {
    paddingBottom: 30,
    paddingTop: 30,
  },
  dropDownContainer: {
    '& .MuiFormControl-root': {
      marginBottom: 10,
    }
  },
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
}));

export interface AttachmentFormProps {
  readOnlyMode: boolean;
  data: any;
  isReport: boolean;
  showDropDown: boolean;
  mandatoryKeys?: string[];
  items: DropDownItem[];
}

const AttachmentForm = (props: AttachmentFormProps) => {
  const dispatch = useDispatch();
  const { state, dispatch: formDispatch } = useOfferFormContext();
  const { readOnlyMode, showDropDown, data, isReport, mandatoryKeys, items } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const [attachmentType, setAttachmentType] = useState<string>('');

  const attachments = data?.attachments;

  const validateForm = useCallback(() => {
    // create error object for error count
    const errorObject = {};
    mandatoryKeys.forEach(mandatoryKey => {
      if (!attachments.some(attachment => attachment.attachmentType === mandatoryKey)) {
        errorObject[mandatoryKey] = 'missingAttachment';
      }
    });
    const actionObject: Action = {
      subFormId: 'attachments',
      errors: errorObject,
      values: attachments,
    };
    if (
      !('attachments' in state) ||
      ('attachments' in state && state['attachments']['errorCount'] !== Object.keys(actionObject.errors).length)
    ) {
      formDispatch(actionObject);
    }
  }, [attachments, formDispatch, mandatoryKeys, state]);

  useEffect(() => {
    if (!readOnlyMode) {
      validateForm();
    }
  }, [readOnlyMode, validateForm]);

  const onDocumentSelected = (file: string | ArrayBuffer | null, name: string) => {
    if (!file) {
      throw new Error('Error while selecting file');
    }

    const attachment = new MAttachment();
    attachment.attachmentType = attachmentType;
    attachment.name = name;
    (attachment as any).dao.file = file;
    (attachment as any).dao.isDeleted = false;
    attachments.push(attachment);

    setAttachmentType('');
    validateForm();
  };

  const onDeleteAttachment = (index: number) => {
    const isSavedAttachment = Boolean(attachments[index]?.ID)

    if (isSavedAttachment) {
      (attachments[index] as any).dao.isDeleted = true;
    } else {
      attachments.splice(index, 1);
    }

    (data as any).dao.attachments = attachments;
    (data as any).hashmap.attachments = attachments;
    validateForm();

    if (isSavedAttachment) {
      isReport ? dispatch(reportActions.updateReportAttachments()) : dispatch(offerActions.updateOfferAttachments());
    }
  };

  return (
    <div className={classes.contentContainer}>
      <Typography variant="subtitle1">{t('mandatory_fields')}</Typography>
      <List>
        {mandatoryKeys.map(mandatoryKey => (
          <ListItem key={mandatoryKey}>
            <ListItemIcon>
              {attachments.some(attachment => attachment.attachmentType === mandatoryKey && !attachment?.dao?.isDeleted) ? <Check /> : <Warning />}
            </ListItemIcon>
            {t(allAttachmentTypes.find(el => el.key === mandatoryKey)?.value || '')}
          </ListItem>
        ))}
      </List>

      {!readOnlyMode && showDropDown && (
        <div className={ classes.dropDownContainer }>
          <DropDown
            labelKey="attachments:attachment-type"
            items={items}
            selectedItemKey={attachmentType}
            onSelectedItemChange={(key: string) => setAttachmentType(key)}
            highlightedItemKeys={mandatoryKeys}
          />
          {Boolean(attachmentType) && <DocumentPickerButton onDocumentSelected={(file, name) => onDocumentSelected(file, name)} />}
        </div>
      )}
      <div>
        <Paper className={classes.root}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>{t('label:attachments-table-name')}</TableCell>
                <TableCell align="right">{t('label:attachments-table-type')}</TableCell>
                <TableCell align="right">{t('label:attachments-table-actions')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {attachments
                ?.filter(item => Boolean((item as any).dao.isDeleted) === false)
                .map((item, i) => (
                  <TableRow key={i}>
                    <TableCell component="th" scope="row">
                      {item.name}
                    </TableCell>
                    <TableCell align="right">{t(getAttachmentTypeName(item))}</TableCell>
                    <TableCell align="right">
                      <Grid container spacing={1} justify="flex-end">
                        {item.fileURL != null && (
                          <Grid item>
                            <Button color="primary" variant="outlined" onClick={() => onDownloadFile(item, 'file')}>
                              {t('view')}
                            </Button>
                          </Grid>
                        )}
                        {!readOnlyMode && (
                          <Grid item>
                            <DeleteButton onDeleteConfirmed={() => onDeleteAttachment(i)} type="icon" />
                          </Grid>
                        )}
                      </Grid>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Paper>
      </div>
    </div>
  );
};

export default AttachmentForm;
