import React from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, makeStyles, Theme } from '@material-ui/core';
import { v4 as uuid } from 'uuid';
import EmptyMessage from './EmptyMessage';
import { DataGridProps } from './DataGrid';

const useStyles = makeStyles((theme: Theme) => ({
  row: {
    verticalAlign: 'top',
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  cellContainer: {
    paddingLeft: 14,
    paddingRight: 4,
  },
}));

const SingleLineDataGrid = (props: DataGridProps) => {
  const { emptyMessage, columns, datasource, onChange } = props;
  const classes = useStyles();

  if (datasource === undefined || datasource.length === 0) {
    return <EmptyMessage title={emptyMessage.title}>{emptyMessage.component}</EmptyMessage>;
  }
  return (
    <Table>
      <TableHead>
        <TableRow>
          {columns.map(column => (
            <TableCell key={uuid()} className={classes.cellContainer}>
              {column.header}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {datasource.map((dataRecord, key) => (
          <TableRow key={key} className={classes.row} hover>
            {columns.map((column, cellKey) => (
              <TableCell key={cellKey} className={classes.cellContainer}>
                {column.component === undefined
                  ? column.valueExtractor(dataRecord)
                  : column.component(dataRecord, x => {
                      if (props.onChange !== undefined) {
                        return;
                      }
                      onChange(x);
                    })}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default SingleLineDataGrid;
