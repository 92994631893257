export enum StateType {
  unsavedChanges = 'offline',
  cloud = 'cloud',
  completed = 'completed',
  rejected = 'rejected',
  approved = 'approved',
  enquiry = 'enquiry',
  enquiryAnswered = 'enquiry-answered',
  easydor = 'easydor',
  easydorError = 'easydor-error',
  easydorInProgress = 'easydor-in-progress',
  easydorLocked = 'easydor-locked',
  amendmentCloud = 'amendment-cloud',
  amendmentCompleted = 'amendment-completed',
  easydorClosed = 'easydor-closed',
  easydorRejected = 'easydor-rejected',
  easydorApproved = 'easydor-approved',
  closedProposalMissio = 'closed-proposal-missio',
  closedProposalUser = 'closed-proposal-user',
  proposalMissio = 'proposal-missio',
  proposalRejectedUser = 'proposal-rejected-user',
  proposalRejectedMissio = 'proposal-rejected-missio',
  proposalUserCloud = 'proposal-user-cloud',
  proposalUserCompleted = 'proposal-user-completed',
  proposalInProgressUser = 'proposal-in-progress-user',
  proposalInProgressMissio = 'proposal-in-progress-missio',
  proposalLocked = 'proposal-locked'
}
