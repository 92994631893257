import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AppState } from '../../store';
import LoadingIndicator from '../../components/Loading/LoadingIndicator';
import FullScreenLoadingIndicator from '../../components/Loading/FullScreenLoadingIndicator';
import FormHead from '../../components/Forms/FormHead';
import { InvalidResult } from '../../validators/OfferValidation';
import { makeStyles, Tab, Tabs } from '@material-ui/core';
import MissingFieldsDialog from '../../components/Dialogs/MissingFieldsDialog';
import { useTranslation } from 'react-i18next';
import { auditActions } from '../../store/audit';
import AuditForm from '../../components/Forms/Audit/AuditForm';
import Audit from '../../sdk/com/apiomat/frontend/missio/Audit';
import { StateType } from '../../enums/StateType';
import { getTranslatedSortedAttachmentTypes } from '../../utils/attachment.util';
import OldAttachmentForm from '../../components/Forms/OldAttachmentForm';
import { OfferFormProvider } from '../../components/Forms/context/OfferFormContext';

const useStyles = makeStyles(() => ({
  titleContainer: {
    flex: 1,
    display: 'flex',
    paddingTop: 15,
    paddingBottom: 15,
  },
  buttonsContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    justifyItems: 'space-between',
  },
  saveButton: {
    width: window.innerWidth / 12,
    display: 'flex',
    borderRadius: 0,
  },
  separator: {
    paddingLeft: 5,
    paddingRight: 5,
  },
  uploadButton: {
    width: window.innerWidth / 12,
    display: 'flex',
    borderRadius: 0,
  },
  titleTextFieldContainer: {
    flex: 1,
    display: 'flex',
    paddingTop: 10,
    paddingBottom: 10,
  },
  tabContainer: {
    paddingTop: 20,
    paddingBottom: 20,
  },
}));

interface TabItem {
  key: string;
  label: string;
  content?: React.ReactNode;
}

export default () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = useParams<{ id: string | undefined }>();

  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const [isMissingFieldsDialogActive, setMissingFieldsDialogActive] = useState<boolean>(false);
  const [missingFields] = useState<InvalidResult[]>([]);

  useEffect(() => {
    if (id) {
      dispatch(auditActions.loadCurrentAudit(id));
    }
  }, [dispatch, id]);

  const { currentAudit, loadingAudits, loadingCurrentAudit } = useSelector((state: AppState) => state.audit);

  const audit = currentAudit?.audit;
  const offer = audit?.offer;

  const readOnly = audit?.state?.name === StateType.easydor || audit?.state?.name === StateType.easydorError;

  if (loadingCurrentAudit === 'pending') {
    return <FullScreenLoadingIndicator />;
  }

  if (loadingAudits === 'pending') {
    return <LoadingIndicator />;
  }
  if (!audit) {
    return null;
  }

  const updateAudit = (audit: Audit) => dispatch(auditActions.updateCurrentAudit(audit));

  const tabs: TabItem[] = [
    {
      key: 'audit',
      label: 'tab:title:audit',
      content: <AuditForm updateAudit={updateAudit} audit={audit} readOnlyMode={readOnly} />,
    },
    {
      key: 'attachments',
      label: 'tab:title:attachments',
      content: (
        <OldAttachmentForm
          data={audit}
          readOnlyMode={readOnly}
          showDropDown={!readOnly}
          items={getTranslatedSortedAttachmentTypes(t)}
          update={updateAudit}
        />
      ),
    },
  ];

  const onSave = () => {
    dispatch(auditActions.saveCurrentAudit(StateType.cloud));
  };

  const onUpload = () => {
    dispatch(auditActions.saveCurrentAudit(StateType.completed));
  };

  return (
    <OfferFormProvider key={Boolean(id) ? `audit-${id}` : 'new-audit'} data={offer} onChange={() => {}} isReport={true}>
      <FormHead
        mode={readOnly ? 'review-admin' : 'edit'}
        title={offer.name}
        isReadonly={true}
        showAdminButtons={false}
        showUserButtons={false}
        isEasydorError={audit?.state?.name === StateType.easydorError}
        onTitleChange={() => {}}
        titleDescription={t('create-audit')}
        onSave={onSave}
        onUpload={onUpload}
        isAssignment={false}
      />
      <div className={classes.tabContainer}>
        <Tabs variant="scrollable" scrollButtons="on" value={activeTabIndex} onChange={(_event, tabIndex) => setActiveTabIndex(tabIndex)}>
          {tabs.map(tab => (
            <Tab fullWidth key={tab.key} disableRipple label={t(tab.label)} />
          ))}
        </Tabs>
        {tabs[activeTabIndex].content}
        {isMissingFieldsDialogActive && (
          <MissingFieldsDialog invalidResults={missingFields} onCancelButtonClick={() => setMissingFieldsDialogActive(false)} />
        )}
      </div>
    </OfferFormProvider>
  );
};
