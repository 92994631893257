import React, { useEffect } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AppState } from '../../store/index';
import { surveyActions } from '../../store/survey';
import Survey from '../../sdk/com/apiomat/frontend/missio/Survey';
import { OfferType } from '../../enums/OfferType';
import { StateType } from '../../enums/StateType';
import { Link } from 'react-router-dom';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import LoadingIndicator from '../../components/Loading/LoadingIndicator';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import { ApplicationStatus } from '../../components/ApplicationStatus/ApplicationStatus';
import MUIDataTable, { MUIDataTableColumnDef } from 'mui-datatables';
import State from '../../sdk/com/apiomat/frontend/missio/State';
import ActionButton from '@mui/material/Button';

interface TableSurvey {
  application: Survey;
  deadline: string;
  status: string;
  completed: State;
  actions: Survey;
}

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  buttonContainer: {
    flex: 1,
    justifyContent: 'flex-end',
    display: 'flex',
  },
  spacing: {
    paddingLeft: 5,
    paddingRight: 5,
  },
}));

export default () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(surveyActions.loadSurveys());
  }, [ dispatch ]);

  const { surveys, loadingSurveys, loadingCurrentSurvey } = useSelector((state: AppState) => state.survey);

  const columns: Array<MUIDataTableColumnDef> = [
    {
      name: 'application',
      label: t('survey:header:application'),
      options: {
        customBodyRender: (value) => {
          return value.state?.name === StateType.easydor || value.state?.name === StateType.easydorError ? (
            value.offerName
          ) : (
            <Button
              href="#text-buttons"
              color="primary"
              component={ Link }
              to={ `/assignment/${ value.offer?.measureType === OfferType.mis ? 'mis' : 'mwi' }/${ value.offer?.ID }` }
            >
              { value.offerName }
            </Button>
          );
        }
      }
    },
    {
      name: 'deadline',
      label: t('survey:header:deadline')
    },
    {
      name: 'status',
      label: t('survey:header:status')
    },
    {
      name: 'completed',
      label: t('survey:header:completed'),
      options: {
        customBodyRender: (value) => {
          return <ApplicationStatus state={ value }></ApplicationStatus>;
        }
      }
    },
    {
      name: 'actions',
      label: ' ',
      options: {
        customBodyRender: (value) => {
          return (
            <div className={ classes.buttonContainer }>
              { !(value.state?.name === StateType.easydor || value.state?.name === StateType.easydorError) && (
                <ActionButton variant="outlined"
                              color="primary"
                              component={ Link }
                              to={ `/survey/reports/${ value.offer?.ID }/true` }
                              disabled={ value.offer?.hasReports !== 1 }
                >
                  <PlaylistAddCheckIcon/>
                </ActionButton>
              ) }
              <div className={ classes.spacing }/>
              <ActionButton variant="outlined"
                            color="primary"
                            component={ Link }
                            to={ `/survey/new/${ value.ID }` }
              >
                { value.state?.name === StateType.completed || value.state?.name === StateType.easydor ?
                  <VisibilityIcon/> : <EditIcon/> }
              </ActionButton>
            </div>
          );
        },
        filter: false,
        searchable: false,
        sort: false,
        viewColumns: false
      }
    }
  ];

  const data: Array<TableSurvey> = surveys.map(survey => {
    return {
      application: survey,
      deadline: survey?.dueDate?.toLocaleDateString('de-DE'),
      status: t(`survey:status:${ survey.state?.name }`),
      completed: survey.state,
      actions: survey
    };
  });

  return loadingSurveys === 'pending' || loadingCurrentSurvey === 'pending' ? (
    <LoadingIndicator/>
  ) : (
    <div className={ classes.container }>
      <MUIDataTable
        title={ t('my-surveys') }
        data={ data }
        columns={ columns }
        options={ {
          selectableRows: 'none',
          print: false,
          download: false,
          filter: false,
          sort: false,
          viewColumns: false,
          search: false
        } }
      />
    </div>
  );
};
