import React, { useEffect, useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Offers from '../../../../sdk/com/apiomat/frontend/missio/Offers';
import Vehicle from '../../../../sdk/com/apiomat/frontend/missio/Vehicle';
import Reports from '../../../../sdk/com/apiomat/frontend/missio/Reports';
import FormDatePicker from '../../../DatePicker/FormDatePicker';
import { date, object, string } from 'yup';
import { useFormik } from 'formik';
import FormTextField from '../../InputFields/FormTextField';
import { useOfferFormContext } from '../../context/OfferFormContext';
export interface VehicleProps {
  readOnlyMode: boolean;
  data: Offers | Reports;
  isReport?: boolean;
}

const VehicleForm = (props: VehicleProps) => {
  const { state, dispatch } = useOfferFormContext();
  const { readOnlyMode, data, isReport } = props;
  const { t } = useTranslation();

  const validationSchema = useMemo(
    () =>
      object({
        start: date().required(t('mandatory_field')),
        end: date().required(t('mandatory_field')),
        measureDescription: string().required(t('mandatory_field')),
        description: string().required(t('mandatory_field')),
        selectionReason: isReport ? string() : string().required(t('mandatory_field')),
        maintenancePossible: isReport ? string() : string().required(t('mandatory_field')),
        usage: string().required(t('mandatory_field')),
        usageFrequency: isReport ? string() : string().required(t('mandatory_field')),
        upkeepCosts: isReport ? string() : string().required(t('mandatory_field')),
        exists: isReport ? string() : string().required(t('mandatory_field')),
        oldVehicle: string().required(t('mandatory_field')),
      }),
    [t, isReport]
  );

  const {
    start,
    end,
    measureDescription,
    description,
    selectionReason,
    maintenancePossible,
    usage,
    usageFrequency,
    upkeepCosts,
    exists,
    oldVehicle,
  } = data.measure as Vehicle;

  const formik = useFormik({
    initialValues: {
      start,
      end,
      measureDescription,
      description,
      selectionReason,
      maintenancePossible,
      usage,
      usageFrequency,
      upkeepCosts,
      exists,
      oldVehicle,
    },
    enableReinitialize: true,
    validationSchema,
    validateOnMount: true,
    onSubmit: () => {},
  });

  useEffect(() => {
    if (!readOnlyMode) {
      if (!formik.isValidating) {
        dispatch({ subFormId: 'measure', errors: formik.errors, values: formik.values });
      }
    }
  }, [formik.errors, formik.values, dispatch, formik.isValidating, readOnlyMode, state]);

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <FormDatePicker
            id="start"
            name="start"
            label={t('input:label:measure:planned-project-start')}
            value={formik.values.start}
            onChange={value => formik.setFieldValue('start', value)}
            disabled={readOnlyMode}
            errorMessage={formik.errors.start}
          />
        </Grid>
        <Grid item xs={6}>
          <FormDatePicker
            id="end"
            name="end"
            label={t('input:label:measure:planned-project-end')}
            value={formik.values.end}
            minDate={formik.values.start}
            onChange={value => formik.setFieldValue('end', value)}
            disabled={readOnlyMode}
            errorMessage={formik.errors.end}
          />
        </Grid>
        <Grid item xs={12}>
          <FormTextField
            id="measureDescription"
            name="measureDescription"
            disabled={readOnlyMode}
            labelKey="input:label:measure:measure-description"
            value={formik.values.measureDescription}
            multiline
            rows={3}
            onChange={formik.handleChange}
            errorMessage={formik.errors.measureDescription}
          />
        </Grid>

        <Grid item xs={12}>
          <FormTextField
            id="description"
            name="description"
            disabled={readOnlyMode}
            labelKey="input:label:measure:vehicle-description"
            descriptionKey="info:measure:vehicle:vehicle-description"
            value={formik.values.description}
            multiline
            rows={3}
            onChange={formik.handleChange}
            errorMessage={formik.errors.description}
          />
        </Grid>
        {!isReport && (
          <>
            <Grid item xs={12}>
              <FormTextField
                id="selectionReason"
                name="selectionReason"
                disabled={readOnlyMode}
                labelKey="input:label:measure:vehicle-selection-reason"
                descriptionKey="info:measure:vehicle:selection-reason"
                value={formik.values.selectionReason}
                multiline
                rows={3}
                onChange={formik.handleChange}
                errorMessage={formik.errors.selectionReason}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                id="maintenancePossible"
                name="maintenancePossible"
                disabled={readOnlyMode}
                labelKey="input:label:measure:vehicle-maintenance"
                descriptionKey="info:measure:vehicle:maintenance"
                value={formik.values.maintenancePossible}
                multiline
                rows={3}
                onChange={formik.handleChange}
                errorMessage={formik.errors.maintenancePossible}
              />
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <FormTextField
            id="usage"
            name="usage"
            disabled={readOnlyMode}
            labelKey="input:label:measure:vehicle-use"
            descriptionKey="info:measure:vehicle:vehicle-use"
            value={formik.values.usage}
            multiline
            rows={3}
            onChange={formik.handleChange}
            errorMessage={formik.errors.usage}
          />
        </Grid>
        {!isReport && (
          <>
            <Grid item xs={12}>
              <FormTextField
                id="usageFrequency"
                name="usageFrequency"
                disabled={readOnlyMode}
                labelKey="input:label:measure:vehicle-use-frequency"
                descriptionKey="input:label:measure:vehicle-use-frequency"
                value={formik.values.usageFrequency}
                multiline
                rows={3}
                onChange={formik.handleChange}
                errorMessage={formik.errors.usageFrequency}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                id="upkeepCosts"
                name="upkeepCosts"
                disabled={readOnlyMode}
                labelKey="input:label:measure:vehicle-maintenance-cost"
                descriptionKey="info:measure:vehicle:maintenance-cost"
                value={formik.values.upkeepCosts}
                multiline
                rows={3}
                onChange={formik.handleChange}
                errorMessage={formik.errors.upkeepCosts}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                id="exists"
                name="exists"
                disabled={readOnlyMode}
                labelKey="input:label:measure:vehicle-availability"
                descriptionKey="info:measure:vehicle:vehicle-availability"
                value={formik.values.exists}
                multiline
                rows={3}
                onChange={formik.handleChange}
                errorMessage={formik.errors.exists}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <FormTextField
            id="oldVehicle"
            name="oldVehicle"
            disabled={readOnlyMode}
            labelKey="input:label:measure:vehicle-end-life-remaining-time"
            descriptionKey="info:measure:vehicle:vehicle-end-life-remaining-time"
            value={formik.values.oldVehicle}
            multiline
            rows={3}
            onChange={formik.handleChange}
            errorMessage={formik.errors.oldVehicle}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default VehicleForm;
