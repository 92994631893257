import React, { useState } from 'react';
import { Dialog, DialogContent, DialogTitle, makeStyles, Theme, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import { Close } from '@material-ui/icons';
import { IconButton } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  iconContainer: {
    flex: 1,
    paddingLeft: 2,
    marginLeft: 3,
    marginBottom: 3,
    display: 'flex',
    cursor: 'pointer',
  },
  highlightedIconContainer: {
    flex: 1,
    paddingLeft: 2,
    marginLeft: 3,
    marginBottom: 3,
    display: 'flex',
    cursor: 'pointer',
    color: theme.palette.error.main,
  },
  dialogTitleContainer: {
    justifyContent: 'flex-end',
    flexDirection: 'row',
    textAlign: 'center',
    backgroundColor: theme.palette.primary.main,
    borderBottom: '1px solid #898989',
  },
  dialogTextContainer: {
    whiteSpace: 'pre-wrap',
  },
  title: {
    color: '#f5f5f5',
    fontWeight: 600,
  },
}));

export interface DefaultTranslatedLabelProps {
  translationId: string;
  infoId: string;
  highlightInfo?: boolean;
}

const DefaultTranslatedLabel = (props: DefaultTranslatedLabelProps) => {
  const { translationId, infoId, highlightInfo } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const [ isOpen, setIsOpen ] = useState<boolean>(false);

  return (
    <div className={ classes.container }>
      { t(translationId) }
      <div>
        <InfoOutlined
          className={ highlightInfo ? classes.highlightedIconContainer : classes.iconContainer }
          onClick={ () => setIsOpen(true) }
        />
      </div>
      <div>
        <Dialog open={ isOpen } fullWidth maxWidth="sm">
          <DialogTitle className={ classes.dialogTitleContainer }>
            <IconButton
              aria-label="close"
              onClick={ () => setIsOpen(false) }
              sx={ {
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[100]
              } }>
              <Close/>
            </IconButton>
            <Typography className={ classes.title } component="span" variant="h5">
              { t('info:title:info') }
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Typography variant="body1" className={ classes.dialogTextContainer }>
              { t(infoId) }
            </Typography>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
};

export default DefaultTranslatedLabel;
