import React, { useEffect, useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import AcquisitionOfTechnicalDevice from '../../../../sdk/com/apiomat/frontend/missio/AcquisitionOfTechnicalDevice';
import { object, string, date } from 'yup';
import { useFormik } from 'formik';
import FormDatePicker from '../../../DatePicker/FormDatePicker';
import FormTextField from '../../InputFields/FormTextField';
import { useOfferFormContext } from '../../context/OfferFormContext';

export interface TechnologyPurchaseProps {
  readOnlyMode: boolean;
  data: any;
  isReport?: boolean;
}

const TechnologyPurchase = (props: TechnologyPurchaseProps) => {
  const { state, dispatch } = useOfferFormContext();
  const { readOnlyMode, data, isReport } = props;
  const { t } = useTranslation();
  const {
    start,
    end,
    measureDescription,
    deviceDescription,
    deviceExisting,
    acquisitionUpkeepCosts,
  } = data.measure as AcquisitionOfTechnicalDevice;

  const validationSchema = useMemo(
    () =>
      object({
        start: date().required(t('mandatory_field')),
        end: date().required(t('mandatory_field')),
        measureDescription: string().required(t('mandatory_field')),
        deviceDescription: string().required(t('mandatory_field')),
        deviceExisting: isReport ? string() : string().required(t('mandatory_field')),
        acquisitionUpkeepCosts: isReport ? string() : string().required(t('mandatory_field')),
      }),
    [t, isReport]
  );

  const formik = useFormik({
    initialValues: {
      start,
      end,
      measureDescription,
      deviceDescription,
      deviceExisting,
      acquisitionUpkeepCosts,
    },
    enableReinitialize: true,
    validationSchema,
    validateOnMount: true,
    onSubmit: () => {},
  });

  useEffect(() => {
    if (!readOnlyMode) {
      if (!formik.isValidating) {
        dispatch({ subFormId: 'measure', errors: formik.errors, values: formik.values });
      }
    }
  }, [formik.errors, formik.values, dispatch, formik.isValidating, readOnlyMode, state]);

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <FormDatePicker
            id="start"
            name="start"
            label={t('input:label:measure:planned-project-start')}
            value={formik.values.start}
            onChange={value => formik.setFieldValue('start', value)}
            disabled={readOnlyMode}
            errorMessage={formik.errors.start}
          />
        </Grid>
        <Grid item xs={6}>
          <FormDatePicker
            id="end"
            name="end"
            label={t('input:label:measure:planned-project-end')}
            value={formik.values.end}
            minDate={formik.values.start}
            onChange={value => formik.setFieldValue('end', value)}
            disabled={readOnlyMode}
            errorMessage={formik.errors.end}
          />
        </Grid>

        <Grid item xs={12}>
          <FormTextField
            id="measureDescription"
            name="measureDescription"
            disabled={readOnlyMode}
            labelKey="input:label:measure:measure-description"
            value={formik.values.measureDescription}
            multiline
            rows={3}
            onChange={formik.handleChange}
            errorMessage={formik.errors.measureDescription}
          />
        </Grid>
        <Grid item xs={12}>
          <FormTextField
            id="deviceDescription"
            name="deviceDescription"
            disabled={readOnlyMode}
            labelKey="input:label:measure:technology-purchase-technique-description"
            descriptionKey="info:measure:technology-purchase:technique-description"
            value={formik.values.deviceDescription}
            multiline
            rows={3}
            onChange={formik.handleChange}
            errorMessage={formik.errors.deviceDescription}
          />
        </Grid>
        {!isReport && (
          <>
            <Grid item xs={12}>
              <FormTextField
                id="deviceExisting"
                name="deviceExisting"
                disabled={readOnlyMode}
                labelKey="input:label:measure:technology-purchase-availability-device"
                descriptionKey="info:measure:technology-purchase:availability-device"
                value={formik.values.deviceExisting}
                multiline
                rows={3}
                onChange={formik.handleChange}
                errorMessage={formik.errors.deviceExisting}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                id="acquisitionUpkeepCosts"
                name="acquisitionUpkeepCosts"
                disabled={readOnlyMode}
                labelKey="input:label:measure:technology-purchase-maintenance-cost"
                descriptionKey="info:measure:technology-purchase:maintenance-cost"
                value={formik.values.acquisitionUpkeepCosts}
                multiline
                rows={3}
                onChange={formik.handleChange}
                errorMessage={formik.errors.acquisitionUpkeepCosts}
              />
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
};

export default TechnologyPurchase;
