import { GeneralValueMap } from './GeneralValueMap';

export const countries: GeneralValueMap[] = [
  {
    locale: 'lbl.country.417',
    value: 'Niederlande',
    key: '417',
  },
  {
    locale: 'lbl.country.345',
    value: 'Oman',
    key: '345',
  },
  {
    locale: 'lbl.country.349',
    value: 'Malediven',
    key: '349',
  },
  {
    locale: 'lbl.country.446',
    value: 'Kosovo',
    key: '446',
  },
  {
    locale: 'lbl.country.511',
    value: 'Mikronesien',
    key: '511',
  },
  {
    locale: 'lbl.country.110',
    value: 'Angola',
    key: '110',
  },
  {
    locale: 'lbl.country.440',
    value: 'Slowenien',
    key: '440',
  },
  {
    locale: 'lbl.country.526',
    value: 'Tahiti',
    key: '526',
  },
  {
    locale: 'lbl.country.348',
    value: 'Ver.Arab.Emir.',
    key: '348',
  },
  {
    locale: 'lbl.country.350',
    value: 'Armenien',
    key: '350',
  },
  {
    locale: 'lbl.country.146',
    value: 'Senegal',
    key: '146',
  },
  {
    locale: 'lbl.country.423',
    value: 'Rumänien',
    key: '423',
  },
  {
    locale: 'lbl.country.122',
    value: 'Guinea',
    key: '122',
  },
  {
    locale: 'lbl.country.127',
    value: 'Mauritius',
    key: '127',
  },
  {
    locale: 'lbl.country.224',
    value: 'Haiti',
    key: '224',
  },
  {
    locale: 'lbl.country.358',
    value: 'Mongolei',
    key: '358',
  },
  {
    locale: 'lbl.country.522',
    value: 'Karolinen',
    key: '522',
  },
  {
    locale: 'lbl.country.445',
    value: 'Litauen',
    key: '445',
  },
  {
    locale: 'lbl.country.322',
    value: 'Japan',
    key: '322',
  },
  {
    locale: 'lbl.country.139',
    value: 'Mosambik',
    key: '139',
  },
  {
    locale: 'lbl.country.410',
    value: 'Griechenland',
    key: '410',
  },
  {
    locale: 'lbl.country.427',
    value: 'Russische Foed.',
    key: '427',
  },
  {
    locale: 'lbl.country.145',
    value: 'Ruanda',
    key: '145',
  },
  {
    locale: 'lbl.country.153',
    value: 'Swasiland',
    key: '153',
  },
  {
    locale: 'lbl.country.353',
    value: 'Kasachstan',
    key: '353',
  },
  {
    locale: 'lbl.country.260',
    value: 'Karibik allgem.',
    key: '260',
  },
  {
    locale: 'lbl.country.235',
    value: 'Puerto Rico',
    key: '235',
  },
  {
    locale: 'lbl.country.209',
    value: 'Argentinien',
    key: '209',
  },
  {
    locale: 'lbl.country.227',
    value: 'Niederl.Antill.',
    key: '227',
  },
  {
    locale: 'lbl.country.424',
    value: 'Polen',
    key: '424',
  },
  {
    locale: 'lbl.country.113',
    value: 'Kamerun',
    key: '113',
  },
  {
    locale: 'lbl.country.519',
    value: 'Samoa',
    key: '519',
  },
  {
    locale: 'lbl.country.318',
    value: 'Indonesien',
    key: '318',
  },
  {
    locale: 'lbl.country.320',
    value: 'Iran',
    key: '320',
  },
  {
    locale: 'lbl.country.129',
    value: 'Seychellen',
    key: '129',
  },
  {
    locale: 'lbl.country.138',
    value: 'Mauretanien',
    key: '138',
  },
  {
    locale: 'lbl.country.114',
    value: 'Republik Kongo',
    key: '114',
  },
  {
    locale: 'lbl.country.230',
    value: 'Mexiko',
    key: '230',
  },
  {
    locale: 'lbl.country.510',
    value: 'Melanesien',
    key: '510',
  },
  {
    locale: 'lbl.country.337',
    value: 'Singapur',
    key: '337',
  },
  {
    locale: 'lbl.country.523',
    value: 'Neukaledonien',
    key: '523',
  },
  {
    locale: 'lbl.country.414',
    value: 'Italien',
    key: '414',
  },
  {
    locale: 'lbl.country.140',
    value: 'Niger',
    key: '140',
  },
  {
    locale: 'lbl.country.314',
    value: 'Nordkorea',
    key: '314',
  },
  {
    locale: 'lbl.country.338',
    value: 'Nepal',
    key: '338',
  },
  {
    locale: 'lbl.country.439',
    value: 'Mazedonien',
    key: '439',
  },
  {
    locale: 'lbl.country.419',
    value: 'Schweden',
    key: '419',
  },
  {
    locale: 'lbl.country.123',
    value: 'Äquatorialguinea',
    key: '123',
  },
  {
    locale: 'lbl.country.416',
    value: 'Norwegen',
    key: '416',
  },
  {
    locale: 'lbl.country.141',
    value: 'Nigeria',
    key: '141',
  },
  {
    locale: 'lbl.country.161',
    value: 'Südsudan',
    key: '161',
  },
  {
    locale: 'lbl.country.231',
    value: 'Nicaragua',
    key: '231',
  },
  {
    locale: 'lbl.country.236',
    value: 'Dominik. Rep.',
    key: '236',
  },
  {
    locale: 'lbl.country.517',
    value: 'Vanuatu',
    key: '517',
  },
  {
    locale: 'lbl.country.244',
    value: 'Antigua/Barbuda',
    key: '244',
  },
  {
    locale: 'lbl.country.132',
    value: 'Liberia',
    key: '132',
  },
  {
    locale: 'lbl.country.116',
    value: 'Elfenbeinküste',
    key: '116',
  },
  {
    locale: 'lbl.country.252',
    value: 'Martinique',
    key: '252',
  },
  {
    locale: 'lbl.country.411',
    value: 'Malta',
    key: '411',
  },
  {
    locale: 'lbl.country.164',
    value: 'Sao Thome u. Pr',
    key: '164',
  },
  {
    locale: 'lbl.country.147',
    value: 'Sierra Leone',
    key: '147',
  },
  {
    locale: 'lbl.country.321',
    value: 'Israel',
    key: '321',
  },
  {
    locale: 'lbl.country.242',
    value: 'Westindien',
    key: '242',
  },
  {
    locale: 'lbl.country.406',
    value: 'Finnland',
    key: '406',
  },
  {
    locale: 'lbl.country.330',
    value: 'Syrien',
    key: '330',
  },
  {
    locale: 'lbl.country.128',
    value: 'Reunion',
    key: '128',
  },
  {
    locale: 'lbl.country.316',
    value: 'Hongkong',
    key: '316',
  },
  {
    locale: 'lbl.country.217',
    value: 'Ecuador',
    key: '217',
  },
  {
    locale: 'lbl.country.216',
    value: 'Kuba',
    key: '216',
  },
  {
    locale: 'lbl.country.443',
    value: 'Estland',
    key: '443',
  },
  {
    locale: 'lbl.country.404',
    value: 'Dänemark',
    key: '404',
  },
  {
    locale: 'lbl.country.300',
    value: 'Asien',
    key: '300',
  },
  {
    locale: 'lbl.country.331',
    value: 'Taiwan',
    key: '331',
  },
  {
    locale: 'lbl.country.325',
    value: 'Laos',
    key: '325',
  },
  {
    locale: 'lbl.country.312',
    value: 'Sri Lanka',
    key: '312',
  },
  {
    locale: 'lbl.country.228',
    value: 'StPier.,Miquel.',
    key: '228',
  },
  {
    locale: 'lbl.country.449',
    value: 'Montenegro',
    key: '449',
  },
  {
    locale: 'lbl.country.222',
    value: 'Guyana',
    key: '222',
  },
  {
    locale: 'lbl.country.218',
    value: 'El Salvador',
    key: '218',
  },
  {
    locale: 'lbl.country.342',
    value: 'Bangladesch',
    key: '342',
  },
  {
    locale: 'lbl.country.428',
    value: 'Vatikanstaat',
    key: '428',
  },
  {
    locale: 'lbl.country.131',
    value: 'Lesotho',
    key: '131',
  },
  {
    locale: 'lbl.country.225',
    value: 'Honduras',
    key: '225',
  },
  {
    locale: 'lbl.country.211',
    value: 'Bolivien',
    key: '211',
  },
  {
    locale: 'lbl.country.346',
    value: 'Brunei Drussal.',
    key: '346',
  },
  {
    locale: 'lbl.country.352',
    value: 'Aserbeidschan',
    key: '352',
  },
  {
    locale: 'lbl.country.219',
    value: 'Guatemala',
    key: '219',
  },
  {
    locale: 'lbl.country.429',
    value: 'Serbien',
    key: '429',
  },
  {
    locale: 'lbl.country.124',
    value: 'Guinea-Bissau',
    key: '124',
  },
  {
    locale: 'lbl.country.143',
    value: 'Ägypten',
    key: '143',
  },
  {
    locale: 'lbl.country.431',
    value: 'Luxemburg',
    key: '431',
  },
  {
    locale: 'lbl.country.229',
    value: 'Jamaika',
    key: '229',
  },
  {
    locale: 'lbl.country.160',
    value: 'Sahara',
    key: '160',
  },
  {
    locale: 'lbl.country.212',
    value: 'Brasilien',
    key: '212',
  },
  {
    locale: 'lbl.country.135',
    value: 'Malawi',
    key: '135',
  },
  {
    locale: 'lbl.country.415',
    value: 'Liechtenstein',
    key: '415',
  },
  {
    locale: 'lbl.country.200',
    value: 'Amerika',
    key: '200',
  },
  {
    locale: 'lbl.country.248',
    value: 'St. Lucia',
    key: '248',
  },
  {
    locale: 'lbl.country.339',
    value: 'Jemen',
    key: '339',
  },
  {
    locale: 'lbl.country.234',
    value: 'Peru',
    key: '234',
  },
  {
    locale: 'lbl.country.323',
    value: 'Jordanien',
    key: '323',
  },
  {
    locale: 'lbl.country.233',
    value: 'Paraguay',
    key: '233',
  },
  {
    locale: 'lbl.country.516',
    value: 'Salomon Inseln',
    key: '516',
  },
  {
    locale: 'lbl.country.109',
    value: 'Algerien',
    key: '109',
  },
  {
    locale: 'lbl.country.400',
    value: 'Europa',
    key: '400',
  },
  {
    locale: 'lbl.country.111',
    value: 'Botsuana',
    key: '111',
  },
  {
    locale: 'lbl.country.250',
    value: 'Brit.Jungferni.',
    key: '250',
  },
  {
    locale: 'lbl.country.412',
    value: 'Irland',
    key: '412',
  },
  {
    locale: 'lbl.country.137',
    value: 'Marokko',
    key: '137',
  },
  {
    locale: 'lbl.country.159',
    value: 'Sambia',
    key: '159',
  },
  {
    locale: 'lbl.country.341',
    value: 'Afghanistan',
    key: '341',
  },
  {
    locale: 'lbl.country.223',
    value: 'Suriname',
    key: '223',
  },
  {
    locale: 'lbl.country.401',
    value: 'Deutschland',
    key: '401',
  },
  {
    locale: 'lbl.country.329',
    value: 'Philippinen',
    key: '329',
  },
  {
    locale: 'lbl.country.418',
    value: 'Portugal',
    key: '418',
  },
  {
    locale: 'lbl.country.121',
    value: 'Ghana',
    key: '121',
  },
  {
    locale: 'lbl.country.317',
    value: 'Indien',
    key: '317',
  },
  {
    locale: 'lbl.country.239',
    value: 'Venezuela',
    key: '239',
  },
  {
    locale: 'lbl.country.524',
    value: 'Bismarck-Archip',
    key: '524',
  },
  {
    locale: 'lbl.country.151',
    value: 'Südafrika',
    key: '151',
  },
  {
    locale: 'lbl.country.426',
    value: 'Monaco',
    key: '426',
  },
  {
    locale: 'lbl.country.413',
    value: 'Island',
    key: '413',
  },
  {
    locale: 'lbl.country.115',
    value: 'Dem. Rep. Kongo',
    key: '115',
  },
  {
    locale: 'lbl.country.240',
    value: 'Grenada',
    key: '240',
  },
  {
    locale: 'lbl.country.405',
    value: 'Spanien',
    key: '405',
  },
  {
    locale: 'lbl.country.512',
    value: 'Papua-Neuguinea',
    key: '512',
  },
  {
    locale: 'lbl.country.311',
    value: 'Kambodscha',
    key: '311',
  },
  {
    locale: 'lbl.country.162',
    value: 'Komoren',
    key: '162',
  },
  {
    locale: 'lbl.country.249',
    value: 'Dominica',
    key: '249',
  },
  {
    locale: 'lbl.country.134',
    value: 'Madagaskar',
    key: '134',
  },
  {
    locale: 'lbl.country.420',
    value: 'Schweiz',
    key: '420',
  },
  {
    locale: 'lbl.country.309',
    value: 'Saudi-Arabien',
    key: '309',
  },
  {
    locale: 'lbl.country.327',
    value: 'Malaysia',
    key: '327',
  },
  {
    locale: 'lbl.country.220',
    value: 'Guadeloupe',
    key: '220',
  },
  {
    locale: 'lbl.country.336',
    value: 'Macau',
    key: '336',
  },
  {
    locale: 'lbl.country.136',
    value: 'Mali',
    key: '136',
  },
  {
    locale: 'lbl.country.450',
    value: 'Moldawien',
    key: '450',
  },
  {
    locale: 'lbl.country.315',
    value: 'Südkorea',
    key: '315',
  },
  {
    locale: 'lbl.country.347',
    value: 'Bhutan',
    key: '347',
  },
  {
    locale: 'lbl.country.310',
    value: 'Myanmar',
    key: '310',
  },
  {
    locale: 'lbl.country.432',
    value: 'Albanien',
    key: '432',
  },
  {
    locale: 'lbl.country.436',
    value: 'Weissrussland',
    key: '436',
  },
  {
    locale: 'lbl.country.319',
    value: 'Irak',
    key: '319',
  },
  {
    locale: 'lbl.country.434',
    value: 'Färöer',
    key: '434',
  },
  {
    locale: 'lbl.country.142',
    value: 'Zentralafr. Rep',
    key: '142',
  },
  {
    locale: 'lbl.country.232',
    value: 'Panama',
    key: '232',
  },
  {
    locale: 'lbl.country.444',
    value: 'Lettland',
    key: '444',
  },
  {
    locale: 'lbl.country.326',
    value: 'Libanon',
    key: '326',
  },
  {
    locale: 'lbl.country.100',
    value: 'Afrika Allgem.',
    key: '100',
  },
  {
    locale: 'lbl.country.435',
    value: 'Slowakei',
    key: '435',
  },
  {
    locale: 'lbl.country.359',
    value: 'Palästina',
    key: '359',
  },
  {
    locale: 'lbl.country.163',
    value: 'Eritrea',
    key: '163',
  },
  {
    locale: 'lbl.country.226',
    value: 'Belize',
    key: '226',
  },
  {
    locale: 'lbl.country.437',
    value: 'Bosnien-Herz.',
    key: '437',
  },
  {
    locale: 'lbl.country.409',
    value: 'Zypern',
    key: '409',
  },
  {
    locale: 'lbl.country.247',
    value: 'Barbados',
    key: '247',
  },
  {
    locale: 'lbl.country.241',
    value: 'St. Vincent',
    key: '241',
  },
  {
    locale: 'lbl.country.513',
    value: 'Fr. Polynesien',
    key: '513',
  },
  {
    locale: 'lbl.country.149',
    value: 'Somalia',
    key: '149',
  },
  {
    locale: 'lbl.country.118',
    value: 'Äthiopien',
    key: '118',
  },
  {
    locale: 'lbl.country.525',
    value: 'Cookinseln',
    key: '525',
  },
  {
    locale: 'lbl.country.324',
    value: 'Kuwait',
    key: '324',
  },
  {
    locale: 'lbl.country.351',
    value: 'Georgien',
    key: '351',
  },
  {
    locale: 'lbl.country.403',
    value: 'Belgien',
    key: '403',
  },
  {
    locale: 'lbl.country.117',
    value: 'Benin',
    key: '117',
  },
  {
    locale: 'lbl.country.344',
    value: 'Ost Timor',
    key: '344',
  },
  {
    locale: 'lbl.country.119',
    value: 'Gabun',
    key: '119',
  },
  {
    locale: 'lbl.country.133',
    value: 'Libyen',
    key: '133',
  },
  {
    locale: 'lbl.country.251',
    value: 'St.Kitts &amp; Nevis',
    key: '251',
  },
  {
    locale: 'lbl.country.148',
    value: 'Dschibuti',
    key: '148',
  },
  {
    locale: 'lbl.country.407',
    value: 'Frankreich',
    key: '407',
  },
  {
    locale: 'lbl.country.500',
    value: 'Ozeanien',
    key: '500',
  },
  {
    locale: 'lbl.country.501',
    value: 'Australien',
    key: '501',
  },
  {
    locale: 'lbl.country.215',
    value: 'Costa Rica',
    key: '215',
  },
  {
    locale: 'lbl.country.360',
    value: 'Bahrein',
    key: '360',
  },
  {
    locale: 'lbl.country.210',
    value: 'Bahamas',
    key: '210',
  },
  {
    locale: 'lbl.country.246',
    value: 'Bermuda',
    key: '246',
  },
  {
    locale: 'lbl.country.221',
    value: 'Französ.Guayana',
    key: '221',
  },
  {
    locale: 'lbl.country.245',
    value: 'Falklandinseln',
    key: '245',
  },
  {
    locale: 'lbl.country.150',
    value: 'Sudan',
    key: '150',
  },
  {
    locale: 'lbl.country.441',
    value: 'Kroatien',
    key: '441',
  },
  {
    locale: 'lbl.country.402',
    value: 'Österreich',
    key: '402',
  },
  {
    locale: 'lbl.country.112',
    value: 'Burundi',
    key: '112',
  },
  {
    locale: 'lbl.country.328',
    value: 'Pakistan',
    key: '328',
  },
  {
    locale: 'lbl.country.120',
    value: 'Gambia',
    key: '120',
  },
  {
    locale: 'lbl.country.514',
    value: 'Fidschi',
    key: '514',
  },
  {
    locale: 'lbl.country.334',
    value: 'Vietnam',
    key: '334',
  },
  {
    locale: 'lbl.country.238',
    value: 'Uruguay',
    key: '238',
  },
  {
    locale: 'lbl.country.354',
    value: 'Kirgistan',
    key: '354',
  },
  {
    locale: 'lbl.country.130',
    value: 'Kenia',
    key: '130',
  },
  {
    locale: 'lbl.country.408',
    value: 'United Kingdom',
    key: '408',
  },
  {
    locale: 'lbl.country.125',
    value: 'Burkina-Faso',
    key: '125',
  },
  {
    locale: 'lbl.country.213',
    value: 'Chile',
    key: '213',
  },
  {
    locale: 'lbl.country.502',
    value: 'Neuseeland',
    key: '502',
  },
  {
    locale: 'lbl.country.433',
    value: 'Bulgarien',
    key: '433',
  },
  {
    locale: 'lbl.country.515',
    value: 'Tonga',
    key: '515',
  },
  {
    locale: 'lbl.country.144',
    value: 'Simbabwe',
    key: '144',
  },
  {
    locale: 'lbl.country.158',
    value: 'Uganda',
    key: '158',
  },
  {
    locale: 'lbl.country.356',
    value: 'Turkmenistan',
    key: '356',
  },
  {
    locale: 'lbl.country.155',
    value: 'Tschad',
    key: '155',
  },
  {
    locale: 'lbl.country.237',
    value: 'Trinidad Tobago',
    key: '237',
  },
  {
    locale: 'lbl.country.430',
    value: 'Tschechische Republik',
    key: '430',
  },
  {
    locale: 'lbl.country.157',
    value: 'Tunesien',
    key: '157',
  },
  {
    locale: 'lbl.country.333',
    value: 'Türkei',
    key: '333',
  },
  {
    locale: 'lbl.country.202',
    value: 'Kanada',
    key: '202',
  },
  {
    locale: 'lbl.country.154',
    value: 'Tansania',
    key: '154',
  },
  {
    locale: 'lbl.country.518',
    value: 'Kiribati',
    key: '518',
  },
  {
    locale: 'lbl.country.126',
    value: 'Kap Verde',
    key: '126',
  },
  {
    locale: 'lbl.country.332',
    value: 'Thailand',
    key: '332',
  },
  {
    locale: 'lbl.country.355',
    value: 'Tadschikistan',
    key: '355',
  },
  {
    locale: 'lbl.country.152',
    value: 'Namibia',
    key: '152',
  },
  {
    locale: 'lbl.country.313',
    value: 'China',
    key: '313',
  },
  {
    locale: 'lbl.country.156',
    value: 'Togo',
    key: '156',
  },
  {
    locale: 'lbl.country.422',
    value: 'Ungarn',
    key: '422',
  },
  {
    locale: 'lbl.country.520',
    value: 'Nauru',
    key: '520',
  },
  {
    locale: 'lbl.country.343',
    value: 'Katar',
    key: '343',
  },
  {
    locale: 'lbl.country.442',
    value: 'Ukraine',
    key: '442',
  },
  {
    locale: 'lbl.country.521',
    value: 'Kokosinseln',
    key: '521',
  },
  {
    locale: 'lbl.country.357',
    value: 'Usbekistan',
    key: '357',
  },
  {
    locale: 'lbl.country.214',
    value: 'Kolumbien',
    key: '214',
  },
  {
    locale: 'lbl.country.201',
    value: 'USA',
    key: '201',
  },
];
