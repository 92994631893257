import React, { ReactNode } from 'react';
import { Typography } from '@material-ui/core';

export interface EmptyMessageProps {
  title: string | ReactNode;
  children?: ReactNode;
}

export default (props: EmptyMessageProps) => {
  return (
    <div>
      <div>
        <Typography>{props.title}</Typography>
      </div>
      <div>{props.children}</div>
    </div>
  );
};
