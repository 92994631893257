import { AOMSessionToken } from '../sdk/com/apiomat/frontend';
import MUser from '../sdk/com/apiomat/frontend/missio/MUser';
import Datastore from '../sdk/com/apiomat/frontend/Datastore';
import { LoginObject } from '../store/auth';
import { User } from '../sdk/com/apiomat/frontend/basics';

type SubscriptionCallback = (credentials: unknown) => unknown;
type ErrorCallback = (err?: unknown) => unknown;

type Subscription = { next: SubscriptionCallback; err: ErrorCallback };

export type RefreshHandlerConfig<T> = {
  currentCredentials: () => T;
  validateCredentials: (credentials: T) => Promise<boolean> | boolean;
  refresh: (credentials: T) => Promise<T>;
  onSuccessfulRefresh: (credentials: T) => unknown;
  onFailedRefresh: () => unknown;
};

export class ConcurrentRefreshHandler<T> {
  /**
   * Indicates if there is currently an in flight refresh task
   */
  get isBusy() {
    return this._isBusy;
  }

  private _isBusy = false;

  private subs: Subscription[] = [];

  constructor(private config: RefreshHandlerConfig<T>) {}

  /**
   * Request refreshed credentials, using the strategy provided in the config.
   * Handler ensures that only exactly one concurrent refresh request is made.
   */
  request(): Promise<T> {
    if (!this.isBusy) {
      console.info('Started refresh');

      this._isBusy = true;
      setTimeout(() => this.refresh(), 0);
    }

    return this.createTokenPromise();
  }

  /**
   * Access the current or future refreshed credentials without triggering a new refresh.
   */
  read(): Promise<T> | T {
    if (!this.isBusy) {
      return this.config.currentCredentials();
    }

    return this.createTokenPromise();
  }

  private async refresh() {
    const credentials = this.config.currentCredentials();

    try {
      /* Test if credentials are really outdated, to reduce unnecessary traffic */
      const hasValidCredentials = await this.config.validateCredentials(credentials);
      if (hasValidCredentials) {
        return this.rejectAll('Credentials appear to be up to date. Skipping refresh...');
      }

      /* AuthN errors during the refresh request mean we have to initiate a logout, can be handled in 'onFailedRefresh' callback */
      const refreshedCredentials = await this.config.refresh(credentials);

      /* Callback to handle side effects like persisting the new creds */
      this.config.onSuccessfulRefresh(refreshedCredentials);

      console.info('Finished refresh. Resolving all promises.');
      return this.resolveAll(refreshedCredentials);
    } catch (err) {
      this.rejectAll(err);

      console.warn('Failed to refresh authentication. Logging out...');
      console.debug('Reason:', err);

      this.config.onFailedRefresh();
      return;
    }
  }

  private resolveAll(credentials: T) {
    this.notify(sub => sub.next(credentials));
    this.reset();
  }

  private rejectAll(err: unknown) {
    this.notify(sub => sub.err(err));
    this.reset();
  }

  private reset() {
    this.subs = [];
    this._isBusy = false;
  }

  private notify(cb: (sub: Subscription) => void) {
    this.subs.map(cb);
    console.debug(`${this.subs.length} subscribers notified...`);
  }

  private createTokenPromise<T>(): Promise<T> {
    return new Promise((res, rej) => {
      this.subs.push({ next: res, err: rej });
    });
  }
}

export const verifyCredentials = (user: User): Promise<boolean> => {
  return user
    .loadMe()
    .then(_ => true)
    .catch(err => err.statusCode !== 840 && err.statusCode !== 401);
};

export const refreshToken = async (token: AOMSessionToken): Promise<LoginObject> => {
  const user = new MUser();
  user.sessionToken = token.sessionToken;
  Datastore.configureWithSessionToken(token.sessionToken);

  const newToken = await user.requestSessionToken(true, token.refreshToken);
  user.sessionToken = newToken.sessionToken;
  await user.loadMe();

  return { user, token: newToken };
};
