import { Grid } from '@material-ui/core';
import React, { useEffect, useMemo } from 'react';
import { useMwiMeasuresStyles } from './Styles';
import { useTranslation } from 'react-i18next';
import RadioButtons from '../../../Buttons/RadioButtons';
import DialogButton from '../../../Dialogs/DialogButton';
import MwiLibrary from '../../../../sdk/com/apiomat/frontend/missio/MwiLibrary';
import DropDown from '../../../Selection/DropDown';
import { countries } from '../../../../value-maps/Countries';
import { alphabeticalSort } from '../../../../utils/sort.util';
import { DropDownItem } from '../../../Selection/DropDownItem';
import { useFormik } from 'formik';
import { object, date, string, number } from 'yup';
import { useOfferFormContext } from '../../context/OfferFormContext';
import FormDatePicker from '../../../DatePicker/FormDatePicker';
import FormTextField from '../../InputFields/FormTextField';

export interface LibraryProps {
  readOnlyMode: boolean;
  data: any;
  isReport?: boolean;
}

const Library = (props: LibraryProps) => {
  const { state, dispatch } = useOfferFormContext();
  const { readOnlyMode, data, isReport } = props;
  const classes = useMwiMeasuresStyles();
  const { t } = useTranslation();

  const {
    start,
    end,
    measureDescription,
    subject,
    nameOfOwner,
    nameAndAddressOfSeller,
    libraryName,
    location,
    country,
    foundingYear,
    numberOfBooksToPurchase,
    isScholarshipApplicationElsewhere,
    hasReadAdvertisingStatement,
    hasReadConditions,
  } = data.measure as MwiLibrary;

  const validationSchema = useMemo(
    () =>
      object({
        start: date().required(t('mandatory_field')),
        end: date().required(t('mandatory_field')),
        measureDescription: string().required(t('mandatory_field')),

        subject: isReport ? string() : string().required(t('mandatory_field')),
        nameOfOwner: isReport ? string() : string().required(t('mandatory_field')),
        nameAndAddressOfSeller: string().required(t('mandatory_field')),
        libraryName: isReport ? string() : string().required(t('mandatory_field')),
        location: string(),
        country: string(),
        // TODO number?
        foundingYear: isReport ? string() : string().required(t('mandatory_field')),
        // TODO number?
        numberOfBooksToPurchase: string().required(t('mandatory_field')),

        isScholarshipApplicationElsewhere: isReport ? string() : string().required(t('mandatory_field')),
        hasReadAdvertisingStatement: isReport ? number() : number().equals([1], t('mandatory_field')),
        hasReadConditions: isReport ? number() : number().equals([1], t('mandatory_field')),
      }),
    [t]
  );

  const formik = useFormik({
    initialValues: {
      start,
      end,
      measureDescription,
      subject,
      nameOfOwner,
      nameAndAddressOfSeller,
      libraryName,
      location,
      country,
      foundingYear,
      numberOfBooksToPurchase,
      isScholarshipApplicationElsewhere,
      hasReadAdvertisingStatement,
      hasReadConditions,
    },
    enableReinitialize: true,
    validationSchema,
    validateOnMount: true,
    onSubmit: () => {},
  });

  useEffect(() => {
    if (!readOnlyMode) {
      if (!formik.isValidating) {
        dispatch({ subFormId: 'measure', errors: formik.errors, values: formik.values });
      }
    }
  }, [formik.errors, formik.values, dispatch, formik.isValidating, readOnlyMode, state]);

  const countryItems: DropDownItem[] = useMemo(() => countries.map(el => ({ key: el.key, value: t(el.key) })).sort(alphabeticalSort), [t]);

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <FormDatePicker
            id="start"
            name="start"
            label={t('input:label:measure:planned-project-start')}
            value={formik.values.start}
            onChange={(date: Date) => formik.setFieldValue('start', date)}
            disabled={readOnlyMode}
            errorMessage={formik.errors.start}
          />
        </Grid>
        <Grid item xs={6}>
          <FormDatePicker
            id="end"
            name="end"
            label={t('input:label:measure:planned-project-end')}
            value={formik.values.end}
            minDate={formik.values.start}
            onChange={(date: Date) => formik.setFieldValue('end', date)}
            disabled={readOnlyMode}
            errorMessage={formik.errors.end}
          />
        </Grid>
        <Grid item xs={12}>
          <FormTextField
            id="measureDescription"
            name="measureDescription"
            disabled={readOnlyMode}
            labelKey="input:label:measure:measure-description"
            value={formik.values.measureDescription}
            multiline
            rows={3}
            onChange={formik.handleChange}
            errorMessage={formik.errors.measureDescription}
          />
        </Grid>
        {!isReport && (
          <>
            <Grid item xs={12}>
              <FormTextField
                id="subject"
                name="subject"
                disabled={readOnlyMode}
                labelKey="input:label:measure:mwi:library:subject"
                descriptionKey="info:measure:mwi:library:subject"
                value={formik.values.subject}
                onChange={formik.handleChange}
                errorMessage={formik.errors.subject}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                id="nameOfOwner"
                name="nameOfOwner"
                disabled={readOnlyMode}
                labelKey="input:label:measure:mwi:library:name-of-the-owner"
                descriptionKey="info:measure:mwi:library:name-of-the-owner"
                value={formik.values.nameOfOwner}
                onChange={formik.handleChange}
                errorMessage={formik.errors.nameOfOwner}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <FormTextField
            id="nameAndAddressOfSeller"
            name="nameAndAddressOfSeller"
            disabled={readOnlyMode}
            labelKey="input:label:measure:mwi:library:book-seller-info"
            descriptionKey="info:measure:mwi:library:book-seller-info"
            multiline
            rows={3}
            value={formik.values.nameAndAddressOfSeller}
            onChange={formik.handleChange}
            errorMessage={formik.errors.nameAndAddressOfSeller}
          />
        </Grid>
        {!isReport && (
          <>
            <Grid item xs={12}>
              <FormTextField
                id="libraryName"
                name="libraryName"
                disabled={readOnlyMode}
                labelKey="input:label:measure:mwi:library:name-of-library"
                value={formik.values.libraryName}
                onChange={formik.handleChange}
                errorMessage={formik.errors.libraryName}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                id="location"
                name="location"
                disabled={readOnlyMode}
                labelKey="input:label:measure:mwi:library:library-address"
                value={formik.values.location}
                onChange={formik.handleChange}
                errorMessage={formik.errors.location}
              />
            </Grid>
            <Grid item xs={12}>
              <DropDown
                labelKey="input:label:measure:mwi:library:library-country"
                disabled={readOnlyMode}
                selectedItemKey={formik.values.country}
                items={countryItems}
                onSelectedItemChange={(key: string) => formik.setFieldValue('country', key)}
                errorMessage={formik.errors.country}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                type="number"
                id="foundingYear"
                name="foundingYear"
                disabled={readOnlyMode}
                labelKey="input:label:measure:mwi:library:year-of-foundation"
                value={formik.values.foundingYear}
                onChange={formik.handleChange}
                errorMessage={formik.errors.foundingYear}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <FormTextField
            type="number"
            id="numberOfBooksToPurchase"
            name="numberOfBooksToPurchase"
            disabled={readOnlyMode}
            labelKey="input:label:measure:mwi:library:number-of-books-to-be-purchased"
            value={formik.values.numberOfBooksToPurchase}
            onChange={formik.handleChange}
            errorMessage={formik.errors.numberOfBooksToPurchase}
          />
        </Grid>
        {!isReport && (
          <>
            <div className={classes.questionContainer}>
              <div className={classes.questionScholarship}>
                <Grid item xs={3}>
                  <RadioButtons
                    title={t('question:label:measure:mwi:library:scholarship-application')}
                    value={formik.values.isScholarshipApplicationElsewhere}
                    disabled={readOnlyMode}
                    onSelectedValueChanged={(value: number) => formik.setFieldValue('isScholarshipApplicationElsewhere', value)}
                    errorMessage={formik.errors.isScholarshipApplicationElsewhere}
                  />
                </Grid>
              </div>
              <div className={classes.questionOne}>
                <Grid item xs={3}>
                  <RadioButtons
                    disabled={readOnlyMode}
                    title={t('question:label:mwi:advertisement')}
                    value={formik.values.hasReadAdvertisingStatement}
                    onSelectedValueChanged={(value: number) => formik.setFieldValue('hasReadAdvertisingStatement', value)}
                    errorMessage={formik.errors.hasReadAdvertisingStatement}
                  />
                </Grid>
                <DialogButton
                  className={classes.dialogButton}
                  textId={'button:label:mwi:measure:library:advertisement'}
                  title={'button:label:mwi:advertisement'}
                />
              </div>
              <div className={classes.questionTwo}>
                <Grid item xs={3}>
                  <RadioButtons
                    disabled={readOnlyMode}
                    title={t('question:label:mwi:conditions')}
                    value={formik.values.hasReadConditions}
                    onSelectedValueChanged={(value: number) => formik.setFieldValue('hasReadConditions', value)}
                    errorMessage={formik.errors.hasReadConditions}
                  />
                </Grid>
                <DialogButton
                  className={classes.dialogButton}
                  textId={'button:label:mwi:measure:library:conditions'}
                  title={'button:label:mwi:conditions'}
                />
              </div>
            </div>
          </>
        )}
      </Grid>
    </div>
  );
};

export default Library;
