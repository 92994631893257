import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Reports from '../sdk/com/apiomat/frontend/missio/Reports';
import { put, call, all, takeLatest, select } from 'redux-saga/effects';
import { deleteSharedUser, shareReport } from '../utils/share-report.utils';
import { notificationActions } from './notification';
import i18n from '../utils/i18n';
import { AppState } from './index';

interface ShareReportState {
  currentSharedReport: { report: Reports } | null;
  loadingSharedReport: 'idle' | 'pending' | 'succeeded' | 'failed' | 'loading-more';
}

const initialState: ShareReportState = {
  loadingSharedReport: 'idle',
  currentSharedReport: null,
};

const shareReportSlice = createSlice({
  name: 'shareTask',
  initialState,
  reducers: {
    shareReport: (state, _action: PayloadAction<Reports>) => {
      state.loadingSharedReport = 'pending';
    },
    shareReportSuccess: (state, _action: PayloadAction<Reports>) => {
      state.loadingSharedReport = 'succeeded';
      state.currentSharedReport = { report: _action.payload };
    },
    shareReportFailure: (state, _action: PayloadAction<string>) => {
      state.loadingSharedReport = 'failed';
    },
    deleteSharedUser: (state, _action: PayloadAction<string>) => {
      state.loadingSharedReport = 'pending';
    },
    deleteSharedUserSuccess: (state, _action: PayloadAction<Reports>) => {
      state.loadingSharedReport = 'succeeded';
      state.currentSharedReport = { report: _action.payload };
    },
    deleteSharedUserFailure: (state, _action: PayloadAction<string>) => {
      state.loadingSharedReport = 'failed';
    },
    updateCurrentSharedReport: (state, _action: PayloadAction<Reports>) => {
      state.currentSharedReport = { report: _action.payload };
    },
  },
});

export const shareReportActions = shareReportSlice.actions;
export const shareReportReducer = shareReportSlice.reducer;

function* onShareReport(action: PayloadAction<Reports>) {
  try {
    const sharedReport = yield call(() => shareReport(action.payload));
    yield put(notificationActions.showSuccessMessage(i18n.t('use-cases:new-assignment:root:saved-notification:title')));
    yield put(shareReportActions.shareReportSuccess(sharedReport));
  } catch (e) {
    yield put(notificationActions.showError(i18n.t('use-cases:new-assignment:root:saving-failed-notification:body')));
    yield put(shareReportActions.shareReportFailure());
  }
}

function* onDeleteSharedUser(action: PayloadAction<string>) {
  const report: Reports = yield select((state: AppState) => state.shareReport.currentSharedReport.report);
  try {
    const deletedReport = yield call(() => deleteSharedUser(action.payload, report));
    yield put(notificationActions.showSuccessMessage(i18n.t('use-cases:new-assignment:root:saved-notification:title')));
    yield put(shareReportActions.deleteSharedUserSuccess(deletedReport));
  } catch (e) {
    yield put(notificationActions.showError(i18n.t('use-cases:new-assignment:root:saving-failed-notification:body')));
    yield put(shareReportActions.deleteSharedUserFailure());
  }
}

export function* shareReportSaga() {
  yield all([takeLatest(shareReportActions.shareReport, onShareReport)]);
  yield all([takeLatest(shareReportActions.deleteSharedUser, onDeleteSharedUser)]);
}
