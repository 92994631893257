import React from 'react';
import { TextField, makeStyles, Grid, Button, InputAdornment } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  textField: {
    backgroundColor: '#fff',
    flex: 1,
    display: 'flex',
    paddingTop: 2,
    paddingLeft: 2,
    paddingRight: 2,
  },
}));

export interface PasswordGridProps {
  onPasswordChange: (password: string) => void;
  onPasswordVerificationChange: (passwordVerification: string) => void;
  onVisibilityButtonClick: () => void;
  password: string;
  passwordVerification: string;
  visibilityButton: React.ReactNode;
  isPasswordVisible: boolean;
}

const PasswordGrid = (props: PasswordGridProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    onPasswordChange,
    onPasswordVerificationChange,
    onVisibilityButtonClick,
    password,
    passwordVerification,
    visibilityButton,
    isPasswordVisible,
  } = props;

  return (
    <>
      <Grid item xs={6}>
        <TextField
          className={classes.textField}
          value={password}
          type={isPasswordVisible ? '' : 'password'}
          onChange={e => onPasswordChange(e.target.value)}
          placeholder={t('input:placeholder:password')}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          className={classes.textField}
          value={passwordVerification}
          type={isPasswordVisible ? '' : 'password'}
          onChange={e => onPasswordVerificationChange(e.target.value)}
          placeholder={t('input:placeholder:password-verification')}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <Button onClick={() => onVisibilityButtonClick()}>{visibilityButton}</Button>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </>
  );
};

export default PasswordGrid;
