import React from 'react';
import OpenAssignments from '../Assignment/OpenAssignments';
import ArchivedAssignments from '../Assignment/ArchivedAssignments';
import TabView, { TabItem } from '../../components/Tabs/TabView';

const AllAssignments = () => {
  const tabs: TabItem[] = [
    {
      key: 'open-assignments',
      label: 'tab:title:open-assignments',
      value: <OpenAssignments />,
    },
    {
      key: 'archived-assigments',
      label: 'tab:title:archived-assignments',
      value: <ArchivedAssignments />,
    }
  ];

  return <TabView tabs={tabs} />;
};

export default AllAssignments;
