import React from 'react';
import { Dialog, DialogContent, DialogTitle, makeStyles, Theme, Typography } from '@material-ui/core';
import { PrimaryButton } from '../Buttons/Buttons';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) => ({
  dialogTitle: {
    textAlign: 'center',
    backgroundColor: '#f5f5f5',
    borderBottom: '1px solid #dbdbdb',
  },
  buttonsContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
  },
  buttonContainer: {
    display: 'root-block',
    padding: 5,
    flex: 1,
    borderTop: '1px solid #dbdbdb',
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    padding: 5,
  },
  iconButton: {
    color: theme.palette.error.main,
  },
  iconButtonContained: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
}));

export interface SaveConfirmationDialogProps {
  isOpen: boolean;
  onSaveConfirmed: () => void;
  onCancelClick: () => void;
}


export default (props: SaveConfirmationDialogProps) => {
  const { isOpen, onCancelClick, onSaveConfirmed } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div>
      <Dialog open={isOpen} fullWidth maxWidth="xs">
        <DialogTitle className={classes.dialogTitle}>
          <Typography component="span" variant="h5">
            {t('button:label:save')}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">{t('cost-plan-save:confirmation-question')}</Typography>
        </DialogContent>
        <div className={classes.buttonsContainer}>
          <div className={classes.buttonContainer}>
            <PrimaryButton fullWidth className={classes.button} onClick={onCancelClick}>
              {t('button:label:cancel')}
            </PrimaryButton>
          </div>
          <div className={classes.buttonContainer}>
            <PrimaryButton fullWidth className={classes.button} onClick={onSaveConfirmed}>
              {t('button:label:save')}
            </PrimaryButton>
          </div>
        </div>
      </Dialog>
    </div>
  );
};
