import Reports from '../sdk/com/apiomat/frontend/missio/Reports';
import { reportAttachmentValidationConditions } from './ReportValidationConditions';
import { ValidationCondition, ValidationType } from './OfferValidation';
import { CostDeviations } from '../store/report';
import CostPlan from '../sdk/com/apiomat/frontend/missio/CostPlan';
import i18n from '../utils/i18n';
import { generalReportValidationConditions, reportMeasureValidationConditions } from './ReportValidationConditions';

export interface InvalidResult {
  readonly key: string;
  readonly discriminator: ValidationType;
}

export const validateReport = (report: Reports): InvalidResult[] => {
  const allConditions = reportMeasureValidationConditions(report).concat(
    generalReportValidationConditions(report),
    reportAttachmentValidationConditions(report)
  );
  return allConditions.filter(x => isValid(x) === false).map(x => ({ discriminator: x.discriminator, key: x.key }));
};

export const validateReportCostPlan = (costPlan: CostPlan, deviations: CostDeviations, DEVIATION_THRESHOLD: number): InvalidResult[] => {
  return validateDeviationReasons(costPlan, deviations, DEVIATION_THRESHOLD)
    .filter(x => isValid(x) === false)
    .map(x => ({ discriminator: x.discriminator, key: x.key }));
};

const validateDeviationReasons = (costPlan: CostPlan, deviations: CostDeviations, DEVIATION_THRESHOLD: number): ValidationCondition[] => {
  const validationConditions: ValidationCondition[] = [];

  if (deviations.projectActivities > DEVIATION_THRESHOLD) {
    validationConditions.push({
      discriminator: ValidationType.fieldNotEmpty,
      key: `${i18n.t('label:cost-plan:difference-reason')} ${i18n.t('cost-plan:cost-plan-category:project-activities')}`,
      value: costPlan.deviationReasonProjectActivities,
    });
  }
  if (deviations.investments > DEVIATION_THRESHOLD) {
    validationConditions.push({
      discriminator: ValidationType.fieldNotEmpty,
      key: `${i18n.t('label:cost-plan:difference-reason')} ${i18n.t('cost-plan:cost-plan-category:investment')}`,
      value: costPlan.deviationReasonInvestment,
    });
  }
  if (deviations.personnel > DEVIATION_THRESHOLD) {
    validationConditions.push({
      discriminator: ValidationType.fieldNotEmpty,
      key: `${i18n.t('label:cost-plan:difference-reason')} ${i18n.t('cost-plan:cost-plan-category:personnel')}`,
      value: costPlan.deviationReasonStaff,
    });
  }
  if (deviations.projectManagement > DEVIATION_THRESHOLD) {
    validationConditions.push({
      discriminator: ValidationType.fieldNotEmpty,
      key: `${i18n.t('label:cost-plan:difference-reason')} ${i18n.t('cost-plan:cost-plan-category:project-management')}`,
      value: costPlan.deviationReasonProjectManagement,
    });
  }
  return validationConditions;
};

const isValid = (validationCondition: ValidationCondition): boolean => {
  switch (validationCondition.discriminator) {
    case ValidationType.fieldNotEmpty:
      return !(validationCondition.value == null || validationCondition.value === '');
    case ValidationType.biggerThanZero:
      return !(validationCondition.value == null || validationCondition.value <= 0);
    case ValidationType.greaterThanOrEqualToZero:
      return !(validationCondition.value == null || validationCondition.value < 0);
    default:
      throw new Error();
  }
};
