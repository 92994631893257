import { GeneralValueMap } from './GeneralValueMap';

export const financialDecisions: GeneralValueMap[] = [
  {
    locale: 'lbl.null',
    value: 'finance-plan:finance-plan-decision:no-status',
    key: '0',
  },
  {
    locale: 'lbl.financialPlanDecisionApproved',
    value: 'finance-plan:finance-plan-decision:approved',
    key: '2',
  },
  {
    locale: 'lbl.financialPlanDecisionRejected',
    value: 'finance-plan:finance-plan-decision:declined',
    key: '3',
  },
  {
    locale: 'lbl.financialPlanDecisionSought',
    value: 'finance-plan:finance-plan-decision:requested',
    key: '1',
  },
];
